export const processTemplatesList = (list = []) => {
  const filteredObj: any = {
    TEXT: [],
    IMAGE: [],
    VIDEO: [],
    DOCUMENT: [],
    ALL: [],
  };
  const objType: any = {};

  list.forEach((item) => {
    const { name = '', components = [], category = '' } = item;
    const listItem: any = {
      name: name,
    };

    let isTextcomponent = true;
    let type = 'TEXT';
    components.forEach((component: any) => {
      listItem.category = category;
      if (component.type === 'BODY') {
        listItem.text = component.text;
      } else if (component.type !== 'BODY') {
        isTextcomponent = false;
        switch (component.format) {
          case 'VIDEO': {
            type = 'VIDEO';
            listItem.type = 'VIDEO';

            break;
          }
          case 'IMAGE': {
            type = 'IMAGE';
            listItem.type = 'IMAGE';

            break;
          }
          case 'DOCUMENT': {
            type = 'DOCUMENT';
            listItem.type = 'DOCUMENT';

            break;
          }
          default: {
            type = 'TEXT';
            listItem.type = 'TEXT';
            break;
          }
        }
      }
    });
    filteredObj[type].push(listItem);
    filteredObj['ALL'].push(listItem);
  });

  return filteredObj;
};
