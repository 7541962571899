import React, { lazy } from "react";
import * as Sentry from "@sentry/react";

const handleImport = async (appName, moduleName) => {
  try {
    const comp = await System.import(appName);
    return comp[moduleName];
  } catch(err){
    (err.response) ? Sentry.captureMessage("Error", err.response) : Sentry.captureMessage("Error", err);
    return () => {
      return null;
    };
  }
};
export const importModule = (appName, moduleName) =>
  lazy(() =>
    handleImport(appName, moduleName).then((comp) => ({
      default: comp,
    }))
  );
