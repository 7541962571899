import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { ConfigProvider, DatePicker, TimePicker } from "antd";
import type { DatePickerProps } from "antd/es/date-picker";
import locale from "antd/es/date-picker/locale/en_GB";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import {
  createSurvey,
  getUssdCodes,
  updateSurvey,
} from "../../../common/services/survey-services";
import {
  getMessageDetails,
  getSenderIds,
} from "../../../common/services/message-services";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import NotificationModal from "../../../modals/notification/NotificationModal";
import { getCurrenySymbol } from "../../../common/utils/currenySymbol";
import {
  getCustomerId,
  getBusinessName,
} from "../../../common/constants/billingConstants";
import { TenantContext } from "../survey-parent/survey-parent";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import * as Sentry from "@sentry/react";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import Dropdown from "../../../../../marketing-cloud-main/src/pages/settingsComponents/Engagements/whatsappTemplateTab/dropdown-component/dropdownComponent";
import {
  Airtel,
  ArrowRight,
  CalendarIcon,
  Chevron,
  EditSurvey,
  Etisalat,
  Glo,
  InfoCircle,
  Mtn,
  RewardAirtime,
  Telephone,
  UserOutline,
  Wifi,
} from "../../../assets/icons/icons";

const SurveyStepOne = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [showChannelMenu, setShowChannelMenu] = useState(false);
  const [showTypeMenu, setShowTypeMenu] = useState(false);
  const [showShortcodeMenu, setShowShortcodeMenu] = useState(false);
  const [showSenderIDMenu, setShowSenderIDMenu] = useState(false);
  const [showNetworkMenu, setShowNetworkMenu] = useState(false);
  const [showDataMenu, setShowDataMenu] = useState(false);
  const [showTriggerMenu, setShowTriggerMenu] = useState(false);
  const [showStartCalendar, setShowStartCalendar] = useState(false);
  const [showRewardStartCalendar, setShowRewardStartCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);
  const [showRewardEndCalendar, setShowRewardEndCalendar] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [rewardStartDate, setRewardStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rewardEndDate, setRewardEndDate] = useState(null);
  const [scheduleError, setScheduleError] = useState("");
  const [startTimeError, setStartTimeError] = useState("");
  const [senderIdError, setSenderIdError] = useState("");
  const [rewardStartTimeError, setRewardStartTimeError] = useState("");
  const [surveyNameError, setSurveyNameError] = useState("");
  const [rewardError, setRewardError] = useState("");
  const [shortCodes, setShortCodes] = useState([]);
  //const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [surveyExists, setSurveyExists] = useState(false);
  const [senderIDs, setSenderIDs] = useState([]);
  const [showTooltip, setShowTooltip] = useState(null);
  const [anonymousSurvey, setAnonymousSurvey] = useState(false);

  const channelMenu = useRef(null);
  const typeMenu = useRef(null);
  const shortcodeMenu = useRef(null);
  const senderIDMenu = useRef(null);
  const networkMenu = useRef(null);
  const dataMenu = useRef(null);
  const triggerMenu = useRef(null);
  const tooltip = useRef(null);

  let country = props.country;
  const currency = props.currency;
  const currencySymbol = getCurrenySymbol(country, currency);

  const tenantContext: any = useContext(TenantContext);

  useEffect(() => {
    setLoading(true);
    if (props.data.id) {
      setSurveyExists(true);
    }

    try {
      getUssdCodes()
        .then((res) => {
          if (!res.error) {
            const shortcodes = res?.response;
            setShortCodes(shortcodes);
          } else {
            Sentry.captureMessage("Error", res);
          }
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log(error);
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
    }
    setLoading(false);

    fetchSenderIDs();
  }, []);

  useEffect(() => {
    if (props.data.surveyStartDate && props.data.surveyStartTime) {
      setStartDate(
        new Date(props.data.surveyStartDate + "T" + props.data.surveyStartTime)
      );
    }
    if (props.data.surveyEndDate && props.data.surveyEndTime) {
      setEndDate(
        new Date(props.data.surveyEndDate + "T" + props.data.surveyEndTime)
      );
    }
    if (props.data.rewardStartDate && props.data.rewardStartTime) {
      setRewardStartDate(
        new Date(props.data.rewardStartDate + "T" + props.data.rewardStartTime)
      );
    }
    if (props.data.rewardEndDate && props.data.rewardEndTime) {
      setRewardEndDate(
        new Date(props.data.rewardEndDate + "T" + props.data.rewardEndTime)
      );
    }

    if (props.data?.invitationMessageDetails?.senderName === "DEFAULT") {
      setAnonymousSurvey(true);
    }
  }, [props.data]);

  useEffect(() => {
    if (anonymousSurvey) {
      dispatch({
        type: "CHANGE",
        payload: {
          name: "senderId",
          value: "",
        },
      });
    }
  }, [anonymousSurvey]);

  dayjs.extend(customParseFormat);
  dayjs.extend(localizedFormat);

  const fetchSenderIDs = () => {
    try {
      getSenderIds()
        .then((res) => {
          const { error, response = {} } = res;
          if (error === false) {
            setSenderIDs(response);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
            Notify({
              type: "error",
              heading: "Failed to retrieve sender IDs.",
              message: error,
            });
            setSenderIDs([]);
          }
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log(error);
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
    }
  };

  const senderIdOptions = senderIDs.map((el) => {
    return { value: el.name, label: <div>{el.name}</div> };
  });

  const onStartTimeChange = (time: Dayjs, timeString: string) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "surveyStartTime",
        value: timeString.split(" ")[0] + ":00",
      },
    });
  };

  const onRewardStartTimeChange = (time: Dayjs, timeString: string) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "rewardStartTime",
        value: timeString.split(" ")[0] + ":00",
      },
    });
  };

  const onStartDateChange = (
    value: DatePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "surveyStartDate",
        value: dateString,
      },
    });
  };

  const onRewardStartDateChange = (
    value: DatePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "rewardStartDate",
        value: dateString,
      },
    });
  };

  const onEndTimeChange = (time: Dayjs, timeString: string) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "surveyEndTime",
        value: timeString.split(" ")[0] + ":00",
      },
    });
  };

  const onRewardEndTimeChange = (time: Dayjs, timeString: string) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "rewardEndTime",
        value: timeString.split(" ")[0] + ":00",
      },
    });
  };

  const onEndDateChange = (
    value: DatePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "surveyEndDate",
        value: dateString,
      },
    });
  };

  const onRewardEndDateChange = (
    value: DatePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "rewardEndDate",
        value: dateString,
      },
    });
  };

  const mobileNetworks = [
    { name: "MTN", value: "mtn", icon: <img src={Mtn} alt="" /> },
    { name: "Airtel", value: "airtel", icon: <img src={Airtel} alt="" /> },
    { name: "Glo", value: "glo", icon: <img src={Glo} alt="" /> },
    { name: "9mobile", value: "9mobile", icon: <img src={Etisalat} alt="" /> },
  ];

  const closeOpenMenus = (e) => {
    if (
      channelMenu.current &&
      showChannelMenu &&
      !channelMenu.current.contains(e.target)
    ) {
      setShowChannelMenu(false);
    }

    if (
      shortcodeMenu.current &&
      showShortcodeMenu &&
      !shortcodeMenu.current.contains(e.target)
    ) {
      setShowShortcodeMenu(false);
    }

    if (
      typeMenu.current &&
      showTypeMenu &&
      !typeMenu.current.contains(e.target)
    ) {
      setShowTypeMenu(false);
    }

    if (
      senderIDMenu.current &&
      showSenderIDMenu &&
      !senderIDMenu.current.contains(e.target)
    ) {
      setShowSenderIDMenu(false);
    }

    if (
      networkMenu.current &&
      showNetworkMenu &&
      !networkMenu.current.contains(e.target)
    ) {
      setShowNetworkMenu(false);
    }

    if (
      dataMenu.current &&
      showDataMenu &&
      !dataMenu.current.contains(e.target)
    ) {
      setShowDataMenu(false);
    }

    if (
      triggerMenu.current &&
      showTriggerMenu &&
      !triggerMenu.current.contains(e.target)
    ) {
      setShowTriggerMenu(false);
    }

    if (tooltip.current && showTooltip && !tooltip.current.contains(e.target)) {
      setShowTooltip(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  const handleNotificationClose = () => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      show: false,
    }));
  };

  const [notificationData, setNotificationData] = useState({
    show: false,
    close: handleNotificationClose,
    type: "",
    message: "",
    heading: "",
  });

  const handleNotificationData = (data: any) => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      ...data,
    }));
    setTimeout(() => {
      handleNotificationClose();
    }, 4000);
  };

  const formInitValue = {
    surveyName: props.data.name || "",
    senderId: props.data.invitationMessageDetails?.senderName || "",
    surveyChannel: props.data.channel || "",
    shortcode: props.data.shortCode || "",
    surveyType: props.data.surveyAudienceType || "",
    surveyStartDate: props.data.surveyStartDate || "",
    surveyStartTime: props.data.surveyStartTime || "",
    surveyEndDate: props.data.surveyEndDate || "",
    surveyEndTime: props.data.surveyEndTime || "",
    rewardStartDate: props.data.rewardStartDate || "",
    rewardStartTime: props.data.rewardStartTime || "",
    rewardEndDate: props.data.rewardEndDate || "",
    rewardEndTime: props.data.rewardEndTime || "",
    senderID: props.data.senderID || "",
    message: props.data.message || "",
    rewardOption: props.data.rewardOption === true ? true : false,
    rewardType: props.data.rewardType || "AIRTIME",
    rewardBudget: props.data.rewardInfo?.rewardBudget || "",
    rewardPerProfile: props.data.rewardInfo?.rewardPerProfile || "",
    rewardOperator: props.data.rewardOperator || "",
    rewardData: props.data.rewardData || "",
    rewardTrigger: props.data.rewardTrigger || "",
    objective: props.data.objective || "",
    multipleSubmissions:
      props.data.multipleSubmissions === false ? false : true,
    sendFollowUpMessage:
      props.data?.sendFollowUpMessage === true ? true : false,
    errors: {
      surveyName: false,
      surveyChannel: false,
      shortcode: false,
      surveyType: false,
      surveyStartDate: false,
      surveyStartTime: false,
      surveyEndDate: false,
      surveyEndTime: false,
      rewardStartDate: false,
      rewardStartTime: false,
      rewardEndDate: false,
      rewardEndTime: false,
      senderID: false,
      message: false,
      objective: false,
      rewardBudget: false,
      rewardPerProfile: false,
      rewardOperator: false,
      rewardData: false,
      rewardTrigger: false,
    },
  };

  useEffect(() => {
    if (props.data.id) {
      let v = formInitValue;
      if (props.data.messageCampaignId)
        getMessageDetails(props.data.messageCampaignId)
          .then((response) => {
            if (response.error) {
              dispatch({ type: "reinitialize", value: formInitValue });
            } else {
              v.message = response.response.content.text
                ? response.response.content.text
                : "";
              dispatch({ type: "reinitialize", value: v });
            }
          })
          .catch((err) => {
            dispatch({ type: "reinitialize", value: formInitValue });
          });
      setSurveyExists(true);
    } else {
      dispatch({ type: "reinitialize", value: formInitValue });
    }
  }, [props.data]);

  const triggerCode = (trigger) => {
    switch (trigger) {
      case "Took part in the survey":
        return "ATTEMPTED";
      case "Answered all questions":
        return "COMPLETED";
      case "Participated within a custom time range":
        return "TIMELINE";
      default:
        return "";
    }
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "CHANGE":
        return {
          ...state,
          [action.payload.name]: action.payload.value,
          errors: {
            ...state.errors,
            [action.payload.name]: "",
          },
        };
      case "SUBMIT":
        const errors: any = {};
        if (!state.surveyName.trim()) {
          errors.surveyName = true;
        }
        if (!state.surveyChannel) {
          errors.surveyChannel = true;
        }
        if (state.surveyChannel === "USSD" && !state.shortcode) {
          errors.shortcode = true;
        }
        if (!state.surveyType) {
          errors.surveyType = true;
        }
        if (!state.senderID) {
          errors.senderID = true;
        }
        if (!state.message.trim()) {
          errors.message = true;
        }
        if (!state.objective.trim()) {
          errors.objective = true;
        }
        if (state.rewardOption && !state.rewardType) {
          errors.rewardType = true;
        }
        if (state.rewardOption && state.rewardType === "AIRTIME") {
          if (!state.rewardBudget) {
            errors.rewardBudget = true;
          }
          if (!state.rewardPerProfile) {
            errors.rewardPerProfile = true;
          }
        }
        if (state.rewardOption && state.rewardType === "DATA") {
          if (!state.rewardOperator) {
            errors.rewardOperator = true;
          }
          if (!state.rewardData) {
            errors.rewardData = true;
          }
          if (!state.rewardBudget) {
            errors.rewardBudget = true;
          }
        }
        if (!state.rewardTrigger) {
          errors.rewardTrigger = true;
        }
        if (!startDate) {
          errors.surveyStartDate = true;
        }
        if (!endDate) {
          errors.surveyEndDate = true;
        }
        return {
          ...state,
          errors: {
            ...errors,
          },
        };

      case "reinitialize":
        return { ...state, ...action.value };
      default:
        return state;
    }
  };

  const [formState, dispatch] = useReducer(reducer, formInitValue);

  const handleFormChange = (e) => {
    let { name, value, checked } = e.target;

    if (name === "surveyName") {
      if (surveyNameError) {
        setSurveyNameError("");
      }
      value = value.replace(/[^a-z0-9 ]/gi, "");
    }

    if (name === "rewardBudget" || name === "rewardPerProfile") {
      value = value.replace(/[^0-9]/gi, "");
    }

    dispatch({
      type: "CHANGE",
      payload: {
        name,
        value: name === "multipleSubmissions" ? !checked : value,
      },
    });
  };

  const onStartDateSave = () => {
    if (!formState.surveyStartDate) {
      formState.errors.surveyStartDate = true;
      return;
    }
    if (!formState.surveyStartTime) {
      formState.errors.surveyStartTime = true;
      return;
    }
    const startDate = new Date(
      formState.surveyStartDate + "T" + formState.surveyStartTime
    );
    if (startDate.getTime() < new Date().getTime()) {
      formState.errors.surveyStartTime = true;
      setStartTimeError("Survey start time has already passed");
      return;
    } else if (
      startDate &&
      endDate &&
      endDate.getTime() < startDate.getTime()
    ) {
      formState.errors.surveyStartTime = true;
      setStartTimeError("Survey start time must be less than end time");
      return;
    } else {
      setStartTimeError("");
    }
    setStartDate(startDate);
    setShowStartCalendar(false);
  };

  const onEndDateSave = () => {
    if (!formState.surveyEndDate) {
      formState.errors.surveyEndDate = true;
      return;
    }
    if (!formState.surveyEndTime) {
      formState.errors.surveyEndTime = true;
      return;
    }
    const endDate = new Date(
      formState.surveyEndDate + "T" + formState.surveyEndTime
    );
    if (endDate.getTime() < startDate.getTime()) {
      formState.errors.surveyEndTime = true;
      setScheduleError("Survey end time must be greater than start time");
      return;
    } else {
      setScheduleError("");
    }
    setEndDate(endDate);
    setShowEndCalendar(false);
  };

  const onRewardStartDateSave = () => {
    if (!formState.rewardStartDate) {
      formState.errors.rewardStartDate = true;
      return;
    }
    if (!formState.rewardStartTime) {
      formState.errors.rewardStartTime = true;
      return;
    }
    const startDate = new Date(
      formState.rewardStartDate + "T" + formState.rewardStartTime
    );
    if (startDate.getTime() < new Date().getTime()) {
      formState.errors.rewardStartTime = true;
      setRewardStartTimeError("Reward start time has already passed");
      return;
    } else {
      setRewardStartTimeError("");
    }
    setRewardStartDate(startDate);
    setShowRewardStartCalendar(false);
  };

  const onRewardEndDateSave = () => {
    if (!formState.rewardEndDate) {
      formState.errors.rewardEndDate = true;
      return;
    }
    if (!formState.rewardEndTime) {
      formState.errors.rewardEndTime = true;
      return;
    }
    const endDate = new Date(
      formState.rewardEndDate + "T" + formState.rewardEndTime
    );
    if (endDate.getTime() < rewardStartDate.getTime()) {
      formState.errors.rewardEndTime = true;
      setRewardError("Reward end time must be greater than reward start time");
      return;
    } else {
      setRewardError("");
    }
    setRewardEndDate(endDate);
    setShowRewardEndCalendar(false);
  };

  const validateForm = () => {
    const {
      surveyName,
      surveyChannel,
      shortcode,
      message,
      senderID,
      objective,
      rewardOption,
      rewardType,
      rewardBudget,
      rewardPerProfile,
      rewardOperator,
      rewardData,
      rewardTrigger,
      sendFollowUpMessage,
      senderId,
    } = formState;

    if (rewardOption && rewardType === "AIRTIME") {
      if (!rewardBudget || !rewardPerProfile || !rewardTrigger) {
        return false;
      }
    } else if (rewardOption && rewardType === "DATA") {
      if (!rewardBudget || !rewardOperator || !rewardData || !rewardTrigger) {
        return false;
      }
    }

    if (surveyChannel === "USSD" && !shortcode) {
      return false;
    }

    if (
      !anonymousSurvey &&
      props?.data?.audienceType !== "general" &&
      !senderId
    ) {
      setSenderIdError("Please select a sender ID");
      return false;
    }

    if (sendFollowUpMessage && !(senderID && message)) {
      return false;
    }

    if (!surveyName || !surveyChannel || !objective || !startDate || !endDate) {
      return false;
    }

    if (endDate.getTime() < startDate.getTime()) {
      formState.errors.surveyEndTime = true;
      setScheduleError("Survey end time must be greater than start time");
      return false;
    }

    return true;
  };

  const disabledDates = (current) => {
    return current && current < dayjs(startDate).subtract(1, "day");
  };

  const getSurveyData = () => {
    const data = {
      name: formState.surveyName,
      objective: formState.objective,
      channel: formState.surveyChannel,
      multipleSubmissions: formState.multipleSubmissions,
      rewardOption: formState.rewardOption,
      sendFollowUpMessage: formState.sendFollowUpMessage,
      senderName: formState.senderID,
      message: formState.message,
      invitationMessageDetails: {
        senderName: anonymousSurvey ? "DEFAULT" : formState.senderId,
        channel: "SMS",
      },
      schedule: {
        immediate: false,
        timeline: {
          start: startDate.getTime(),
          end: endDate.getTime(),
        },
      },
      shortCode: formState.shortcode,
      businessId: getCustomerId(),
      businessName: getBusinessName(),
      status: "DRAFT",
      surveyInvite: false,
    };

    if (data.rewardOption) {
      if (formState.rewardType === "DATA") {
        const rewardDetails = {
          rewardType: formState.rewardType,
          dataRewardDetails: [
            {
              operator: formState.rewardOperator.toLowerCase(),
              value: formState.rewardData.split(" ")[0],
              amount: Number(
                formState.rewardData.split(currencySymbol)[1].replace(",", "")
              ),
            },
          ],
        };
        data["rewardDetails"] = rewardDetails;
        data["rewardInfo"] = {
          rewardDetails,
          budgetEstimate: Number(formState.rewardBudget),
        };
      } else if (formState.rewardType === "AIRTIME") {
        const rewardDetails = {
          rewardType: formState.rewardType,
          airtimeRewardDetails: [
            {
              amount: Number(formState.rewardPerProfile),
              currency: currencySymbol === "₦" ? "NGN" : "KSh",
            },
          ],
        };
        data["rewardDetails"] = rewardDetails;
        data["rewardInfo"] = {
          rewardDetails,
          budgetEstimate: Number(formState.rewardBudget),
          rewardBudget: Number(formState.rewardBudget),
          rewardPerProfile: Number(formState.rewardPerProfile),
          rewardTrigger: {
            trigger: triggerCode(formState.rewardTrigger),
            schedule: {
              start: rewardStartDate ? rewardStartDate.getTime() : "",
              end: rewardEndDate ? rewardEndDate.getTime() : "",
            },
          },
        };
      }
      data["rewardBudgetDetails"] = {
        totalBudget: Number(formState.rewardBudget),
      };

      if (formState.sendFollowUpMessage) {
        data["followUpMessageDetails"] = {
          channel: "SMS",
          content: {
            type: "TEXT",
            text: formState.message.trim(),
          },
          senderName: formState.senderID,
        };
      }
    }

    return data;
  };

  const setSurveyData = (data) => {
    const surveyData = {
      ...props,
      data: {
        ...props.data,
        ...data,
        surveyAudienceType: formState.surveyType,
        sendFollowUpMessage: formState.sendFollowUpMessage,
        message: formState.message,
        senderID: formState.senderID,
        rewardType: formState.rewardType,
        rewardBudget: formState.rewardBudget,
        rewardPerProfile: formState.rewardPerProfile,
        rewardOperator: formState.rewardOperator,
        rewardData: formState.rewardData,
        rewardTrigger: formState.rewardTrigger,
        surveyStartDate: formState.surveyStartDate,
        surveyStartTime: formState.surveyStartTime,
        surveyEndDate: formState.surveyEndDate,
        surveyEndTime: formState.surveyEndTime,
        rewardStartDate: formState.rewardStartDate,
        rewardStartTime: formState.rewardStartTime,
        rewardEndDate: formState.rewardEndDate,
        rewardEndTime: formState.surveyEndTime,
      },
    };

    return surveyData;
  };

  const saveSurveyAsDraft = () => {
    handleNotificationData({
      show: true,
      type: "success",
      heading: "Survey saved.",
      message: "Your survey has been saved as draft.",
    });

    setTimeout(() => {
      props.navigate("/engagements/surveys");
    }, 4000);
  };

  const handleSubmit = (e, saveAsDraft) => {
    setLoading(true);
    e.preventDefault();

    const updatedState = reducer(formState, { type: "SUBMIT" });
    dispatch({ type: "SUBMIT", payload: updatedState });

    if (!validateForm()) {
      setLoading(false);
      return;
    }
    const data = getSurveyData();
    const surveyData = setSurveyData(data);

    setLoading(true);
    if (!surveyExists) {
      try {
        createSurvey(surveyData.data)
          .then((res) => {
            setLoading(false);
            if (!res.error) {
              if (saveAsDraft) {
                saveSurveyAsDraft();
              } else {
                surveyData.data["id"] = res.response;
                props.setData(surveyData.data);
                props.setStep(3);
              }
            } else {
              Sentry.captureMessage("Error", res);
            }
          })
          .catch((err) => {
            setLoading(false);
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            console.log("error", err);
            if (
              err?.response?.data?.response ===
              "Survey name already exists, please provide a different name"
            ) {
              setSurveyNameError(err.response.data.response);
            } else if (
              err?.response?.data?.response ===
              "Required parameters missing - reward budget detail is needed"
            ) {
              setRewardError("Reward budget detail is needed");
            }
          });
      } catch (err) {
        setLoading(false);
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
      }
    } else {
      try {
        updateSurvey(
          surveyData.data,
          props.data.surveyId?.replaceAll("/", "%2F") ||
            props.data.id?.replaceAll("/", "%2F")
        )
          .then((res) => {
            setLoading(false);
            if (!res.error) {
              if (saveAsDraft) {
                saveSurveyAsDraft();
              } else {
                const surveyData = setSurveyData(data);
                props.setData(surveyData.data);
                props.setStep(3);
              }
            } else {
              Sentry.captureMessage("Error", res);
              console.log(res.error);
            }
          })
          .catch((err) => {
            setLoading(false);
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            console.log("error", err);
            if (
              err?.response?.data?.response ===
              "Survey name already exists, please provide a different name"
            ) {
              setSurveyNameError(err.response.data.response);
            }
          });
      } catch (error) {
        setLoading(false);
        error.response
          ? Sentry.captureMessage("Error", error.response)
          : Sentry.captureMessage("Error", error);
        console.log(error);
      }
    }
  };

  return (
    <div className="py-12 px-12">
      <div className="text-gray-500 font-semibold text-sm">STEP 2 OF 4</div>
      <div className="flex justify-between items-center w-full">
        <div className="flex-wrap justify-start items-start">
          <div className="flex rounded-xl py-4">
            <div className="flex gap-3">
              <div className="flex justify-center items-center flex-shrink-0 w-16 bg-gray-100 rounded-full h-16">
                <img src={EditSurvey} alt="" />
              </div>
              <div className="text-left py-2">
                <div className="text-gray-70 font-semibold text-base">
                  Survey details
                </div>
                <div className="text-gray-500 mt-1 font-normal text-sm whitespace-no-wrap">
                  Bring your survey to life. Fill in your survey details below.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <button
            className="py-3 px-6 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
            //onClick={() => props.navigate("/engagements/surveys")}
            onClick={() => props.setStep(1)}
            disabled={loading}
          >
            Back
          </button>
          <button
            onClick={(e) => handleSubmit(e, false)}
            disabled={loading}
            className="py-3 px-5 w-24 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75 disabled:bg-blue-75 flex justify-center items-center"
          >
            {loading ? (
              <Spinner className={"w-4 h-4"} />
            ) : (
              <div className="flex gap-3 items-center">
                {"Next  "} <img src={ArrowRight} alt="" />
              </div>
            )}
          </button>
        </div>
      </div>

      <div className="py-4 w-[694px]">
        <div className="flex justify-between gap-6">
          <div className="py-3 w-1/2">
            <div className="text-gray-70 font-semibold text-sm mb-1">
              Survey name
            </div>
            <input
              type="text"
              name="surveyName"
              placeholder="Enter the name of your survey"
              value={formState.surveyName}
              maxLength={50}
              className={`w-full h-12 px-3 py-3 mt-1 border border-gray-300 rounded-md focus:outline-none ${
                formState.errors.surveyName && "border-red-500"
              }`}
              onChange={handleFormChange}
              disabled={loading}
            />
            {surveyNameError && (
              <div className="text-red-500 text-sm mt-2">{surveyNameError}</div>
            )}
            {!surveyNameError && (
              <div
                className={`text-gray-70 font-normal text-sm mt-2 ${
                  formState.surveyName.length === 50 && "text-red-500"
                }`}
              >
                {formState.surveyName.length + "/50 characters"}
              </div>
            )}
          </div>
          <div className="py-3 w-1/2">
            <div className="text-gray-70 font-semibold text-sm mb-1.5">
              Channel
            </div>
            <div className="relative w-full">
              <button
                name="surveyChannel"
                className={`flex w-[340px] justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-300 rounded-md focus:outline-none ${
                  formState.surveyChannel === "" && "text-gray-400"
                } ${formState.errors.surveyChannel && "border-red-500"}`}
                type="button"
                onClick={() => setShowChannelMenu(!showChannelMenu)}
                disabled={loading}
              >
                {formState.surveyChannel || "Select survey channel"}
                <img src={Chevron} alt="" />
              </button>

              {/* Channel Dropdown menu */}
              {showChannelMenu && (
                <div
                  ref={channelMenu}
                  className="absolute z-10 bg-white rounded-lg shadow w-[340px]"
                >
                  {["WEB", "USSD"].map((channel, index) => (
                    <button
                      key={index}
                      className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                      role="menuitem"
                      onClick={() => {
                        dispatch({
                          type: "CHANGE",
                          payload: {
                            name: "surveyChannel",
                            value: channel,
                          },
                        });
                        setShowChannelMenu(false);
                      }}
                    >
                      {channel}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          {formState.surveyChannel === "USSD" && (
            <div className="py-3">
              <div className="text-gray-70 font-semibold text-sm mb-1">
                Short code
              </div>
              <div className="relative w-full">
                <button
                  className={`flex w-[340px] justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-300 rounded-md focus:outline-none ${
                    formState.shortcode === "" && "text-gray-400"
                  } ${formState.errors.shortcode && "border-red-500"}`}
                  type="button"
                  onClick={() => setShowShortcodeMenu(!showShortcodeMenu)}
                  disabled={loading}
                >
                  {formState.shortcode || "Select short code"}
                  <img src={Chevron} alt="" />
                </button>

                {/* Shortcode Dropdown menu */}
                {showShortcodeMenu && (
                  <div
                    ref={shortcodeMenu}
                    className="absolute z-10 bg-white rounded-lg shadow w-[340px]"
                  >
                    {shortCodes.map((shortcode, index) => (
                      <button
                        key={index}
                        className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                        role="menuitem"
                        onClick={() => {
                          dispatch({
                            type: "CHANGE",
                            payload: {
                              name: "shortcode",
                              value: shortcode,
                            },
                          });
                          setShowShortcodeMenu(false);
                        }}
                      >
                        {shortcode}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="py-3">
          <div className="text-gray-70 font-semibold text-sm mb-1">
            Survey objective
          </div>
          <textarea
            name="objective"
            placeholder="Enter your objective for creating the survey"
            value={formState.objective}
            maxLength={160}
            className={`w-full h-16 px-3 py-3 mt-1 border border-gray-300 rounded-md focus:outline-none ${
              formState.errors.objective && "border-red-500"
            }`}
            onChange={handleFormChange}
            disabled={loading}
          />
        </div>
      </div>
      {props?.data?.audienceType !== "general" && (
        <div>
          <div className="text-gray-70 font-semibold text-sm mb-1">
            Sender ID
          </div>
          <div className="py-3 flex justify-start">
            <Dropdown
              options={senderIdOptions}
              placeholder={"Select Sender ID"}
              value={formState.senderId === "DEFAULT" ? "" : formState.senderId}
              disabled={anonymousSurvey}
              handleChange={(value) => {
                dispatch({
                  type: "CHANGE",
                  payload: {
                    name: "senderId",
                    value: value,
                  },
                });
              }}
              className={`w-[320px] px-3 py-3 border rounded-lg ${
                senderIdError && "border-red-500"
              }`}
            />
          </div>
          {senderIdError && (
            <div className="text-red-500 text-sm mt-2">{senderIdError}</div>
          )}
        </div>
      )}

      <div
        onClick={() => setAnonymousSurvey(!anonymousSurvey)}
        className="flex w-fit items-center gap-2 my-4 cursor-pointer"
      >
        <input
          type="checkbox"
          checked={anonymousSurvey}
          className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded-lg cursor-pointer"
        />
        <span className="text-sm font-medium">
          Send this as an anonymous survey
        </span>
      </div>

      <div className="mb-4">
        <div className="py-3 w-56">
          <label
            className={`relative flex items-center mb-2 ${
              !props.data?.subscribedPlan ||
              props.data?.subscribedPlan === "Starter"
                ? "cursor-not-allowed"
                : "cursor-pointer"
            } group`}
          >
            <input
              type="checkbox"
              checked={formState.rewardOption === true}
              className="sr-only peer"
              disabled={
                loading ||
                !props.data?.subscribedPlan ||
                props.data?.subscribedPlan === "Starter"
              }
              onClick={() => {
                dispatch({
                  type: "CHANGE",
                  payload: {
                    name: "rewardOption",
                    value: !formState.rewardOption,
                  },
                });
              }}
            />
            <div
              className={`w-11 h-6 ${
                formState.rewardOption ? "bg-gray-200" : "bg-gray-300"
              } rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-75`}
            ></div>
            <span className="ml-3 text-sm font-semibold text-gray-70">
              Add reward to survey
            </span>

            {(!props.data?.subscribedPlan ||
              props.data?.subscribedPlan === "Starter") && (
              <div className="absolute hidden group-hover:flex">
                <div className="w-[196px] p-3 z-10 -mt-14 mb-4 bg-gray-900 text-white text-xs text-center leading-6 rounded-lg shadow-lg">
                  <p>Upgrade plan to include rewards.</p>
                </div>
              </div>
            )}
          </label>
        </div>

        {formState.rewardOption && (
          <div className="flex items-center justify-center border border-gray-200 rounded-lg w-[694px]">
            <div className="pt-6">
              <div className="text-gray-70 font-semibold text-sm mb-2">
                Reward type
              </div>
              <div className="w-full flex gap-4">
                <div
                  className={`w-[314px] h-12 flex justify-between items-center px-6 border rounded-md dark:border-gray-700 cursor-pointer ${
                    formState.rewardType === "AIRTIME"
                      ? "border-blue-75"
                      : "border-gray-300"
                  }`}
                  onClick={() => {
                    dispatch({
                      type: "CHANGE",
                      payload: {
                        name: "rewardType",
                        value: "AIRTIME",
                      },
                    });
                    dispatch({
                      type: "CHANGE",
                      payload: {
                        name: "rewardBudget",
                        value: "",
                      },
                    });
                  }}
                >
                  <div className="flex items-center">
                    <img className="-mt-1" src={RewardAirtime} alt="" />
                    <label
                      htmlFor="airtime-radio"
                      className="w-full py-4 ml-3 font-medium text-gray-900 dark:text-gray-300"
                    >
                      Airtime
                    </label>
                  </div>
                  <input
                    name="airtime-radio"
                    id="airtime-radio"
                    type="radio"
                    value=""
                    disabled={loading}
                    checked={formState.rewardType === "AIRTIME"}
                    onChange={() => {
                      dispatch({
                        type: "CHANGE",
                        payload: {
                          name: "rewardType",
                          value: "AIRTIME",
                        },
                      });
                      dispatch({
                        type: "CHANGE",
                        payload: {
                          name: "rewardBudget",
                          value: "",
                        },
                      });
                    }}
                    className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 cursor-pointer"
                  />
                </div>

                {/* <div
                  className={`w-[314px] h-12 flex justify-between items-center px-6 border rounded-md dark:border-gray-700 cursor-pointer ${
                    formState.rewardType === "DATA"
                      ? "border-blue-75"
                      : "border-gray-300"
                  }`}
                  onClick={() => {
                    dispatch({
                      type: "CHANGE",
                      payload: {
                        name: "rewardType",
                        value: "DATA",
                      },
                    });
                    dispatch({
                      type: "CHANGE",
                      payload: {
                        name: "rewardBudget",
                        value: "",
                      },
                    });
                  }}
                >
                  <div className="flex items-center">
                    <img className="-mt-1" src={RewardData} alt="" />
                    <label
                      htmlFor="data-radio"
                      className="w-full py-4 ml-3 font-medium text-gray-900 dark:text-gray-300"
                    >
                      Data
                    </label>
                  </div>
                  <input
                    name="data-radio"
                    id="data-radio"
                    type="radio"
                    value=""
                    disabled={loading}
                    checked={formState.rewardType === "DATA"}
                    onChange={() => {
                      dispatch({
                        type: "CHANGE",
                        payload: {
                          name: "rewardType",
                          value: "DATA",
                        },
                      });
                      dispatch({
                        type: "CHANGE",
                        payload: {
                          name: "rewardBudget",
                          value: "",
                        },
                      });
                    }}
                    className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 cursor-pointer"
                  />
                </div> */}
              </div>

              {formState.rewardType === "AIRTIME" && (
                <div className="flex gap-4 mt-4 pt-3">
                  <div>
                    <div className="text-gray-70 font-semibold text-sm mb-2">
                      Reward budget
                    </div>
                    <div
                      className={`flex w-[314px] justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-300 rounded-md focus:outline-none ${
                        formState.errors.rewardBudget && "border-red-500"
                      }`}
                    >
                      <div
                        className={`border-r h-12 border-gray-300 py-3.5 pr-4 ${
                          formState.errors.rewardBudget && "border-red-500"
                        }`}
                      >
                        {currencySymbol === "₦" ? "NGN" : "KSh"}
                      </div>
                      <input
                        type="number"
                        name="rewardBudget"
                        placeholder="Enter amount"
                        className="w-full px-3 focus:outline-none"
                        onChange={handleFormChange}
                        value={formState.rewardBudget}
                        disabled={loading}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="text-gray-70 font-semibold text-sm mb-2">
                      Reward per profile
                    </div>
                    <div
                      className={`flex w-[314px] justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-300 rounded-md focus:outline-none ${
                        formState.errors.rewardPerProfile && "border-red-500"
                      }`}
                    >
                      <div
                        className={`border-r h-12 border-gray-300 py-3.5 pr-4 ${
                          formState.errors.rewardPerProfile && "border-red-500"
                        }`}
                      >
                        {currencySymbol === "₦" ? "NGN" : "KSh"}
                      </div>
                      <input
                        type="number"
                        name="rewardPerProfile"
                        placeholder="Enter amount"
                        className="w-full px-3 focus:outline-none"
                        onChange={handleFormChange}
                        value={formState.rewardPerProfile}
                        disabled={loading}
                      />
                    </div>
                  </div>
                </div>
              )}

              {formState.rewardType === "DATA" && (
                <>
                  <div className="flex gap-4 mt-4">
                    <div className="py-3">
                      <div className="text-gray-70 font-semibold text-sm mb-1.5">
                        Network
                      </div>
                      <div className="relative w-full">
                        <button
                          className={`flex w-[314px] justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-300 rounded-md focus:outline-none ${
                            formState.rewardOperator === "" && "text-gray-400"
                          } ${
                            formState.errors.rewardOperator && "border-red-500"
                          }`}
                          type="button"
                          disabled={loading}
                          onClick={() => setShowNetworkMenu(!showNetworkMenu)}
                        >
                          {formState.rewardOperator || "Select network"}
                          <img src={Chevron} alt="" />
                        </button>

                        {/* Network Dropdown menu */}
                        {showNetworkMenu && (
                          <div
                            ref={networkMenu}
                            className="absolute z-10 bg-white rounded-lg shadow w-[314px]"
                          >
                            {mobileNetworks.map((network, index) => (
                              <div
                                key={index}
                                className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left cursor-pointer"
                                onClick={() => {
                                  dispatch({
                                    type: "CHANGE",
                                    payload: {
                                      name: "rewardOperator",
                                      value: network.name,
                                    },
                                  });
                                  setShowNetworkMenu(false);
                                }}
                              >
                                <div className="flex gap-4 items-center">
                                  {network.icon}
                                  {network.name}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="py-3">
                      <div className="text-gray-70 font-semibold text-sm mb-1.5">
                        Data reward
                      </div>
                      <div className="relative w-full">
                        <button
                          className={`flex w-[314px] justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-300 rounded-md focus:outline-none ${
                            formState.rewardData === "" && "text-gray-400"
                          } ${formState.errors.rewardData && "border-red-500"}`}
                          type="button"
                          disabled={loading}
                          onClick={() => setShowDataMenu(!showDataMenu)}
                        >
                          {formState.rewardData || "Select data reward"}
                          <img src={Chevron} alt="" />
                        </button>

                        {/* Data Dropdown menu */}
                        {showDataMenu && (
                          <div
                            ref={dataMenu}
                            className="absolute z-10 bg-white rounded-lg shadow w-[314px]"
                          >
                            {[
                              `500MB at ${currencySymbol}500`,
                              `1GB at ${currencySymbol}1,000`,
                              `2GB at ${currencySymbol}2,000`,
                              `3GB at ${currencySymbol}3,500`,
                              `10GB at ${currencySymbol}10,000`,
                              `15GB at ${currencySymbol}17,500`,
                            ].map((data, index) => (
                              <button
                                key={index}
                                className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                                role="menuitem"
                                onClick={() => {
                                  dispatch({
                                    type: "CHANGE",
                                    payload: {
                                      name: "rewardData",
                                      value: data,
                                    },
                                  });
                                  setShowDataMenu(false);
                                }}
                              >
                                {data}
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex gap-2 items-center text-gray-70 mb-4 cursor-pointer">
                    <div className="text-3xl">+</div>
                    <div className="text-sm font-semibold mt-1">
                      Add Network
                    </div>
                  </div>

                  <div className="mt-2">
                    <div className="text-gray-70 font-semibold text-sm mb-2">
                      Reward budget
                    </div>
                    <div
                      className={`flex w-[640px] justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-300 rounded-md focus:outline-none ${
                        formState.errors.rewardBudget && "border-red-500"
                      }`}
                    >
                      <div
                        className={`border-r h-12 border-gray-300 py-3.5 pr-4 ${
                          formState.errors.rewardBudget && "border-red-500"
                        }`}
                      >
                        {currencySymbol === "₦" ? "NGN" : "KSh"}
                      </div>
                      <input
                        type="number"
                        name="rewardBudget"
                        placeholder="Enter amount"
                        className="w-full px-3 focus:outline-none"
                        onChange={handleFormChange}
                        value={formState.rewardBudget}
                        disabled={loading}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="py-3">
                <div className="text-gray-70 font-semibold text-sm my-3">
                  Triggers
                </div>
                <p className="text-gray-500 text-sm mb-2">
                  Decide which participant gets rewarded
                </p>
                <div className="relative w-full">
                  <button
                    className={`flex w-full justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-300 rounded-md focus:outline-none ${
                      formState.rewardTrigger === "" && "text-gray-400"
                    } ${formState.errors.rewardTrigger && "border-red-500"}`}
                    type="button"
                    disabled={loading}
                    onClick={() => setShowTriggerMenu(!showTriggerMenu)}
                  >
                    {formState.rewardTrigger ||
                      "Select your preferred reward trigger"}
                    <img src={Chevron} alt="" />
                  </button>

                  {/* Trigger Dropdown menu */}
                  {showTriggerMenu && (
                    <div
                      ref={triggerMenu}
                      className="absolute z-10 bg-white rounded-lg shadow w-full"
                    >
                      {[
                        "Took part in the survey",
                        "Answered all questions",
                        "Participated within a custom time range",
                      ].map((data, index) => (
                        <button
                          key={index}
                          className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                          role="menuitem"
                          onClick={() => {
                            dispatch({
                              type: "CHANGE",
                              payload: {
                                name: "rewardTrigger",
                                value: data,
                              },
                            });
                            setShowTriggerMenu(false);
                          }}
                        >
                          {data}
                        </button>
                      ))}
                    </div>
                  )}

                  {triggerCode(formState.rewardTrigger) === "TIMELINE" && (
                    <div className="pt-3">
                      <div className="text-gray-500 text-sm py-1.5 mb-1">
                        Select the date and time range
                      </div>
                      <ConfigProvider
                        theme={{
                          token: {
                            colorIcon: "#98A2B3",
                            colorBorder: "#D0D5DD",
                            colorPrimary: "#008138",
                            colorPrimaryHover: "#008138",
                            colorText: "#344054",
                            colorTextHeading: "#344054",
                            colorTextDisabled: "#D0D5DD",
                            colorTextLightSolid: "var(--main-color)",
                            colorTextPlaceholder: "#98A2B3",
                            controlItemBgActive: "#DAEEE1",
                            controlOutline: "#FFFFFF",
                          },
                        }}
                      >
                        <div className="flex gap-4">
                          <div>
                            <div className="text-gray-70 font-light text-sm mb-1.5">
                              From
                              {rewardStartDate && (
                                <span className="ml-1.5">
                                  {dayjs(rewardStartDate).format("LLLL")}
                                </span>
                              )}
                            </div>

                            <div
                              onClick={() => {
                                if (!showRewardStartCalendar) {
                                  setShowRewardStartCalendar(true);
                                }
                              }}
                            >
                              <DatePicker
                                className={`w-[314px] h-12 px-4 hover:border-gray-300 ${
                                  (formState.errors.rewardStartDate ||
                                    formState.errors.rewardStartTime) &&
                                  "border-red-500"
                                }`}
                                placeholder="From"
                                disabled={loading}
                                value={
                                  formState.rewardStartDate
                                    ? dayjs(
                                        formState.rewardStartDate,
                                        "YYYY-MM-DD"
                                      )
                                    : undefined
                                }
                                defaultValue={
                                  formState.rewardStartDate
                                    ? dayjs(
                                        formState.rewardStartDate,
                                        "YYYY-MM-DD"
                                      )
                                    : undefined
                                }
                                format="YYYY-MM-DD"
                                onChange={onRewardStartDateChange}
                                locale={locale}
                                open={showRewardStartCalendar}
                                showNow={false}
                                showToday={false}
                                suffixIcon={<img src={CalendarIcon} />}
                                allowClear={false}
                                popupStyle={{ paddingBottom: "64px" }}
                                cellRender={(current) => {
                                  const style: React.CSSProperties = {};
                                  style.borderRadius = "50%";
                                  style.width = "30px";
                                  style.height = "30px";
                                  style.lineHeight = "30px";
                                  return (
                                    <div
                                      className="ant-picker-cell-inner"
                                      style={style}
                                    >
                                      {current.date()}
                                    </div>
                                  );
                                }}
                                renderExtraFooter={() => {
                                  return (
                                    <>
                                      <div className="flex justify-between items-end px-5">
                                        <input
                                          type="text"
                                          placeholder="Pick a start date"
                                          value={
                                            formState.rewardStartDate &&
                                            dayjs(
                                              formState.rewardStartDate
                                            ).format("MMM D, YYYY")
                                          }
                                          className={`w-36 h-12 px-2 py-3 mt-3 text-[13.5px] border border-gray-300 rounded-md focus:outline-none ${
                                            formState.errors.rewardStartDate &&
                                            "border-red-500"
                                          }`}
                                          onChange={() => {}}
                                        />
                                        <TimePicker
                                          className={`h-12 ${
                                            formState.errors.rewardStartTime &&
                                            "border-red-500"
                                          }`}
                                          defaultValue={
                                            formState.rewardStartTime
                                              ? dayjs(
                                                  formState.rewardStartTime,
                                                  "HH:mm:ss"
                                                )
                                              : undefined
                                          }
                                          format="HH:mm A"
                                          onChange={onRewardStartTimeChange}
                                          use12Hours
                                          size="small"
                                          showNow={false}
                                          placement="bottomRight"
                                          inputReadOnly={true}
                                        />
                                      </div>
                                      <div className="pt-4 mt-80 bg-white rounded-b-lg drop-shadow-lg">
                                        <hr className="border-gray-300" />
                                        <div className="flex gap-4 p-4 rounded-b-lg">
                                          <button
                                            className="py-3 px-10 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
                                            onClick={() =>
                                              setShowRewardStartCalendar(false)
                                            }
                                          >
                                            Cancel
                                          </button>

                                          <button
                                            className="py-3 px-14 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75"
                                            onClick={() => {
                                              onRewardStartDateSave();
                                            }}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }}
                              />
                            </div>
                            {rewardStartTimeError && (
                              <div className="text-red-500 text-sm mt-2">
                                {rewardStartTimeError}
                              </div>
                            )}
                          </div>

                          <div>
                            <div className="text-gray-70 font-light text-sm mb-1.5">
                              To
                              {rewardEndDate && (
                                <span className="ml-1.5">
                                  {dayjs(rewardEndDate).format("LLLL")}
                                </span>
                              )}
                            </div>

                            <div
                              onClick={() => {
                                if (!showRewardEndCalendar) {
                                  setShowRewardEndCalendar(true);
                                }
                              }}
                            >
                              <DatePicker
                                className={`w-[314px] h-12 px-4 hover:border-gray-300 ${
                                  (formState.errors.rewardEndDate ||
                                    formState.errors.rewardEndTime) &&
                                  "border-red-500"
                                }`}
                                placeholder="To"
                                disabled={!rewardStartDate || loading}
                                value={
                                  formState.rewardEndDate
                                    ? dayjs(
                                        formState.rewardEndDate,
                                        "YYYY-MM-DD"
                                      )
                                    : undefined
                                }
                                defaultValue={
                                  formState.rewardEndDate
                                    ? dayjs(
                                        formState.rewardEndDate,
                                        "YYYY-MM-DD"
                                      )
                                    : undefined
                                }
                                format="YYYY-MM-DD"
                                onChange={onRewardEndDateChange}
                                disabledDate={disabledDates}
                                locale={locale}
                                open={showRewardEndCalendar}
                                showNow={false}
                                showToday={false}
                                suffixIcon={<img src={CalendarIcon} />}
                                allowClear={false}
                                popupStyle={{ paddingBottom: "64px" }}
                                cellRender={(current) => {
                                  const style: React.CSSProperties = {};
                                  style.borderRadius = "50%";
                                  style.width = "30px";
                                  style.height = "30px";
                                  style.lineHeight = "30px";
                                  return (
                                    <div
                                      className="ant-picker-cell-inner"
                                      style={style}
                                    >
                                      {current.date()}
                                    </div>
                                  );
                                }}
                                renderExtraFooter={() => {
                                  return (
                                    <>
                                      <div className="flex justify-between items-end px-5">
                                        <input
                                          type="text"
                                          placeholder="Pick an end date"
                                          value={
                                            formState.rewardEndDate &&
                                            dayjs(
                                              formState.rewardEndDate
                                            ).format("MMM D, YYYY")
                                          }
                                          className={`w-36 h-12 px-2 py-3 mt-3 text-[13.5px] border border-gray-300 rounded-md focus:outline-none ${
                                            formState.errors.rewardEndDate &&
                                            "border-red-500"
                                          }`}
                                          onChange={() => {}}
                                        />
                                        <TimePicker
                                          className={`h-12 ${
                                            formState.errors.rewardEndTime &&
                                            "border-red-500"
                                          }`}
                                          defaultValue={
                                            formState.rewardEndTime
                                              ? dayjs(
                                                  formState.rewardEndTime,
                                                  "HH:mm:ss"
                                                )
                                              : undefined
                                          }
                                          format="HH:mm A"
                                          onChange={onRewardEndTimeChange}
                                          use12Hours
                                          size="small"
                                          showNow={false}
                                          placement="bottomRight"
                                          inputReadOnly={true}
                                        />
                                      </div>
                                      <div className="pt-4 mt-80 bg-white rounded-b-lg drop-shadow-lg">
                                        <hr className="border-gray-300" />
                                        <div className="flex gap-4 p-4 rounded-b-lg">
                                          <button
                                            className="py-3 px-10 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
                                            onClick={() =>
                                              setShowRewardEndCalendar(false)
                                            }
                                          >
                                            Cancel
                                          </button>

                                          <button
                                            className="py-3 px-14 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75"
                                            onClick={() => {
                                              onRewardEndDateSave();
                                            }}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </ConfigProvider>
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-4">
                <div className="flex gap-2">
                  <div className="py-3 w-56 mt-3">
                    <label className="relative flex items-center mb-2 cursor-pointer">
                      <input
                        type="checkbox"
                        checked={formState.sendFollowUpMessage === true}
                        className="sr-only peer"
                        disabled={loading}
                        onClick={() => {
                          dispatch({
                            type: "CHANGE",
                            payload: {
                              name: "sendFollowUpMessage",
                              value: !formState.sendFollowUpMessage,
                            },
                          });
                        }}
                      />
                      <div
                        className={`w-11 h-6 ${
                          formState.sendFollowUpMessage
                            ? "bg-gray-200"
                            : "bg-gray-300"
                        } rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-75`}
                      ></div>
                      <div className="ml-3 text-sm font-semibold text-gray-70 whitespace-nowrap flex">
                        Send follow-up message
                      </div>
                    </label>
                  </div>
                  <div className="relative mt-4 pt-0.5">
                    <button
                      className="inline-flex items-center py-2.5 text-sm font-semibold text-center text-gray-70"
                      type="button"
                      onClick={() => setShowTooltip(!showTooltip)}
                    >
                      <img src={InfoCircle} alt="" />
                    </button>

                    {/* Target audience tooltip */}
                    {showTooltip && (
                      <div ref={tooltip} className="absolute">
                        <div className="p-3 z-10 ml-7 -mt-10 bg-[#E6F0FA] text-[#143D7B] border border-[#B5D5F4] text-sm leading-6 rounded-lg shadow-lg w-[212px]">
                          <p>This will be charged per profile.</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {formState.sendFollowUpMessage && (
                  <>
                    <div className="py-3">
                      <div className="text-gray-70 font-semibold text-sm mb-1.5">
                        Sender ID
                      </div>
                      <div className="relative w-full">
                        <button
                          name="senderID"
                          className={`flex w-[640px] justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-200 rounded-md focus:outline-none ${
                            formState.senderID === "" && "text-gray-400"
                          } ${formState.errors.senderID && "border-red-500"}`}
                          type="button"
                          onClick={() => setShowSenderIDMenu(!showSenderIDMenu)}
                          disabled={loading}
                        >
                          {senderIDs.length
                            ? formState.senderID || "Select your sender ID"
                            : "No enabled Sender ID available!"}
                          <img src={Chevron} alt="" />
                        </button>

                        {/* SenderID Dropdown menu */}
                        {showSenderIDMenu && (
                          <div
                            ref={senderIDMenu}
                            className="absolute z-10 bg-white rounded-lg shadow w-[640px]"
                          >
                            <button
                              className="block p-4 text-blue-75 hover:text-green-75 w-full text-left text-sm font-semibold"
                              role="menuitem"
                              onClick={() => {
                                props.navigate(
                                  "/settings?tab=Engagement&item=Sender%20ID"
                                );
                              }}
                            >
                              Create Sender ID +
                            </button>
                            {senderIDs?.map((senderID, index) => (
                              <button
                                key={index}
                                className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                                role="menuitem"
                                onClick={() => {
                                  dispatch({
                                    type: "CHANGE",
                                    payload: {
                                      name: "senderID",
                                      value: senderID?.name,
                                    },
                                  });
                                  setShowSenderIDMenu(false);
                                }}
                              >
                                {senderID?.name}
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="pt-3">
                      <div className="text-gray-70 font-semibold text-sm my-2">
                        Message
                      </div>
                      <div
                        className={`border border-gray-300 rounded-md  ${
                          formState.errors.message && "border-red-500"
                        }`}
                      >
                        <textarea
                          name="message"
                          placeholder="Enter message here"
                          value={formState.message}
                          maxLength={160}
                          className={`w-full h-24 px-3 py-3 mt-1 focus:outline-none ${
                            formState.errors.message && "border-red-500"
                          }`}
                          onChange={handleFormChange}
                          disabled={loading}
                        />
                        <hr className="border-gray-300" />
                        <div>
                          <div className="px-4 py-2 cursor-pointer">
                            <img src={UserOutline} alt="" />
                          </div>
                          <div className="text-gray-400 bg-[#F9FAFB] px-4 py-3 rounded-b-md">
                            {props.businessName
                              ? `Courtesy: ${props.businessName}`
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`text-gray-70 font-normal text-sm pt-2 ${
                          formState.message.length === 160 && "text-red-500"
                        }`}
                      >
                        {formState.message.length + "/160 characters"}
                      </div>
                    </div>
                  </>
                )}
              </div>

              {rewardError && (
                <div className="text-red-500 text-sm mt-2">{rewardError}</div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="py-3">
        <div className="text-gray-70 font-semibold text-sm mb-1.5">
          Schedule
        </div>
        <ConfigProvider
          theme={{
            token: {
              colorIcon: "#98A2B3",
              colorBorder: "#D0D5DD",
              colorPrimary: "#008138",
              colorPrimaryHover: "#008138",
              colorText: "#344054",
              colorTextHeading: "#344054",
              colorTextDisabled: "#D0D5DD",
              colorTextLightSolid: "var(--main-color)",
              colorTextPlaceholder: "#98A2B3",
              controlItemBgActive: "#DAEEE1",
              controlOutline: "#FFFFFF",
            },
          }}
        >
          <div className="py-3 w-[694px] flex justify-between">
            <div>
              <div className="text-gray-70 font-semibold text-sm mb-1.5">
                Start
                {startDate && (
                  <span className="font-light ml-1.5">
                    (on {dayjs(startDate).format("LLLL")})
                  </span>
                )}
              </div>

              <div
                onClick={() => {
                  if (!showStartCalendar) {
                    setShowStartCalendar(true);
                  }
                }}
              >
                <DatePicker
                  className={`w-[340px] h-12 px-4 hover:border-gray-300 ${
                    (formState.errors.surveyStartDate ||
                      formState.errors.surveyStartTime) &&
                    "border-red-500"
                  }`}
                  placeholder="Enter date"
                  disabled={loading}
                  value={
                    formState.surveyStartDate
                      ? dayjs(formState.surveyStartDate, "YYYY-MM-DD")
                      : undefined
                  }
                  defaultValue={
                    formState.surveyStartDate
                      ? dayjs(formState.surveyStartDate, "YYYY-MM-DD")
                      : undefined
                  }
                  format="YYYY-MM-DD"
                  onChange={onStartDateChange}
                  locale={locale}
                  open={showStartCalendar}
                  showNow={false}
                  showToday={false}
                  suffixIcon={<img src={CalendarIcon} />}
                  allowClear={false}
                  popupStyle={{ paddingBottom: "64px" }}
                  cellRender={(current) => {
                    const style: React.CSSProperties = {};
                    style.borderRadius = "50%";
                    style.width = "30px";
                    style.height = "30px";
                    style.lineHeight = "30px";
                    return (
                      <div className="ant-picker-cell-inner" style={style}>
                        {current.date()}
                      </div>
                    );
                  }}
                  renderExtraFooter={() => {
                    return (
                      <>
                        <div className="flex justify-between items-end px-5">
                          <input
                            type="text"
                            placeholder="Pick a start date"
                            value={
                              formState.surveyStartDate &&
                              dayjs(formState.surveyStartDate).format(
                                "MMM D, YYYY"
                              )
                            }
                            className={`w-36 h-12 px-2 py-3 mt-3 text-[13.5px] border border-gray-300 rounded-md focus:outline-none ${
                              formState.errors.surveyStartDate &&
                              "border-red-500"
                            }`}
                            onChange={() => {}}
                          />
                          <TimePicker
                            className={`h-12 ${
                              formState.errors.surveyStartTime &&
                              "border-red-500"
                            }`}
                            defaultValue={
                              formState.surveyStartTime
                                ? dayjs(formState.surveyStartTime, "HH:mm:ss")
                                : undefined
                            }
                            format="HH:mm A"
                            onChange={onStartTimeChange}
                            use12Hours
                            size="small"
                            showNow={false}
                            placement="bottomRight"
                            inputReadOnly={true}
                          />
                        </div>
                        <div className="pt-4 mt-80 bg-white rounded-b-lg drop-shadow-lg">
                          <hr className="border-gray-300" />
                          <div className="flex gap-4 p-4 rounded-b-lg">
                            <button
                              className="py-3 px-10 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
                              onClick={() => setShowStartCalendar(false)}
                            >
                              Cancel
                            </button>

                            <button
                              className="py-3 px-14 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75"
                              onClick={() => {
                                onStartDateSave();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
              {startTimeError && (
                <div className="text-red-500 text-sm mt-2">
                  {startTimeError}
                </div>
              )}
            </div>

            <div>
              <div className="text-gray-70 font-semibold text-sm mb-1.5">
                End
                {endDate && (
                  <span className="font-light ml-1.5">
                    (on {dayjs(endDate).format("LLLL")})
                  </span>
                )}
              </div>

              <div
                onClick={() => {
                  if (!showEndCalendar) {
                    setShowEndCalendar(true);
                  }
                }}
              >
                <DatePicker
                  className={`w-[340px] h-12 px-4 hover:border-gray-300 ${
                    (formState.errors.surveyEndDate ||
                      formState.errors.surveyEndTime) &&
                    "border-red-500"
                  }`}
                  placeholder="Enter date"
                  disabled={!startDate || loading}
                  value={
                    formState.surveyEndDate
                      ? dayjs(formState.surveyEndDate, "YYYY-MM-DD")
                      : undefined
                  }
                  defaultValue={
                    formState.surveyEndDate
                      ? dayjs(formState.surveyEndDate, "YYYY-MM-DD")
                      : undefined
                  }
                  format="YYYY-MM-DD"
                  onChange={onEndDateChange}
                  disabledDate={disabledDates}
                  locale={locale}
                  open={showEndCalendar}
                  showNow={false}
                  showToday={false}
                  suffixIcon={<img src={CalendarIcon} />}
                  allowClear={false}
                  popupStyle={{ paddingBottom: "64px" }}
                  cellRender={(current) => {
                    const style: React.CSSProperties = {};
                    style.borderRadius = "50%";
                    style.width = "30px";
                    style.height = "30px";
                    style.lineHeight = "30px";
                    return (
                      <div className="ant-picker-cell-inner" style={style}>
                        {current.date()}
                      </div>
                    );
                  }}
                  renderExtraFooter={() => {
                    return (
                      <>
                        <div className="flex justify-between items-end px-5">
                          <input
                            type="text"
                            placeholder="Pick an end date"
                            value={
                              formState.surveyEndDate &&
                              dayjs(formState.surveyEndDate).format(
                                "MMM D, YYYY"
                              )
                            }
                            className={`w-36 h-12 px-2 py-3 mt-3 text-[13.5px] border border-gray-300 rounded-md focus:outline-none ${
                              formState.errors.surveyEndDate && "border-red-500"
                            }`}
                            onChange={() => {}}
                          />
                          <TimePicker
                            className={`h-12 ${
                              formState.errors.surveyEndTime && "border-red-500"
                            }`}
                            defaultValue={
                              formState.surveyEndTime
                                ? dayjs(formState.surveyEndTime, "HH:mm:ss")
                                : undefined
                            }
                            format="HH:mm A"
                            onChange={onEndTimeChange}
                            use12Hours
                            size="small"
                            showNow={false}
                            placement="bottomRight"
                            inputReadOnly={true}
                          />
                        </div>
                        <div className="pt-4 mt-80 bg-white rounded-b-lg drop-shadow-lg">
                          <hr className="border-gray-300" />
                          <div className="flex gap-4 p-4 rounded-b-lg">
                            <button
                              className="py-3 px-10 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
                              onClick={() => setShowEndCalendar(false)}
                            >
                              Cancel
                            </button>

                            <button
                              className="py-3 px-14 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75"
                              onClick={() => {
                                onEndDateSave();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
              {scheduleError && (
                <div className="text-red-500 text-sm mt-2">{scheduleError}</div>
              )}
            </div>
          </div>
        </ConfigProvider>
      </div>

      {/* <div className="flex justify-between py-10 w-[694px]">
        <button
          onClick={(e) => {
            handleSubmit(e, true);
          }}
          disabled={loading}
          className="text-gray-70 font-semibold text-sm px-6 py-3 border border-white hover:border-gray-50 rounded-lg"
        >
          Save as draft
        </button>
      </div> */}
      <NotificationModal data={notificationData} />
    </div>
  );
};

export default SurveyStepOne;
