import { useEffect, useState } from "react";

const MultipleChoiceProperty = (props: any) => {
  const [question, setQuestion] = useState(props.question);

  useEffect(() => {
    setQuestion(props.question);
  });

  const onSelectMultipleChange = () => {
    let value = question.property?.selectMultiple
      ? question.property.selectMultiple
      : false;
    value = !value;
    let q = question;
    q.property.selectMultiple = value;
    props.setRating(q);
  };

  const onOtherOptionChange = () => {
    let value = question.property?.otherOptions
      ? question.property.otherOptions
      : false;
    value = !value;
    let q = question;
    q.property.otherOptions = value;
    props.setRating(q);
  };

  const onMinValueChange = (event) => {
    let q = question;
    q.property.minValue = event.target.value;
    props.setRating(question);
  };

  const onMaxValueChange = (event) => {
    let q = question;
    q.property.maxValue = event.target.value;
    props.setRating(question);
  };

  return (
    <div className="mt-6">
      <div className="flex gap-4 items-center">
        <div>
          <div
            onClick={() => {
              onSelectMultipleChange();
            }}
            className={
              question.property?.selectMultiple
                ? "bg-blue-75 h-[20px] w-[36px] rounded-xl p-1 flex justify-end cursor-pointer"
                : "bg-[#F2F4F7] h-[20px] w-[36px] rounded-xl p-1 flex justify-start cursor-pointer"
            }
          >
            <div className="bg-[#FFFFFF] rounded-full h-[14px] w-[14px] drop-shadow-md"></div>
          </div>
        </div>
        <div className="text-sm font-semibold text-[#475467]">
          Select multiple
        </div>
      </div>
      <div>
        {/* {question.property?.selectMultiple ? (
          <div className="flex gap-4">
            <div>
              <div className="text-sm font-semibold text-[#475467] mt-6">
                Min
              </div>
              <div className="border rounded mt-1 h-[40px] p-1">
                <input
                  value={
                    question?.property?.minValue
                      ? question.property.minValue
                      : 1
                  }
                  onChange={(event) => onMinValueChange(event)}
                  type="number"
                  className="border-0 outline-0 h-full w-full"
                />
              </div>
            </div>
            <div>
              <div className="text-sm font-semibold text-[#475467] mt-6">
                Max
              </div>
              <div className="border rounded mt-1 h-[40px] p-1">
                <input
                  value={
                    question?.property?.maxValue
                      ? question.property.maxValue
                      : 2
                  }
                  onChange={(event) => onMaxValueChange(event)}
                  type="number"
                  className="border-0 outline-0 h-full w-full"
                />
              </div>
            </div>
          </div>
        ) : null} */}
      </div>
      <div className="flex gap-4 items-center mt-4">
        <div>
          <div
            onClick={() => {
              onOtherOptionChange();
            }}
            className={
              question.property?.otherOptions
                ? "bg-blue-75 h-[20px] w-[36px] rounded-xl p-1 flex justify-end cursor-pointer"
                : "bg-[#F2F4F7] h-[20px] w-[36px] rounded-xl p-1 flex justify-start cursor-pointer"
            }
          >
            <div className="bg-[#FFFFFF] rounded-full h-[14px] w-[14px] drop-shadow-md"></div>
          </div>
        </div>
        <div className="text-sm font-semibold text-[#475467]">
          “Other” option
        </div>
      </div>
    </div>
  );
};

export default MultipleChoiceProperty;
