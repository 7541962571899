import { useEffect, useState } from "react";
import { getSurveySummary } from "../../../../../common/services/survey-services";
import ReactECharts from "echarts-for-react";
import * as Sentry from "@sentry/react";

const SurveyDropOff = (props: any) => {
  const { id, respondentsType, dispatch, setShowCommonLoader } = props;
  const [option, setOption] = useState({});

  useEffect(() => {
    let xData = [];
    let yData = [];
    try {
      dispatch(setShowCommonLoader(true));

      getSurveySummary("drop-off", id.replaceAll("~", "%2F"), "")
        .then((response) => {
          if (!response.error) {
            let data = response.response;
            data.sort(function (a, b) {
              return a.questionIndex - b.questionIndex;
            });
            data.map((ele, i) => {
              xData.push(`Q${ele.questionIndex}`);
              yData.push(ele.count);
            });
            setChartData(xData, yData);
            dispatch(setShowCommonLoader(false));
          } else {
            Sentry.captureMessage("Error", response);
            dispatch(setShowCommonLoader(false));
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          dispatch(setShowCommonLoader(false));
          console.log(err);
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      dispatch(setShowCommonLoader(false));
      console.log(error);
    }
  }, []);

  const setChartData = (xData, yData) => {
    const o = {
      tooltip: {
        trigger: "item",
        backgroundColor: "#13163E",
        borderColor: "#13163E",
        textStyle: { color: "#FFFFFF", fontSize: 12, lineHeight: 16 },
        formatter: `{b}<br />{c}`,
      },
      xAxis: {
        type: "category",
        data: xData,
        axisLabel: {
          interval: 0,
          overflow: "truncate",
        },
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: yData,
          type: "bar",
          color: ["#5c9de7"],
        },
      ],
    };
    setOption(o);
  };

  return (
    <div className="border rounded p-6 mt-6 w-[calc(50%-4px)]">
      <div className="text-sm text-gray-700 font-semibold h-[40px]">
        Drop off point
      </div>
      {option && <ReactECharts style={{ height: "350px" }} option={option} />}
    </div>
  );
};

export default SurveyDropOff;
