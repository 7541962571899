import axios from 'axios';
import { api } from '../../../common/services/common-api-service';
const loginAccessToken = localStorage.getItem('loginAccessToken');
import * as Sentry from '@sentry/react';

export const getApiReport = (channel, dateRange?) => {
  let { startDate, endDate } = dateRange;
  let url = `/analytics?channel=${channel}${
    startDate
      ? `&startDate=${new Date(+startDate).toISOString()}&endDate=${new Date(
          +endDate
        ).toISOString()}`
      : ''
  }`;

  return api
    .get(url, {
      headers: {
        Authorization: `bearer ${localStorage.getItem('loginAccessToken')}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });

  // return api
  //   .get(url, {
  //     headers: {
  //       Authorization: `bearer ${localStorage.getItem('loginAccessToken')}`,
  //     },
  //   })
  //   .then((response) => {
  //     return response.data;
  //   })
  //   .catch((error) => {
  //     error.response
  //       ? Sentry.captureMessage('Error', error.response)
  //       : Sentry.captureMessage('Error', error);
  //   });
};
