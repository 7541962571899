import { useEffect, useState } from "react";

const LinearScale = (props: any) => {
  const [question, setQuestion] = useState(props.question);
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [arr, setArr] = useState([]);

  useEffect(() => {
    const { question, minScale, maxScale } = props;
    setQuestion(question);
    setMin(minScale);
    setMax(maxScale);
    let count = Number(maxScale) - Number(minScale) + 1;
    let a = Array.from(Array(Number(count)).keys());
    setArr(a);
  }, [props]);

  const onQuestionChange = (event) => {
    let q = question;
    q.q = event.target.value;
    props.setRating(q);
  };

  const onSubTextChange = (event) => {
    let q = question;
    q.s = event.target.value;
    props.setRating(q);
  };

  const getScaleValue = (v1, v2) => {
    return Number(v1) + Number(v2);
  };

  const setInput = (event, i) => {
    let q = question;
    let options = q.property?.input ? q.property?.input : [];
    options[i] = event.target.value;
    let ip = { input: options };
    q.property ? (q.property.input = options) : (q.property = ip);
    props.setRating(q);
  };

  return (
    <div>
      <div>
        <div className="border border-[#E4E7EC] rounded px-2 py-3 mb-1">
          <input
            className="w-full text-xl text-[#98A2B3] border-0 outline-0 font-semibold"
            onChange={(event) => {
              onQuestionChange(event);
            }}
            value={question?.q}
            placeholder="Main question here"
          />
        </div>
        <div className="border border-[#E4E7EC] rounded px-2 py-3 mb-1">
          <input
            className="w-full text-sm text-[#98A2B3] border-0 outline-0"
            onChange={(event) => {
              onSubTextChange(event);
            }}
            value={question?.s}
            placeholder="Subtext goes here (optional)"
          />
        </div>
      </div>
      <div>
        {arr.length > 0 ? (
          <div className="flex mt-11 gap-6 flex-wrap">
            {arr.map((ele, i) => {
              return (
                <div key={i} className="w-[80px]">
                  <div className="h-[48px] w-full  border rounded flex justify-center items-center">
                    <span>{getScaleValue(i, min ? min : 0)}</span>
                  </div>
                  <div className="h-[22px] mt-4 rounded border flex justify-center items-center p-1">
                    <input
                      value={
                        question?.property?.input
                          ? question?.property?.input[i]
                            ? question.property.input[i]
                            : ""
                          : question?.property?.options
                          ? question?.property?.options[i].optionText
                          : ""
                      }
                      onChange={(event) => {
                        setInput(event, i);
                      }}
                      className="w-full text-xs text-[#667085] outline-0 border-0"
                      placeholder="Input label"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LinearScale;
