import { template } from "./initial-template";

const EmailBuilder = ({ beeEmailBuilder, beeConfig, savedTemplate }) => {
  beeEmailBuilder
    .getToken(
      "80d288da-4120-49ad-8a52-6639c17416b0",
      "97551o8acY0qGps7W6784ntp5Iu6TIXHrD5rF0rdksu5bYAcmeKS"
    )
    .then(() => beeEmailBuilder.start(beeConfig, savedTemplate || template));

  return (
    <div className="w-full h-full">
      <div id="bee-plugin-container" className="w-full h-full"></div>
    </div>
  );
};

export default EmailBuilder;
