import { useState } from "react";
import { useSelector } from "react-redux";
import RejectPopUp from "../../../pages/reject-popup/reject-popup";
// import { MESSAGE } from "../../../../../marketing-cloud-main/src/common/constants/AccessControlMessage";

const ApproveRejectCampaigns = (props) => {
  const { hasSuperAdminAccess, status, approveCampaigns, id } = props;
  const accountPermissions = useSelector(
    (state: any) => state.accountPermissions
  );

  const [openPopUp, setOpenPopUp] = useState(false);
  const [selectReason, setSelectReason] = useState("");
  const [otherReason, setOthertReason] = useState("");

  const resetForm = () => {
    setOpenPopUp(false);
    setSelectReason("");
    setOthertReason("");
  };

  const handleSubmitApproval = (status: String) => {
    approveCampaigns(status, selectReason || otherReason);
    resetForm();
  };

  const showApproveRejectButtons =
    (accountPermissions?.approve_campaign_policy ||
      accountPermissions?.reject_campaign_policy) &&
    status?.toLowerCase() === "pending approval";

  return showApproveRejectButtons ? (
    <div className="flex gap-8">
      <div className="relative group">
        <button
          className="px-5 py-2 rounded-md text-sm leading-6 text-slate-600 font-semibold border border-gray-40 hover:border-gray-200 disabled:text-white disabled:border-none disabled:hover:border-none"
          disabled={!accountPermissions?.reject_campaign_policy}
          onClick={() => setOpenPopUp(true)}
        >
          Reject
          {/* {!accountPermissions["reject_campaign_policy"] && (
            <div className="absolute hidden group-hover:flex bg-black p-3 text-xs rounded-lg w-[300px] right-[136px] top-[-10px] z-20">
              {MESSAGE}
            </div>
          )} */}
        </button>
        {openPopUp && (
          <div className="absolute z-50">
            <RejectPopUp
              approveCampaigns={handleSubmitApproval}
              setOthertReason={setOthertReason}
              otherReason={otherReason}
              setSelectReason={setSelectReason}
              setOpenPopup={setOpenPopUp}
              resetForm={resetForm}
            />
          </div>
        )}
      </div>

      <div className="group">
        <button
          onClick={() => {
            handleSubmitApproval("Approved");
          }}
          className={`px-5 py-2 w-24 rounded-md text-sm leading-6 bg-blue-75 text-white font-semibold disabled:text-white ${
            accountPermissions?.approve_campaign_policy && "hover:bg-green-75"
          }`}
          disabled={!accountPermissions?.approve_campaign_policy}
        >
          Approve
          {/* {!accountPermissions["approve_campaign_policy"] && (
            <div className="absolute hidden group-hover:flex bg-black p-3 text-xs rounded-lg w-[300px] right-[136px] top-[-10px] z-20">
              {MESSAGE}
            </div>
          )} */}
        </button>
      </div>
    </div>
  ) : null;
};

export default ApproveRejectCampaigns;
