import { useEffect, useState, createContext } from "react";
import RewardsStepOne from "../step-one";
import RewardsStepTwo from "../step-two";
import RewardsStepThree from "../step-three";
import { getRewardDetails } from "../../../common/services/reward-services";
import * as Sentry from "@sentry/react";
import { getWalletDetails } from "../../../../../marketing-cloud-main/src/common/apis/billing";

export const TenantContext = createContext({});

const RewardsParent = (props: any) => {
  let { dispatch, setShowCommonLoader, setWalletBalance, walletBalance } =
    props;
  let rewardsBody: any = {};
  const [step, setStep] = useState(1);
  const [rewardsData, setRewardsData] = useState(rewardsBody);

  const setStepFromPage = (step) => {
    setStep(step);
  };

  const setRewardsDataFromPage = (data) => {
    setRewardsData(data);
  };

  useEffect(() => {
    if (props.id) {
      dispatch(setShowCommonLoader(true));
      try {
        getRewardDetails(props.id)
          .then((res) => {
            const { error, response = {} } = res;
            if (!error || error === "false") {
              setRewardsData(response);
              dispatch(setShowCommonLoader(false));
            } else {
              error.response
                ? Sentry.captureMessage("Error", error.response)
                : Sentry.captureMessage("Error", error);
              dispatch(setShowCommonLoader(false));
            }
          })

          .catch((err) => {
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            console.log(err);
            dispatch(setShowCommonLoader(false));
          });
      } catch (err) {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        dispatch(setShowCommonLoader(false));
      }
    }
  }, []);

  const getWalletBalance = () => {
    try {
      getWalletDetails()
        .then((response) => {
          if (response) {
            if (response?.data[0]?.balance) {
              dispatch(setWalletBalance(response.data[0].balance));
            }
          }
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log(error);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  return (
    <TenantContext.Provider value={props.tenantContext}>
      <div>
        <div>
          {step == 1 ? (
            <RewardsStepOne
              data={rewardsData}
              setStep={(step) => {
                setStepFromPage(step);
              }}
              setData={(data) => {
                setRewardsDataFromPage(data);
              }}
              country={props.country}
              currency={props.currency}
              currencySymbol={props.currencySymbol}
              businessName={props.businessName}
              navigate={props.navigate}
            />
          ) : null}
          {step == 2 ? (
            <RewardsStepTwo
              data={rewardsData}
              setStep={(step) => {
                setStepFromPage(step);
              }}
              setData={(data) => {
                setRewardsDataFromPage(data);
              }}
              country={props.country}
              currency={props.currency}
              currencySymbol={props.currencySymbol}
              businessName={props.businessName}
              navigate={props.navigate}
            />
          ) : null}
          {step == 3 ? (
            <RewardsStepThree
              data={rewardsData}
              setStep={(step) => {
                setStepFromPage(step);
              }}
              setData={(data) => {
                setRewardsDataFromPage(data);
              }}
              getBalance={() => getWalletBalance()}
              walletBalance={walletBalance}
              country={props.country}
              currencySymbol={props.currencySymbol}
              navigate={props.navigate}
            />
          ) : null}
        </div>
        <div></div>
      </div>
    </TenantContext.Provider>
  );
};

export default RewardsParent;
