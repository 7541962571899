import { useState, useEffect, createContext } from "react";
import SurveyStepOne from "../step-one/step-one";
import SurveyStepTwoUssd from "../step-two-ussd/survey-creation-step-two-ussd";
import SurveyStepTwoWeb from "../step-two-web/survey-creation-step-two-web";
import SurveyStepThree from "../step-three/survey-creation-step-three";
import SurveyLaunch from "../surveyLaunch/surveyLaunch";
import { getSurveyDetails } from "../../../common/services/survey-services";
import * as Sentry from "@sentry/react";
import { getWalletDetails } from "../../../../../marketing-cloud-main/src/common/apis/billing";

export const TenantContext = createContext({});

const SurveyParent = (props: any) => {
  let { dispatch, setShowCommonLoader, setWalletBalance, walletBalance } =
    props;
  let surveyBody: any = {};
  const [step, setStep] = useState(1);
  const [surveyData, setSurveyData] = useState(surveyBody);
  const [surveyQuestionData, setSurveyQuestionData] = useState();

  const setStepFromPage = (step) => {
    setStep(step);
  };

  const setSurveyDataFromPage = (data) => {
    setSurveyData(data);
  };

  useEffect(() => {
    if (props.id) {
      dispatch(setShowCommonLoader(true));
      try {
        getSurveyDetails(props.id)
          .then((res) => {
            const { error, response = {} } = res;
            if (!error || error === "false") {
              setSurveyData(response);
              dispatch(setShowCommonLoader(false));
            } else {
              error.response
                ? Sentry.captureMessage("Error", error.response)
                : Sentry.captureMessage("Error", error);
              dispatch(setShowCommonLoader(false));
            }
          })

          .catch((err) => {
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            console.log(err);
            dispatch(setShowCommonLoader(false));
          });
      } catch (err) {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        dispatch(setShowCommonLoader(false));
      }
    }
  }, []);

  const getWalletBalance = () => {
    try {
      getWalletDetails()
        .then((response) => {
          if (response) {
            if (response?.data[0]?.balance) {
              dispatch(setWalletBalance(response.data[0].balance));
            }
          }
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log(error);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  return (
    <TenantContext.Provider value={props.tenantContext}>
      <div>
        <div>
          {step == 1 ? (
            <SurveyStepThree
              data={surveyData}
              navigate={props.navigate}
              setStep={(step) => {
                setStepFromPage(step);
              }}
              setData={(data) => {
                setSurveyDataFromPage(data);
              }}
              country={props.country}
              currency={props.currency}
              businessName={props.businessName}
              surveyQuestionData={surveyQuestionData}
            />
          ) : null}
          {step == 3 ? (
            surveyData.channel == "USSD" ? (
              <SurveyStepTwoUssd
                data={surveyData}
                setStep={(step) => {
                  setStepFromPage(step);
                }}
                setData={(data) => {
                  setSurveyDataFromPage(data);
                }}
                country={props.country}
                currency={props.currency}
                businessName={props.businessName}
              />
            ) : (
              <SurveyStepTwoWeb
                data={surveyData}
                surveyQuestionData={surveyQuestionData}
                setStep={(step) => {
                  setStepFromPage(step);
                }}
                setData={(data) => {
                  setSurveyDataFromPage(data);
                }}
                setSurveyQuestionData={(data) => {
                  setSurveyQuestionData(data);
                }}
                country={props.country}
                currency={props.currency}
              />
            )
          ) : null}
          {step == 2 ? (
            <SurveyStepOne
              data={surveyData}
              navigate={props.navigate}
              setStep={(step) => {
                setStepFromPage(step);
              }}
              setData={(data) => {
                setSurveyDataFromPage(data);
              }}
              country={props.country}
              currency={props.currency}
              businessName={props.businessName}
              surveyQuestionData={surveyQuestionData}
            />
          ) : null}
          {step === 4 ? (
            <SurveyLaunch
              data={surveyData}
              navigate={props.navigate}
              setStep={(step) => {
                setStepFromPage(step);
              }}
              country={props.country}
              currency={props.currency}
              surveyQuestionData={surveyQuestionData}
              getBalance={() => getWalletBalance()}
              walletBalance={walletBalance}
            />
          ) : null}
        </div>
        <div></div>
      </div>
    </TenantContext.Provider>
  );
};

export default SurveyParent;
