import { useEffect, useState } from "react";
import NotificationModal from "../../../modals/notification/NotificationModal";
import { updateMessage } from "../../../common/services/message-services";
import UnitValidation from "../../../../../marketing-cloud-main/src/common/components/unit-validation/unit-validation";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import * as Sentry from "@sentry/react";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import { getCampaignCost } from "../../../common/services/common-service";
import { Popup } from "semantic-ui-react";
import CurrencySymbol from "../../../../../marketing-cloud-main/src/common/components/currency-symbol/currency-symbol";
import chatSvg from "../../../common/assets/icons/Chat.png";
import {
  InfoCircle,
  SmsLaunchIconAudience,
  SmsLaunchIconChannel,
  SmsLaunchIconLaunch,
  SmsLaunchIconMessage,
  SmsLaunchIconName,
  SmsLaunchIconObjective,
  SmsLaunchIconProfile,
  SmsLaunchIconSchedule,
} from "../../../assets/icons/icons";

const RcsStepThree = (props: any) => {
  useEffect(() => {
    if (props.data) {
      setData(props.data);
      updateCampaignCost();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [unitModalState, setUnitModalState] = useState(false);
  const [campaignCost, setCampaignCost] = useState(null);
  const selectedTemplate = props.data.message;
  const [data, setData] = useState({
    name: "",
    senderID: "",
    objective: "",
    content: {
      type: "TEXT",
      text: "",
    },
    landingPagesURL: "",
    operatorCostBreakdown: [],
    schedule: {
      immediate: true,
      timeline: {
        start: new Date().getTime(),
      },
    },
    profiles: 0,
    unitPrice: 0,
    rcsPages: 1,
    campaignStartDate: "",
    campaignStartTime: "",
    rcsTemplate: "",
  });

  const handleNotificationClose = () => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      show: false,
    }));
  };

  const getOperatorCostBreakdown = () => {
    let colors = ["#00A1E1", "#E53E51", "#1DBA71", "#EE46BC"];
    if (data.operatorCostBreakdown?.length !== 0) {
      return (
        <div>
          {data.operatorCostBreakdown?.map((el, index) => (
            <div key={index} className="flex justify-between">
              <div className="flex gap-1 items-center">
                <div
                  className={`rounded-full h-2 w-2 bg-[${colors[index]}]`}
                ></div>
                {el.operator}
              </div>
              <div>{<CurrencySymbol value={el.cost} />}</div>
            </div>
          ))}
        </div>
      );
    } else return null;
  };

  const getOperatorCost = () => {
    let min = Math.min(...data.operatorCostBreakdown.map((el) => el.cost));
    let max = Math.max(...data.operatorCostBreakdown.map((el) => el.cost));

    return min == max ? (
      <CurrencySymbol value={min} />
    ) : (
      <>
        From{" "}
        <span className="flex font-semibold text-gray-700">
          <div className="flex gap-1">
            <CurrencySymbol value={min} /> - {<CurrencySymbol value={max} />}
          </div>
        </span>
      </>
    );
  };

  const checkCostDIfferrence = () => {
    let min = Math.min(...data.operatorCostBreakdown.map((el) => el.cost));
    let max = Math.max(...data.operatorCostBreakdown.map((el) => el.cost));

    return min === max;
  };

  const [notificationData, setNotificationData] = useState({
    show: false,
    close: handleNotificationClose,
    type: "",
    message: "",
    heading: "",
  });

  const handleNotificationData = (data: any) => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      ...data,
    }));
    setTimeout(() => {
      handleNotificationClose();
    }, 4000);
  };

  const saveDraft = () => {
    Notify({
      type: "success",
      heading: "Saved successfully.",
      message: "Your campaign has been saved as draft.",
    });

    props.navigate("/engagements");
  };

  const validateWallet = () => {
    setLoading(true);
    setUnitModalState(true);
  };

  const handleSubmit = () => {
    setUnitModalState(false);

    try {
      setLoading(true);
      updateMessage({ ...props.data, status: "RUNNING" }, props.data.id)
        .then((res) => {
          const { error, response = {} } = res.data;
          if (error === false) {
            if (saveAsDraft) {
              saveDraft();
            } else {
              props.getBalance();
              Notify({
                type: "success",
                heading: "Campaign created successfully",
                message:
                  "Your campaign has been created successfully and will go live at the scheduled time after it has been approved by our content and ad policy team.",
              });
              props.navigate("/engagements");
            }
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }

          setLoading(false);
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.error(err);
          setLoading(false);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.error(err);
      return;
    }
  };

  const updateCampaignCost = async () => {
    setLoading(true);
    let cost = await getCampaignCost(data?.rcsPages * props.data?.budget, 41);
    setCampaignCost(cost);
    setLoading(false);
  };

  return (
    <div className="py-12 px-12">
      <div className="text-gray-500 font-semibold text-sm">STEP 3 OF 3</div>
      <div className="flex justify-between items-center w-full">
        <div className="flex-wrap justify-start items-start">
          <div className="flex rounded-xl py-4">
            <div className="flex gap-6">
              <div className="flex justify-center items-center flex-shrink-0 w-16 bg-gray-100 rounded-full h-16">
                <img src={SmsLaunchIconLaunch} alt="" />
              </div>
              <div className="text-left py-2">
                <div className="text-gray-70 font-semibold">
                  Preview and Launch
                </div>
                <div className="text-gray-500 mt-1 font-normal text-sm whitespace-no-wrap">
                  Preview your message and click “Launch” if everything checks
                  out.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <button
            className="py-3 px-6 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
            onClick={() => props.setStep(2)}
            disabled={loading}
          >
            Back
          </button>
          <button
            onClick={validateWallet}
            disabled={loading}
            className="py-2 px-10 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75 disabled:bg-blue-75 disabled:text-slate-500 disabled:border-slate-200"
          >
            {loading ? <Spinner className={"w-4 h-4"} /> : "Launch"}
          </button>
        </div>
      </div>

      <div className="mt-6 flex items-start gap-10">
        <div className="w-4/6 text-sm">
          <div className="mt-6">
            <div className="mb-6">
              <span className="text-gray-700 font-semibold mr-4">
                Audiences
              </span>
              <span
                onClick={() => props.setStep(1)}
                className="text-green-15 cursor-pointer"
              >
                Edit
              </span>
            </div>
            <div className="flex gap-16 justify-start mt-2.5">
              <div className="flex gap-4 mb-6 w-1/2">
                <img src={SmsLaunchIconAudience} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Audience
                  </span>
                  <span className="mt-1 text-gray-500">
                    {/* {data?.audience} */}Custom audience
                  </span>
                </div>
              </div>
              <div className="flex gap-4 mb-6">
                <img src={SmsLaunchIconProfile} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Profiles
                  </span>
                  <span className="mt-1 inline-block break-words text-gray-500">
                    {props.data?.budget}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-6">
              <span className="text-gray-700 font-semibold mr-4">Message</span>
              <span
                onClick={() => props.setStep(2)}
                className="text-green-15 cursor-pointer"
              >
                Edit
              </span>
            </div>
            <div className="flex gap-16 justify-start">
              <div className="flex gap-4 mb-6 w-1/2">
                <img src={SmsLaunchIconName} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">Name</span>
                  <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                    {data.name}
                  </span>
                </div>
              </div>
              <div className="flex gap-4 mb-6">
                <img src={SmsLaunchIconChannel} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Channel
                  </span>
                  <span className="mt-1 inline-block break-words text-gray-500">
                    RCS
                  </span>
                </div>
              </div>
            </div>
            <div className="flex gap-16 justify-start mt-2.5">
              <div className="flex gap-4 mb-6 w-1/2">
                <img src={SmsLaunchIconObjective} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Objective
                  </span>
                  <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                    {data.objective}
                  </span>
                </div>
              </div>
              <div className="flex gap-4 mb-6">
                <img src={SmsLaunchIconSchedule} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">Date</span>
                  <span className="mt-1 inline-block break-words text-gray-500">
                    {data.schedule?.immediate
                      ? new Date().toLocaleDateString("en-GB")
                      : new Date(
                          data.schedule?.timeline.start
                        ).toLocaleDateString("en-GB")}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex gap-16 justify-start mt-2.5">
              <div className="flex gap-4 mb-6 w-1/2">
                <img src={SmsLaunchIconMessage} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Template
                  </span>
                  <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                    {data.rcsTemplate}
                  </span>
                </div>
              </div>
              <div className="flex gap-4 mb-6">
                <img src={SmsLaunchIconSchedule} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Push time
                  </span>
                  <span className="mt-1 inline-block break-words text-gray-500">
                    {data.schedule?.immediate
                      ? "Immediate"
                      : new Date(
                          data.schedule?.timeline.start
                        ).toLocaleTimeString()}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <div className="mb-6">
              <span className="text-gray-700 font-semibold mr-4">Pricing</span>
              <span
                onClick={() => props.setStep(1)}
                className="text-green-15 cursor-pointer"
              >
                Edit
              </span>
            </div>
            <div className="flex gap-16 justify-start mt-2.5">
              <div className="flex gap-4 mb-6 w-1/2">
                <img src={SmsLaunchIconProfile} alt="" />
                <div>
                  <div className="flex gap-1 font-semibold text-gray-70 mb-1">
                    Unit price per SMS
                    {!checkCostDIfferrence() && (
                      <Popup
                        content={getOperatorCostBreakdown()}
                        style={{
                          width: "130px",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "18px",
                        }}
                        trigger={
                          data.operatorCostBreakdown?.length !== 0 && (
                            <div>
                              <img src={InfoCircle} alt="" />
                            </div>
                          )
                        }
                        on={"hover"}
                        inverted={true}
                      />
                    )}
                  </div>

                  {data.operatorCostBreakdown?.length !== 0 ? (
                    <div className="flex gap-2">{getOperatorCost()}</div>
                  ) : (
                    data.unitPrice
                  )}
                </div>
              </div>
              <div className="flex gap-4 mb-6">
                <img src={SmsLaunchIconProfile} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Total pricing
                  </span>
                  <span className="mt-1 inline-block break-words text-gray-500">
                    {props.currencySymbol}
                    {campaignCost ?? 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative mt-10 h-[510px]">
          <img className="h-[510px]" src={chatSvg} alt="" />
          {selectedTemplate && (
            <div className="absolute top-[53px] left-[12px] right-[12px] bottom-[50px]">
              <div className="flex items-end h-full overflow-hidden">
                <div className="p-2 w-full h-full overflow-auto">
                  <div className="flex gap-3 w-full h-auto">
                    <div
                      className="flex items-end"
                      style={{ height: "inherit" }}
                    >
                      <div className="h-[20px] w-[20px] rounded-full bg-blue-400 text-white flex justify-center items-center text-xs font-semibold">
                        S
                      </div>
                    </div>
                    <div className="h-full flex items-end w-[85%]">
                      <div className="bg-white text-xs p-2 w-full h-fit">
                        {selectedTemplate.carouselDetails?.length > 0 && (
                          <div className="py-2 px-6">
                            {selectedTemplate.carouselDetails.map(
                              (carousel, index) => {
                                return (
                                  <div className="mt-4" key={carousel.cardId}>
                                    <div className="text-gray-700 leading-6">
                                      {carousel.cardDescription}
                                    </div>
                                    <img
                                      className="h-[60px] w-[100px] mt-4 rounded"
                                      src={carousel.mediaUrl}
                                      alt=""
                                    />
                                    {carousel.suggestions?.length > 0 && (
                                      <div>
                                        {carousel.suggestions.map(
                                          (suggestion, index) => {
                                            return (
                                              <div
                                                key={suggestion.suggestionId}
                                              >
                                                {suggestion.suggestionType ===
                                                  "reply" && (
                                                  <div className="mt-4 border rounded-md text-xs py-2 px-5 text-gray-500 w-fit bg-white">
                                                    {suggestion.displayText}
                                                  </div>
                                                )}
                                                {suggestion.suggestionType ===
                                                  "dialer_action" && (
                                                  <div className="mt-4 border rounded-md text-xs py-2 px-5 text-gray-500 w-fit bg-white">
                                                    {suggestion.phoneNumber}
                                                  </div>
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {unitModalState && (
        <UnitValidation
          productId={41}
          requiredUnits={campaignCost}
          currencySymbol={props.currencySymbol}
          successCallBack={(status) => {
            if (status) {
              handleSubmit();
            } else {
              setLoading(false);
              setUnitModalState(false);
            }
          }}
          type="SMS"
        />
      )}
      <NotificationModal data={notificationData} />
    </div>
  );
};

export default RcsStepThree;
