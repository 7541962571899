import { useDispatch } from "react-redux";
import Header from "../../common/components/header";
import { importModule } from "../../common/utils/importModule";
import { useNavigate } from "react-router-dom";
import { setShowCommonLoader } from "../../redux-loader/loader-slice/loaderSlice";
import { TenantContext } from "../home";
import { useContext } from "react";

const SegmentApp = importModule("@tg/audience", "SegmentMain");

const Segment = () => {
  const tenantContext: any = useContext(TenantContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div>
      <Header pageName="Audience" />
      <div>
        <SegmentApp
          navigate={navigate}
          dispatch={dispatch}
          setShowCommonLoader={setShowCommonLoader}
          tenantContext={tenantContext}
        />
      </div>
    </div>
  );
};

export default Segment;
