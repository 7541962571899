import "./template-creation/index.css";

const Loader = ({ className }) => {
  return (
    <div className="animate-spin grid items-center justify-center">
      <div
        className={`${className} border-[5px] border-t-indigo-950 border-white rounded-[50%]`}
      ></div>
    </div>
  );
};

export default Loader;
