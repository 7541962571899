import {
  Link,
  Bold,
  Tt,
  Italic,
  UnderLine,
} from "../../../../assets/icons/icons";

const QuestionStyle = (props: any) => {
  return (
    <div className="h-[48px] bg-[#F9FAFB] border border-[#F9FAFB] rounded drop-shadow-md flex">
      <div className="flex p-3">
        <div className="flex items-center">
          <img src={Tt} alt="" />
        </div>
      </div>
      <div className="border-x-1 p-3 flex items-center gap-3">
        <div>
          <img src={Bold} alt="" />
        </div>
        <div>
          <img src={Italic} alt="" />
        </div>
        <div>
          <img src={UnderLine} alt="" />
        </div>
      </div>
      <div className="flex items-center p-3">
        <img src={Link} alt="" />
      </div>
    </div>
  );
};

export default QuestionStyle;
