import { useEffect, useState } from "react";
import DropdownSurveySummary from "./survey-summary-questions/dropdown-survey-summary";
import FormSurveySummary from "./survey-summary-questions/form-survey-summary";
import ImageChoiceSurveySummary from "./survey-summary-questions/image-choice-survey-summary";
import LinearScaleSurveySummary from "./survey-summary-questions/linear-scale-survey-summary";
import MatrixSurveySummary from "./survey-summary-questions/matrix-survey-summary";
import MultipleChoiceSurveySummary from "./survey-summary-questions/multiple-choice-survey-summary";
import RatingSurveySummary from "./survey-summary-questions/rating-survey-summary";
import TextSurveySummary from "./survey-summary-questions/text-survey-summary";
import { getSurveySummary } from "../../../../common/services/survey-services";
import * as Sentry from "@sentry/react";

const SurveySummary = ({ id, dispatch, setShowCommonLoader }, props: any) => {
  const [surveySummary, setSurveySummary] = useState([]);

  useEffect(() => {
    try {
      dispatch(setShowCommonLoader(true));
      getSurveySummary("responses-summary", id.replaceAll("~", "%2F"))
        .then((res) => {
          setSurveySummary(
            res?.response?.responseSummary ? res.response.responseSummary : []
          );
          dispatch(setShowCommonLoader(false));
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          dispatch(setShowCommonLoader(false));
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
      dispatch(setShowCommonLoader(false));
    }
  }, []);

  const getSumOfresponses = (question) => {
    let sum = 0;
    question.responseCounts.map((count) => {
      sum += count.totalCount;
    });
    return sum;
  };

  return (
    <div className="flex gap-2 flex-wrap">
      {surveySummary &&
        surveySummary.map((question, index) => {
          return (
            <div
              key={index}
              className={` border rounded p-6 mt-6 ${
                question.questionType === "matrix"
                  ? "w-full"
                  : "w-[calc(50%-4px)]"
              }`}
            >
              <div className="text-sm text-gray-700 font-semibold">
                Q{question.index}. {question.questionText}
              </div>
              <div className="mt-2 bg-[#F2F4F7] py-1 px-2 text-xs text-[#30466A] rounded-full w-fit">
                {getSumOfresponses(question)} responses
              </div>
              {question.questionType === "text" ? (
                <TextSurveySummary question={question} />
              ) : null}
              {question.questionType === "multiple choice" ? (
                <MultipleChoiceSurveySummary question={question} />
              ) : null}
              {question.questionType === "options" ? (
                <MultipleChoiceSurveySummary question={question} />
              ) : null}
              {question.questionType === "form" ? (
                <FormSurveySummary question={question} />
              ) : null}
              {question.questionType === "matrix" ? (
                <MatrixSurveySummary question={question} />
              ) : null}
              {question.questionType === "image choice" ? (
                <ImageChoiceSurveySummary question={question} />
              ) : null}
              {question.questionType === "linear scale" ? (
                <LinearScaleSurveySummary question={question} />
              ) : null}
              {question.questionType === "rating" ? (
                <RatingSurveySummary question={question} />
              ) : null}
              {question.questionType === "dropdown" ? (
                <DropdownSurveySummary question={question} />
              ) : null}
            </div>
          );
        })}
    </div>
  );
};

export default SurveySummary;
