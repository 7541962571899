import { useState } from "react";
import { Close } from "../../../assets/icons/icons";

const RcsTemplateSelector = ({ templates, selectedTemplate, close }) => {
  const [currentTemplate, setCurrentTemplate] = useState(selectedTemplate);
  return (
    <div className="absolute w-full h-full top-0 left-0 bg-gray-712 flex justify-center items-center">
      <div className="w-[720px] h-[630px] bg-white p-8 rounded shadow-md">
        <div className="flex justify-between items-center">
          <div className="flex items-center text-gray-700 text-xl leading-7 font-semibold">
            Message templates
          </div>
          <div
            className="flex items-center justify-center cursor-pointer"
            onClick={() => close(false)}
          >
            <img src={Close} alt="" />
          </div>
        </div>
        <div>
          <div className="flex gap-2">
            {["All Templates"].map((template, index) => {
              return (
                <div
                  key={index}
                  className="py-2 text-primary-green border-b-2 border-b-primary-green leading-6 w-fit mt-6"
                >
                  {template}
                </div>
              );
            })}
          </div>
        </div>
        {templates.length > 0 && (
          <div className="flex mt-6 h-[393px]">
            <div className="w-1/3 h-full">
              {templates.map((t, index) => {
                let template = JSON.parse(t.content);
                return (
                  <div
                    key={template.templateDeails.templateId}
                    className={`py-2 px-4 text-sm font-semibold leading-6 w-full h-[40px] ${
                      template.templateDeails.templateId ===
                      currentTemplate.templateDeails.templateId
                        ? "text-primary-green bg-overlay-green"
                        : "text-gray-700 cursor-pointer"
                    }`}
                    onClick={() => setCurrentTemplate(template)}
                  >
                    {template.templateDeails.templateName}
                  </div>
                );
              })}
            </div>
            <div className="flex w-2/3 h-full">
              {currentTemplate && (
                <div className="flex h-full w-full">
                  {currentTemplate.carouselDetails?.length > 0 && (
                    <div className="py-2 px-6">
                      {currentTemplate.carouselDetails.map(
                        (carousel, index) => {
                          return (
                            <div className="mt-4" key={carousel.cardId}>
                              <div className="text-gray-700 leading-6">
                                {carousel.cardDescription}
                              </div>
                              <img
                                className="h-[60px] w-[100px] mt-4 rounded"
                                src={carousel.mediaUrl}
                                alt=""
                              />
                              {carousel.suggestions?.length > 0 && (
                                <div>
                                  {carousel.suggestions.map(
                                    (suggestion, index) => {
                                      return (
                                        <div key={suggestion.suggestionId}>
                                          {suggestion.suggestionType ===
                                            "reply" && (
                                            <div className="mt-4 border rounded-md text-xs py-2 px-5 text-gray-500 w-fit">
                                              {suggestion.displayText}
                                            </div>
                                          )}
                                          {suggestion.suggestionType ===
                                            "dialer_action" && (
                                            <div className="mt-4 border rounded-md text-xs py-2 px-5 text-gray-500 w-fit">
                                              {suggestion.phoneNumber}
                                            </div>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        {templates.length === 0 && (
          <div className="flex mt-6 h-[393px] justify-center items-center text-sm text-gray-400">
            No templates found
          </div>
        )}
        <div className="flex gap-4 justify-end mt-6 h-[40px]">
          <div
            className="h-full flex justify-center items-center rounded-md border border-gray-40 cursor-pointer px-5 text-gray-70 font-semibold leading-4"
            onClick={() => close(false)}
          >
            Cancel
          </div>
          <div
            className="h-full flex justify-center items-center rounded-md border border-blue-75 cursor-pointer px-5 text-white bg-blue-75 font-semibold leading-4"
            onClick={() => close(currentTemplate)}
          >
            Continue
          </div>
        </div>
      </div>
    </div>
  );
};

export default RcsTemplateSelector;
