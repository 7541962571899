import { useState } from "react";

const DefaultMessageModal = (props: any) => {
  if (!props.show || props.selectedAttributeIndex < 0) {
    return null;
  }

  const [text, setText] = useState("");

  const handleChange = (e) => {
    const text = e.target.value;
    setText(text);
  };

  const handleSave = () => {
    if (text) {
      props.insertVariable(
        `{{${props.defaultNames[props.selectedAttributeIndex]?.fieldName}}}`
      );
      //let defaultValueArray = [...props.defaultNames, { de }];
      const selectAttrAr = [...props.defaultNames];
      selectAttrAr[props.selectedAttributeIndex].defaultName = text;
      props.setDefaultName(selectAttrAr);
      props.closeModal();
    }
  };

  return (
    <div
      className="fixed z-[1] top-0 left-0 right-0 bottom-0 bg-black bg-opacity-30 flex items-center justify-center"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="bg-white rounded-xl  max-h-5/6 w-[600px]">
        <div className="">
          <div className="flex justify-between items-start text-gray-700 px-8 pt-8">
            <div className="flex items-center gap-2 text-lg leading-6 font-semibold">
              <span>Default Message</span>
            </div>

            <div
              className="font-extrabold cursor-pointer"
              onClick={props.closeModal}
            >
              ✕
            </div>
          </div>
          <div className="flex justify-between items-start text-gray-600 px-8 py-3">
            A default message is required for profiles that do not have this
            attribute
          </div>
          <input
            type="text"
            value={text}
            maxLength={10}
            placeholder={
              props.defaultNames[props.selectedAttributeIndex]?.fieldName
            }
            onChange={handleChange}
            className={`py-2    px-8 border-2 ml-7  mr-4 text-sm leading-6 rounded outline-1 text-gray-700`}
          />

          <div className="text-gray-70 text-sm leading-6 cursor-pointer">
            <div className="flex justify-end p-8">
              <div
                className="border border-gray-50 rounded-lg mr-4 py-3 px-5 bg-white text-gray-90 font-semibold text-sm leading-4 cursor-pointer"
                onClick={() => {
                  props.closeModal();
                }}
              >
                Cancel
              </div>

              <div>
                <button
                  className="rounded-lg py-3 px-5 bg-blue-75 text-white font-semibold text-sm leading-4 cursor-pointer"
                  onClick={() => {
                    handleSave();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultMessageModal;
