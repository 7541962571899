import { useEffect, useRef, useState } from "react";
import Chevron from "../../../../../common/assets/icons/Chevron";

const Dropdown = (props: any) => {
  let { options = [] } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownMarker = useRef<HTMLInputElement>(null);

  const closeOpenMenus = (e: any) => {
    if (dropdownMarker.current && !dropdownMarker.current?.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);

    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  });

  const handleSelection = (option) => {
    // console.log(event);
    // let value = event.target.getAttribute("data-value");
    // console.log(value);
    props.handleChange(option);
  };

  // let Marker = props.marker;

  return (
    <div
      ref={dropdownMarker}
      className="relative cursor-pointer"
      onClick={(e) => {
        setShowDropdown(!showDropdown);
      }}
    >
      <button
        disabled={props.disabled}
        className={`relative px-3 py-2 ${props.className} flex justify-between items-center gap-2`}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className={`first-letter:capitalize ${
              !props.value && "text-gray-500"
            }`}
            onClick={(e) => {
              setShowDropdown(!showDropdown);
            }}
          >
            {props.value ? props.value : props.placeholder}
          </div>
        </div>

        <Chevron />
      </button>
      {showDropdown && (
        <ul
          className={`absolute bg-white shadow-xl z-20 rounded-lg max-h-[200px] overflow-y-auto min-w-full w-max`}
          id="dropdown-scroll"
        >
          {options.map((option, index) => (
            <li
              className={`flex items-center hover:bg-gray-100 rounded-[5px]`}
              key={index}
              onClick={() => handleSelection(option.value)}

              //   style={{ color: tags?.includes(option) ? "green" : "" }}
            >
              {/* <Checkbox selected={props.value === option}></Checkbox> */}
              {/* {Marker ? <Marker /> : null} */}
              <div
                data-value={option.value}
                className={`text-sm p-3 leading-6 ${
                  props.value === option
                    ? "text-green-700 font-medium"
                    : "font-normal"
                }`}
              >
                {option.label}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
