import ReactECharts from "echarts-for-react";
import { useEffect, useState } from "react";

const colors = ["#EF8B8E", "#5AA0F1", "#3DE18C", "#F3D48E", "#8172C6"];

const MatrixSurveySummary = ({ question }) => {
  const [option, setOption] = useState({});

  useEffect(() => {
    let list = [];
    let newCol = [];
    let series = [];
    question.responseCounts.map((element, i) => {
      let ob: any = {};
      Object.keys(element).map((key, j) => {
        let entries = [];
        key !== "totalCount" ? entries.push(key) : null;
        let col = [];
        let s = [];
        Object.keys(element[key]).map((final, k) => {
          col.push(final);
          entries.push(element[key][final]);
          newCol = ["col", ...col];
          s.push({ type: "bar", itemStyle: { color: colors[k] }, barGap: 0 });
          series = [...s];
        });
        entries.length > 0 ? list.push(entries) : null;
      });
    });
    let newList = [newCol, ...list];
    const o = {
      legend: { itemHeight: 4 },
      tooltip: {
        trigger: "item",
        backgroundColor: "#13163E",
        borderColor: "#13163E",
        textStyle: { color: "#FFFFFF", fontSize: 12, lineHeight: 16 },
        // formatter: `{a}<br />{e}`,
      },
      dataset: {
        source: newList,
      },
      xAxis: {
        type: "category",
        axisLabel: {
          interval: 0,
          overflow: "truncate",
        },
      },
      yAxis: {},
      series: series,
    };
    setOption(o);
  }, []);
  return (
    <div>
      {option && <ReactECharts style={{ height: "350px" }} option={option} />}
    </div>
  );
};

export default MatrixSurveySummary;
