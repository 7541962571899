import { useContext, useEffect } from "react";
import Header from "../../common/components/header";
import { importModule } from "../../common/utils/importModule";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { TenantContext } from "../home";
import { useDispatch } from "react-redux";
import { setShowCommonLoader } from "../../redux-loader/loader-slice/loaderSlice";
import { pageLoadEvent } from "../../../../marketing-cloud-container/src/ga-events";

const ConversationsApp = importModule("@tg/conversations", "ConversationsMain");

const Conversations = () => {
  useEffect(() => {
    pageLoadEvent("conversations_page");
  }, []);
  let { id } = useParams();
  const navigate = useNavigate();
  const tenantContext: any = useContext(TenantContext);
  const dispatch = useDispatch();

  return (
    <div>
      <Header pageName="Conversations" />
      <div>
        <ConversationsApp
          id={id}
          navigate={navigate}
          tenantContext={tenantContext}
          dispatch={dispatch}
          setShowCommonLoader={setShowCommonLoader}
        />
      </div>
    </div>
  );
};

export default Conversations;
