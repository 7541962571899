import { useCallback, useEffect, useState } from "react";
import sanitizeHtml from "sanitize-html";
import { Search } from "react-feather";
import * as Sentry from "@sentry/react";
import {
  fetchSelectedTemplate,
  getEmailTemplateList,
} from "../../../common/services/message-services";
import { Close } from "../../../assets/icons/icons";
import { debounce } from "../../../common/utils/debounce";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";

const ChooseTemplateModal = (props) => {
  const [templateList, setTemplateList] = useState([]);
  const [totalListLength, setTotalListLength] = useState(null);
  const [nameString, setNameString] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [emailContent, setEmailContent] = useState({
    jsonContent: "",
    htmlContent: "",
  });
  const [from, setFrom] = useState(0);
  const [loading, setLoading] = useState({
    gettingList: false,
    gettingTemplate: false,
  });

  useEffect(() => {
    getTemplateList(0, 7);
  }, [nameString]);

  useEffect(() => {
    if (selectedTemplate) getSelectedTemplate();
  }, [selectedTemplate]);

  const handleTemplateSelection = (e) => {
    const target = e.target;
    const id = target.getAttribute("data-value");
    if (id && id !== selectedTemplate) {
      setSelectedTemplate(id);
    }
  };

  const onNameChange = (value) => {
    setTemplateList([]);
    setNameString(value);
  };

  const handleMsisdnSearch = useCallback(debounce(onNameChange), []);

  const getSelectedTemplate = () => {
    setLoading((loading) => ({ ...loading, gettingTemplate: true }));
    try {
      fetchSelectedTemplate(selectedTemplate)
        .then((res) => {
          setLoading((loading) => ({ ...loading, gettingTemplate: false }));
          const { error, response = {} } = res;
          if (!error) {
            setEmailContent(response?.response?.content);
            props.setTemplateName(response?.response?.name);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          setLoading((loading) => ({ ...loading, gettingTemplate: false }));
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      setLoading((loading) => ({ ...loading, gettingTemplate: false }));
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
    }
  };

  const getTemplateList = (from, size) => {
    let params = { name: nameString };
    setLoading((loading) => ({ ...loading, gettingList: true }));
    try {
      getEmailTemplateList(from, size, params)
        .then((res) => {
          setLoading((loading) => ({ ...loading, gettingList: false }));
          const { error, response } = res;
          if (!error) {
            setFrom(from + 7);
            setTotalListLength(response.total);
            setTemplateList([...templateList, ...response.templateList]);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          setLoading((loading) => ({ ...loading, gettingList: false }));
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      setLoading((loading) => ({ ...loading, gettingList: false }));
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
    }
  };

  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
      onClick={() => {
        props.closeModal();
      }}
    >
      <div
        className="bg-white rounded-xl p-8 max-h-5/6 overflow-y-auto"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex justify-between text-xl text-gray-700 mb-7 font-semibold items-center">
          <div>Email Template</div>
          <div className="cursor-pointer" onClick={props.closeModal}>
            <img src={Close} alt="" />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="w-[350px] mt-5">
            <div className="flex place-items-start">
              <div className="w-full flex rounded border border-gray-200">
                <Search className="text-xl m-3" size="16" />
                <input
                  className="w-full px-3 py-2 outline-0 rounded-md"
                  placeholder="Search templates"
                  onChange={(e) => handleMsisdnSearch(e.target.value)}
                />
              </div>
            </div>
            <div
              onClick={handleTemplateSelection}
              className="max-h-[420px] overflow-y-auto no-scrollbarTrack mt-5"
            >
              <div
                onClick={() => {
                  props.closeModal();
                }}
                className={`mx-3 text-sm font-medium text-gray-700 rounded-md cursor-pointer`}
              >
                <div className="text-sm font-semibold leading-6 text-gray-700">
                  Blank Template
                </div>
                <div className="text-xs">
                  Use this to create your email from scratch
                </div>
              </div>
              {templateList.map((el, index) => (
                <div
                  className={`text-gray-700 ${
                    el.id === selectedTemplate && "bg-gray-200 text-white"
                  } my-3 mr-2 p-3 text-sm font-medium rounded-md cursor-pointer`}
                  key={index}
                  data-value={el.id}
                >
                  {el.name ? el.name : "no name"}
                </div>
              ))}

              {totalListLength !== templateList.length ? (
                <div className="flex justify-center mt-3">
                  <div className="p-2 rounded-full bg-gray-200 hover:bg-green-75">
                    {loading.gettingList ? (
                      <Spinner className={"w-4 h-4"} />
                    ) : (
                      <div
                        onClick={() => getTemplateList(from, 7)}
                        className="text-center text-xs font-semibold cursor-pointer text-white"
                      >
                        Load more
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="relative overflow-hidden h-[520px] w-[340px] bg-slate-200 rounded-lg p-6">
            <div className="text-sm text-center font-semibold text-gray-700 align-text-top">
              Template preview
            </div>

            {selectedTemplate ? (
              loading.gettingTemplate ? (
                <div className="flex items-center justify-center mt-32">
                  <Spinner className={"w-8 h-8"} />{" "}
                </div>
              ) : (
                <div className="absolute top-[-130px] left-[-80px] right-0 bottom-0 tranform scale-[0.43]">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(emailContent?.htmlContent),
                    }}
                  ></div>
                </div>
              )
            ) : (
              <div className="flex justify-center items-center mt-4">
                <div className="text-sm text-gray-35 max-w-[150px] text-center">
                  Select a template to preview it here
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-end mt-5 gap-5">
          <button
            onClick={props.closeModal}
            className="px-5 py-2 rounded-md text-sm leading-6 text-slate-600 font-semibold border border-gray-40 hover:border-gray-200"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              if (!emailContent?.htmlContent) {
                Notify({
                  type: "error",
                  heading: "Error",
                  message: "Please choose a template to continue",
                });
                return;
              }
              props.selectTemplate(emailContent);
              props.closeModal();
            }}
            className="px-5 py-2 w-24 rounded-md text-sm leading-6 bg-blue-75 disabled:bg-blue-75 hover:bg-green-75 text-white font-semibold flex justify-center items-center"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChooseTemplateModal;
