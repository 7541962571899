import { useEffect, useRef, useState } from "react";
import { getMessageDetails } from "../../../common/services/message-services";
import { getAccountStat } from "../../../../../marketing-cloud-main/src/common/apis/billing";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import { isMobileNumberDuplicate } from "../../../common/utils/mobileNumberValidation";
import UpgradePlanModal from "../../../../../marketing-cloud-main/src/modals/upgrade-plan-modal";
import { cappingCheck } from "../../../../../marketing-cloud-main/src/common/utils/capping-check";
import {
  Chevron,
  DropDownArrow,
  People,
  SelectedFile,
  File,
  InfoCircle,
} from "../../../assets/icons/icons";
import {
  getAudienceById,
  getSegments,
  postAudienceFromCampaign,
  uploadSegmentCSV,
} from "../../../../../marketing-cloud-main/src/common/apis/audience";
import { X } from "react-feather";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import { SubscriptionRedirectModal } from "../../../common/components/subscription-redirect-modal/Subscription-redirect-modal";
import * as Sentry from "@sentry/react";
import ArrowRight from "../../../../../marketing-cloud-main/src/common/assets/icons/ArrowRight";

const StepOne = (props: any) => {
  const [manualType, setManualType] = useState("Add numbers manually");
  const [uploadType, setUploadType] = useState(true);
  const [isConsentReceived, setIsConsentReceived] = useState(false);
  const [mobileNumbersList, setMobileNUmbersList] = useState([]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [fileName, setFielName] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [profiles, setProfiles] = useState(0);
  const [budget, setBudget] = useState(0);
  const [profilesAdded, setProfilesAdded] = useState(0);
  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  const [fileValidation, setFileValidation] = useState(false);
  const [numberValidation, setNumberValidation] = useState(false);
  const [checkValidation, setCheckValidation] = useState(false);
  const [numberListError, setNumberListError] = useState("");
  const [unitPrice, setUnitPrice] = useState(0);
  const [data, setData] = useState([]);
  const [segmentId, setSegmentId] = useState();
  const country = props.country;
  const currency = props.currency;
  const [planUpdgrade, setPlanUpgrade] = useState(false);
  const [segmentError, setSegmentError] = useState(false);
  const [segmentList, setSegmentList] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [showSegmentSelector, setShowSegmentSelector] = useState(false);
  const segmentListInnerRef = useRef();
  const [lastUpdatedSegmentListLength, setLastUpdatedSegmentListLength] =
    useState(-1);
  const [manualTypeError, setManualTypeError] = useState(false);
  const [showManualOptions, setShowManualOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const manualOptions = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploadSpinnerStatus, setFileUploadSpinnerStatus] = useState(false);
  const [audiencename, setAudiencename] = useState("");
  const [tierId, setTierId] = useState(null);
  const [subscriptionRedirectModalStatus, setSubscriptionRedirectModalStatus] =
    useState(false);
  const [fileSizeValidator, setFileSizeValidator] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const closeOpenMenus = (e) => {
    if (
      manualOptions.current &&
      showManualOptions &&
      !manualOptions.current.contains(e.target)
    ) {
      setShowManualOptions(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  useEffect(() => {
    getSegmentList(0, 20);
    getAccountStat()
      .then((response) => {
        let tierId = JSON.parse(response?.subscriptions[0])[0]?.tier_id;
        setTierId(tierId);
      })
      .catch((error) => {
        setTierId(0);
        console.log(error);
      });

    if (props.data.audience.segmentId) {
      setUploadType(false);
      getAudienceById(props.data.audience.segmentId)
        .then((response) => {
          if (!response.error) {
            setSelectedSegment(response?.data[0]);
            setProfilesAdded(response?.data[0].audience_size);
            if (props?.data?.budget) setBudget(props.data.budget);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setMobileNUmbersList(props.data.audience.phoneNumbers);
      setProfilesAdded(props.data.audience.phoneNumbers.length);
    }
    if (props?.data?.budget) setBudget(props.data.budget);
    if (props?.data?.fileName) setFielName(props.data.fileName);
    if (props?.data.uploadedfile) setUploadedFile(props?.data.uploadedfile);
    if (props?.data?.profiles) setProfiles(props.data.profiles);
    if (props?.data?.audience.phoneNumbers.length > 0)
      setMobileNUmbersList(
        props.data.audience.phoneNumbers ? props.data.audience.phoneNumbers : []
      );
    if (props?.data?.uploadedFile) setUploadedFile(props?.data?.uploadedFile);
    if (props?.data?.manualType) setManualType(props.data.manualType);
    if (props.data.segment) {
      onSegmentSelectionChange(props.data.segment);
    }
  }, [props.data]);

  useEffect(() => {
    audiencename?.length > 0 && setUploadType(false);

    if (props?.data?.audienceName) {
      var name = props?.data?.audienceName;
    }
    if (
      selectedSegment?.audience_name &&
      props?.data?.audienceName != selectedSegment?.audience_name
    ) {
      name = selectedSegment?.audience_name;
    }

    setAudiencename(name);
  }, [selectedSegment?.audience_name]);

  useEffect(() => {
    try {
      const id = props.id || props.data.id;
      setLoading(true);
      getMessageDetails(id)
        .then((res) => {
          setLoading(false);
          const { error, response = {} } = res;
          if (!error || error === "false") {
            setData(response);

            // dispatch(setShowCommonLoader(false));
          } else {
            // dispatch(setShowCommonLoader(false));
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })

        .catch((err) => {
          setLoading(false);
          console.log(err);
          // dispatch(setShowCommonLoader(false));
        });
    } catch (err) {
      setLoading(false);
      // dispatch(setShowCommonLoader(false));
    }
    if (props?.data?.audience.phoneNumbers.length > 0) {
      setMobileNUmbersList(
        props.data.audience.phoneNumbers ? props.data.audience.phoneNumbers : []
      );
    }
    if (props?.data?.uploadedFile) {
      setSelectedFile(props?.data?.uploadedFile);
    }

    if (
      props?.data?.audience.phoneNumbers.length > 0 ||
      props?.data?.uploadedFile
    )
      setIsConsentReceived(true);
  }, []);

  const getProductIdForCategory = (category) => {
    switch (category) {
      case "AUTHENTICATION":
        return 18;
      case "MARKETING":
        return 9;
      case "UTILITY":
        return 19;
    }
  };

  const manualTypeOnChange = (event) => {
    setProfiles(0);
    setBudget(0);
    setManualType(event.target.value);
  };

  const consentRecievedOnChange = (event) => {
    if (event.target.checked) setCheckValidation(false);
    setIsConsentReceived(event.target.checked);
  };

  const onInputChange = (event) => {
    if (event.code == "Enter") {
      if (isMobileNumberDuplicate(mobileNumber, mobileNumbersList)) {
        Notify({
          type: "error",
          heading: "Duplicate Mobile Number",
          message: `${mobileNumber} is already added. Add a different number.`,
        });
      } else {
        mobileNumbersList.push(mobileNumber);
        setMobileNumber("");
        let profiles: any = getNumberOfValidphones();
        setProfiles(profiles);
        setProfilesAdded(profiles);
        setBudget(profiles * unitPrice);
        if (numberValidation) {
          if (isNumberListValid()) setNumberValidation(false);
        }
      }
    }
  };

  const onInputMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const popItemFromMobileList = (item) => {
    let list = [...mobileNumbersList];
    let index = list.lastIndexOf(item);
    if (index > -1) {
      list.splice(index, 1);
    }
    setMobileNUmbersList(list);
    let profiles: any = getNumberOfValidphones();
    setProfiles(profiles);
    setProfilesAdded(profiles);
    setBudget(profiles * unitPrice);
  };

  const isPhoneNumberValid = (number: any) => {
    if (number[0] === 0 || number.length !== 10) {
      return false;
    }
    return true;
  };

  const onFileSelected = (event) => {
    setFileSizeValidator(false);
    if (event.target.files && event.target.files[0]) {
      if (
        parseFloat(
          (event.target.files[0].size / Math.pow(1000, 2)).toFixed(2)
        ) > 50
      ) {
        setFileSizeValidator(true);
        return;
      }
      setFielName("Uploading...");
      setFileUploadSpinnerStatus(true);
      const file = event.target.files[0];
      setFileValidation(false);
      try {
        uploadSegmentCSV(file)
          .then((res) => {
            const { data, response = {} } = res;
            if (data && !data?.error) {
              //TODO add api call to create an audience with segment Id
              const {
                total_profiles,
                duplicate,
                uploaded_profiles,
                invalid_profiles,
              } = data.data;
              const breakdownMessage = `Uploaded: ${uploaded_profiles}, Duplicate: ${duplicate}, Invalid: ${invalid_profiles}, Total: ${total_profiles}`;
              postAudienceFromCampaign({
                segment: data.data.segment,
                name: file.name.split(".")[0],
                size: data.data.uploaded_profiles,
                description: "Campaign",
              })
                .then((audience: any) => {
                  setFileUploadSpinnerStatus(false);
                  setSelectedFile(file);
                  setUploadedFile(file);
                  setFielName(file.name);
                  setFileUploadStatus(true);
                  let f = {
                    name: file.name,
                    size: file.size,
                    count: uploaded_profiles,
                  };
                  setSelectedFile(f);
                  setUploadedFile(f);
                  processCsv(f);
                  setSegmentId(audience.data.audience_id);
                  Notify({
                    type: "success",
                    heading: "Import successful",
                    message: `${breakdownMessage}`,
                  });
                })
                .catch((err) => {
                  err.response
                    ? Sentry.captureMessage("Error", err.response)
                    : Sentry.captureMessage("Error", err);
                  setFileUploadSpinnerStatus(false);
                  unselectFile();
                  console.log(err);
                });
            } else {
              Sentry.captureMessage("Error", res);
              setFileUploadSpinnerStatus(false);
              unselectFile();
            }
          })
          .catch((err) => {
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            setFileUploadSpinnerStatus(false);
            unselectFile();
            console.log("File upload error", err);
          });
      } catch (err) {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        setFileUploadSpinnerStatus(false);
        unselectFile();
        console.log("File upload error", err);
      }
    } else {
      setFileUploadSpinnerStatus(false);
      unselectFile();
    }
  };

  const processCsv = (file) => {
    setProfiles(file.count);
    setBudget(file.count * unitPrice);
    setProfilesAdded(file.count);
  };

  const unselectFile = () => {
    setSelectedFile(null);
    setFielName("");
    setUploadedFile("");
    setProfiles(0);
    setBudget(0);
    setProfilesAdded(0);
  };

  const budgetOnChange = (event) => {
    if (event.target.value === "") {
      setBudget(0);
      setProfiles(0);
    } else {
      let value =
        event.target.value[0] == 0
          ? event.target.value.substring(1)
          : event.target.value;
      setBudget(value);
      setProfiles(Math.floor(value / unitPrice));
    }
  };

  const profileOnChange = (event) => {
    if (event.target.value === "") {
      setProfiles(0);
      setBudget(0);
    } else {
      let value =
        event.target.value[0] == 0
          ? event.target.value.substring(1)
          : event.target.value;
      setProfiles(value);
      setBudget(value * unitPrice);
    }
  };

  const continueToNext = async (event, draft) => {
    setShowErrors(true);
    setLoading(true);

    let status = await cappingCheck(profiles, 9);
    if (!status) {
      setPlanUpgrade(true);
      setLoading(false);
      return;
    }
    const data: any = props.data;
    data.message = "";
    let id = data.id.replaceAll("/", "~");
    if (uploadType) {
      if (manualType === "Upload a file") {
        if (!fileUploadStatus) {
          setFileValidation(true);
          setLoading(false);
          return;
        }
        data.profiles = Number(profiles);
        data.budget = Number(profiles);
        data.fileName = fileName;
        data.uploadedFile = uploadedFile;
        data.audienceName = audiencename;
        data.manualType = manualType;
        data.unitPrice = unitPrice;
        data.totalPricing = budget;
        data.audience = { phoneNumbers: [], segmentId: segmentId };
        props.setData(data);
        props.setStep(2);
        setFileValidation(false);
      } else {
        if (mobileNumbersList.length === 0) {
          setNumberValidation(true);
          setNumberListError("Phone number list cannot be empty");
          setLoading(false);
          return;
        }
        if (!isNumberListValid()) {
          setNumberValidation(true);
          setNumberListError(
            "One or more of the inputted number is in a wrong format. Please ensure the phone number doesn’t include 0 or country code eg. 8066603063."
          );
          setLoading(false);
          return;
        }
        if (!isConsentReceived) {
          setCheckValidation(true);
          setLoading(false);
          return;
        }
        data.audience = { phoneNumbers: mobileNumbersList, segmentId: "" };
        data.profiles = Number(profiles);
        data.budget = Number(profiles);
        data.manualType = manualType;
        data.unitPrice = unitPrice;
        data.totalPricing = budget;
        data.audienceName = audiencename;
        data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        props.setData(data);
        props.setStep(2);
        setLoading(false);
        setNumberValidation(false);
      }
    } else {
      if (profiles <= 0 || profiles > profilesAdded) {
        return;
      }
      data.audience = {
        segmentId: selectedSegment?.audience_id,
        phoneNumbers: [],
      };
      data.profiles = Number(profiles);
      data.budget = Number(profiles);
      data.audienceName = audiencename;
      data.manualType = manualType;
      data.unitPrice = unitPrice;
      data.totalPricing = budget;
      data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      props.setData(data);
      props.setStep(2);
      setLoading(false);
      setNumberValidation(false);
    }
  };

  const isNumberListValid = () => {
    let status = true;
    mobileNumbersList.map((element, i) => {
      if (!isPhoneNumberValid(element)) {
        status = false;
      }
    });
    return status;
  };

  const goBack = (event) => {
    props.setStep(1);
  };

  const saveAsDraft = () => {};

  const getNumberOfValidphones = () => {
    let count = 0;
    mobileNumbersList.map((element, i) => {
      if (isPhoneNumberValid(element)) {
        count++;
      }
    });
    return count;
  };

  const resetAudience = () => {
    setProfiles(0);
    setProfilesAdded(0);
    setMobileNUmbersList([]);
    setMobileNumber("");
    setNumberValidation(false);
    setNumberListError("");
    setSelectedSegment(null);
    setSegmentError(false);
    setCheckValidation(false);
    setFileValidation(false);
    setManualType("Add numbers manually");
  };

  const segmentSelectorOnChange = () => {
    let segmentSelction = showSegmentSelector;
    setShowSegmentSelector(!segmentSelction);
  };

  const onSegmentListScroll = () => {
    if (segmentListInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        segmentListInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (lastUpdatedSegmentListLength !== 0) {
          getSegmentList(lastUpdatedSegmentListLength + 1, 20);
        }
      }
    }
  };

  const onSegmentSelectionChange = (segment) => {
    setSegmentError(false);
    setSelectedSegment(segment);
    setProfiles(segment.audience_size);
    setProfilesAdded(segment.audience_size);
    setBudget(segment.audience_size * unitPrice);
    setShowSegmentSelector(false);
  };

  const getSegmentList = (from, pageSize) => {
    setLoading(true);
    try {
      getSegments({ from, pageSize })
        .then((response) => {
          setLoading(false);
          let list: any[] = [];
          response.data.map((element) => {
            if (element.audience_id) {
              list.push(element);
            }
          });

          const item = list.find((val, ind) => {
            if (val.audience_name === props.data.audienceName) return val;
          });

          if (lastUpdatedSegmentListLength === -1) setSelectedSegment(item);
          let previousList = [...segmentList];
          let newList = previousList.concat(list);
          setSegmentList(newList);
          setLastUpdatedSegmentListLength(list.length);
        })
        .catch((err) => {
          setLoading(false);
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  const handleSubscriptionModalClick = (status) => {
    if (status) props.navigate("/pricing");
    else setSubscriptionRedirectModalStatus(false);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1000;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const dm = decimals < 0 ? 0 : decimals;

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  return (
    <div
      className="p-10"
      onClick={() => {
        if (showSegmentSelector) {
          setShowSegmentSelector(false);
        }
      }}
    >
      <div className="text-gray-500 text-sm font-semibold">STEP 1 OF 3</div>
      <div className="flex items-center justify-between mt-3">
        <div className="flex gap-4 items-center">
          <div className="bg-[#FFF4DD] h-[48px] w-[48px] rounded-[50%] flex items-center justify-center">
            <img src={People} alt="" />
          </div>
          <div>
            <div className="text-[#475467] font-semibold text-base">
              Audience details
            </div>
            <div className="text-gray-500 text-sm">Who are you targeting?</div>
          </div>
        </div>
        <div className="flex justify-end gap-2">
          <button
            onClick={() => props.navigate("/engagements")}
            className="border border-button-border rounded-md py-2 px-5 text-slate-600 font-semibold text-sm leading-6"
          >
            Cancel
          </button>
          <button
            disabled={loading}
            onClick={(event) => continueToNext(event, false)}
            className="border flex items-center gap-2 bg-blue-75 disabled:bg-blue-75 rounded-md py-2 px-5 font-semibold text-sm text-white hover:text-white hover:bg-green-75 leading-6"
          >
            <div>Next</div>
            <ArrowRight />
          </button>
        </div>
      </div>
      <div className="mt-9 flex">
        <div className="w-[62%]">
          <div className="flex justify-between gap-4">
            <div
              className={`border flex rounded-md py-3 px-5 justify-between w-1/2 cursor-pointer ${
                !uploadType ? "border-gray-700" : "border-gray-50"
              }`}
              onClick={() => {
                setUploadType(false);
                resetAudience();
              }}
            >
              <div>Add from Segments</div>
              <input
                className="accent-gray-700 cursor-pointer"
                type="radio"
                checked={!uploadType}
                onChange={() => {
                  setUploadType(false);
                  resetAudience();
                }}
              />
            </div>
            <div
              className={`border flex rounded-md py-3 px-5 justify-between w-1/2 cursor-pointer ${
                uploadType ? "border-gray-700" : "border-gray-50"
              }`}
              onClick={() => {
                setUploadType(true);
                resetAudience();
              }}
            >
              <div>Manual upload</div>
              <input
                className="accent-gray-700 cursor-pointer"
                type="radio"
                checked={uploadType}
                onChange={() => {
                  setUploadType(true);
                  resetAudience();
                }}
              />
            </div>
          </div>
          {uploadType && (
            <div className="mt-8">
              <div className="text-gray-70 font-semibold text-sm mb-1.5">
                Manual upload types
              </div>
              <div className="relative w-full">
                <button
                  name="surveyChannel"
                  className={`flex w-full justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-50 rounded-md focus:outline-none ${
                    manualType === "" && "text-gray-400"
                  } ${manualTypeError && "border-red-500"}`}
                  type="button"
                  onClick={() => {
                    setShowManualOptions(!showManualOptions);
                    setManualTypeError(false);
                  }}
                  disabled={loading}
                >
                  {manualType || "Select your manual upload option"}
                  <img src={Chevron} alt="" />
                </button>

                {showManualOptions && (
                  <div
                    ref={manualOptions}
                    className="absolute z-10 bg-white rounded-lg shadow w-full"
                  >
                    {["Add numbers manually", "Upload a file"].map(
                      (option, index) => (
                        <button
                          key={index}
                          className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                          role="menuitem"
                          onClick={() => {
                            setManualType(option);
                            setShowManualOptions(false);
                          }}
                        >
                          {option}
                        </button>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {!uploadType && (
            <>
              <div className="mt-8">
                <div className="mt-6">
                  <span className="text-sm text-gray-70 font-semibold">
                    Segments
                  </span>
                  <div className="relative mt-1">
                    <div
                      className={`border rounded h-[40px] px-3 py-2 cursor-pointer ${
                        segmentError && "border-red-500"
                      }`}
                      onClick={() => {
                        segmentSelectorOnChange();
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <span>
                          {segmentList.length
                            ? audiencename || "Select Audience"
                            : "No Audience available!"}
                        </span>
                        <img src={DropDownArrow} alt="" />
                      </div>
                    </div>
                    {showSegmentSelector && (
                      <div
                        className="absolute w-full bg-white top-12 drop-shadow-md rounded max-h-[160px] overflow-auto"
                        onScroll={onSegmentListScroll}
                        ref={segmentListInnerRef}
                      >
                        {segmentList.map((ele, i) => {
                          return (
                            <div
                              className="py-3 px-4 cursor-pointer flex justify-between items-center hover:bg-[#F9FAFB]"
                              key={i}
                              onClick={() => {
                                tierId === 0 &&
                                ele.audience_source === "terragon"
                                  ? setSubscriptionRedirectModalStatus(true)
                                  : onSegmentSelectionChange(ele);
                              }}
                            >
                              <div className="flex gap-2 items-center">
                                <span className="text-sm text-gray-700">
                                  {ele.audience_name}
                                </span>
                                {tierId === 0 &&
                                  ele.audience_source === "terragon" && (
                                    <span className="bg-blue-50 text-blue-800 font-semibold text-xs rounded-full flex items-center px-3 py-1">
                                      Upgrade plan to use
                                    </span>
                                  )}
                              </div>
                              <span className="text-sm text-gray-400">
                                {ele.audience_size ? ele.audience_size : 0}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {uploadType && (
            <div className="mt-6">
              <div>
                {manualType == "Upload a file" ? (
                  <div>
                    <div className="mt-1">
                      For a lot of profiles. Upload a file with their numbers.{" "}
                      <span>
                        <a
                          href="https://mcassets.blob.core.windows.net/cdn/AudienceAssets/Audience_Sample_Template.csv"
                          download="Sample_Template.csv"
                          className="text-blue-75 font-semibold cursor-pointer"
                        >
                          Download sample file
                        </a>
                      </span>
                    </div>

                    <div className="relative">
                      {fileUploadSpinnerStatus && (
                        <div className="absolute top-0 left-0 z-10 flex justify-center h-full w-full bg-[#ffffffb8] border rounded">
                          <Spinner className="w-8 h-8" />
                        </div>
                      )}
                      {fileName ? (
                        <div
                          className={`mt-3 py-3 bg-gray-25 border border-dashed border-gray-200 rounded-lg cursor-pointer relative ${
                            fileValidation
                              ? "border-red-500"
                              : "border-gray-200"
                          }`}
                        >
                          <div className="flex justify-between items-center px-4">
                            <div className="flex gap-4 items-center px-2">
                              <img src={SelectedFile} alt="" />
                              <div className="text-gray-70">
                                <div className="text-base font-semibold">
                                  {selectedFile?.name}
                                </div>
                                <div>{formatBytes(selectedFile?.size)}</div>
                              </div>
                            </div>
                            <X
                              className="text-gray-600 cursor-pointer"
                              size="16"
                              onClick={(e) => {
                                e.stopPropagation();
                                unselectFile();
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`mt-3 py-6 bg-gray-25 flex items-center justify-center border border-dashed border-gray-200 rounded-lg cursor-pointer relative ${
                            fileValidation
                              ? "border-red-500"
                              : "border-gray-200"
                          }`}
                        >
                          <div className="flex flex-col text-center items-center justify-center">
                            <img src={File} alt="" />
                            <div className="my-1">
                              <span className="text-blue-75 font-semibold cursor-pointer">
                                Choose a file to upload
                              </span>
                            </div>
                            <div className="text-gray-500 text-sm">
                              or drag and drop it here
                            </div>
                            <p
                              className={`${
                                fileSizeValidator
                                  ? "text-red-500"
                                  : "text-gray-500"
                              } text-xs mt-1`}
                            >
                              {fileSizeValidator
                                ? "File exceeds the 50 MB upload limit. Please upload a smaller CSV or XLS file."
                                : "Only CSV, XLS supported. Max file size: 50 MB"}
                            </p>
                          </div>
                          <input
                            className="absolute w-full h-full cursor-pointer opacity-0"
                            type="file"
                            accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            id="importFile"
                            onChange={onFileSelected}
                            required
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      Use ‘Enter’ to separate phone numbers. Do not add country
                      code or ‘0’ before the number.
                    </div>
                    <div>
                      <div
                        className={
                          numberValidation
                            ? "border border-[#D92D20] rounded text-[#475467]"
                            : "border border-gray-40 rounded text-[#475467]"
                        }
                        style={{
                          marginTop: "8px",
                          width: "100%",
                          display: "inline-block",
                        }}
                      >
                        <div style={{ float: "left" }}>
                          {mobileNumbersList.map((element, i) => {
                            return (
                              <span
                                className={
                                  isPhoneNumberValid(element)
                                    ? "border border-[#D8E0E7] inline-block rounded-sm py-2 px-3 text-gray-700 text-sm mt-2 ml-2"
                                    : "border border-[#D92D20] inline-block rounded-sm py-2 px-3 text-gray-700 text-sm mt-2 ml-2"
                                }
                                key={i}
                              >
                                {element}{" "}
                                <span
                                  className="text-[#687992] font-semibold cursor-pointer ml-2"
                                  onClick={() => popItemFromMobileList(element)}
                                >
                                  x
                                </span>
                              </span>
                            );
                          })}
                        </div>
                        <div>
                          <input
                            placeholder="Enter your number here."
                            value={mobileNumber}
                            onChange={($event) =>
                              onInputMobileNumberChange($event)
                            }
                            className="h-[34px] outline-0 border-0 m-2"
                            style={{ width: "-webkit-fill-available" }}
                            type="number"
                            onKeyDown={($event) => onInputChange($event)}
                          />
                        </div>
                      </div>
                      {numberValidation ? (
                        <div className="text-[#E53E51] text-sm mt-2">
                          {numberListError}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
              {manualType !== "Upload a file" && (
                <div>
                  <div className="mt-6 flex items-baseline gap-2 text-gray-700 text-sm">
                    <span>
                      <input
                        className={
                          checkValidation
                            ? "outline-2 outline-[#EF4444] outline-offset-[-1px]"
                            : "accent-gray-700"
                        }
                        type="checkbox"
                        checked={isConsentReceived}
                        onChange={consentRecievedOnChange}
                      />
                    </span>
                    <div>
                      Numbers uploaded have given consent to receive promotional
                      messages from this business.
                    </div>
                  </div>
                  {checkValidation ? (
                    <div className="text-sm text-[#EF4444]">
                      This field must be checked to continue.
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          )}
          {!uploadType && (
            <div className="mt-10">
              <div className="text-sm font-semibold mb-1 text-[#475467]">
                Set your budget
              </div>
              <div className="text-gray-500 text-sm">
                Enter the number of profiles you want to reach or the amount you
                want to spend.
              </div>
              {showErrors && (
                <div className="text-red-500 text-sm">
                  {profiles <= 0
                    ? "Please provide a valid number of profiles"
                    : profiles > profilesAdded
                    ? "Please set a budget less than or equal to number of profiles added"
                    : null}
                </div>
              )}
              <div className="mt-5 flex border border-gray-40 rounded">
                <div className="h-[40px] flex border-t-1 border-l-1 p-1 w-[80%]">
                  <input
                    className="outline-0 border-0 w-full"
                    value={profiles}
                    onChange={(event) => profileOnChange(event)}
                    type="number"
                    placeholder="Enter number"
                  />
                </div>
                <div className="border-l border-gray-40 py-0 px-3 flex justify-center items-center w-[20%]">
                  Profiles
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-[38%]">
          <div className="ml-6 border border-summary-box-border bg-summary-box-bg rounded-lg py-6 px-8">
            <div className="text-gray-707 font-semibold text-base leading-6">
              Audience summary
            </div>
            <div className="bg-gray-708 w-full h-[1px] mt-2"></div>
            <div className="text-gray-700 leading-8 text-xl font-semibold flex gap-1 mt-6 items-center">
              {profilesAdded}{" "}
              <div className="text-xs text-gray-500 font-normal flex items-center leading-6">
                profiles matched
              </div>
            </div>
            <div className="text-xs text-gray-500 mt-2 leading-6">
              Based on the numbers inputted, this is your potential target
              audience.
            </div>
            <div className="flex justify-between items-center mt-11">
              <div className="text-sm leading-6 text-gray-707 font-semibold flex items-center gap-2">
                Audience budget <img src={InfoCircle} alt="" />
              </div>
              <div className="text-gray-700 text-xl leading-6 font-semibold flex items-center gap-2">
                {/* {currencySymbol} */}
                {profiles}
                <span className="text-xs text-gray-500 leading-6 font-normal">
                  Profiles
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {planUpdgrade && (
        <UpgradePlanModal close={setPlanUpgrade} navigate={props.navigate} />
      )}
      {subscriptionRedirectModalStatus && (
        <SubscriptionRedirectModal
          handleClick={(status) => handleSubscriptionModalClick(status)}
        />
      )}
    </div>
  );
};

export default StepOne;
