import dayjs from 'dayjs';
import { api } from '../../../../marketing-cloud-main/src/common/services/common-api-service';
import { Notify } from '../../../../marketing-cloud-main/src/common/components/Notify/Notify';

export const downloadJSONasCSV = (objArray: any) => {
  const items = objArray;
  const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
  const header = Object.keys(items[0]);
  const csv = [
    header.join(','), // header row first
    ...items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    ),
  ].join('\r\n');

  downloadCSV(csv, `${items[0]['Campaign name']}-Report.csv`);
};

export const downloadStringAsCSV = (data: string, fileName = '') => {
  const lines = data.trim().split('\n');
  // Modify the header row: replace underscores with spaces and convert to title case
  const headerRow = lines[0]
    .split(',')
    .map((columnName) =>
      columnName
        .replace(/_/g, ' ')
        .replace(
          /\w\S*/g,
          (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        )
    )
    .join(',');

  // Modify each timestamp format
  const formattedLines = lines.slice(1).map((line) => {
    const columns = line.split(',');
    const timestamp = parseInt(columns[0], 10)
      ? dayjs(new Date(parseInt(columns[0], 10))).format(
          'MMM DD YYYY - HH:mm A'
        )
      : '';
    return [timestamp, ...columns.slice(1)];
  });

  // Create CSV content with the modified header and formatted timestamps
  const csvContent = [headerRow, ...formattedLines]
    .map((line: string[] | string) =>
      Array.isArray(line) ? line.join(',') : line
    )
    .join('\n');

  downloadCSV(csvContent, `${fileName && fileName + ' - '}Report.csv`);
};

export const downloadCSV = (csv, name) => {
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', name);
  a.click();
};

export const downloadZip = (data, name) => {
  const blob = new Blob([data], { type: 'application/zip' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', name);
  a.click();
};

export const downloadCampaignReport = (id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/report/${id}`;

  return api
    .get(url, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
      responseType: 'blob',
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const reader = new FileReader();
        reader.onload = () => {
          const result = reader.result;
          if (typeof result === 'string') {
            try {
              const errorJson = JSON.parse(result);
              Notify({
                type: 'error',
                heading: 'Campaign Report Unavailable.',
                message: errorJson.response || 'Unknown error',
              });
              throw new Error(errorJson.response || 'Unknown error');
            } catch (parsingError) {
              console.error('Error parsing blob as JSON:', parsingError);
            }
          } else {
            console.error('Unexpected FileReader result type:', result);
          }
        };
        reader.readAsText(error.response.data);
      } else {
        console.error('Error:', error.message);
      }
      throw error;
    });
};
