import { useEffect, useRef, useState } from "react";
import ChannelModal from "../../../modals/channel-selection-modal/channel";
import ChannelMenu from "../../../common/components/channel-menu";
import StatusMenu from "../../../common/components/status-menu";
import { ConfigProvider, DatePicker } from "antd";
const { RangePicker } = DatePicker;
import locale from "antd/es/date-picker/locale/en_GB";
import MessagesList from "../messages-list/messages-list";
import {
  addGAEventListener,
  getPageName,
} from "../../../../../marketing-cloud-container/src/ga-events";
import { MESSAGE } from "../../../../../marketing-cloud-main/src/common/constants/AccessControlMessage";
import { DownArrow } from "../../../assets/icons/icons";

const CampaignList = (props: any) => {
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  const [showChannelMenu, setShowChannelMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [hasAccessToCreate, setHasAccessToCreate] = useState(true);
  const statusMenu = useRef(null);
  const channelMenu = useRef(null);
  const datepicker = useRef(null);
  const tenantContext: any = props.tenantContext;

  const {
    messageList = [],
    country,
    currency,
    navigate,
    filter,
    setFilter,
    setStartDate,
    startDate,
    endDate,
    setEndDate,
    setChannel,
    channel,
    setStatus,
    status,
    setSize,
    size,
    setPageNumber,
    pageNumber,
    setFrom,
    messageText,
    setMessageText,
    hideFilters,
    hasSuperAdminAccess,
    createMessage = true,
  } = props;

  const navigateToCreate = (channel: string) => {
    navigate(`/engagements/${channel}`);
  };

  const closeOpenMenus = (e) => {
    if (
      channelMenu.current &&
      showChannelMenu &&
      !channelMenu.current.contains(e.target)
    ) {
      setShowChannelMenu(false);
    }

    if (
      statusMenu.current &&
      showStatusMenu &&
      !statusMenu.current.contains(e.target)
    ) {
      setShowStatusMenu(false);
    }
    if (
      datepicker.current &&
      visible &&
      !datepicker.current.contains(e.target)
    ) {
      setVisible(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  const onRangeSelectionChange = (event) => {
    let sDate = new Date(event[0].$d);
    let eDate = new Date(event[1].$d);
    sDate.setHours(0, 0, 0, 0);
    eDate.setHours(0, 0, 0, 0);
    setStartDate(sDate.getTime().toString());
    setEndDate(eDate.getTime().toString());
  };

  const setStatusOption = (value) => {
    setShowStatusMenu(false);
    setStatus(value);
  };

  const setChannelOption = (value) => {
    setShowChannelMenu(false);
    setChannel(value);
  };

  const checkQuesryString = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const n = urlParams.get("new");
    if (n === "1") setShowModal(true);
  };

  return (
    <div className={`${!hideFilters ? "my-11 mx-10" : ""}`}>
      {!hideFilters && (
        <div className="flex justify-between">
          <div className="flex gap-4">
            <div className="relative">
              <button
                className="h-[40px] gap-2 inline-flex items-center px-4 py-3 text-[14px] leading-4 font-semibold text-center text-gray-70 bg-gray-10 rounded-full hover:bg-gray-100 triggerGA"
                data-event-category={`${getPageName()}-list-page`}
                data-event-label={`${getPageName()}-list_channel-filter_click`}
                data-event-action="filter_click"
                type="button"
                onClick={() => setShowChannelMenu(!showChannelMenu)}
              >
                Channel <img src={DownArrow} alt="" />
              </button>

              <ChannelMenu
                showChannelMenu={showChannelMenu}
                channelMenu={channelMenu}
                setChannel={(value) => setChannelOption(value)}
                channel={channel}
                setFilter={setFilter}
                setPageNum={setPageNumber}
                setPageSize={setSize}
                setFrom={setFrom}
                status={status}
              />
            </div>

            <div className="relative">
              <button
                className="h-[40px] gap-2 inline-flex items-center px-4 py-3 text-[14px] leading-4 font-semibold text-center text-gray-70 bg-gray-10 rounded-full hover:bg-gray-100 triggerGA"
                data-event-category={`${getPageName()}-list-page`}
                data-event-label={`${getPageName()}-list_status-filter_click`}
                data-event-action="filter_click"
                type="button"
                onClick={() => setShowStatusMenu(!showStatusMenu)}
              >
                Status
                <img src={DownArrow} alt="" />
              </button>

              <StatusMenu
                showStatusMenu={showStatusMenu}
                statusMenu={statusMenu}
                setStatus={(value) => setStatusOption(value)}
                status={status}
                channel={channel}
                setFilter={setFilter}
                setPageNum={setPageNumber}
                setPageSize={setSize}
                setFrom={setFrom}
              />
            </div>

            <div
              className="h-[40px] w-[100px] py-3 px-5 bg-[#F6F9FC] text-[#475467] font-semibold flex rounded-full items-center cursor-pointer relative triggerGA"
              data-event-category={`${getPageName()}-list-page`}
              data-event-label={`${getPageName()}-list_date-filter_click`}
              data-event-action="filter_click"
            >
              <div className="absolute left-0 top-0 h-[40px] w-[100px] bg-[#F6F9FC] rounded-full z-10 flex items-center cursor-pointer gap-3 justify-center pointer-events-none">
                <span className="cursor-pointer">Date</span>
                <img src={DownArrow} alt="" />
              </div>
              <div>
                {/* <RangePicker className="invisible" size={"middle"} /> */}
                <ConfigProvider
                  theme={{
                    token: {
                      colorIcon: "#98A2B3",
                      colorBorder: "#D0D5DD",
                      colorPrimary: "#008138",
                      colorPrimaryHover: "#008138",
                      colorText: "#344054",
                      colorTextHeading: "#344054",
                      colorTextDisabled: "#D0D5DD",
                      colorTextLightSolid: "var(--main-color)",
                      colorTextPlaceholder: "#98A2B3",
                      controlItemBgActive: "#DAEEE1",
                      controlOutline: "#FFFFFF",
                      colorBgTextActive: "FFFFFF",
                      colorTextLabel: "#FFFFFF",
                    },
                  }}
                >
                  <RangePicker
                    className="outline-none border-none bg-[#F6F9FC] text-[#F6F9FC] shadow-none focus:{outline-none border-none bg-[#F6F9FC] shadow-none text-[#F6F9FC]} placeholder:{text-[#F6F9FC]}"
                    onChange={onRangeSelectionChange}
                    locale={locale}
                    showNow={false}
                    allowClear={false}
                    cellRender={(current, info) => {
                      if (info.type !== "date") return info.originNode;
                      const style: React.CSSProperties = {};
                      style.borderRadius = "50%";
                      style.width = "30px";
                      style.height = "30px";
                      style.lineHeight = "30px";
                      return (
                        <div className="ant-picker-cell-inner" style={style}>
                          {current.date()}
                        </div>
                      );
                    }}
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
          {createMessage && (
            <div>
              <div className="group">
                <button
                  className={`px-[20px] py-[12px] bg-blue-75 text-white text-[14px] font-semibold leading-4 rounded-lg ${
                    tenantContext.productList["create_message_policy"] &&
                    "hover:bg-green-75"
                  }  cursor-pointer relative`}
                  onClick={() => {
                    setShowModal(true);
                  }}
                  disabled={!tenantContext.productList["create_message_policy"]}
                >
                  Engage customers
                  {!tenantContext.productList["create_message_policy"] && (
                    <div className="absolute hidden group-hover:flex bg-black p-3 text-xs rounded-lg w-[300px] right-[180px] top-[-10px] z-20">
                      {MESSAGE}
                    </div>
                  )}
                </button>
              </div>
              <div>
                {showModal ? (
                  <ChannelModal
                    handleNavigate={navigateToCreate}
                    handleClose={() => {
                      setShowModal(false);
                    }}
                    tenantContext={tenantContext}
                    navigate={navigate}
                  />
                ) : null}
              </div>
            </div>
          )}
        </div>
      )}

      <MessagesList
        messageList={messageList}
        totalListLength={props.totalListLength}
        country={country}
        currency={currency}
        navigate={navigate}
        setSize={setSize}
        size={size}
        filter={filter}
        setFilter={setFilter}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        setFrom={setFrom}
        setMessageText={setMessageText}
        messageText={messageText}
        hasSuperAdminAccess={hasSuperAdminAccess}
        tenantContext={tenantContext}
      />
    </div>
  );
};

export default CampaignList;
