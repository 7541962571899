import { useEffect, useState } from "react";
import { Delete } from "../../../../assets/icons/icons";

const Matrix = (props: any) => {
  const [question, setQuestion] = useState(props.question);

  useEffect(() => {
    const { question } = props;
    if (question?.property?.matrix) {
      setQuestion(question);
    } else {
      let q = question;
      let matrix = { row: ["", ""], column: ["", ""] };
      let property = { matrix: matrix };
      q.property = property;
      setQuestion(q);
      props.setRating(q);
    }
  }, [props]);

  const onQuestionChange = (event) => {
    let q = question;
    q.q = event.target.value;
    props.setRating(q);
  };

  const onSubTextChange = (event) => {
    let q = question;
    q.s = event.target.value;
    props.setRating(q);
  };

  const onRowChange = (event, index) => {
    if (question.property) {
      question.property.matrix.row[index] = event.target.value;
    } else {
      let property: any = {};
      property.row[index] = event.target.value;
      question.property = property;
    }
    props.setRating(question);
  };

  const deleteRow = (index) => {
    let q = question;
    q.property.matrix.row.splice(index, 1);
    props.setRating(q);
  };

  const deleteColunm = (index) => {
    let q = question;
    q.property.matrix.column.splice(index, 1);
    props.setRating(q);
  };

  const addRow = () => {
    let q = question;
    if (q.property.matrix.row.length > 4) return;
    q.property.matrix.row.push("");
    props.setRating(q);
  };

  const addColumn = () => {
    let q = question;
    if (q.property.matrix.column.length > 4) return;
    q.property.matrix.column.push("");
    props.setRating(q);
  };

  const columnValueOnChange = (event, index) => {
    if (question.property) {
      question.property.matrix.column[index] = event.target.value;
    } else {
      let property: any = {};
      property.column[index] = event.target.value;
      question.property = property;
    }
    props.setRating(question);
  };

  return (
    <div>
      <div>
        <div>
          <div className="border border-[#E4E7EC] rounded px-2 py-3 mb-1">
            <input
              onChange={(event) => {
                onQuestionChange(event);
              }}
              value={question?.q}
              className="w-full text-xl text-[#98A2B3] border-0 outline-0 font-semibold"
              placeholder="Main question here"
            />
          </div>
          <div className="border border-[#E4E7EC] rounded px-2 py-3 mb-1">
            <input
              onChange={(event) => {
                onSubTextChange(event);
              }}
              value={question?.s}
              className="w-full text-sm text-[#98A2B3] border-0 outline-0"
              placeholder="Subtext goes here (optional)"
            />
          </div>
        </div>
        <div
          className="text-sm font-semibold text-blue-75 mt-[18px] cursor-pointer flex justify-end"
          onClick={() => {
            addColumn();
          }}
        >
          Add column
        </div>
        <div className="flex justify-end">
          <div className="flex justify-end mt-3 mr-[10px] gap-3">
            {question?.property?.matrix?.column?.map((column, i) => {
              let index = i + 1;
              return (
                <div key={i}>
                  <div className="flex justify-center">
                    <div className="cursor-pointer h-[15px]">
                      {index > 2 ? (
                        <div onClick={() => deleteColunm(i)}>
                          <img src={Delete} alt="" />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <input
                    className="w-[70px] outline-none border-none"
                    onChange={(event) => {
                      columnValueOnChange(event, i);
                    }}
                    placeholder={"Choice " + index}
                    value={question.property.matrix.column[i]}
                  />
                </div>
              );
            })}
          </div>
          <div className="w-[14px]"></div>
        </div>
        <div>{question?.property?.matrix?.row?.map((c, i) => {})}</div>
        <div>
          {question?.property?.matrix?.row?.map((c, i) => {
            let index = i + 1;
            return (
              <div key={i} className="flex items-center gap-[10px] mt-4">
                <div>{i + 1}.</div>
                <div className="w-full">
                  <div className="border-b h-[40px] w-full flex items-center">
                    <input
                      onChange={(event) => {
                        onRowChange(event, i);
                      }}
                      value={question.property.matrix.row[i]}
                      className="border-0 outline-0 w-full h-full"
                      placeholder={"Row choice " + index}
                    />
                    <div className="flex gap-3">
                      {question?.property?.matrix?.column?.map((column, i) => {
                        return (
                          <div
                            key={i}
                            className="w-[70px] flex justify-center items-center"
                          >
                            <div className="h-[20px] w-[20px] border rounded-full"></div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="cursor-pointer w-[14px]">
                  {index > 2 ? (
                    <div onClick={() => deleteRow(i)}>
                      <img src={Delete} alt="" />
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="text-sm font-semibold text-blue-75 mt-[18px] cursor-pointer"
          onClick={() => {
            addRow();
          }}
        >
          Add row
        </div>
      </div>
    </div>
  );
};

export default Matrix;
