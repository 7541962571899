export const timeFormat = (date: any) => {
  if(!date) return
  const data = new Date(date)
  return new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "long",
    day: "2-digit"
  }).format(data)
}

export const dateTimeFormatter = (timestamp) => {
  if (!timestamp) return "";
  if (timestamp.toString().length === 10) {
    timestamp *= 1000;
  }
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedDate = `${date.getFullYear()}-${
    month < 10 ? "0" + month : month
  }-${day < 10 ? "0" + day : day}, ${
    hours > 12
      ? hours - 12 < 10
        ? "0" + (hours - 12)
        : hours - 12
      : hours < 10
      ? "0" + hours
      : hours
  }:${minutes < 10 ? "0" + minutes : minutes} ${hours > 11 ? "PM" : "AM"}`;
  return formattedDate;
};
