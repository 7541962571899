import { useEffect, useState } from "react";
import { Delete } from "../../../../assets/icons/icons";

const Form = (props: any) => {
  const [question, setQuestion] = useState(props.question);

  useEffect(() => {
    const { question } = props;
    if (question?.property?.options) {
      setQuestion(question);
    } else {
      let q = question;
      let property = { options: [""] };
      q.property = property;
      setQuestion(q);
      props.setRating(q);
    }
  }, []);

  const onQuestionChange = (event) => {
    let q = question;
    q.q = event.target.value;
    props.setRating(q);
  };

  const onSubTextChange = (event) => {
    let q = question;
    q.s = event.target.value;
    props.setRating(q);
  };

  const onChoiceChange = (event, index) => {
    if (question.property) {
      question.property.options[index] = event.target.value;
    } else {
      let property: any = {};
      property.options[index] = event.target.value;
      question.property = property;
    }
    props.setRating(question);
  };

  const deleteChoice = (index) => {
    let q = question;
    q.property.options.splice(index, 1);
    props.setRating(q);
  };

  const addChoice = () => {
    let q = question;
    q.property.options.push("");
    props.setRating(q);
  };

  return (
    <div>
      <div>
        <div>
          <div className="border border-[#E4E7EC] rounded px-2 py-3 mb-1">
            <input
              onChange={(event) => {
                onQuestionChange(event);
              }}
              value={question?.q}
              className="w-full text-xl text-[#98A2B3] border-0 outline-0 font-semibold"
              placeholder="Main question here"
            />
          </div>
          <div className="border border-[#E4E7EC] rounded px-2 py-3 mb-1">
            <input
              onChange={(event) => {
                onSubTextChange(event);
              }}
              value={question?.s}
              className="w-full text-sm text-[#98A2B3] border-0 outline-0"
              placeholder="Subtext goes here (optional)"
            />
          </div>
        </div>
        <div>
          {question?.property?.options?.map((c, i) => {
            let index = i + 1;
            return (
              <div key={i} className="mt-4">
                <div className="flex gap-6">
                  <div className="h-[40px] w-[50%] flex items-center border rounded py-2 px-3">
                    <input
                      onChange={(event) => {
                        onChoiceChange(event, i);
                      }}
                      value={
                        question.property.options[i].optionText
                          ? question.property.options[i].optionText
                          : question.property.options[i]
                      }
                      className="border-0 outline-0 w-full h-full text-[#475467] font-semibold"
                      placeholder="Input label goes here"
                    />
                  </div>
                  <div
                    className="cursor-pointer flex items-center"
                    onClick={() => deleteChoice(i)}
                  >
                    {index > 1 ? <img src={Delete} alt="" /> : null}
                  </div>
                </div>
                <div className="h-[40px]  w-[50%] bg-[#F9FAFB] border rounded text-[#98A2B3] text-sm opacity-80 py-2 px-3 flex items-center mt-2">
                  Reply
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="text-sm font-semibold text-blue-75 mt-[18px] cursor-pointer"
          onClick={() => {
            addChoice();
          }}
        >
          Add input field
        </div>
      </div>
    </div>
  );
};

export default Form;
