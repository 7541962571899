import { useCallback, useEffect } from "react";
import Close from "../common/assets/icons/Close";
import { useNavigate, Link } from "react-router-dom";

const UpgradePlanModal = ({ close, navigate }) => {
  useEffect(() => {}, []);

  const openEmail = () => {
    window.location.href = "mailto:support@terragonltd.com?subject=&body=";
  };

  const navigateToPricing = useCallback(
    () => navigate("/pricing", { replace: true }),
    [navigate]
  );

  return (
    <div className="absolute w-full h-full bg-[#3440544D] flex items-center justify-center top-0 left-0">
      <div className="bg-[#ffffff] px-8 py-6 w-1/2 rounded">
        <div className="flex justify-between items-center">
          <div className="text-[#3A4356] text-lg font-semibold">
            Upgrade your plan!
          </div>{" "}
          <div
            className="cursor-pointer"
            onClick={() => {
              close(false);
            }}
          >
            <Close />{" "}
          </div>
        </div>
        <div className="text-gray-700 text-sm mt-7">
          Upgrade to a higher plan on the Marketing Cloud and get access to more
          relevant insights and audience engagement channels such as SMS,
          WhatsApp and Email. Contact our team at support@terragonltd.com to
          learn more.{" "}
        </div>
        <div className="flex justify-end mt-7">
          <div className="flex gap-3">
            <div
              onClick={openEmail}
              className="px-5 py-3 text-gray-700 text-sm font-semibold rounded cursor-pointer"
            >
              Contact support
            </div>
            <div
              className="px-5 py-3 text-[#ffffff] bg-blue-75 text-sm font-semibold rounded cursor-pointer"
              onClick={() => navigateToPricing()}
            >
              View plans
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlanModal;
