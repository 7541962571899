import { useEffect, useState } from "react";
import { getRewardList } from "../../common/services/reward-services";
import RewardList from "../reward-list";
import { getCurrenySymbol } from "../../common/utils/currenySymbol";
import * as Sentry from "@sentry/react";
import { pageLoadEvent } from "../../../../marketing-cloud-container/src/ga-events";
import { MESSAGE } from "../../../../marketing-cloud-main/src/common/constants/AccessControlMessage";
import { RewardMix } from "../../assets/icons/icons";

const EngagementsRewards = (props: any) => {
  const [rewardList, setRewardList] = useState([]);
  const [totalRewardListLength, setTotalRewardListLength] = useState(0);
  const [filter, setFilter] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchRewards, setSearchRewards] = useState("");
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [type, setType] = useState("");
  const [audience, setAudience] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const country = props.country;
  const currency = props.currency;
  const currencySymbol = getCurrenySymbol(country, currency);
  const { dispatch, setShowCommonLoader, hasSuperAdminAccess } = props;
  const tenantContext = props.tenantContext;

  useEffect(() => {
    setFrom(Number(size * pageNumber));
    const params = {
      startDate,
      endDate,
      from,
      size,
      type,
      audience,
      status,
      searchRewards,
    };
    fetchRewardList(params);
    pageLoadEvent("engagements-rewards_page");
  }, [
    startDate,
    endDate,
    size,
    searchRewards,
    type,
    from,
    audience,
    status,
    pageNumber,
  ]);

  const fetchRewardList = (params = {}) => {
    dispatch(setShowCommonLoader(true));
    try {
      getRewardList(params)
        .then((res) => {
          const { error, response } = res;
          if (!error) {
            setTotalRewardListLength(res.total);
            setRewardList(response);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
          dispatch(setShowCommonLoader(false));
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          dispatch(setShowCommonLoader(false));
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      dispatch(setShowCommonLoader(false));
      console.log(err);
    }
  };
  const openRewardsInfo = () => {
    window.open("https://terragongroup.com/terragon-rewards/", "_blank");
  };
  const createReward = () => {
    props.navigate("/engagements/create-reward");
  };
  return (
    <>
      {!filter && pageNumber === 0 && rewardList?.length === 0 ? (
        <div className="flex items-center justify-between w-[64rem] mx-auto mt-48">
          <div className="w-128">
            <div className="text-gray-800 text-4xl font-semibold leading-[2.75rem] mb-4">
              Create your first reward
            </div>
            <div className="text-gray-70 text-base mb-6">
              Engage and retain your loyal customers with exclusive rewards
              delivered instantly.
            </div>
            <div className="flex gap-4">
              <div className="group">
                <button
                  className={`py-3 px-6 text-white text-sm font-semibold border rounded-lg bg-blue-75 ${
                    tenantContext.productList["create_reward_policy"] &&
                    "hover:bg-green-75"
                  } relative`}
                  onClick={createReward}
                  disabled={!tenantContext.productList["create_reward_policy"]}
                >
                  Create rewards
                  {!tenantContext.productList["create_reward_policy"] && (
                    <div className="absolute hidden group-hover:flex bg-black p-3 text-xs rounded-lg w-[300px] right-[-306px] top-[-12px] z-20">
                      {MESSAGE}
                    </div>
                  )}
                </button>
              </div>
              <button
                className="py-3 px-6 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
                onClick={openRewardsInfo}
              >
                Learn more
              </button>
            </div>
          </div>
          <img src={RewardMix} alt="" />
        </div>
      ) : (
        <div>
          <RewardList
            createReward={createReward}
            rewardList={rewardList}
            totalRewardListLength={totalRewardListLength}
            navigate={props.navigate}
            setFilter={setFilter}
            filter={filter}
            currencySymbol={currencySymbol}
            setSearchRewards={setSearchRewards}
            searchRewards={searchRewards}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            size={size}
            setSize={setSize}
            setFrom={setFrom}
            type={type}
            setType={setType}
            audience={audience}
            setAudience={setAudience}
            status={status}
            setStatus={setStatus}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            hasSuperAdminAccess={hasSuperAdminAccess}
            tenantContext={tenantContext}
          />
        </div>
      )}
    </>
  );
};
export default EngagementsRewards;
