import { useEffect, useState } from "react";
import SurveyDropOff from "./survey-drop-off/survey-drop-off";
import SurveyResponseTime from "./survey-response-time/survey-response-time";

const SurveyRespondents = ({
  questionCount,
  id,
  surveyDuration,
  dispatch,
  setShowCommonLoader,
}) => {
  const [respondentsType, setRespondentsType] = useState("");

  const [typeList, setTypeList] = useState([]);

  useEffect(() => {
    let list = [...typeList];
    let arr = new Array(questionCount).fill(0);
    list.push(
      { name: "All", value: "" },
      { name: "Completed Respondents", value: "true" },
      { name: "Uncompleted Respondents", value: "false" }
    );
    arr.map((ele, i) => {
      list.push({ name: `Q${i + 1} respondents`, value: `${i + 1}` });
    });
    setTypeList(list);
  }, []);

  const onTypeChange = (event) => {
    setRespondentsType(event.target.value);
  };

  return (
    <div className="w-full">
      <div className="border rounded h-[40px] w-min px-1">
        <select
          className="h-full outline-none border-none cursor-pointer rounded"
          name="respondents-type-select"
          value={respondentsType}
          onChange={(event) => onTypeChange(event)}
        >
          {typeList.map((ele, i) => {
            return (
              <option key={i} value={ele.value}>
                {ele.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="w-full flex gap-2 flex-wrap">
        <SurveyDropOff
          id={id}
          respondentsType={respondentsType}
          dispatch={dispatch}
          setShowCommonLoader={setShowCommonLoader}
        />
        <SurveyResponseTime
          id={id}
          respondentsType={respondentsType}
          surveyDuration={surveyDuration}
          dispatch={dispatch}
          setShowCommonLoader={setShowCommonLoader}
        />
      </div>
    </div>
  );
};

export default SurveyRespondents;
