const Spinner = ({ className }) => {
  return (
    <div className="animate-spin grid items-center justify-center">
      <div
        className={`${className} border-2 border-t-gray-700 border-white rounded-full`}
      ></div>
    </div>
  );
};

export default Spinner;
