import Header from "../../common/components/header";
import { importModule } from "../../common/utils/importModule";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setShowCommonLoader } from "../../redux-loader/loader-slice/loaderSlice";
import { useContext } from "react";
import { TenantContext } from "../home";

const DataConnector = importModule("@tg/data", "DataConnectors");

const DataConnectors = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const tenantContext = useContext(TenantContext);

  return (
    <div>
      <Header pageName="Data" />
      <div className="content py-8 px-10">
        <DataConnector
          navigate={navigate}
          dispatch={dispatch}
          setShowCommonLoader={setShowCommonLoader}
          tenantContext={tenantContext}
        />
      </div>
    </div>
  );
};

export default DataConnectors;
