import { useEffect, useState } from "react";
import { X } from "react-feather";
import { getChannels } from "../../common/services/common-service";
import * as Sentry from "@sentry/react";
import { Notify } from "../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import Spinner from "../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import { MESSAGE } from "../../../../marketing-cloud-main/src/common/constants/AccessControlMessage";
import {
  EmailIcon,
  SmsIcon,
  PushIcon,
  RcsIcon,
  WhatsappIcon,
} from "../../assets/icons/icons";

const ChannelModal = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [channels, setChannels] = useState([]);
  const handleNavigate = (channelType: string) => {
    let channel;
    switch (channelType) {
      case "sms":
        channel = "sms";
        break;
      case "whatsapp":
        channel = "whatsapp";
        break;
      case "email":
        channel = "email";
        break;
      case "web push":
        channel = "web-push";
        break;
      case "rcs Messaging":
        channel = "rcs";
        break;
    }
    props.handleNavigate(channel);
  };

  const { tenantContext } = props;

  const fetchChannels = () => {
    try {
      setLoading(true);
      getChannels()
        .then((res) => {
          const { error, response = {} } = res;
          if (error === false) {
            setChannels(response);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
            Notify({
              type: "error",
              heading: "Failed to retrieve channels.",
              message: error,
            });
            setChannels([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChannels();
  }, []);

  const getChannelIcon = (channelType) => {
    switch (channelType) {
      case "sms":
        return <img src={SmsIcon} alt="" />;
      case "whatsapp":
        return <img src={WhatsappIcon} alt="" />;
      case "email":
        return <img src={EmailIcon} alt="" />;
      case "rcs Messaging":
        return <img src={RcsIcon} alt="" />;
      case "web push":
        return <img src={PushIcon} alt="" />;
      default:
        return <img src={SmsIcon} alt="" />;
    }
  };

  const getChannelName = (channelType) => {
    switch (channelType) {
      case "sms":
        return "SMS";
      case "whatsapp":
        return "WhatsApp";
      default:
        return channelType;
    }
  };

  const getPolicyForChannel = (channel) => {
    switch (channel) {
      case "sms":
        return "create_sms_policy";
      case "whatsapp":
        return "create_whatsapp_policy";
      case "email":
        return "create_email_policy";
      case "web push":
        return "create_message_policy";
      case "rcs Messaging":
        return "create_rcs_policy";
      default:
        return "create_sms_policy";
    }
  };

  return (
    <div>
      <div
        className="fixed z-10 overflow-y-auto top-0 w-full left-0 rounded"
        id="modal"
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center rounded bg-white shadow-xl transform transition-all py-6 px-12 sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="flex justify-between p-6 pb-0">
              <div className=" text-gray-700 font-semibold text-lg">
                Select your preferred messaging channel
              </div>
              <X
                className="text-gray-600 mt-1 cursor-pointer rounded-full hover:bg-gray-30 hover:p-0.5"
                size={16}
                onClick={props.handleClose}
              />
            </div>

            {loading ? (
              <div className="flex h-full w-full justify-center items-center">
                <div className="mt-40 h-64">
                  <Spinner className="w-8 h-8" />
                </div>
              </div>
            ) : (
              <>
                {channels.length > 0 ? (
                  <div className="grid grid-cols-3 gap-4 grid-flow-row py-8">
                    {channels.map((channel: any) => (
                      <div
                        key={channel?.channelType}
                        className="flex hover:border hover:bg-[#EEF6F14D] rounded-xl pl-2 py-4 group"
                      >
                        {channel?.isEnabled && channel?.isAvailable && (
                          <a
                            onClick={() => {
                              tenantContext.productList[
                                getPolicyForChannel(channel?.channelType)
                              ]
                                ? handleNavigate(channel?.channelType)
                                : null;
                            }}
                          >
                            <div className="flex gap-4 p-3 cursor-pointer relative">
                              <div className="flex justify-center items-center flex-shrink-0 w-16 bg-gray-100 rounded-full h-16">
                                {getChannelIcon(channel?.channelType)}
                              </div>
                              <div className="text-left">
                                <span className="text-gray-700 font-semibold capitalize">
                                  {getChannelName(channel?.channelType)}
                                </span>

                                <p
                                  className="w-[160px] mt-1 text-gray-500
                                 font-normal text-sm whitespace-no-wrap"
                                >
                                  {channel?.description}
                                </p>
                              </div>
                              {!tenantContext.productList[
                                getPolicyForChannel(channel?.channelType)
                              ] && (
                                <div className="absolute hidden group-hover:flex bg-black p-3 text-xs text-white rounded-lg w-[300px] right-[-58px] top-[5px] z-20">
                                  {MESSAGE}
                                </div>
                              )}
                            </div>
                          </a>
                        )}
                        {!channel?.isEnabled && channel?.isAvailable && (
                          <div
                            onClick={() => props.navigate("/pricing")}
                            className="flex gap-4 p-3 cursor-pointer relative"
                          >
                            <div className="flex justify-center items-center flex-shrink-0 w-16 bg-gray-100 rounded-full h-16">
                              {getChannelIcon(channel?.channelType)}
                            </div>
                            <div className="text-left">
                              <span className="text-gray-700 font-semibold capitalize">
                                {getChannelName(channel?.channelType)}
                              </span>

                              <p
                                className="w-[160px] mt-1 text-gray-500
                                 font-normal text-sm whitespace-no-wrap"
                              >
                                {channel?.description}
                              </p>
                              <div className="mt-1 p-2 bg-blue-100 text-gray-200 border border-gray-50 font-normal text-xs whitespace-no-wrap rounded-md">
                                <strong>Upgrade</strong> to Enterprise Plan!
                              </div>
                            </div>
                            {!tenantContext.productList[
                              getPolicyForChannel(channel?.channelType)
                            ] && (
                              <div className="absolute hidden group-hover:flex bg-black p-3 text-xs text-white rounded-lg w-[300px] right-[-58px] top-[5px] z-20">
                                {MESSAGE}
                              </div>
                            )}
                          </div>
                        )}
                        {!channel?.isAvailable && (
                          <div className="flex gap-4 p-3 cursor-not-allowed opacity-50">
                            <div className="flex justify-center items-center flex-shrink-0 w-16 bg-gray-100 rounded-full h-16">
                              {getChannelIcon(channel?.channelType)}
                            </div>
                            <div className="text-left">
                              <div className="flex justify-start items-center gap-3">
                                <span className="text-gray-300 font-semibold capitalize">
                                  {getChannelName(channel?.channelType)}
                                </span>
                                <div className="bg-blue-50 text-white font-medium mb-4 text-xs pt-0.5 pb-1 px-2 rounded-2xl">
                                  Coming soon!
                                </div>
                              </div>
                              <p className="w-[160px] mt-1 text-gray-300 font-normal text-sm whitespace-no-wrap">
                                {channel?.description}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <div className="text-gray-700 font-medium text-lg mt-40 h-64">
                      No channels available.
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChannelModal;
