import { useEffect } from "react";
import {
  addGAEventListener,
  getPageName,
} from "../../../../../marketing-cloud-container/src/ga-events";

const ChannelMenu = (props) => {
  const {
    showChannelMenu,
    channelMenu,
    setChannel,
    channel,
    setFilter,
    setPageNum,
    setPageSize,
    setFrom,
    audience,
    status,
    checkFlag,
  } = props;

  useEffect(() => {
    addGAEventListener();
  }, [showChannelMenu]);

  const handleSetChannel = (e: any) => {
    setPageNum(0);
    setPageSize(10);
    setFrom(0);
    setChannel(e.target.value);
    setFilter(true);
    if (channel === e.target.value) {
      setChannel("");
      if (audience || status) {
        return setFilter(true);
      } else {
        return setFilter(false);
      }
    }
  };

  return (
    showChannelMenu && (
      <div
        ref={channelMenu}
        className="absolute mt-1 z-10 bg-white rounded-lg shadow w-40"
      >
        <ul className="p-2 text-sm text-gray-700">
          <li
            className="triggerGA"
            data-event-category={`${getPageName()}-list-page`}
            data-event-label={`${getPageName()}-list_channel-option_click`}
            data-event-action="filter_click"
            data-event-value="SMS"
          >
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={channel === "SMS"}
                id="sms"
                type="checkbox"
                value="sms"
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                onChange={handleSetChannel}
              />
              <label
                htmlFor="sms"
                className="w-full  ml-3 text-sm leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                SMS
              </label>
            </div>
          </li>
          <li
            className="triggerGA"
            data-event-category={`${getPageName()}-list-page`}
            data-event-label={`${getPageName()}-list_channel-option_click`}
            data-event-action="filter_click"
            data-event-value="Whatsapp"
          >
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={channel === "Whatsapp"}
                id="whatsapp"
                type="checkbox"
                value="whatsapp"
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                onChange={handleSetChannel}
              />
              <label
                htmlFor="whatsapp"
                className="w-full  ml-3 text-sm leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                Whatsapp
              </label>
            </div>
          </li>
          <li
            className="triggerGA"
            data-event-category={`${getPageName()}-list-page`}
            data-event-label={`${getPageName()}-list_channel-option_click`}
            data-event-action="filter_click"
            data-event-value="Email"
          >
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={channel === "Email"}
                id="email"
                type="checkbox"
                value="email"
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                onChange={handleSetChannel}
              />
              <label
                htmlFor="email"
                className="w-full  ml-3 text-sm leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                Email
              </label>
            </div>
          </li>
        </ul>
      </div>
    )
  );
};

export default ChannelMenu;
