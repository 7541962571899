import ReactECharts from "echarts-for-react";
import { useEffect, useState } from "react";

const colors = ["#6BF1E9", "#F3D48E", "#71CC9D", "#EF8B8E", "#7699D4"];

const RatingSurveySummary = ({ question }) => {
  const [option, setOption] = useState({});

  useEffect(() => {
    let data = [];
    Object.keys(question.responseCounts[0].counts).map((key, i) => {
      let d = {
        value: question.responseCounts[0].counts[key],
        name: key,
        itemStyle: { color: colors[i] },
      };
      data.push(d);
    });
    const o = {
      tooltip: {
        trigger: "item",
        backgroundColor: "#13163E",
        borderColor: "#13163E",
        textStyle: { color: "#FFFFFF", fontSize: 12, lineHeight: 16 },
        formatter: `{b}: {d}%<br />{c} responses`,
      },
      legend: {
        top: "5%",
        left: "left",
        itemHeight: 4,
      },
      series: [
        {
          name: "Access From",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 0,
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: data,
        },
      ],
    };
    setOption(o);
  });

  return (
    <div>
      {option && <ReactECharts style={{ height: "350px" }} option={option} />}
    </div>
  );
};

export default RatingSurveySummary;
