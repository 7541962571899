import { getAccountStat } from "../apis/billing"
import * as Sentry from "@sentry/react";

export const cappingCheck = async (requestingCount, productId) => {
    return new Promise((resolve, reject) => {
        getAccountStat().then((response) => {
            try {
                let accountStatus = response.account_status
                let index = accountStatus.findIndex((ele) => {
                    return (ele.product_id === productId)
                })
                let product = accountStatus[index]
                if (product.capping !== undefined) {  //Remove this later. Added for backward support.
                    if (product.capping < requestingCount && product.capping !== -1) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                } else {
                    resolve(true);
                }
            } catch (error) {
                reject(error)
            }
        })
    }).then((res) => {
        return res
    })
        .catch((error) => {
            (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
        })
}

// export const setAccountStatus = () => {
//     getAccountStat().then((response) => {
//         localStorage.setItem("accountStatus", JSON.stringify(response.account_status))
//     })
// }