import { createSlice } from '@reduxjs/toolkit';
interface NotificationItem {
  id: string;
  text: number;
  read: boolean;
  thumbnail?: string;
  type: string;
  username: string;
  dateCreated: number;
}

interface NotificationObj {
  totalCount?: number;
  notificationList?: Array<NotificationItem>;
}

const initialState = {
  showCommonLoader: false,
  totalCount: 0,
  notificationList: [],
  loaderAccountStatus: {},
  subscriptionPlan: '',
  allSubscriptionPlans: [],
  accountStatusDetails: {},
  accountPermissions: {},
  topupPlans: [],
  walletBalance: 0,
};

const loaderSlice = createSlice({
  name: 'loaderSlice',
  initialState,
  reducers: {
    setShowCommonLoader: (state, action) => {
      state.showCommonLoader = action.payload;
    },
    addNewNotification: (state, action) => {
      const newNotification = action.payload;
      const existingNotificationIndex = state.notificationList.findIndex(
        (notification) =>
          notification.title === newNotification.title &&
          notification.text === newNotification.text &&
          notification.type === newNotification.type &&
          notification.dateCreated === newNotification.dateCreated
      );
      if (existingNotificationIndex === -1) {
        state.notificationList.push(newNotification);
        state.notificationList.sort((a, b) =>
          b?.dateCreated?.localeCompare(a?.dateCreated)
        );
        state.totalCount++;
      }
    },
    clearNotifications: (state) => {
      state.notificationList = [];
      state.totalCount = 0;
    },
    markNotificationAsRead: (state, action) => {
      const { index } = action.payload;
      if (index >= 0 && index < state.notificationList.length) {
        if (!state.notificationList[index]?.isRead) {
          state.notificationList[index].isRead = true;
          state.totalCount--;
        }
      }
    },
    markAllNotificationsAsRead: (state) => {
      state.notificationList.forEach((notification) => {
        notification.isRead = true;
      });
      state.totalCount = 0;
    },
    setAccountStatusDetails: (state, action) => {
      state.accountStatusDetails = action.payload;
    },
    setLoaderAccountStatus: (state, action) => {
      state.loaderAccountStatus = action.payload;
    },
    setWalletBalance: (state, action) => {
      state.walletBalance = action.payload;
    },
    setSubscriptionPlan: (state, action) => {
      state.subscriptionPlan = action.payload;
    },
    setAllSubscriptionPlans: (state, action) => {
      state.allSubscriptionPlans = action.payload;
    },
    setTopupPlans: (state, action) => {
      state.topupPlans = action.payload;
    },
    setAccountPermissions: (state, action) => {
      state.accountPermissions = action.payload;
    },
  },
});

export const {
  setShowCommonLoader,
  addNewNotification,
  clearNotifications,
  markNotificationAsRead,
  markAllNotificationsAsRead,
  setLoaderAccountStatus,
  setWalletBalance,
  setSubscriptionPlan,
  setAllSubscriptionPlans,
  setAccountStatusDetails,
  setAccountPermissions,
  setTopupPlans,
} = loaderSlice.actions;
export default loaderSlice.reducer;
