import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "../../common/components/header";
import { importModule } from "../../common/utils/importModule";
import { TenantContext } from "../home";
import { setShowCommonLoader } from "../../redux-loader/loader-slice/loaderSlice";
import ChevronRight from "../../common/assets/icons/chevronRight";

const CsvUploads = importModule("@tg/data", "CsvUploads");

const CsvUploadList = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const tenantContext = useContext(TenantContext);

  return (
    <div>
      <Header
        pageName={
          <div className="flex text-base items-center gap-3">
            <span className="text-gray-500">Data</span>
            <ChevronRight />
            <span>CSV Uploads</span>
          </div>
        }
      />
      <div className="content py-8 px-10">
        <CsvUploads
          navigate={navigate}
          dispatch={dispatch}
          setShowCommonLoader={setShowCommonLoader}
          tenantContext={tenantContext}
        />
      </div>
    </div>
  );
};

export default CsvUploadList;
