import { useEffect, useRef, useState } from "react";
import { Provider } from "react-redux";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import {
  getSurveyReportDetails,
  downloadSurveyReportCSV,
  getSurveyDetails,
  updateSurvey,
} from "../../common/services/survey-services";
import SurveyAnalytics from "./survey-analytics/survey-analytics";
import { colorCode } from "../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";
import { downloadCSV } from "../../common/utils/fileDownload";
import ApproveRejectCampaigns from "../../common/components/approve-reject-campaigns";
import { approveCampaign } from "../../common/services/message-services";
import { Notify } from "../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import * as Sentry from "@sentry/react";
import SurveyData from "../create-survey/surveyLaunch/surveyData";
import SurveyInviteMessage from "./surveyInviteMessage";
import {
  Close,
  PreviewSurvey,
  SurveyDetailsIconsCaretDown,
  SurveyDetailsIconsCsv,
  SurveyDetailsIconsExport,
  SurveyDetailsIconsGlobe,
  SurveyDetailsIconsMessage,
  SurveyDetailsIconsMoney,
  SurveyDetailsIconsSchedule,
  SurveyDetailsIconsUsers,
  SurveyInviteIconsDelete,
  SurveyInviteIconsEdit,
  SurveyLaunchIconLaunch,
  SurveyLaunchIconSidebar,
} from "../../assets/icons/icons";
import UssdSurveyPreview from "../create-survey/step-two-ussd/ussd-survey-preview";
import WebSurveyPreview from "../create-survey/step-two-web/web-survey-preview/web-survey-preview";
import PDFViewer from "../message-insights/campaign-insight/pdf-viewer";
import loaderStore from "../../../../marketing-cloud-main/src/redux-loader/loader-store/loaderStore";

const SurveyDetails = (props: any) => {
  const [surveyDetails, setSurveyDetails] = useState({
    audienceType: "",
    channel: "",
    createdAt: "",
    duration: {
      startDate: "",
      endDate: "",
    },
    rewardSpend: null,
    surveyLink: "",
    status: "",
    totalRespondents: 0,
    totalCompletedRespondents: 0,
    totalIncompletedRespondents: 0,
    surveyName: "",
    questionsCount: 0,
  });
  const [surveyData, setSurveyData]: any = useState();
  const [showInviteMessageModal, setShowInviteMessageModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showDownloadMenu, setShowDownloadMenu] = useState(false);
  const [inviteMessages, setInviteMessages] = useState([]);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [previewToggle, setPreviewToggle] = useState(false);
  const downloadMenu = useRef(null);
  const { dispatch, setShowCommonLoader, hasSuperAdminAccess = false } = props;
  const businessId = props.tenantContext.id;
  useEffect(() => {
    fetchSurveyDetails();
  }, []);

  const getQuestions = () => {
    let questions = [];
    surveyData.content.questions.map((page) => {
      let pageQuestions = [];
      page.elements.map((question) => {
        let q: any = {};
        q.q = question?.text;
        q.s = question?.subQuestion;
        q.index = question?.index;
        q.type = question?.type;
        q.property = question?.property;
        q.rules = question?.rules;
        pageQuestions.push(q);
      });
      questions.push(pageQuestions);
    });
    return questions;
  };

  const addInviteMessage = (message) => {
    let newMessages = [...inviteMessages, message];
    setSelectedIndex(null);
    saveInviteMessage(newMessages);
  };

  const deleteInviteMessage = () => {
    let newInvitesMessages = inviteMessages.filter(
      (el, i) => i !== selectedIndex
    );
    //setInviteMessages(newInvitesMessages);
    saveInviteMessage(newInvitesMessages);
    setSelectedIndex(null);
    setShowDeletionModal(false);
  };

  const editInviteMessage = (index, newMessage) => {
    let newInviteMessages = [...inviteMessages];
    newInviteMessages[index] = newMessage;
    //setInviteMessages(newInviteMessages);
    saveInviteMessage(newInviteMessages);
  };

  const saveInviteMessage = (message) => {
    let invitationMessageDetails = surveyData.invitationMessageDetails;
    invitationMessageDetails["messages"] = message;
    let data = {
      ...surveyData,
      invitationMessageDetails: invitationMessageDetails,
    };
    dispatch(setShowCommonLoader(true));
    try {
      updateSurvey(data, data.id)
        .then((res) => {
          dispatch(setShowCommonLoader(false));
          const { error, response = {} } = res;
          if (error === false && response) {
            setInviteMessages(message);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
          dispatch(setShowCommonLoader(false));
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log(error);
          dispatch(setShowCommonLoader(false));
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
      dispatch(setShowCommonLoader(false));
    }
  };

  const fetchSurveyDetails = () => {
    dispatch(setShowCommonLoader(true));

    try {
      getSurveyReportDetails(props.id)
        .then((res) => {
          const { error, response = {} } = res;
          if (error === false && response) {
            setSurveyDetails(response);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
          dispatch(setShowCommonLoader(false));
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log(error);
          dispatch(setShowCommonLoader(false));
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
      dispatch(setShowCommonLoader(false));
    }
  };

  dayjs.extend(localizedFormat);

  const closeOpenMenus = (e) => {
    if (
      downloadMenu.current &&
      showDownloadMenu &&
      !downloadMenu.current.contains(e.target)
    ) {
      setShowDownloadMenu(false);
    }
  };

  const downloadCSVREport = () => {
    setShowDownloadMenu(false);
    try {
      downloadSurveyReportCSV(props.id)
        .then((response) => {
          downloadCSV(response, `${props.id}-report`);
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  const approveCampaigns = (statusApproval: String, reason: String) => {
    if (
      surveyDetails.audienceType !== "general" &&
      inviteMessages.length === 0 &&
      statusApproval !== "Rejected"
    ) {
      Notify({
        heading: "Failed",
        message: "Please add an invite message",
        type: "Error",
      });
      return;
    }
    const payload = {
      approvalStatus: statusApproval,
      campaignType: "SURVEY",
      campaignId: props.id,
      reason,
    };
    try {
      approveCampaign({ payload })
        .then((res) => {
          const { error, response } = res;
          if (!error) {
            Notify({
              type: "success",
              heading: "Survey Approved",
              message: `${surveyDetails.channel} survey approved. ${surveyDetails?.channel} was approved successfully for launch.`,
            });
            fetchSurveyDetails();
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  useEffect(() => {
    if (surveyDetails.status?.toLowerCase() === "pending approval") {
      try {
        getSurveyDetails(props.id)
          .then((res) => {
            const { error, response = {} } = res;
            if (!error || error === "false") {
              setSurveyData(response);
              dispatch(setShowCommonLoader(false));
              response.invitationMessageDetails?.messages &&
                setInviteMessages(response.invitationMessageDetails?.messages);
            } else {
              error.response
                ? Sentry.captureMessage("Error", error.response)
                : Sentry.captureMessage("Error", error);
              dispatch(setShowCommonLoader(false));
            }
          })

          .catch((err) => {
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            console.log(err);
            dispatch(setShowCommonLoader(false));
          });
      } catch (err) {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        dispatch(setShowCommonLoader(false));
      }
    }
  }, [surveyDetails]);

  const getPdfUrlForBusinessId = () => {
    switch (businessId) {
      case 761:
        return "https://s3.eu-west-1.amazonaws.com/cdn.terragonbase.com/761/insights.pdf";
    }
  };

  const getdeletionModal = () => {
    return (
      <div className="fixed left-0 right-0 bottom-0 top-0 bg-black bg-opacity-20 flex items-center justify-center">
        <div className="bg-white rounded-lg p-8 text-base text-gray-600 font-semibold max-h-[80%] overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <span>Delete survey message</span>
            <div
              onClick={() => setShowDeletionModal(false)}
              className="cursor-pointer"
            >
              <img src={Close} alt="" />
            </div>
          </div>
          <div className="text-sm leading-6 text-gray-500 max-w-[380px]">
            Are you sure you want to delete this survey invite message? This
            action cannot be undone.
          </div>
          <div className="flex justify-end gap-4 text-sm leading-6 font-semibold mt-7">
            <button
              className="px-5 py-3 border rounded-lg"
              onClick={() => setShowDeletionModal(false)}
            >
              Cancel
            </button>
            <button
              className="px-7 py-3 bg-[#D14343] text-white rounded-lg"
              onClick={deleteInviteMessage}
            >
              Yes, delete
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Provider store={loaderStore}>
      <div>
        {surveyDetails.status === "PENDING APPROVAL" ? (
          <div className="p-10 flex justify-between gap-10">
            <div className="grow">
              <div className="flex justify-between mb-8">
                <div>
                  <span>{surveyData?.name}</span>
                  <span className="bg-[#FFFAEB] font-semibold text-[#B54708] rounded-2xl px-2 py-1 ml-3 text-xs capitalize">
                    {surveyData?.status.toLowerCase()}
                  </span>
                </div>
                <ApproveRejectCampaigns
                  hasSuperAdminAccess={hasSuperAdminAccess}
                  status={surveyDetails.status}
                  approveCampaigns={approveCampaigns}
                />
              </div>
              {hasSuperAdminAccess &&
                surveyDetails.audienceType !== "general" && (
                  <div>
                    <span className="text-sm text-gray-700 font-semibold">
                      Survey invite
                    </span>
                    {inviteMessages.length !== 0 && (
                      <div className="border-b">
                        {inviteMessages.map((el, index) => (
                          <div
                            key={index}
                            className="p-3 flex gap-16 items-center"
                          >
                            <div className="max-w-[500px] overflow-hidden whitespace-nowrap text-ellipsis">
                              {el.text}
                            </div>
                            <div className="flex gap-3">
                              <div
                                onClick={() => {
                                  setSelectedIndex(index);
                                  setShowInviteMessageModal(true);
                                }}
                                className="px-3 py-2 border rounded-md cursor-pointer"
                              >
                                <img src={SurveyInviteIconsEdit} alt="" />
                              </div>
                              <div
                                onClick={() => {
                                  setSelectedIndex(index);
                                  setShowDeletionModal(true);
                                }}
                                className="px-3 py-2 border rounded-md cursor-pointer"
                              >
                                {" "}
                                <img src={SurveyInviteIconsDelete} alt="" />
                              </div>
                              {showDeletionModal && getdeletionModal()}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <button
                      onClick={() => setShowInviteMessageModal(true)}
                      disabled={inviteMessages.length >= 3}
                      className={`text-green-15 mt-2 mb-12 block underline text-sm leading-6 cursor-pointer`}
                    >
                      Add survey invite message
                    </button>
                  </div>
                )}

              <div className="mb-4 flex gap-4">
                <span className="text-gray-700 font-semibold mr-4">
                  Survey Details
                </span>
                <span
                  className="text-green-15 cursor-pointer flex gap-1 items-center text-sm"
                  onClick={() => {
                    setPreviewToggle(true);
                  }}
                >
                  Preview Survey
                  <img src={PreviewSurvey} alt="" />
                </span>
              </div>
              <SurveyData data={surveyData} edit={false} />
              {showInviteMessageModal && (
                <SurveyInviteMessage
                  closeModal={() => {
                    if (selectedIndex !== null) setSelectedIndex(null);
                    setShowInviteMessageModal(false);
                  }}
                  selectedMessage={inviteMessages[selectedIndex]}
                  addInviteMessage={addInviteMessage}
                  editInviteMessage={editInviteMessage}
                  selectedIndex={selectedIndex}
                  url={surveyDetails.surveyLink}
                  senderName={
                    surveyData.invitationMessageDetails?.senderName !==
                    "DEFAULT"
                      ? surveyData.invitationMessageDetails?.senderName
                      : null
                  }
                />
              )}

              {surveyData?.channel === "USSD" ? (
                <UssdSurveyPreview
                  previewToggle={previewToggle}
                  setPreviewToggle={setPreviewToggle}
                  question={{
                    introduction:
                      surveyData?.content?.welcomeMessage?.questionText,
                    questions: surveyData?.content?.questions,
                    endMessage: surveyData?.content?.endMessage?.questionText,
                  }}
                />
              ) : (
                previewToggle && (
                  <WebSurveyPreview
                    pages={getQuestions()}
                    introduction={{
                      description: surveyData.content.surveyDetails,
                      buttonLabel: surveyData.content.buttonLabel,
                      name: surveyData.name,
                    }}
                    imageUrl={surveyData.content.logoUrl}
                    exit={surveyData.endMessage?.content.questionText}
                    setStatus={(status) => {
                      setPreviewToggle(status);
                    }}
                  />
                )
              )}
            </div>
            <div className="mr-10">
              <img src={SurveyLaunchIconSidebar} alt="" />
            </div>
          </div>
        ) : (
          <div className="p-8">
            <div className="flex justify-between">
              <div className="flex justify-center items-center">
                <img src={SurveyDetailsIconsGlobe} alt="" />
                <div className="ml-2.5 text-xl font-semibold text-gray-700">
                  {surveyDetails.surveyName || "-"}
                </div>
                {surveyDetails.status && (
                  <div
                    className={`first-letter:capitalize ml-4 rounded-2xl py-0.5 px-2 text-xs font-medium ${colorCode(
                      surveyDetails.status
                    )}`}
                  >
                    {surveyDetails.status.toLowerCase() === "running"
                      ? "active"
                      : surveyDetails.status.toLowerCase()}
                  </div>
                )}
              </div>
              {/* <ApproveRejectCampaigns
          hasSuperAdminAccess={hasSuperAdminAccess}
          status={surveyDetails.status}
          approveCampaigns={approveCampaigns}
        /> */}
              <div className="relative">
                <button
                  className="inline-flex gap-2 items-center px-6 py-2.5 text-gray-700 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
                  onClick={() => setShowDownloadMenu(!showDownloadMenu)}
                >
                  Download report{" "}
                  <img src={SurveyDetailsIconsCaretDown} alt="" />
                </button>

                {/* Download dropdown menu */}
                {showDownloadMenu && (
                  <div
                    ref={downloadMenu}
                    className="absolute mt-2 z-10 bg-white rounded-lg shadow w-48"
                  >
                    <ul className="p-2 text-sm text-gray-700">
                      {/* <li>
                  <div className="flex items-center p-3 rounded hover:bg-gray-10">
                    <SurveyDetailsIcons type={"pdf"} />
                    <div className="w-full ml-3 text-sm text-gray-700 cursor-pointer">
                      Download as PDF
                    </div>
                  </div>
                </li> */}
                      <li>
                        <div
                          onClick={() => downloadCSVREport()}
                          className="flex items-center p-3 rounded hover:bg-gray-10"
                        >
                          <img src={SurveyDetailsIconsCsv} alt="" />
                          <div className="w-full ml-3 text-sm text-gray-700 cursor-pointer">
                            Download as CSV
                          </div>
                        </div>
                      </li>
                      {/* <li>
                  <div className="flex items-center p-3 rounded hover:bg-gray-10">
                    <SurveyDetailsIcons type={"email"} />
                    <div className="w-full ml-3 text-sm text-gray-700 cursor-pointer">
                      Export to email
                    </div>
                  </div>
                </li> */}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-12">
              <div className="flex gap-1">
                <div className="w-36 text-base font-semibold text-gray-70">
                  Survey details
                </div>
                <div className="grow h-1 border-t mt-3 border-[#EBEFF3]"></div>
              </div>
              <div className="py-8">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
                  <div className="flex rounded-xl">
                    <div className="flex justify-center items-center">
                      <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                        <img src={SurveyDetailsIconsMessage} alt="" />
                      </div>
                      <div className="ml-3">
                        <span className="text-gray-700 font-semibold text-sm leading-6 whitespace-no-wrap">
                          Survey channel
                        </span>
                        <p className="text-gray-70 pt-1 font-normal text-sm whitespace-no-wrap">
                          {surveyDetails.channel || "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex rounded-xl">
                    <div className="flex justify-center items-center">
                      <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                        <img src={SurveyDetailsIconsExport} alt="" />
                      </div>
                      <div className="ml-3">
                        <span className="text-gray-700 font-semibold text-sm leading-6 whitespace-no-wrap">
                          Audience type
                        </span>
                        <p className="text-gray-70 pt-1 font-normal text-sm whitespace-no-wrap first-letter:capitalize">
                          {surveyDetails.audienceType || "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex rounded-xl">
                    <div className="flex justify-center items-center">
                      <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                        <img src={SurveyDetailsIconsUsers} alt="" />
                      </div>
                      <div className="ml-3">
                        <span className="text-gray-700 font-semibold text-sm leading-6 whitespace-no-wrap">
                          Survey link
                        </span>
                        <p className="text-gray-70 pt-1 font-normal text-sm whitespace-no-wrap break-all">
                          {surveyDetails.surveyLink || "-"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex rounded-xl">
                    <div className="flex justify-center items-center">
                      <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                        <img src={SurveyDetailsIconsUsers} alt="" />
                      </div>
                      <div className="ml-3">
                        <span className="text-gray-700 font-semibold text-sm leading-6 whitespace-no-wrap">
                          Respondents
                        </span>
                        <p className="text-gray-70 pt-1 font-normal text-sm whitespace-no-wrap">
                          {surveyDetails.totalRespondents || "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex rounded-xl">
                    <div className="flex justify-center items-center">
                      <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                        <img src={SurveyDetailsIconsSchedule} alt="" />
                      </div>
                      <div className="ml-3">
                        <span className="text-gray-700 font-semibold text-sm leading-6 whitespace-no-wrap">
                          Completed responses
                        </span>
                        <p className="text-gray-70 pt-1 font-normal text-sm whitespace-no-wrap">
                          {surveyDetails.totalCompletedRespondents || "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex rounded-xl">
                    <div className="flex justify-center items-center">
                      <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                        <img src={SurveyDetailsIconsMoney} alt="" />
                      </div>
                      <div className="ml-3">
                        <span className="text-gray-700 font-semibold text-sm leading-6 whitespace-no-wrap">
                          Uncompleted responses
                        </span>
                        <p className="text-gray-70 pt-1 font-normal text-sm whitespace-no-wrap">
                          {surveyDetails.totalIncompletedRespondents || "-"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex rounded-xl">
                    <div className="flex justify-center items-center">
                      <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                        <img src={SurveyDetailsIconsMoney} alt="" />
                      </div>
                      <div className="ml-3">
                        <span className="text-gray-700 font-semibold text-sm leading-6 whitespace-no-wrap">
                          Reward spend
                        </span>
                        <p className="text-gray-70 pt-1 font-normal text-sm whitespace-no-wrap">
                          {surveyDetails.rewardSpend
                            ? props.currencySymbol + surveyDetails.rewardSpend
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex rounded-xl">
                    <div className="flex justify-center items-center">
                      <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                        <img src={SurveyDetailsIconsSchedule} alt="" />
                      </div>
                      <div className="ml-3">
                        <span className="text-gray-700 font-semibold text-sm leading-6 whitespace-no-wrap">
                          Date created
                        </span>
                        <p className="text-gray-70 pt-1 font-normal text-sm whitespace-no-wrap">
                          {surveyDetails.createdAt
                            ? dayjs(surveyDetails.createdAt).format("lll")
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex rounded-xl">
                    <div className="flex justify-center items-center">
                      <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                        <img src={SurveyDetailsIconsSchedule} alt="" />
                      </div>
                      <div className="ml-3">
                        <span className="text-gray-700 font-semibold text-sm leading-6 whitespace-no-wrap">
                          Duration
                        </span>
                        <p className="text-gray-70 pt-1 font-normal text-sm whitespace-no-wrap">
                          {surveyDetails.duration?.startDate &&
                          surveyDetails.duration?.endDate
                            ? dayjs(surveyDetails.duration.startDate).format(
                                "lll"
                              ) +
                              " - " +
                              dayjs(surveyDetails.duration.endDate).format(
                                "lll"
                              )
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {businessId === 761 && (
              <div className="mx-10 mt-5">
                <div className="text-base font-semibold text-gray-70 mb-8">
                  Post Campaign Report
                </div>
                <div className="h-[500px] flex justify-center items-center">
                  <PDFViewer pdfURL={getPdfUrlForBusinessId()} />
                </div>
              </div>
            )}
            <SurveyAnalytics
              id={props.id}
              questionCount={surveyDetails.questionsCount}
              surveyDuration={surveyDetails.duration}
              dispatch={dispatch}
              setShowCommonLoader={setShowCommonLoader}
            />
          </div>
        )}
      </div>
    </Provider>
  );
};

export default SurveyDetails;
