import { useEffect, useState } from "react";
import QuestionStyle from "../web-survey-question-property/question-style";

const Text = (props: any) => {
  const [question, setQuestion] = useState(props.question);
  const [questionStyleVisibility, setQuestionStyleVisibility] = useState(false);

  const onQuestionChange = (event) => {
    let q = question;
    q.q = event.target.value;
    props.setRating(q);
  };

  const onSubTextChange = (event) => {
    let q = question;
    q.s = event.target.value;
    props.setRating(q);
  };

  useEffect(() => {
    const { question } = props;
    setQuestion(question);
  }, [props]);

  const questionStyleVisibilityOnChange = (status) => {
    setQuestionStyleVisibility(status);
  };

  return (
    <div>
      <div>
        <div
          className="border border-[#E4E7EC] rounded px-2 py-3 mb-1 relative"
          //   onMouseOver={() => questionStyleVisibilityOnChange(true)}
          //   onMouseOut={() => questionStyleVisibilityOnChange(false)}
        >
          <input
            className="w-full text-xl text-[#98A2B3] border-0 outline-0 font-semibold"
            onChange={(event) => {
              onQuestionChange(event);
            }}
            value={question?.q}
            placeholder="Main question here"
          />
          {questionStyleVisibility ? (
            <div className="absolute left-0 top-12">
              <QuestionStyle />
            </div>
          ) : null}
        </div>
        <div className="border border-[#E4E7EC] rounded px-2 py-3 mb-1">
          <input
            className="w-full text-sm text-[#98A2B3] border-0 outline-0"
            onChange={(event) => {
              onSubTextChange(event);
            }}
            value={question?.s}
            placeholder="Subtext goes here (optional)"
          />
        </div>
      </div>
      <div>
        <div className="mt-4 bg-[#F9FAFB] h-[196px] w-full border rounded">
          <div className="pt-2 pl-3 text-[#98A2B3]">Answer goes here</div>
        </div>
      </div>
    </div>
  );
};

export default Text;
