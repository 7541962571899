import { useEffect, useState } from "react";
import { getStatus } from "../../services/common-service";
import * as Sentry from "@sentry/react";
import {
  addGAEventListener,
  getPageName,
} from "../../../../../marketing-cloud-container/src/ga-events";

const StatusMenu = (props: any) => {
  const [statusList, setStatusList] = useState([]);
  const {
    showStatusMenu,
    statusMenu,
    setStatus,
    setFilter,
    setPageSize,
    setPageNum,
    setFrom,
    status,
    type,
    audience,
  } = props;

  useEffect(() => {
    getValidStatus();
  }, []);

  useEffect(() => {
    addGAEventListener();
  }, [showStatusMenu]);

  const getValidStatus = () => {
    getStatus()
      .then((response) => {
        if (!response.error) {
          setStatusList(response.response);
        } else {
          Sentry.captureMessage("Error", response);
          console.log(response);
        }
      })
      .catch((err) => {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        console.log(err);
      });
  };

  const handleSetStatus = (e: any) => {
    setPageNum(0);
    setPageSize(10);
    setFrom(0);
    setStatus(e.target.value);
    setFilter(true);
    if (status === e.target.value) {
      setStatus("");
      if (audience || type) {
        return setFilter(true);
      } else {
        return setFilter(false);
      }
    }
  };

  return (
    showStatusMenu && (
      <div
        ref={statusMenu}
        className="absolute mt-1 z-10 bg-white rounded-lg shadow w-52"
      >
        <ul
          className="p-2 text-sm text-gray-700 triggerGA"
          data-event-category={`${getPageName()}-list-page`}
          data-event-label={`${getPageName()}-list_status-option_click`}
          data-event-action="filter_click"
        >
          {statusList.map((ele, i) => {
            return (
              <li key={i}>
                <div className="flex items-center p-3 rounded hover:bg-gray-100">
                  <input
                    checked={status === ele.internalValue}
                    id={ele.internalValue}
                    onChange={handleSetStatus}
                    type="checkbox"
                    value={ele.internalValue}
                    className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                  />
                  <label
                    htmlFor={ele.internalValue}
                    className="w-full ml-3  text-sm leading-6 font-medium text-gray-700 rounded cursor-pointer"
                  >
                    {ele.displayName}
                  </label>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
};

export default StatusMenu;
