import { useState } from "react";
import Spinner from "../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import { Close, DataUpload } from "../../assets/icons/icons";

const PersonalizationModal = (props) => {
  const [loading, setLoading] = useState(false);

  if (!props.show) {
    return null;
  }

  return (
    <div
      className="fixed z-[1] top-0 left-0 right-0 bottom-0 bg-black bg-opacity-30 flex items-center justify-center"
      // onClick={() => {
      //   props.closeModal();
      // }}
    >
      <div
        className="bg-white rounded-xl  w-5/12"
        // onClick={(e) => {
        //   e.stopPropagation();
        // }}
      >
        <div>
          <div className="mb-5 flex justify-between px-8 pt-8">
            <input
              placeholder="Filter"
              className="outline-none text-2xl flex-auto font-normal"
              onChange={props.filterAttributes}
              autoFocus
            />
            <div onClick={props.closeModal} className="cursor-pointer">
              <img src={Close} alt="" />
            </div>
          </div>
          <hr />
          <div className="h-164 overflow-y-auto">
            {loading ? (
              <div className="m-6">
                <Spinner className={"w-8 h-8"} />
              </div>
            ) : (
              <div>
                <div className="flex justify-between items-start text-gray-700 p-8">
                  <div className="flex items-center gap-2 text-sm leading-6 font-semibold">
                    <img src={DataUpload} alt="" />
                    <span>Data Upload</span>
                  </div>
                </div>
                <hr className="h-[0.5px] border-none bg-gray-40" />

                {props.attributeMatches.length === 0 ? (
                  <div className="text-center my-10">No attributes found</div>
                ) : (
                  <div className="text-gray-70 text-sm leading-6 cursor-pointer">
                    {props.attributeMatches.map((el, index) => (
                      <div key={index}>
                        <div
                          className="px-8 py-6"
                          onClick={() => {
                            // props.insertVariable(el.column_name);
                            props.setSelectedAttributeIndex(index);
                            props.setDefaultMessageModal(true);
                            props.closeModal();
                          }}
                        >
                          {el?.display_name}
                        </div>
                        <hr className="h-[0.1px] border-none bg-gray-40" />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
          {/* <div className="text-gray-70 text-sm leading-6 cursor-pointer">
            <div
              className="px-8 py-6"
              onClick={(e) => {
                setDefaultMessageModal(true);
              }}
            >
              first_name
            </div>
            <DefaultMessageModal
              show={defaultMessageModal}
              closeModal={() => setDefaultMessageModal(false)}
              insertVariable={props.insertVariable}
              closePresonalizeModal={props.closeModal}
            />
            <hr />
            <div
              className="px-8 py-6"
              onClick={() => {
                setDefaultMessageModal(true);
              }}
            >
              last_name
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PersonalizationModal;
