import { useEffect, useReducer, useRef, useState } from "react";
import sanitizeHtml from "sanitize-html";
import * as Sentry from "@sentry/react";
import { ConfigProvider, DatePicker, TimePicker } from "antd";
import type { DatePickerProps } from "antd/es/date-picker";
import locale from "antd/es/date-picker/locale/en_GB";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { CUSTOMER_ID } from "../../../common/constants/billingConstants";
import NotificationModal from "../../../modals/notification/NotificationModal";
import PersonalizationModal from "../../../modals/PersonalizationModal/personalizationModal";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import DefaultMessageModal from "../../../modals/default-message-modal/DefaultMessageModal";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import DeletionModal from "../../../common/components/campaign-deletion-modal/campaignDeletionModal";
import NoURLPrompt from "../../../modals/no-url-prompt/NoURLPrompt";
import Copy from "../../../common/assets/icons/Copy";
import {
  postStepOne,
  updateMessage,
  getSenderIds,
  getObjectives,
  getSegmentVariables,
} from "../../../common/services/message-services";
import {
  ArrowRight,
  CalendarIcon,
  Chevron,
  Divider,
  PhoneSms,
  Sms,
  User,
} from "../../../assets/icons/icons";

const sendSchedule = [
  { schedule: "Send now", description: "Message is sent immediately." },
  { schedule: "Send later", description: "Message is sent at a later time." },
  {
    schedule: "Send frequently",
    description: "Message is sent frequently at set intervals.",
  },
];

const frequency = ["Daily", "Weekly", "Monthly", "Custom Intervals"];

const dayOfWeek = (day) => {
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  return days[day];
};

const SmsStepTwo = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [showScheduleMenu, setShowScheduleMenu] = useState(false);
  const [showFrequencyMenu, setShowFrequencyMenu] = useState(false);
  const [showIntervalMenu, setShowIntervalMenu] = useState(false);
  const [showSenderIDMenu, setShowSenderIDMenu] = useState(false);
  const [showStartCalendar, setShowStartCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);
  const [showObjectiveMenu, setShowObjectiveMenu] = useState(false);
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const [senderIDs, setSenderIDs] = useState([]);
  const [messageLength, setMessageLength] = useState(160);
  const [totalMessageLength, setTotalMessageLength] = useState(480);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [scheduleError, setScheduleError] = useState("");

  const [smsExists, setSmsExists] = useState(false);
  const [smsNameError, setSmsNameError] = useState("");
  const [pushTimeError, setPushTimeError] = useState("");
  const [personalizationModal, setPersonalizationModal] = useState(false);
  const [defaultMessageModal, setDefaultMessageModal] = useState(false);
  const [defaultName, setDefaultName] = useState([]);

  const scheduleMenu = useRef(null);
  const frequencyMenu = useRef(null);
  const intervalMenu = useRef(null);
  const senderIDMenu = useRef(null);
  const objectiveMenu = useRef(null);

  const [smsObjectives, setSmsObjectives] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [attributeMatches, setAttributeMatches] = useState([]);
  const [selectedAttributeIndex, setSelectedAttributeIndex] = useState(-1);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [hasNoURLApproved, setHasNoURLApproved] = useState(false);
  const [showURLprompt, setShowURLPrompt] = useState(false);

  useEffect(() => {
    if (props.data.content.default?.length) {
      setDefaultName(props.data.content.default);
      return;
    }
    if (attributesList && attributesList.length) {
      // const params = attributeMatches.map((attr) => attr.column_name);
      // setParams(params);

      const defaultNames = attributesList.map((attr) => {
        return {
          fieldName: attr.column_name,
          defaultName: attr.display_name?.substring(0, 10),
        };
      });
      setDefaultName([...defaultNames]);
    }
  }, [attributesList, props.data]);

  const getPersonalizationVariables = (segmentId) => {
    // try {
    //   getattributes("csv")
    //     .then((res) => {
    //       const { error, response = [] } = res;
    //       if (!error || error === "false") {
    //         setAttributesList(res.data);
    //         setAttributeMatches(res.data);
    //       }
    //     })
    //     .catch((err) => {});
    // } catch (err) {
    //   console.log(err);
    // }
    try {
      getSegmentVariables(segmentId)
        .then((res) => {
          const { error, response = [] } = res;
          if (!error || error === "false") {
            setAttributesList(res.data);
            setAttributeMatches(res.data);
          }
        })
        .catch((err) => {});
    } catch (err) {
      console.log(err);
    }
  };

  const filterAttributes = (event) => {
    let matches = attributesList.filter((el) =>
      el.display_name
        ?.toLowerCase()
        ?.includes(event.target.value?.toLowerCase())
    );
    setAttributeMatches(matches);
  };

  const enablePersonalization = () => {
    return (
      props?.data?.audience?.segmentId &&
      props?.data?.selectedSegment &&
      props?.data?.selectedSegment.audience_source !== "terragon"
    );
  };

  useEffect(() => {
    if (enablePersonalization())
      getPersonalizationVariables(props?.data?.selectedSegment?.segment_ids);
  }, [props.data]);

  const fetchObjectives = () => {
    try {
      getObjectives()
        .then((res) => {
          const { error, response = {} } = res;
          if (!error) {
            setSmsObjectives(response.campaignObjectives || []);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  useEffect(() => {
    if (props.data.id) {
      setSmsExists(true);
    }

    if (props.data.campaignStartDate && props.data.campaignStartTime) {
      setStartDate(
        new Date(
          props.data.campaignStartDate + "T" + props.data.campaignStartTime
        )
      );
    }

    if (props.data.campaignEndDate && props.data.campaignEndTime) {
      setEndDate(
        new Date(props.data.campaignEndDate + "T" + props.data.campaignEndTime)
      );
    }

    fetchSenderIDs();
    fetchObjectives();
  }, []);

  const fetchSenderIDs = () => {
    try {
      getSenderIds()
        .then((res) => {
          const { error, response = {} } = res;
          if (error === false) {
            setSenderIDs(response);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
            Notify({
              type: "error",
              heading: "Failed to retrieve sender IDs.",
              message: error,
            });
            setSenderIDs([]);
          }
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log(error);
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
    }
  };

  dayjs.extend(customParseFormat);
  dayjs.extend(localizedFormat);

  const onStartTimeChange = (time: Dayjs, timeString: string) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "campaignStartTime",
        value: time.format("HH:mm:ss").split(" ")[0],
      },
    });

    // Compensate for BE auto-setting endDate
    if (endDate && formState.schedule !== "Send frequently") {
      dispatch({
        type: "CHANGE",
        payload: {
          name: "campaignEndTime",
          value: time.format("HH:mm:ss").split(" ")[0],
        },
      });
    }
  };

  const onStartDateChange = (
    value: DatePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "campaignStartDate",
        value: dateString,
      },
    });

    // Compensate for BE auto-setting endDate
    if (endDate && formState.schedule !== "Send frequently") {
      dispatch({
        type: "CHANGE",
        payload: {
          name: "campaignEndDate",
          value: dateString,
        },
      });
    }
  };

  const onStartDateSave = () => {
    if (!formState.campaignStartDate) {
      formState.errors.campaignStartDate = true;
      return;
    }
    if (!formState.campaignStartTime) {
      formState.errors.campaignStartTime = true;
      return;
    }

    if (
      Number(formState.campaignStartTime.replaceAll(":", "")) < 80000 ||
      Number(formState.campaignStartTime.replaceAll(":", "")) > 190000
    ) {
      formState.errors.campaignStartTime = true;
      setScheduleError("Select an active time (8 AM - 7 PM WAT)");
      return;
    }

    const startDate = new Date(
      formState.campaignStartDate + "T" + formState.campaignStartTime
    );
    if (startDate.getTime() < new Date().getTime()) {
      formState.errors.campaignStartTime = true;
      setScheduleError("Selected time has already passed");
      return;
    } else if (
      startDate &&
      endDate &&
      formState.schedule === "Send frequently" && // Compensate for BE auto-setting endDate
      endDate.getTime() < startDate.getTime()
    ) {
      formState.errors.campaignStartTime = true;
      setScheduleError("Start time must be less than end time");
      return;
    } else {
      setScheduleError("");
    }
    setStartDate(startDate);
    setShowStartCalendar(false);

    // Compensate for BE auto-setting endDate
    if (endDate && formState.schedule !== "Send frequently") {
      setEndDate(startDate);
    }
  };

  const onEndTimeChange = (time: Dayjs, timeString: string) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "campaignEndTime",
        value: time.format("HH:mm:ss").split(" ")[0],
      },
    });
  };

  const onEndDateChange = (
    value: DatePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "campaignEndDate",
        value: dateString,
      },
    });
  };

  const onEndDateSave = () => {
    if (!formState.campaignEndDate) {
      formState.errors.campaignEndDate = true;
      return;
    }
    if (!formState.campaignEndTime) {
      formState.errors.campaignEndTime = true;
      return;
    }

    const endDate = new Date(
      formState.campaignEndDate + "T" + formState.campaignEndTime
    );
    if (endDate && startDate && endDate.getTime() < startDate.getTime()) {
      formState.errors.campaignEndTime = true;
      setScheduleError("End time must be greater than start time");
      return;
    } else {
      setScheduleError("");
    }
    setEndDate(endDate);
    setShowEndCalendar(false);
  };

  const disabledDates = (current) => {
    return current && current < dayjs().endOf("day").subtract(1, "day");
  };

  const copyToClipboard = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setShowCopyTooltip(true);
        setTimeout(() => {
          setShowCopyTooltip(false);
        }, 4000);
      })
      .catch((error) => {
        error.response
          ? Sentry.captureMessage("Error", error.response)
          : Sentry.captureMessage("Error", error);
        console.error("Failed to copy message body:", error);
      });
  };

  const closeOpenMenus = (e) => {
    if (
      senderIDMenu.current &&
      showSenderIDMenu &&
      !senderIDMenu.current.contains(e.target)
    ) {
      setShowSenderIDMenu(false);
    }

    if (
      objectiveMenu.current &&
      showObjectiveMenu &&
      !objectiveMenu.current.contains(e.target)
    ) {
      setShowObjectiveMenu(false);
    }

    if (
      scheduleMenu.current &&
      showScheduleMenu &&
      !scheduleMenu.current.contains(e.target)
    ) {
      setShowScheduleMenu(false);
    }

    if (
      frequencyMenu.current &&
      showFrequencyMenu &&
      !frequencyMenu.current.contains(e.target)
    ) {
      setShowFrequencyMenu(false);
    }

    if (
      intervalMenu.current &&
      showIntervalMenu &&
      !intervalMenu.current.contains(e.target)
    ) {
      setShowIntervalMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);

    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  });

  const handleNotificationClose = () => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      show: false,
    }));
  };

  const [notificationData, setNotificationData] = useState({
    show: false,
    close: handleNotificationClose,
    type: "",
    message: "",
    heading: "",
  });

  const formInitValue = {
    campaignName: props.data.name || "",
    campaignStartDate: props.data.campaignStartDate || "",
    campaignStartTime: props.data.campaignStartTime || "",
    campaignEndDate: props.data.campaignEndDate || "",
    campaignEndTime: props.data.campaignEndTime || "",
    senderID: props.data.senderName || "",
    message: props.data.content?.text || "",
    objective: props.data.objective || "Brand Awareness",
    url: props.data.landingPagesURL || "",
    schedule: props.data?.schedule
      ? props.data?.schedule?.isRecurring
        ? "Send frequently"
        : props.data?.schedule?.immediate
        ? "Send now"
        : "Send later"
      : "",
    campaignFrequency: props.data.campaignFrequency || "",
    campaignInterval: props.data.campaignInterval || "",
    intervalFrequency: props.data.intervalFrequency || "",
    saveAsDraft: false,
    errors: {
      campaignName: false,
      campaignStartDate: false,
      campaignStartTime: false,
      campaignEndDate: false,
      campaignEndTime: false,
      senderID: false,
      message: false,
      objective: false,
      schedule: false,
      campaignFrequency: false,
      campaignInterval: false,
      intervalFrequency: false,
      url: false,
    },
  };

  useEffect(() => {
    dispatch({ type: "reinitialize", value: formInitValue });
    // setParams(props.data.content.params || []);
    if (props.data.id) {
      setSmsExists(true);
    }
  }, [props.data]);

  const isValidURL = (str) => {
    if (
      /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g.test(
        str
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "CHANGE":
        return {
          ...state,
          [action.payload.name]: action.payload.value,
          errors: {
            ...state.errors,
            [action.payload.name]: "",
          },
        };
      case "SUBMIT":
        const errors: any = {};
        if (!state.campaignName.trim()) {
          errors.campaignName = true;
        }
        if (!state.schedule) {
          errors.schedule = true;
        }
        if (
          ["Send later", "Send frequently"].includes(state.schedule) &&
          !startDate
        ) {
          errors.campaignStartDate = true;
        }
        if (state.schedule === "Send frequently") {
          if (!endDate) {
            errors.campaignEndDate = true;
          }
          if (!state.campaignFrequency) {
            errors.campaignFrequency = true;
          }
        }
        if (state.campaignFrequency === "Custom Intervals") {
          if (!state.campaignInterval) {
            errors.campaignInterval = true;
          }
          if (!state.intervalFrequency) {
            errors.intervalFrequency = true;
          }
        }
        if (!state.senderID) {
          errors.senderID = true;
        }
        if (!state.message.trim()) {
          errors.message = true;
        }
        if (!state.objective) {
          errors.objective = true;
        }
        if (
          state.objective !== "Brand Awareness" &&
          (state.url.length === 0 || !isValidURL(state.url))
        ) {
          errors.url = true;
        }

        return {
          ...state,
          errors: {
            ...errors,
          },
        };

      case "reinitialize":
        return { ...state, ...action.value };
      default:
        return state;
    }
  };
  const [formState, dispatch] = useReducer(reducer, formInitValue);

  const handleFormChange = (e) => {
    let { name, value } = e.target;

    if (name === "campaignName") {
      if (smsNameError) {
        setSmsNameError("");
      }
      value = value.replace(/[^a-z0-9 ]/gi, "");
    }

    if (name === "message") {
      value = value.replace(/[‘’]/g, "'");
      value = value.replace(/₦/g, "N");

      try {
        e.preventDefault();
      } catch (error) {
        error.response
          ? Sentry.captureMessage("Error", error.response)
          : Sentry.captureMessage("Error", error);
      }
      if (value.length > totalMessageLength) {
        return;
      }
    }

    if (name === "campaignInterval") {
      value = value.replace(/[^0-9]/g, "");
    }

    dispatch({
      type: "CHANGE",
      payload: {
        name,
        value,
      },
    });
  };

  const generatePreviewMessage = () => {
    if (!props.data?.sampleData || props.data?.sampleData?.length === 0) {
      return formState.message.replace(/\n/g, "<br>");
    }

    const userdata = props.data?.sampleData[0];

    const previewMessage = formState.message.replace(/{{(.*?)}}/g, (_, key) => {
      return userdata[key] || "";
    });

    return previewMessage.replace(/\n/g, "<br>");
  };

  const sanitizedMessage = sanitizeHtml(generatePreviewMessage());

  const getTimelineSummary = () => {
    if (formState.schedule === "Send later") {
      return `(Send on ${dayjs(startDate).format("LLLL")})`;
    }
    if (formState.schedule === "Send frequently") {
      if (startDate && endDate) {
        return `(From ${dayjs(startDate).format("LLLL")} to ${dayjs(
          endDate
        ).format("LLLL")})`;
      }
    }
  };

  const getCronExpression = () => {
    const {
      campaignStartDate,
      campaignStartTime,
      schedule,
      campaignFrequency,
      campaignInterval,
      intervalFrequency,
    } = formState;

    if (schedule !== "Send frequently") {
      return "";
    }

    const [year, month, day] = campaignStartDate.split("-");
    const [hour, minute, second] = campaignStartTime.split(":");

    let cronExpression = "";

    switch (campaignFrequency) {
      case "Daily":
        cronExpression = `${second} ${minute} ${hour} * * ?`;
        break;
      case "Weekly":
        cronExpression = `${second} ${minute} ${hour} ? * ${dayOfWeek(
          new Date(campaignStartDate).getDay()
        )}`;
        break;
      case "Monthly":
        cronExpression = `${second} ${minute} ${hour} ${day} * ?`;
        break;
      case "Custom Intervals":
        if (intervalFrequency === "day(s)") {
          cronExpression = `${second} ${minute} ${hour} */${campaignInterval} * ?`;
        } else if (intervalFrequency === "week(s)") {
          cronExpression = `${second} ${minute} ${hour} ? * */${campaignInterval}`;
        } else if (intervalFrequency === "month(s)") {
          cronExpression = `${second} ${minute} ${hour} ${day} */${campaignInterval} ?`;
        }
        break;
      default:
        cronExpression = `${second} ${minute} ${hour} ${day} ${month} ?`;
        break;
    }

    return cronExpression;
  };

  const calculateCronFires = () => {
    const {
      campaignStartDate,
      campaignStartTime,
      campaignEndDate,
      campaignEndTime,
      schedule,
      campaignFrequency,
      campaignInterval,
      intervalFrequency,
    } = formState;

    if (schedule !== "Send frequently") {
      return 1;
    }

    const startDate = new Date(`${campaignStartDate}T${campaignStartTime}`);
    const endDate = new Date(`${campaignEndDate}T${campaignEndTime}`);

    if (startDate >= endDate) {
      return 0;
    }

    let intervalMilliseconds;

    switch (campaignFrequency) {
      case "Daily":
        intervalMilliseconds = 24 * 60 * 60 * 1000; // One day in milliseconds
        break;
      case "Weekly":
        intervalMilliseconds = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds
        break;
      case "Monthly":
        intervalMilliseconds = 30 * 24 * 60 * 60 * 1000; // Approx. one month in milliseconds
        break;
      case "Custom Intervals":
        if (intervalFrequency === "day(s)") {
          intervalMilliseconds =
            parseInt(campaignInterval) * 24 * 60 * 60 * 1000;
        } else if (intervalFrequency === "week(s)") {
          intervalMilliseconds =
            parseInt(campaignInterval) * 7 * 24 * 60 * 60 * 1000;
        } else if (intervalFrequency === "month(s)") {
          intervalMilliseconds =
            parseInt(campaignInterval) * 30 * 24 * 60 * 60 * 1000;
        }
        break;
      default:
        intervalMilliseconds = 0;
        break;
    }

    let currentDate = startDate;
    let count = 0;

    while (currentDate <= endDate) {
      count++;
      currentDate = new Date(currentDate.getTime() + intervalMilliseconds);
    }

    return count;
  };

  const validateForm = () => {
    const {
      campaignName,
      senderID,
      objective,
      message,
      schedule,
      url,
      campaignFrequency,
      campaignInterval,
      intervalFrequency,
    } = formState;

    if (!schedule) {
      return false;
    }

    if (["Send later", "Send frequently"].includes(schedule) && !startDate) {
      return false;
    }

    if (schedule === "Send frequently" && (!campaignFrequency || !endDate)) {
      return false;
    }

    if (campaignFrequency === "Custom Intervals") {
      if (!campaignInterval) {
        return false;
      }
      if (!intervalFrequency) {
        return false;
      }
    }

    if (!campaignName || !senderID || !message || !objective) {
      return false;
    }

    if (
      objective !== "Brand Awareness" &&
      (url.length === 0 || !isValidURL(url))
    ) {
      return false;
    }

    return true;
  };

  const getCampaignData = () => {
    const data = {
      id: "",
      name: formState.campaignName,
      channel: "SMS",
      senderID: formState.senderID,
      objective: formState.objective,
      content: {
        type: "TEXT",
        default: defaultName,
        text: formState.message,
      },
      messageType: "PUSH",
      chatChannel: "",
      senderName: formState.senderID,
      landingPagesURL: formState.url,
      status: "DRAFT",
      businessId: CUSTOMER_ID,
      budget: props.data?.budget,
      schedule: {
        immediate: formState.schedule === "Send now" ? true : false,
        timeline: {
          start: startDate ? startDate.getTime() : "",
          end: endDate ? endDate.getTime() : "",
        },
        isRecurring: formState.schedule === "Send frequently" ? true : false,
        scheduleCron: getCronExpression(),
        nextRun: startDate ? startDate.getTime() : "",
      },
      audience: props.data.audience || {
        segmentId: "",
        phoneNumbers: [],
      },
      message: formState.message,
      campaignStartDate: formState.campaignStartDate,
      campaignStartTime: formState.campaignStartTime,
      campaignEndDate: formState.campaignEndDate,
      campaignEndTime: formState.campaignEndTime,
      smsPages:
        formState.objective === "Brand Awareness"
          ? Math.ceil(formState.message.length / messageLength)
          : Math.ceil(formState.message.length / (messageLength - 33)),
    };

    const { campaignFrequency, campaignInterval, intervalFrequency } =
      formState;
    if (campaignFrequency) {
      data["campaignFrequency"] = campaignFrequency;
    }
    if (campaignInterval) {
      data["campaignInterval"] = campaignInterval;
    }
    if (intervalFrequency) {
      data["intervalFrequency"] = intervalFrequency;
    }

    const cronFires = calculateCronFires();
    data["cronFires"] = cronFires;

    return data;
  };

  const setCampaignData = (data) => {
    const campaignData = {
      ...props,
      data: {
        ...props.data,
        ...data,
      },
    };

    return campaignData;
  };

  const saveDraft = () => {
    Notify({
      type: "success",
      heading: "Saved successfully.",
      message: "Your campaign has been saved as draft.",
    });
    props.navigate("/engagements");
  };

  const handleSubmit = (event, saveAsDraft) => {
    if (event) event.preventDefault();

    const updatedState = reducer(formState, { type: "SUBMIT" });
    dispatch({ type: "SUBMIT", payload: updatedState });

    if (!validateForm()) {
      return;
    }

    // if (
    //   formState.objective !== "Brand Awareness" &&
    //   formState.url.length === 0 &&
    //   !hasNoURLApproved
    // ) {
    //   setShowURLPrompt(true);
    //   return;
    // }

    const data = getCampaignData();
    const campaignData = setCampaignData(data);

    if (!smsExists) {
      try {
        setLoading(true);
        campaignData.data.timezone =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
        postStepOne(campaignData.data)
          .then((res) => {
            const { error, response = {} } = res;
            if (error === false) {
              if (saveAsDraft) {
                saveDraft();
              } else {
                campaignData.data = response;
                props.setData(campaignData.data);
                props.setStep(3);
              }
            } else {
              error.response
                ? Sentry.captureMessage("Error", error.response)
                : Sentry.captureMessage("Error", error);
            }

            setLoading(false);
          })
          .catch((err) => {
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            if (
              err?.response?.data?.response ===
              "Message name already exists, please provide a different name"
            ) {
              setSmsNameError(err.response.data.response);
            } else if (
              err?.response?.data?.response ===
              "Push time should be before 19:00 if start date is today."
            ) {
              setPushTimeError(err.response.data.response);
            }
            setLoading(false);
          });
      } catch (err) {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        setLoading(false);
        return;
      }
    } else {
      campaignData.data["id"] = props.data.id;
      try {
        setLoading(true);
        updateMessage(campaignData.data, props.data.id.replaceAll("/", "%2F"))
          .then((res) => {
            setLoading(false);
            if (!res.error) {
              if (saveAsDraft) {
                saveDraft();
              } else {
                // const campaignData = setCampaignData(data);
                props.setData(campaignData.data);
                props.setStep(3);
              }
            } else {
              Sentry.captureMessage("Error", res);
            }
          })
          .catch((err) => {
            setLoading(false);
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            console.log(err);
          });
      } catch (err) {
        setLoading(false);
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        Notify({
          type: "error",
          heading: "Error",
          message:
            err?.response?.data?.response ||
            err?.message ||
            `${err} Please try after some time`,
        });
      }
    }
  };

  const insertVariable = (variable) => {
    let textArea = document.getElementById("text-box") as HTMLInputElement;
    textArea.setRangeText(variable);
    let { value = "" } = textArea;
    handleFormChange({ target: { name: "message", value: value } });
  };

  const closeShowURLPrompt = (e) => {
    setShowURLPrompt(false);
    if (e) {
      setHasNoURLApproved(true);
      handleSubmit(null, false);
    }
  };

  return (
    <div className="py-12 px-12">
      <div className="text-gray-500 font-semibold text-sm">STEP 2 OF 3</div>
      <div className="flex justify-between items-center w-full">
        <div className="flex-wrap justify-start items-start">
          <div className="flex rounded-xl py-4">
            <div className="flex gap-6">
              <div className="flex justify-center items-center flex-shrink-0 w-16 bg-gray-100 rounded-full h-16">
                <img src={Sms} alt="" />
              </div>
              <div className="text-left py-2">
                <div className="text-gray-70 font-semibold text-base">
                  Message details
                </div>
                <div className="text-gray-500 mt-1 font-normal text-sm whitespace-no-wrap">
                  Compose your message.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-4 ml-auto">
          <button
            onClick={() => props.setStep(1)}
            className="px-5 py-2 rounded-md text-sm leading-6 text-slate-600 font-semibold border border-gray-40 hover:border-gray-200"
          >
            Back
          </button>
          <button
            onClick={(event) => {
              handleSubmit(event, false);
            }}
            disabled={loading}
            className="px-5 py-2 w-24 rounded-md text-sm leading-6 bg-blue-75 disabled:bg-blue-75 hover:bg-green-75 text-white font-semibold flex justify-center items-center"
          >
            {loading ? (
              <Spinner className={"w-4 h-4"} />
            ) : (
              <div className="flex gap-3 items-center">
                {"Next  "} <img src={ArrowRight} alt="" />
              </div>
            )}
          </button>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="py-4 w-[60%]">
          <div className="py-3">
            <div className="flex justify-between gap-6">
              <div className="text-gray-70 w-[calc(50%-12px)] text-sm mb-1.5">
                <span className="font-semibold">Campaign name</span>
                <input
                  type="text"
                  name="campaignName"
                  placeholder="Enter campaign name"
                  value={formState.campaignName}
                  maxLength={50}
                  className={`block w-full h-12 px-3 py-3 mt-1 border border-gray-40 rounded-md focus:outline-none ${
                    formState.errors.campaignName && "border-red-500"
                  }`}
                  onChange={handleFormChange}
                  disabled={loading}
                />
                {smsNameError && (
                  <div className="text-red-500 text-sm mt-2">
                    {smsNameError}
                  </div>
                )}
              </div>

              <div className="text-gray-70 w-[calc(50%-12px)] text-sm mb-1.5">
                <span className="font-semibold"> Message objective</span>
                <div className="relative w-full">
                  <div>
                    <button
                      onClick={() => setShowObjectiveMenu(!showObjectiveMenu)}
                      className={`flex w-full justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-40 rounded-md focus:outline-none ${
                        formState.objective === "" && "text-gray-400"
                      } ${formState.errors.objective && "border-red-500"}`}
                      //type="button"
                      // disabled={loading}
                    >
                      {formState.objective || "Select your objective"}
                      <img src={Chevron} alt="" />
                    </button>
                  </div>

                  {showObjectiveMenu && (
                    <div
                      ref={objectiveMenu}
                      className="absolute z-10 bg-white rounded-lg shadow w-full"
                    >
                      {smsObjectives?.map((objective, index) => (
                        <button
                          key={index}
                          className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                          role="menuitem"
                          onClick={() => {
                            dispatch({
                              type: "CHANGE",
                              payload: {
                                name: "objective",
                                value: objective,
                              },
                            });
                            setShowObjectiveMenu(false);
                            if (objective === "Brand Awareness") {
                              //setMessageLength(160);
                              setTotalMessageLength(480);
                            } else {
                              //setMessageLength(127);
                              setTotalMessageLength(447);
                              formState.message.length > 447
                                ? handleFormChange({
                                    target: {
                                      name: "message",
                                      value: formState.message.substring(
                                        0,
                                        447
                                      ),
                                    },
                                  })
                                : null;
                            }
                            // objective === "Brand Awareness"
                            //   ? setMessageLength(160)
                            //   : setMessageLength(130);
                            // setShowObjectiveMenu(false);
                            // formState.message.length > messageLength
                            //   ? handleFormChange({
                            //       target: {
                            //         name: "message",
                            //         value: formState.message.substring(
                            //           0,
                            //           messageLength
                            //         ),
                            //       },
                            //     })
                            //   : null;
                          }}
                        >
                          {objective}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div className="text-gray-70 font-semibold text-sm mb-1.5">
                Message body
              </div>
              <div
                className={`border border-gray-40 rounded-md  ${
                  formState.errors.message && "border-red-500"
                }`}
              >
                <textarea
                  id="text-box"
                  name="message"
                  placeholder="Your message body goes here..."
                  value={formState.message}
                  // maxLength={messageLength}
                  className={`w-full h-52 p-3 mt-1 resize-none focus:outline-none`}
                  onChange={handleFormChange}
                  disabled={loading}
                />
                <div className="relative">
                  <div className="px-4 py-2 cursor-pointer">
                    <div
                      className={`text-[#94A3B8] flex justify-end font-normal text-sm pt-2`}
                    >
                      {`${formState.message.length}/${totalMessageLength}
                      | ${
                        formState.objective === "Brand Awareness"
                          ? Math.ceil(formState.message.length / messageLength)
                          : Math.ceil(
                              (formState.message.length +
                                (formState.message.length > 0 ? 33 : 0)) /
                                messageLength
                            )
                      } SMS`}
                    </div>
                  </div>
                  <div className="text-gray-400 bg-[#F9FAFB] flex gap-4 items-center h-16 px-4 py-3 border-t-[1px] border-gray-40 rounded-b-md">
                    <div
                      onClick={() => {
                        setPersonalizationModal(true);
                      }}
                      className={`${
                        enablePersonalization()
                          ? "cursor-pointer"
                          : "cursor-not-allowed pointer-events-none"
                      }`}
                    >
                      <img src={User} alt="" />
                    </div>
                    <PersonalizationModal
                      show={personalizationModal}
                      closeModal={() => {
                        setPersonalizationModal(false);
                      }}
                      setDefaultMessageModal={setDefaultMessageModal}
                      attributeMatches={attributeMatches}
                      filterAttributes={filterAttributes}
                      setSelectedAttributeIndex={setSelectedAttributeIndex}
                    />

                    {defaultMessageModal && (
                      <DefaultMessageModal
                        show={defaultMessageModal}
                        defaultNames={defaultName}
                        closeModal={() => setDefaultMessageModal(false)}
                        insertVariable={insertVariable}
                        selectedAttributeIndex={selectedAttributeIndex}
                        setDefaultName={setDefaultName}
                      />
                    )}
                    <img src={Divider} alt="" />
                    <div
                      className="cursor-pointer"
                      onClick={() => copyToClipboard(formState.message)}
                    >
                      <Copy />
                    </div>
                  </div>
                  {showCopyTooltip && (
                    <div className="absolute w-60 p-3 z-10 -mt-14 ml-28 bg-gray-900 text-white text-xs rounded-lg shadow-lg">
                      Message body copied to clipboard.
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div className="py-3">
                <div className="flex justify-between gap-6">
                  {formState.objective !== "Brand Awareness" && (
                    <div className="w-[calc(50%-12px)]">
                      <div className="text-gray-70 font-semibold text-sm">
                        Destination URL (optional)
                      </div>
                      <input
                        type="text"
                        name="url"
                        placeholder="Enter URL"
                        value={formState.url}
                        className={`h-12 px-3 py-3 text-sm w-full mt-1 border border-gray-40 rounded-md focus:outline-none ${
                          formState.errors.url && "border-red-500"
                        }`}
                        onChange={handleFormChange}
                        maxLength={500}
                        disabled={loading}
                      />
                      {formState.errors.url && (
                        <div className="mt-1 text-red-500 text-sm">
                          Enter a valid URL
                        </div>
                      )}
                    </div>
                  )}

                  <div className="text-gray-70 w-[calc(50%-12px)] text-sm mb-1.5">
                    <span className="font-semibold">Sender ID</span>
                    <div className="relative w-full">
                      <button
                        name="senderID"
                        className={`flex w-full justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-40 rounded-md focus:outline-none ${
                          formState.senderID === "" && "text-gray-400"
                        } ${formState.errors.senderID && "border-red-500"}`}
                        type="button"
                        onClick={() => setShowSenderIDMenu(!showSenderIDMenu)}
                      >
                        {senderIDs.length
                          ? formState.senderID || "Select your sender ID"
                          : "No enabled Sender ID available!"}
                        <img src={Chevron} alt="" />
                      </button>

                      {/* SenderID Dropdown menu */}
                      {showSenderIDMenu && (
                        <div
                          ref={senderIDMenu}
                          className="absolute z-10 bg-white rounded-lg shadow w-full"
                        >
                          {senderIDs?.map((senderID, index) => (
                            <button
                              key={index}
                              className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                              role="menuitem"
                              onClick={() => {
                                dispatch({
                                  type: "CHANGE",
                                  payload: {
                                    name: "senderID",
                                    value: senderID?.name,
                                  },
                                });
                                setShowSenderIDMenu(false);
                              }}
                            >
                              {senderID?.name}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="pt-4">
                  <div className="text-gray-70 font-semibold text-sm mb-2">
                    Schedule
                    {startDate && (
                      <span className="font-light ml-1.5">
                        {getTimelineSummary()}
                      </span>
                    )}
                  </div>

                  <div className="flex justify-between gap-6 text-sm">
                    <div className="relative w-[calc(50%-12px)]">
                      <button
                        name="schedule"
                        className={`flex w-full justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-40 rounded-md focus:outline-none ${
                          formState.schedule === "" && "text-gray-400"
                        } ${formState.errors.schedule && "border-red-500"}`}
                        type="button"
                        onClick={() => setShowScheduleMenu(!showScheduleMenu)}
                      >
                        {formState.schedule || "Select schedule"}
                        <img src={Chevron} alt="" />
                      </button>

                      {/* Schedule Dropdown menu */}
                      {showScheduleMenu && (
                        <div
                          ref={scheduleMenu}
                          className="absolute z-10 bg-white rounded-lg shadow w-full"
                        >
                          {sendSchedule?.map((item, index) => (
                            <div key={index}>
                              <button
                                className="block px-4 py-3 text-gray-700 hover:bg-gray-10 w-full text-left"
                                role="menuitem"
                                onClick={() => {
                                  dispatch({
                                    type: "CHANGE",
                                    payload: {
                                      name: "schedule",
                                      value: item.schedule,
                                    },
                                  });
                                  setPushTimeError("");
                                  setShowScheduleMenu(false);
                                }}
                              >
                                <div className="font-semibold">
                                  {item.schedule}
                                </div>
                                <div className="text-slate-500 text-[12px] mt-1">
                                  {item.description}
                                </div>
                              </button>
                              {index < sendSchedule.length - 1 && <hr></hr>}
                            </div>
                          ))}
                        </div>
                      )}
                      {formState.schedule === "Send now" && !pushTimeError && (
                        <div className="mt-2 text-sm text-slate-600">
                          Message is sent immediately after review
                        </div>
                      )}
                      {pushTimeError && (
                        <div className="text-red-500 text-sm mt-2">
                          {pushTimeError}
                        </div>
                      )}
                    </div>

                    {["Send later", "Send frequently"].includes(
                      formState.schedule
                    ) && (
                      <div className="w-[calc(50%-12px)]">
                        <ConfigProvider
                          theme={{
                            token: {
                              colorIcon: "#98A2B3",
                              colorBorder: "#D0D5DD",
                              colorPrimary: "#008138",
                              colorPrimaryHover: "#008138",
                              colorText: "#344054",
                              colorTextHeading: "#344054",
                              colorTextDisabled: "#D0D5DD",
                              colorTextLightSolid: "var(--main-color)",
                              colorTextPlaceholder: "#98A2B3",
                              controlItemBgActive: "#DAEEE1",
                              controlOutline: "#FFFFFF",
                            },
                          }}
                        >
                          <div className="flex justify-between gap-3">
                            <div
                              className="w-full mt-1"
                              onClick={() => {
                                if (!showStartCalendar) {
                                  setShowEndCalendar(false);
                                  setShowStartCalendar(true);
                                }
                              }}
                            >
                              <DatePicker
                                className={`w-full h-12 hover:border-gray-300 ${
                                  (formState.errors.campaignStartDate ||
                                    formState.errors.campaignStartTime) &&
                                  "border-red-500"
                                }`}
                                placeholder="Start"
                                disabled={loading}
                                value={
                                  formState.campaignStartDate
                                    ? dayjs(
                                        formState.campaignStartDate,
                                        "YYYY-MM-DD"
                                      )
                                    : undefined
                                }
                                defaultValue={
                                  formState.campaignStartDate
                                    ? dayjs(
                                        formState.campaignStartDate,
                                        "YYYY-MM-DD"
                                      )
                                    : undefined
                                }
                                format="YYYY-MM-DD"
                                onChange={onStartDateChange}
                                disabledDate={disabledDates}
                                locale={locale}
                                open={showStartCalendar}
                                showNow={false}
                                showToday={false}
                                suffixIcon={<img src={CalendarIcon} />}
                                allowClear={false}
                                popupStyle={{ paddingBottom: "64px" }}
                                cellRender={(current) => {
                                  const style: React.CSSProperties = {};
                                  style.borderRadius = "50%";
                                  style.width = "30px";
                                  style.height = "30px";
                                  style.lineHeight = "30px";
                                  return (
                                    <div
                                      className="ant-picker-cell-inner"
                                      style={style}
                                    >
                                      {current.date()}
                                    </div>
                                  );
                                }}
                                renderExtraFooter={() => {
                                  return (
                                    <>
                                      <div className="flex justify-between items-end px-5">
                                        <input
                                          type="text"
                                          placeholder="Pick a start date"
                                          value={
                                            formState.campaignStartDate &&
                                            dayjs(
                                              formState.campaignStartDate
                                            ).format("MMM D, YYYY")
                                          }
                                          className={`w-36 h-12 px-2 py-3 mt-3 text-[13.5px] border border-gray-300 rounded-md focus:outline-none ${
                                            formState.errors
                                              .campaignStartDate &&
                                            "border-red-500"
                                          }`}
                                          onChange={() => {}}
                                        />
                                        <TimePicker
                                          className={`h-12 ${
                                            formState.errors
                                              .campaignStartTime &&
                                            "border-red-500"
                                          }`}
                                          defaultValue={
                                            formState.campaignStartTime
                                              ? dayjs(
                                                  formState.campaignStartTime,
                                                  "HH:mm:ss"
                                                )
                                              : undefined
                                          }
                                          format="h:mm A"
                                          onChange={onStartTimeChange}
                                          use12Hours
                                          size="small"
                                          showNow={false}
                                          placement="bottomRight"
                                          inputReadOnly={true}
                                        />
                                      </div>
                                      <div className="pt-4 mt-80 bg-white rounded-b-lg drop-shadow-lg">
                                        <hr className="border-gray-300" />
                                        <div className="flex gap-4 p-4 rounded-b-lg">
                                          <button
                                            className="py-3 px-10 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
                                            onClick={() =>
                                              setShowStartCalendar(false)
                                            }
                                          >
                                            Cancel
                                          </button>

                                          <button
                                            className="py-3 px-14 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75"
                                            onClick={() => {
                                              onStartDateSave();
                                            }}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }}
                              />
                            </div>

                            {formState.schedule === "Send frequently" && (
                              <div
                                className="w-full mt-1"
                                onClick={() => {
                                  if (!showEndCalendar) {
                                    setShowStartCalendar(false);
                                    setShowEndCalendar(true);
                                  }
                                }}
                              >
                                <DatePicker
                                  className={`w-full h-12 hover:border-gray-300 ${
                                    (formState.errors.campaignEndDate ||
                                      formState.errors.campaignEndTime) &&
                                    "border-red-500"
                                  }`}
                                  placeholder="End"
                                  disabled={loading}
                                  value={
                                    formState.campaignEndDate
                                      ? dayjs(
                                          formState.campaignEndDate,
                                          "YYYY-MM-DD"
                                        )
                                      : undefined
                                  }
                                  defaultValue={
                                    formState.campaignEndDate
                                      ? dayjs(
                                          formState.campaignEndDate,
                                          "YYYY-MM-DD"
                                        )
                                      : undefined
                                  }
                                  format="YYYY-MM-DD"
                                  onChange={onEndDateChange}
                                  disabledDate={disabledDates}
                                  locale={locale}
                                  open={showEndCalendar}
                                  showNow={false}
                                  showToday={false}
                                  suffixIcon={<img src={CalendarIcon} />}
                                  allowClear={false}
                                  popupStyle={{ paddingBottom: "64px" }}
                                  cellRender={(current) => {
                                    const style: React.CSSProperties = {};
                                    style.borderRadius = "50%";
                                    style.width = "30px";
                                    style.height = "30px";
                                    style.lineHeight = "30px";
                                    return (
                                      <div
                                        className="ant-picker-cell-inner"
                                        style={style}
                                      >
                                        {current.date()}
                                      </div>
                                    );
                                  }}
                                  renderExtraFooter={() => {
                                    return (
                                      <>
                                        <div className="flex justify-between items-end px-5">
                                          <input
                                            type="text"
                                            placeholder="Pick an end date"
                                            value={
                                              formState.campaignEndDate &&
                                              dayjs(
                                                formState.campaignEndDate
                                              ).format("MMM D, YYYY")
                                            }
                                            className={`w-36 h-12 px-2 py-3 mt-3 text-[13.5px] border border-gray-300 rounded-md focus:outline-none ${
                                              formState.errors
                                                .campaignEndDate &&
                                              "border-red-500"
                                            }`}
                                            onChange={() => {}}
                                          />
                                          <TimePicker
                                            className={`h-12 ${
                                              formState.errors
                                                .campaignEndTime &&
                                              "border-red-500"
                                            }`}
                                            defaultValue={
                                              formState.campaignEndTime
                                                ? dayjs(
                                                    formState.campaignEndTime,
                                                    "HH:mm:ss"
                                                  )
                                                : undefined
                                            }
                                            format="h:mm A"
                                            onChange={onEndTimeChange}
                                            use12Hours
                                            size="small"
                                            showNow={false}
                                            placement="bottomRight"
                                            inputReadOnly={true}
                                          />
                                        </div>
                                        <div className="pt-4 mt-80 bg-white rounded-b-lg drop-shadow-lg">
                                          <hr className="border-gray-300" />
                                          <div className="flex gap-4 p-4 rounded-b-lg">
                                            <button
                                              className="py-3 px-10 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
                                              onClick={() =>
                                                setShowEndCalendar(false)
                                              }
                                            >
                                              Cancel
                                            </button>

                                            <button
                                              className="py-3 px-14 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75"
                                              onClick={() => {
                                                onEndDateSave();
                                              }}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </ConfigProvider>
                        <div>
                          {!startDate && !scheduleError && (
                            <div className="mt-2 text-sm text-slate-600">
                              Select a time between 8 AM - 7 PM WAT
                            </div>
                          )}
                          {scheduleError && (
                            <div className="text-red-500 text-sm mt-2">
                              {scheduleError}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {formState.schedule === "Send frequently" && (
                    <div className="flex justify-between gap-6 mt-4">
                      <div className="w-[calc(50%-12px)] text-gray-70 text-sm mb-1.5">
                        <span className="font-semibold">Send Frequency</span>
                        <div className="relative">
                          <button
                            name="campaignFrequency"
                            className={`flex w-full justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-40 rounded-md focus:outline-none ${
                              formState.campaignFrequency === "" &&
                              "text-gray-400"
                            } ${
                              formState.errors.campaignFrequency &&
                              "border-red-500"
                            }`}
                            type="button"
                            onClick={() =>
                              setShowFrequencyMenu(!showFrequencyMenu)
                            }
                          >
                            {formState.campaignFrequency ||
                              "Select send frequency"}
                            <img src={Chevron} alt="" />
                          </button>

                          {/* Frequency Dropdown menu */}
                          {showFrequencyMenu && (
                            <div
                              ref={frequencyMenu}
                              className="absolute z-10 bg-white rounded-lg shadow w-full"
                            >
                              {frequency?.map((frequency, index) => (
                                <button
                                  key={index}
                                  className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                                  role="menuitem"
                                  onClick={() => {
                                    dispatch({
                                      type: "CHANGE",
                                      payload: {
                                        name: "campaignFrequency",
                                        value: frequency,
                                      },
                                    });
                                    setShowFrequencyMenu(false);
                                  }}
                                >
                                  {frequency}
                                </button>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>

                      {formState.campaignFrequency === "Custom Intervals" && (
                        <div className="w-[calc(50%-12px)] text-gray-70 text-sm mb-1.5">
                          <span className="font-semibold">Set Intervals</span>
                          <div className="relative flex justify-between">
                            <div className="w-[calc(50%-6px)] flex items-center">
                              <div className="w-1/2 p-3 mt-1 h-12 flex items-center justify-center bg-slate-700 border border-gray-40 rounded-l-md">
                                Every
                              </div>
                              <input
                                type="text"
                                name="campaignInterval"
                                placeholder="Interval"
                                value={formState.campaignInterval}
                                maxLength={3}
                                className={`w-1/2 p-3 mt-1 h-12 border border-gray-40 rounded-r-md focus:outline-none ${
                                  formState.errors.campaignInterval &&
                                  "border-red-500"
                                }`}
                                onChange={handleFormChange}
                              />
                            </div>

                            <div className="w-[calc(50%-6px)]">
                              <button
                                name="intervalFrequency"
                                className={`flex justify-between w-full items-center px-4 py-3 text-center h-12 mt-1 border border-gray-40 rounded-md focus:outline-none ${
                                  formState.intervalFrequency === "" &&
                                  "text-gray-400"
                                } ${
                                  formState.errors.intervalFrequency &&
                                  "border-red-500"
                                }`}
                                type="button"
                                onClick={() =>
                                  setShowIntervalMenu(!showIntervalMenu)
                                }
                              >
                                {formState.intervalFrequency ||
                                  "Select frequency"}
                                <img src={Chevron} alt="" />
                              </button>

                              {/* Interval Dropdown menu */}
                              {showIntervalMenu && (
                                <div
                                  ref={intervalMenu}
                                  className="absolute z-10 bg-white rounded-lg shadow w-[calc(50%-6px)]"
                                >
                                  {["day(s)", "week(s)", "month(s)"]?.map(
                                    (interval, index) => (
                                      <button
                                        key={index}
                                        className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                                        role="menuitem"
                                        onClick={() => {
                                          dispatch({
                                            type: "CHANGE",
                                            payload: {
                                              name: "intervalFrequency",
                                              value: interval,
                                            },
                                          });
                                          setShowIntervalMenu(false);
                                        }}
                                      >
                                        {interval}
                                      </button>
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-[40%] pb-5 rounded-lg border border-b-slate-200 justify-center mt-14 ml-6 bg-[#F9FBFE]">
          <div className="relative overflow-hidden">
            <div className="my-5 pb-3 mb-10 border-b border-b-slate-200 text-base font-semibold text-gray-700">
              Preview
            </div>
            <div className="absolute w-[237px] h-5 text-black bg-[#E4E4E8] text-[10px] text-center font-medium top-[172px]  mx-6">
              {formState.senderID || "Sender ID"}
            </div>
            {(formState.message || formState.url) && (
              <div
                className={`absolute w-[210px] p-2 pl-3 ml-8 mt-[124px] bg-[#E4E4E8] rounded-lg text-[10px] leading-4 font-medium`}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: sanitizedMessage }}
                ></div>
                <div className="text-[#0068D9]">{formState.url}</div>
              </div>
            )}

            <img src={PhoneSms} alt="" />
          </div>
        </div>
      </div>

      <NotificationModal data={notificationData} />
      {showDeletionModal && (
        <DeletionModal
          step={2}
          navigate={props.navigate}
          closeModal={setShowDeletionModal}
        />
      )}
      {showURLprompt && (
        <NoURLPrompt
          close={(e) => {
            closeShowURLPrompt(e);
          }}
        />
      )}
    </div>
  );
};

export default SmsStepTwo;
