import { useEffect } from "react";
import { addGAEventListener, getPageName } from "../../../../../marketing-cloud-container/src/ga-events";

const AudienceMenu = (props) => {
  const {
    showAudienceMenu,
    audienceMenu,
    setAudience,
    type,
    setFilter,
    setPageNum,
    setPageSize,
    setFrom,
    audience,
    status,
  } = props;

  useEffect(() => {
    addGAEventListener();
  }, [showAudienceMenu]);
  
  const handleSelectAudience = (e) => {
    setPageNum(0);
    setPageSize(10);
    setFrom(0);
    setAudience(e.target.value);
    setFilter(true);
    if (audience === e.target.value) {
      setAudience("");
      if (type || status) {
        return setFilter(true);
      } else {
        return setFilter(false);
      }
    }
  };
  return (
    showAudienceMenu && (
      <div
        ref={audienceMenu}
        className="absolute mt-1 z-10 bg-white rounded-lg shadow w-40"
      >
        <ul className="p-2 text-sm text-gray-700">
          <li
            className="triggerGA"
            data-event-category={`${getPageName()}-list-page`}
            data-event-label={`${getPageName()}-list_audience-option_click`}
            data-event-action="filter_click"
            data-event-value="open"
          >
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={audience === "open"}
                id="open"
                type="checkbox"
                value="open"
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                onClick={handleSelectAudience}
              />
              <label
                htmlFor="open"
                className="w-full ml-3 text-sm leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                Open
              </label>
            </div>
          </li>
          <li
            className="triggerGA"
            data-event-category={`${getPageName()}-list-page`}
            data-event-label={`${getPageName()}-list_audience-option_click`}
            data-event-action="filter_click"
            data-event-value="segment"
          >
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={audience === "segment"}
                id="segment"
                type="checkbox"
                value="segment"
                onClick={handleSelectAudience}
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
              />
              <label
                htmlFor="segment"
                className="w-full ml-3 text-sm leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                Segment
              </label>
            </div>
          </li>
          <li
            className="triggerGA"
            data-event-category={`${getPageName()}-list-page`}
            data-event-label={`${getPageName()}-list_audience-option_click`}
            data-event-action="filter_click"
            data-event-value="custom"
          >
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={audience === "custom"}
                id="custom"
                type="checkbox"
                value="custom"
                onClick={handleSelectAudience}
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
              />
              <label
                htmlFor="custom"
                className="w-full ml-3 text-sm leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                Custom
              </label>
            </div>
          </li>
          <li
            className="triggerGA"
            data-event-category={`${getPageName()}-list-page`}
            data-event-label={`${getPageName()}-list_audience-option_click`}
            data-event-action="filter_click"
            data-event-value="retarget"
          >
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={audience === "retarget"}
                id="retarget"
                type="checkbox"
                onClick={handleSelectAudience}
                value="retarget"
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
              />
              <label
                htmlFor="retarget"
                className="w-full ml-3 text-sm leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                Retarget
              </label>
            </div>
          </li>
        </ul>
      </div>
    )
  );
};

export default AudienceMenu;
