const engagementAssetUrl =
  'https://mcassets.blob.core.windows.net/cdn/EngagementAssets';

export const Close = `${engagementAssetUrl}/Close.svg`;
export const NotificationIcon = `${engagementAssetUrl}/NotificationIcon.svg`;
export const Back = `${engagementAssetUrl}/Back.svg`;
export const CrossSmall = `${engagementAssetUrl}/CrossSmall.svg`;
export const ImagePlaceholder = `${engagementAssetUrl}/ImagePlaceholder.svg`;
export const ImageHolder = `${engagementAssetUrl}/ImageHolder.svg`;
export const DocumentPlaceholder = `${engagementAssetUrl}/DocumentPlaceholder.svg`;
export const Channel = `${engagementAssetUrl}/Channel.svg`;
export const Profile = `${engagementAssetUrl}/Profile.svg`;
export const RewardName = `${engagementAssetUrl}/RewardName.svg`;
export const MegaphoneSVG = `${engagementAssetUrl}/Megaphone.svg`;
export const DropDownArrow = `${engagementAssetUrl}/Dropdown-Arrow.svg`;
export const Add = `${engagementAssetUrl}/Add.svg`;
export const Ellipsis = `${engagementAssetUrl}/Ellipsis.svg`;
export const RightArrow = `${engagementAssetUrl}/RightArrow.svg`;
export const Play = `${engagementAssetUrl}/Play.svg`;
export const ChevronBack = `${engagementAssetUrl}/ChevronBack.svg`;
export const Delete = `${engagementAssetUrl}/Delete.svg`;
export const Plus = `${engagementAssetUrl}/Plus.svg`;
export const QuestionFile = `${engagementAssetUrl}/QuestionFile.svg`;
export const RightChevron = `${engagementAssetUrl}/Right-Chevron.svg`;
export const LeftChevron = `${engagementAssetUrl}/Left-Chevron.svg`;
export const Apple = `${engagementAssetUrl}/Apple.svg`;
export const SurveyLogoPlaceholder = `${engagementAssetUrl}/SurveyLogoPlaceholder.svg`;
export const Mobile = `${engagementAssetUrl}/Mobile.svg`;
export const Desktop = `${engagementAssetUrl}/Desktop.svg`;
export const TerragonLogo = `${engagementAssetUrl}/Terragon-Logo.svg`;
export const RatingStar = `${engagementAssetUrl}/Rating-star.svg`;
export const Link = `${engagementAssetUrl}/Link.svg`;
export const Bold = `${engagementAssetUrl}/Bold.svg`;
export const Tt = `${engagementAssetUrl}/Tt.svg`;
export const Italic = `${engagementAssetUrl}/Italic.svg`;
export const UnderLine = `${engagementAssetUrl}/UnderLine.svg`;
export const FilePlus = `${engagementAssetUrl}/File-plus.svg`;
export const ArrowBend = `${engagementAssetUrl}/ArrowBend.svg`;
export const CsvDownload = `${engagementAssetUrl}/CsvDownload.svg`;
export const EmailDownload = `${engagementAssetUrl}/EmailDownload.svg`;
export const Info = `${engagementAssetUrl}/Info.svg`;
export const Magnifier = `${engagementAssetUrl}/Magnifier.svg`;
export const Clock = `${engagementAssetUrl}/Clock.svg`;
export const Fisend = `${engagementAssetUrl}/Fisend.svg`;
export const CalendarIcon = `${engagementAssetUrl}/CalendarIcon.svg`;
export const Users = `${engagementAssetUrl}/Users.svg`;
export const Megaphone = `${engagementAssetUrl}/Megaphone.svg`;
export const PhoneSmsLaunch = `${engagementAssetUrl}/PhoneSmsLaunch.svg`;
export const PhoneFrame = `${engagementAssetUrl}/PhoneFrame.svg`;
export const SenderIcon = `${engagementAssetUrl}/SenderIcon.svg`;
export const MarketingIcon = `${engagementAssetUrl}/MarketingIcon.svg`;
export const SenderEmail = `${engagementAssetUrl}/SenderEmail.svg`;
export const MessageBox = `${engagementAssetUrl}/MessageBox.svg`;
export const PhoneWhatsappLaunch = `${engagementAssetUrl}/PhoneWhatsappLaunch.svg`;
export const PhoneSms = `${engagementAssetUrl}/PhoneSms.svg`;
export const LoudSpeaker = `${engagementAssetUrl}/LoudSpeaker.svg`;
export const Whatsapp = `${engagementAssetUrl}/Whatsapp.svg`;
export const Email = `${engagementAssetUrl}/Email.svg`;
export const EmailIcon = `${engagementAssetUrl}/EmailIcon.svg`;
export const SmsIcon = `${engagementAssetUrl}/SmsIcon.svg`;
export const PushIcon = `${engagementAssetUrl}/PushIcon.svg`;
export const RcsIcon = `${engagementAssetUrl}/RcsIcon.svg`;
export const WhatsappIcon = `${engagementAssetUrl}/WhatsappIcon.svg`;
export const Sms = `${engagementAssetUrl}/Sms.svg`;
export const Web = `${engagementAssetUrl}/Web.svg`;
export const DataUpload = `${engagementAssetUrl}/DataUpload.svg`;
export const EmailArchive = `${engagementAssetUrl}/EmailArchive.svg`;
export const EmailDelete = `${engagementAssetUrl}/EmailDelete.svg`;
export const EmailBack = `${engagementAssetUrl}/EmailBack.svg`;
export const EmailDots = `${engagementAssetUrl}/EmailDots.svg`;
export const EmailReply = `${engagementAssetUrl}/EmailReply.svg`;
export const Phone = `${engagementAssetUrl}/Phone.svg`;
export const File = `${engagementAssetUrl}/File.svg`;
export const InfoCircle = `${engagementAssetUrl}/InfoCircle.svg`;
export const PeopleGroup = `${engagementAssetUrl}/PeopleGroup.svg`;
export const SelectedFile = `${engagementAssetUrl}/SelectedFile.svg`;
export const ArrowRight = `${engagementAssetUrl}/ArrowRight.svg`;
export const People = `${engagementAssetUrl}/People.svg`;
export const Chevron = `${engagementAssetUrl}/Chevron.svg`;
export const Rocket = `${engagementAssetUrl}/Rocket.svg`;
export const Gift = `${engagementAssetUrl}/Gift.svg`;
export const Peopleshadow = `${engagementAssetUrl}/Peopleshadow.svg`;
export const TickCircle = `${engagementAssetUrl}/TickCircle.svg`;
export const LinkIcon = `${engagementAssetUrl}/LinkIcon.svg`;
export const BgImagePlaceholder = `${engagementAssetUrl}/BgImagePlaceholder.svg`;
export const DisplayPhoneNumber = `${engagementAssetUrl}/DisplayPhoneNumber.svg`;
export const PhoneNumberId = `${engagementAssetUrl}/PhoneNumberId.svg`;
export const Bubble = `${engagementAssetUrl}/Bubble.svg`;
export const Copy = `${engagementAssetUrl}/Copy.svg`;
export const Divider = `${engagementAssetUrl}/Divider.svg`;
export const User = `${engagementAssetUrl}/User.svg`;
export const EditSurvey = `${engagementAssetUrl}/EditSurvey.svg`;
export const Telephone = `${engagementAssetUrl}/Telephone.svg`;
export const Wifi = `${engagementAssetUrl}/Wifi.svg`;
export const UserOutline = `${engagementAssetUrl}/UserOutline.svg`;
export const MarketingMix = `${engagementAssetUrl}/MarketingMix.svg`;
export const RewardMix = `${engagementAssetUrl}/RewardMix.svg`;
export const SurveyMix = `${engagementAssetUrl}/SurveyMix.svg`;
export const DownArrow = `${engagementAssetUrl}/DownArrow.svg`;
export const WhatsappInsight = `${engagementAssetUrl}/WhatsappInsight.svg`;
export const Message = `${engagementAssetUrl}/Message.svg`;
export const Cash = `${engagementAssetUrl}/Cash.svg`;
export const Eye = `${engagementAssetUrl}/Eye.svg`;
export const Tick = `${engagementAssetUrl}/Tick.svg`;
export const Optout = `${engagementAssetUrl}/Optout.svg`;
export const SmsInsight = `${engagementAssetUrl}/SmsInsight.svg`;
export const EmailInsight = `${engagementAssetUrl}/EmailInsight.svg`;
export const DeliveryRateDown = `${engagementAssetUrl}/DeliveryRateDown.svg`;
export const DeliveryRateUp = `${engagementAssetUrl}/DeliveryRateUp.svg`;
export const RefundInitiated = `${engagementAssetUrl}/RefundInitiated.svg`;
export const OneTick = `${engagementAssetUrl}/OneTick.svg`;
export const LeftChevronReport = `${engagementAssetUrl}/LeftChevronReport.svg`;
export const EditCampaign = `${engagementAssetUrl}/EditCampaign.svg`;
export const Pause = `${engagementAssetUrl}/Pause.svg`;
export const Resume = `${engagementAssetUrl}/Resume.svg`;
export const RcsInsight = `${engagementAssetUrl}/RcsInsight.svg`;
export const RewardListIconAirtime = `${engagementAssetUrl}/RewardListIconAirtime.svg`;
export const RewardListIconData = `${engagementAssetUrl}/RewardListIconData.svg`;
export const SurveyListIcon = `${engagementAssetUrl}/SurveyListIcon.svg`;
export const Budget = `${engagementAssetUrl}/Budget.svg`;
export const Type = `${engagementAssetUrl}/Type.svg`;
export const Balance = `${engagementAssetUrl}/Balance.svg`;
export const Spent = `${engagementAssetUrl}/Spent.svg`;
export const Profiles = `${engagementAssetUrl}/Profiles.svg`;
export const Schedule = `${engagementAssetUrl}/Schedule.svg`;
export const RewardAirtime = `${engagementAssetUrl}/RewardAirtime.svg`;
export const RewardData = `${engagementAssetUrl}/RewardData.svg`;
export const RewardCalendar = `${engagementAssetUrl}/RewardCalendar.svg`;
export const RewardClock = `${engagementAssetUrl}/RewardClock.svg`;
export const SurveyInviteIcons = `${engagementAssetUrl}/SurveyInviteIcons.svg`;
export const PreviewSurvey = `${engagementAssetUrl}/PreviewSurvey.svg`;
export const SurveyInviteIconsEdit = `${engagementAssetUrl}/SurveyInviteIconsEdit.svg`;
export const SurveyInviteIconsDelete = `${engagementAssetUrl}/SurveyInviteIconsDelete.svg`;
export const SurveyLaunchIconLaunch = `${engagementAssetUrl}/SurveyLaunchIconLaunch.svg`;
export const SurveyDetailsIconsGlobe = `${engagementAssetUrl}/SurveyDetailsIconsGlobe.svg`;
export const SurveyDetailsIconsCaretDown = `${engagementAssetUrl}/SurveyDetailsIconsCaretDown.svg`;
export const SurveyDetailsIconsCsv = `${engagementAssetUrl}/SurveyDetailsIconsCsv.svg`;
export const SurveyDetailsIconsMessage = `${engagementAssetUrl}/SurveyDetailsIconsMessage.svg`;
export const SurveyDetailsIconsExport = `${engagementAssetUrl}/SurveyDetailsIconsExport.svg`;
export const SurveyDetailsIconsUsers = `${engagementAssetUrl}/SurveyDetailsIconsUsers.svg`;
export const SurveyDetailsIconsSchedule = `${engagementAssetUrl}/SurveyDetailsIconsSchedule.svg`;
export const SurveyDetailsIconsMoney = `${engagementAssetUrl}/SurveyDetailsIconsMoney.svg`;
export const Bounced = `${engagementAssetUrl}/Bounced.svg`;
export const SurveyLaunchIconSidebar = `${engagementAssetUrl}/SurveyLaunchIconSidebar.svg`;
export const SmsLaunchIconLaunch = `${engagementAssetUrl}/SmsLaunchIconLaunch.svg`;
export const SmsLaunchIconAudience = `${engagementAssetUrl}/SmsLaunchIconAudience.svg`;
export const SmsLaunchIconProfile = `${engagementAssetUrl}/SmsLaunchIconProfile.svg`;
export const SmsLaunchIconName = `${engagementAssetUrl}/SmsLaunchIconName.svg`;
export const SmsLaunchIconChannel = `${engagementAssetUrl}/SmsLaunchIconChannel.svg`;
export const SmsLaunchIconObjective = `${engagementAssetUrl}/SmsLaunchIconObjective.svg`;
export const SmsLaunchIconSchedule = `${engagementAssetUrl}/SmsLaunchIconSchedule.svg`;
export const SmsLaunchIconMessage = `${engagementAssetUrl}/SmsLaunchIconMessage.svg`;
export const Reward = `${engagementAssetUrl}/Reward.svg`;
export const Calendar = `${engagementAssetUrl}/Calendar.svg`;
export const PhoneRewardLaunch = `${engagementAssetUrl}/PhoneRewardLaunch.svg`;
export const LeftArrow = `${engagementAssetUrl}/LeftArrow.svg`;
export const Edit = `${engagementAssetUrl}/Edit.svg`;
export const SurveyLaunchIconName = `${engagementAssetUrl}/SurveyLaunchIconName.svg`;
export const SurveyLaunchIconAudience = `${engagementAssetUrl}/SurveyLaunchIconAudience.svg`;
export const SurveyLaunchIconTimeline = `${engagementAssetUrl}/SurveyLaunchIconTimeline.svg`;
export const SurveyLaunchIconMegaphone = `${engagementAssetUrl}/SurveyLaunchIconMegaphone.svg`;
export const SurveyLaunchIconBudget = `${engagementAssetUrl}/SurveyLaunchIconBudget.svg`;
export const EmptyChart = `${engagementAssetUrl}/EmptyChart.svg`;
export const ChartAudience = `${engagementAssetUrl}/ChartAudience.svg`;
export const FileUploadAudience = `${engagementAssetUrl}/FileUploadAudience.svg`;
export const PeopleAudience = `${engagementAssetUrl}/PeopleAudience.svg`;
export const Mtn = `${engagementAssetUrl}/TELCOS_mtn.png`;
export const Airtel = `${engagementAssetUrl}/TELCOS_airtel.png`;
export const Etisalat = `${engagementAssetUrl}/TELCOS_9mobile.png`;
export const Glo = `${engagementAssetUrl}/TELCOS_glo.png`;
export const Equitel = `${engagementAssetUrl}/TELCOS_equitel.png`;
export const Safaricom = `${engagementAssetUrl}/TELCOS_safaricom.png`;
export const Telkom = `${engagementAssetUrl}/TELCOS_telkom.png`;
