const ChevronRight = () => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 5L0.75 9.54663L0.75 0.453367L6 5Z" fill="#101828" />
    </svg>
  );
};

export default ChevronRight;
