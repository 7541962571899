import { getCustomerId } from '../constants/billingConstants';
import { api } from '../../../../marketing-cloud-main/src/common/services/common-api-service';
import * as Sentry from '@sentry/react';

export const getRewardList = (params: any) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const {
    startDate = '',
    endDate = '',
    from = 0,
    size = 10,
    status = '',
    searchRewards = '',
    audience = '',
    type,
  } = params;

  let url = `/rewardcampaign?&from=${from}&size=${size}&businessId=${getCustomerId()}`;

  if (startDate) {
    url = url + `&startDate=${startDate}`;
  }
  if (endDate) {
    url = url + `&endDate=${endDate}`;
  }
  if (type) {
    url = url + `&type=${type}`;
  }
  if (audience) {
    url = url + `&audience=${audience}`;
  }
  if (searchRewards) {
    url = url + `&search_query=${searchRewards}`;
  }
  if (status) {
    url = url + `&status=${status}`;
  }

  return api
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const createReward = (requestBody: any) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/rewardcampaign`;
  return api
    .post(url, requestBody, {
      headers: { Authorization: `Bearer ${loginAccessToken}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const updateReward = (data, id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/rewardcampaign/${id}`;
  return api
    .put(url, data, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const launchReward = (data, id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/rewardcampaign/${id}/confirm`;
  return api
    .post(url, data, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getRewardDetails = (id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/rewardcampaign/${id}`;
  return api
    .get(url, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getRewardReport = (id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/rewardcampaign/${id}?channel=REWARD`;
  return api
    .get(url, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const downloadRewardReport = (id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/reward/download/report?id=${id}`;
  return api
    .get(url, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const emailRewardReport = (id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/reward/email/report?id=${id}`;
  return api
    .get(url, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getRewardRecepients = (activePage, itemsPerPage, props) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  let { operator, campaignId, status, startDate, endDate, msisdn } = props;
  let statusFilter = status === 'Successful' ? 'success' : 'failed';
  const url = `/recipients?from=${activePage}&size=${itemsPerPage}${
    campaignId ? `&campaignId=${campaignId}` : ''
  }&status=${statusFilter}${operator ? `&operator=${operator}` : ''}${
    startDate ? `&startDate=${startDate}` : ''
  }${endDate ? `&endDate=${endDate}` : ''}${msisdn ? `&msisdn=${msisdn}` : ''}`;

  return api
    .get(url, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getTelcoOperators = (country) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/operators/?country=${country}`;
  return api
    .get(url, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};
