import { useNavigate, useParams } from "react-router-dom";
import Header from "../../common/components/header";
import { importModule } from "../../common/utils/importModule";
import { useContext, useEffect } from "react";
import { TenantContext } from "../home";
import { getCurrenySymbol } from "../../common/utils/currencySymbol";
import { useDispatch } from "react-redux";
import { setShowCommonLoader } from "../../redux-loader/loader-slice/loaderSlice";
import { hasSuperAdminAccess } from "../../common/utils/roles";
import { pageLoadEvent } from "../../../../marketing-cloud-container/src/ga-events";

const CampaignReview = importModule("@tg/engagements", "CampaignReview");

//TODO: To set the pagename dynamically

const CampaignReviewApp = () => {
  useEffect(() => {
    pageLoadEvent("engagement-review_page");
  }, []);
  const tenantContext: any = useContext(TenantContext);
  let country = tenantContext?.country || "";
  const navigate = useNavigate();
  const currencySymbol = getCurrenySymbol(country);
  const { id } = useParams();
  const dispatch = useDispatch();

  return (
    <div>
      <Header pageName="Messaging - Review" />
      <div>
        <CampaignReview
          navigate={navigate}
          id={id}
          currencySymbol={currencySymbol}
          dispatch={dispatch}
          setShowCommonLoader={setShowCommonLoader}
          hasSuperAdminAccess={hasSuperAdminAccess(tenantContext.role)}
        />
      </div>
    </div>
  );
};

export default CampaignReviewApp;
