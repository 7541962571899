import { api } from '../../../../marketing-cloud-main/src/common/services/common-api-service';
import * as Sentry from '@sentry/react';

export const getEmailDetails = (
  id,
  additionalAttributes = ''
) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/message/${id}?channel=EMAIL&additionalAttributes=${
    additionalAttributes ? additionalAttributes : ''
  }`;
  return api
    .get(url, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getEmailStats = (id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/email/statistics?id=${id}`;
  return api
    .get(url, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getEmailSummary = (id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/email/summary?id=${id}`;
  return api
    .get(url, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getEmailDlrs = (id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');

  let url = `/email/dlrs?id=${id}`;

  return api
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken} `,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};
