import { useEffect, useState } from "react";

const LinearScaleProperty = (props: any) => {
  const [question, setQuestion] = useState(props.question);

  const onMinScaleChange = (event) => {
    let q = question;
    if (
      (q.property?.maxScale ? q.property.maxScale : 5) -
        event.target.value +
        1 >
      10
    )
      return;
    q.property
      ? (q.property.minScale = event.target.value)
      : (q.property = { minScale: event.target.value });
    props.setRating(q);
  };

  const onMaxScaleChange = (event) => {
    let q = question;
    if (
      event.target.value -
        (q.property?.minScale ? question.property.minScale : 1) +
        1 >
      10
    )
      return;
    q.property
      ? (q.property.maxScale = event.target.value)
      : (q.property = { maxScale: event.target.value });
    props.setRating(q);
  };

  useEffect(() => {
    const { question } = props;
    setQuestion(question);
  }, []);

  return (
    <div className="flex gap-4">
      <div>
        <div className="text-sm font-semibold text-[#475467] mt-6">
          Min value
        </div>
        <div className="border rounded mt-1 h-[40px] p-1">
          <input
            value={question.property?.minScale ? question.property.minScale : 1}
            onChange={(event) => onMinScaleChange(event)}
            type="number"
            className="border-0 outline-0 h-full w-full"
          />
        </div>
      </div>
      <div>
        <div className="text-sm font-semibold text-[#475467] mt-6">
          Max value
        </div>
        <div className="border rounded mt-1 h-[40px] p-1">
          <input
            value={question.property?.maxScale ? question.property.maxScale : 5}
            onChange={(event) => onMaxScaleChange(event)}
            type="number"
            className="border-0 outline-0 h-full w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default LinearScaleProperty;
