import { tenantApi } from "../services/common-api-service";
const loginAccessToken = localStorage.getItem("loginAccessToken");
import * as Sentry from "@sentry/react";

export const getSidebarOptions = (token) => {
  const url = `/resource1`;
  return tenantApi.get(url, { headers: { "Authorization": `bearer ${token}` } }).then((response) => {
    return response.data;
  })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
}
