import { useContext, useEffect, useRef, useState } from "react";
import { ChevronDown } from "react-feather";
import * as Sentry from "@sentry/react";
import { IoMdCloseCircle } from "react-icons/io";
import { ConfigProvider, DatePicker } from "antd";
const { RangePicker } = DatePicker;
import locale from "antd/es/date-picker/locale/en_GB";
import {
  downloadBillingReport,
  getUsageLogs,
} from "../../../common/apis/billing";
import EventType from "./event-menu";
import WhatsAppChart from "./whatsapp";
import SMSChart from "./sms";
import LogsList from "./logs-list/logs-list";
import { importModule } from "../utils/importModule";
import { useNavigate, useParams } from "react-router-dom";
import { TenantContext } from "../../home";
import { setShowCommonLoader } from "../../../redux-loader/loader-slice/loaderSlice";
import { useDispatch } from "react-redux";
import AllEventsChart from "./all-events";
import PaginationComp from "../../../../../engagements/src/common/components/pagination";
import Spinner from "../../../common/components/spinner/Spinner";
import { DownArrow } from "../../../common/assets/icons/icons";
import { downloadStringAsCSV } from "../../../common/utils/UserInterfaceUtils";

const EngagementsApp = importModule("@tg/engagements", "EngagementsMain");

const Logs = () => {
  const [loading, setLoading] = useState({
    downloadingReport: false,
  });
  const [logsError, setLogsError] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Logs");
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [rangeValue, setRangeValue] = useState(null);
  const [templateType, setTemplateType] = useState("");
  const [WabaName, setWabaName] = useState("");
  const [showEventMenu, setShowEventMenu] = useState(false);
  const [eventType, setEventType] = useState("allevents");
  const [usageLogs, setUsageLogs] = useState([]);
  const [hideFilters, setHideFilters] = useState(false);
  const [prevDateKey, setPrevDateKey] = useState("");
  const [totalListLength, setTotalListLength] = useState(0);
  const [resetPagination, setResetPagination] = useState(false);

  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    name: "",
    channel: "",
    wabaDetials: { verifiedName: "" },
    audience: { segmentId: "" },
    budget: 0,
    schedule: {
      immediate: false,
      timeline: { start: "", end: "" },
    },
    spend: 0,
    analaytics: {
      delivered: 0,
      read: 0,
      sent: 0,
      undelivered: 0,
      clicks: 0,
    },
    status: "",
    senderName: "",
  });

  let { id } = useParams();
  const navigate = useNavigate();
  const tenantContext: any = useContext(TenantContext);
  const country = tenantContext?.country || "";
  const currency = tenantContext?.currency || "";

  const eventMenu = useRef(null);

  useEffect(() => {
    const params = {
      eventType: eventType === "allevents" ? "" : eventType,
      dateRange,
      templateType,
      WabaName,
    };
    getLogs(params);
    if (eventType === "sms") {
      setHideFilters(true);
    } else {
      setHideFilters(false);
    }
    setShowEventMenu(false);
  }, [eventType, WabaName, templateType, dateRange]);

  const getLogs = (params = {}) => {
    dispatch(setShowCommonLoader(true));
    try {
      getUsageLogs(params)
        .then((res) => {
          const { error, response = {} } = res;

          if (!error) {
            setUsageLogs(res?.data);
            setTotalListLength(res?.totalCount);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
          dispatch(setShowCommonLoader(false));
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          setLogsError(true);
          console.log(err);
          dispatch(setShowCommonLoader(false));
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
      dispatch(setShowCommonLoader(false));
    }
  };

  const closeOpenMenus = (e) => {
    if (
      eventMenu.current &&
      showEventMenu &&
      !eventMenu.current.contains(e.target)
    ) {
      setShowEventMenu(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  const handleDateRangeChange = (dates) => {
    if (!dates) {
      resetDateRange();
      return;
    }
    let sDate = new Date(dates[0].$d);
    let eDate = new Date(dates[1].$d);
    sDate.setHours(0, 0, 0, 0);
    eDate.setHours(0, 0, 0, 0);

    setRangeValue(dates);
    setDateRange({
      startDate: sDate.getTime().toString(),
      endDate: eDate.getTime().toString(),
    });
  };

  const resetDateRange = () => {
    setRangeValue(null);
    setDateRange({
      startDate: null,
      endDate: null,
    });
  };

  const downloadReport = () => {
    setLoading((loading) => ({ ...loading, downloadingReport: true }));

    try {
      downloadBillingReport({ dateRange })
        .then((res: any) => {
          setLoading((loading) => ({ ...loading, downloadingReport: false }));
          if (res.status === 200) {
            downloadStringAsCSV(res.data, response?.name || "");
          }
        })
        .catch((err) => {
          setLoading((loading) => ({ ...loading, downloadingReport: false }));
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      setLoading((loading) => ({ ...loading, downloadingReport: false }));
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-10">
        <div>
          <span className="text-gray-700 text-xl font-semibold py-2">
            Events count
          </span>
          <p className="text-gray-500 py-2">View detailed event consumption</p>
        </div>

        <div className="flex gap-4">
          <div className="relative">
            <button
              className="inline-flex gap-2 items-center px-6 py-3 text-sm font-semibold text-center text-gray-70 bg-gray-10 rounded-full hover:bg-gray-100"
              onClick={() => setShowEventMenu(true)}
            >
              {eventType === "allevents" && "All events"}
              {eventType === "whatsapp" && "WhatsApp"}
              {eventType === "sms" && "SMS"}
              <ChevronDown className="text-xl mt-0.5" size="14" />
            </button>

            {/* Event Type dropdown menu */}
            {showEventMenu && (
              <EventType eventMenu={eventMenu} setEventType={setEventType} />
            )}
          </div>

          <div
            className={`h-[40px] ${
              dateRange.startDate ? "w-[300px]" : "w-[100px]"
            }
             py-3 px-5 bg-[#F6F9FC] text-[#475467] text-sm leading-4 font-semibold flex rounded-full items-center cursor-pointer relative`}
          >
            {!dateRange.startDate ? (
              <div className="absolute left-0 top-0 h-[40px] w-[100px] bg-[#F6F9FC] rounded-full z-10 flex items-center cursor-pointer gap-3 justify-center pointer-events-none">
                <span className="cursor-pointer text-sm leading-4 font-semibold text-gray-70">
                  Date
                </span>
                <img src={DownArrow} alt="" />
              </div>
            ) : (
              <div
                className="font-black text-gray-600 cursor-pointer hover:text-red-500"
                onClick={resetDateRange}
              >
                <IoMdCloseCircle size={16} />
              </div>
            )}
            <div>
              <ConfigProvider
                theme={{
                  token: {
                    colorIcon: "#98A2B3",
                    colorBorder: "#D0D5DD",
                    colorPrimary: "#008138",
                    colorPrimaryHover: "#008138",
                    colorText: "#344054",
                    colorTextHeading: "#344054",
                    colorTextDisabled: "#D0D5DD",
                    colorTextLightSolid: "var(--main-color)",
                    colorTextPlaceholder: "#98A2B3",
                    controlItemBgActive: "#DAEEE1",
                    controlOutline: "#FFFFFF",
                    colorBgTextActive: "FFFFFF",
                    colorTextLabel: "#FFFFFF",
                  },
                }}
              >
                <RangePicker
                  className="outline-none border-none bg-[#F6F9FC] text-[#F6F9FC] shadow-none focus:{outline-none border-none bg-[#F6F9FC] shadow-none text-[#F6F9FC]} placeholder:{text-[#F6F9FC]}"
                  onChange={handleDateRangeChange}
                  format={(value) => {
                    return `${value["$d"].toString().substring(4, 16)}`;
                  }}
                  locale={locale}
                  showNow={false}
                  allowClear={true}
                  value={rangeValue}
                  cellRender={(current, info) => {
                    if (info.type !== "date") return info.originNode;
                    const style: React.CSSProperties = {};
                    style.borderRadius = "50%";
                    style.width = "30px";
                    style.height = "30px";
                    style.lineHeight = "30px";
                    return (
                      <div className="ant-picker-cell-inner" style={style}>
                        {current.date()}
                      </div>
                    );
                  }}
                />
              </ConfigProvider>
            </div>
          </div>

          <button
            className="flex justify-center gap-2 items-center px-6 text-gray-700 font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500 disabled:border-none"
            disabled={loading.downloadingReport}
            onClick={downloadReport}
          >
            <span className="mr-2 leading-4 text-sm font-semibold text-gray-700">
              {loading.downloadingReport
                ? "Downloading report"
                : "Download report"}
            </span>
            <div className="py-1">
              {loading.downloadingReport ? (
                <Spinner className={"w-3 h-3"} />
              ) : (
                <img src={DownArrow} alt="" />
              )}
            </div>
          </button>
        </div>
      </div>

      <div className="mb-10">
        {eventType === "whatsapp" && (
          <WhatsAppChart
            setWabaName={setWabaName}
            WabaName={WabaName}
            setTemplateType={setTemplateType}
            templateType={templateType}
            response={response}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
          />
        )}
        {eventType === "sms" && (
          <SMSChart
            response={response}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
          />
        )}

        {eventType === "allevents" && (
          <AllEventsChart
            response={response}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
          />
        )}
      </div>

      <div>
        <span className="text-gray-700 text-xl font-semibold py-2">
          Events logs
        </span>
        <p className="text-gray-500 py-2">
          Event logs help you get a detailed overview of all events by usage.
        </p>

        <div className="flex gap-8 mt-8">
          {["Logs"].map((option, index) => (
            <div
              className={`${
                selectedTab === option
                  ? "text-gray-700 font-semibold border-b-2 border-black hover:text-gray-800 cursor-default"
                  : "font-medium text-gray-500 hover:text-green-75 cursor-pointer"
              }`}
              key={index}
              onClick={() => setSelectedTab(option)}
            >
              <div className="mb-2.5">{option}</div>
            </div>
          ))}
        </div>

        <div className="py-3 mt-4">
          {selectedTab === "Logs" && (
            <>
              {eventType === "sms" ? (
                <EngagementsApp
                  id={id}
                  navigate={navigate}
                  country={country}
                  currency={currency}
                  defaultChannel="sms"
                  dateRange={dateRange}
                  hideFilters={hideFilters}
                  dispatch={dispatch}
                  setShowCommonLoader={setShowCommonLoader}
                />
              ) : (
                <div>
                  <LogsList
                    navigate={navigate}
                    logsError={logsError}
                    usageLogs={usageLogs}
                    prevDateKey={prevDateKey}
                    setPrevDateKey={setPrevDateKey}
                  />
                  <div className="mt-8">
                    <PaginationComp
                      onPageChange={(from, size) => {
                        if (resetPagination) setResetPagination(false);
                        getLogs({ from, size });
                      }}
                      reset={resetPagination}
                      totalCount={totalListLength}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Logs;
