import { importModule } from "../../common/utils/importModule";
import Header from "../../common/components/header";
import { setShowCommonLoader } from "../../redux-loader/loader-slice/loaderSlice";
import { useDispatch } from "react-redux";
const DataApp = importModule("@tg/data", "Data");
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { pageLoadEvent } from "../../../../marketing-cloud-container/src/ga-events";
import { useContext } from "react";
import { TenantContext } from "../home";

function Data() {
  const tenantContext = useContext(TenantContext);
  useEffect(() => {
    pageLoadEvent("data_page");
  }, []);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div>
      <Header pageName="Data" />
      <div className="content py-20 px-12">
        <DataApp
          navigate={navigate}
          dispatch={dispatch}
          setShowCommonLoader={setShowCommonLoader}
          tenantContext={tenantContext}
        />
      </div>
    </div>
  );
}
export default Data;
