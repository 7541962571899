import { useEffect, useState } from "react";

const RatingProperty = (props: any) => {
  const [question, setQuestion] = useState({ property: { rating: "" } });

  const onRatingChange = (event) => {
    if (event.target.value > 10 || event.target.value < 3) return;
    let q = question;
    q.property
      ? (q.property.rating = event.target.value)
      : (q.property = { rating: event.target.value });
    props.setRating(question);
  };

  useEffect(() => {
    const { question } = props;
    setQuestion(question);
  });

  return (
    <div>
      <div className="text-sm font-semibold text-[#475467] mt-6">
        Number of rating
      </div>
      <div className="border rounded mt-1 h-[40px] p-1">
        <input
          type="number"
          value={question.property?.rating ? question.property?.rating : 5}
          onChange={(event) => {
            onRatingChange(event);
          }}
          className="border-0 outline-0 h-full w-full"
        />
      </div>
    </div>
  );
};

export default RatingProperty;
