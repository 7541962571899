import axios from 'axios';
import { FRONTEND_BASE, PESAPAL_URL } from '../../../../../marketing-cloud-main/src/common/constants/baseUrl';
const loginAccessToken = localStorage.getItem("loginAccessToken");
const sandBoxURL = "https://cybqa.pesapal.com/pesapalv3" //For testing in local
import { billingApi } from '../../../common/services/common-api-service';
import * as Sentry from "@sentry/react";

export const authenticatePayment = () => {
  const url = `/payment/token/pesapal`;
  // const data = {
  //   consumer_key: 'qkio1BGGYAXTu2JOfm7XSXNruoZsrqEW',
  //   consumer_secret: 'osGQ364R49cXKeOYSpaOnT++rHs=',
  // };
  return billingApi
    .get(url, { headers: { Authorization: `Bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
};

export const submitOrderRequest = ({ amount, token }) => {

  const url = `${PESAPAL_URL}/api/Transactions/SubmitOrderRequest`;

  let data = {
    id: Date.now(),
    currency: 'KES',
    amount: amount,
    description: 'Testing for Kenyan Payments',
    callback_url: `${FRONTEND_BASE}/billing`,
    cancellation_url: `${FRONTEND_BASE}/billing`,
    // callback_url: `http://localhost:9000/billing`,
    // cancellation_url: `http://localhost:9000/billing`,
    notification_id: '2e0a211b-a9ba-411c-b679-de27c6e46794',
    billing_address: {
      email_address: '',
      phone_number: '',
      country_code: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      line_1: '',
      line_2: '',
      city: '',
      state: '',
      postal_code: '',
      zip_code: '',
    },
  };

  return axios
    .post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        // Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
};
