import { useCallback, useEffect, useRef, useState } from "react";
import { X } from "react-feather";
import NotificationModal from "../../../modals/notification/NotificationModal";
import { getTopupPlans } from "../../../common/services/message-services";
import { useContext } from "react";
import { TenantContext } from "../rcs-parent/rcs-parent";
import { isNumberValid } from "../../../common/utils/mobileNumberValidation";
import {
  ArrowRight,
  Chevron,
  DropDownArrow,
  PeopleGroup,
  SelectedFile,
  File,
  InfoCircle,
} from "../../../assets/icons/icons";
import { Popup } from "semantic-ui-react";
import {
  getSegments,
  postAudienceFromCampaign,
  uploadSegmentCSV,
} from "../../../../../marketing-cloud-main/src/common/apis/audience";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import { isMobileNumberDuplicate } from "../../../common/utils/mobileNumberValidation";
import { cappingCheck } from "../../../../../marketing-cloud-main/src/common/utils/capping-check";
import UpgradePlanModal from "../../../../../marketing-cloud-main/src/modals/upgrade-plan-modal";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import * as Sentry from "@sentry/react";
import { SubscriptionRedirectModal } from "../../../common/components/subscription-redirect-modal/Subscription-redirect-modal";
import { getAccountStat } from "../../../../../marketing-cloud-main/src/common/apis/billing";
import DeletionModal from "../../../common/components/campaign-deletion-modal/campaignDeletionModal";
import { getApproximateCost } from "../../../common/services/common-service";
import { debounce } from "../../../common/utils/debounce";
import CurrencySymbol from "../../../../../marketing-cloud-main/src/common/components/currency-symbol/currency-symbol";

const RcsStepTwo = (props: any) => {
  const [loading, setLoading] = useState({
    submit: false,
    gettingAudience: false,
    gettingApprxCost: false,
  });
  const [manualType, setManualType] = useState("Add numbers manually");
  const [uploadType, setUploadType] = useState(true);
  const [showManualOptions, setShowManualOptions] = useState(false);
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [segmentId, setSegmentId] = useState("");
  const [segmentError, setSegmentError] = useState(false);
  const [fileValidation, setFileValidation] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumbersList, setMobileNUmbersList] = useState([]);
  const [numberValidation, setNumberValidation] = useState(false);
  const [checkValidation, setCheckValidation] = useState(false);
  const [numberListError, setNumberListError] = useState("");
  const [manualTypeError, setManualTypeError] = useState(false);
  const [isConsentReceived, setIsConsentReceived] = useState(false);
  const [profiles, setProfiles] = useState(null);
  const [profilesAdded, setProfilesAdded] = useState(0);
  const [showTooltip, setShowTooltip] = useState(null);
  const [showSegmentSelector, setShowSegmentSelector] = useState(false);
  const [segmentList, setSegmentList] = useState([]);
  const [showError, setShowError] = useState(false);
  const [planUpdgrade, setPlanUpgrade] = useState(false);
  const [lastUpdatedSegmentListLength, setLastUpdatedSegmentListLength] =
    useState(-1);
  const segmentListInnerRef = useRef<HTMLInputElement>(null);
  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  const [tierId, setTierId] = useState(null);
  const [
    subscriptionRredirectModalStatus,
    setSubscriptionRredirectModalStatus,
  ] = useState(false);
  const [audienceSource, setAudienceSource] = useState("");
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [fileSizeValidator, setFileSizeValidator] = useState(false);
  const [campaignCost, setCampaignCost] = useState(0);
  const [operatorCostBreakdown, setOperatorCostBreakdown] = useState([]);
  const [unitPrice, setUnitPrice] = useState(0);

  const [data, setData] = useState({
    audience: "",
    segment: "",
    profiles: "",
    consent: "",
    budget: "",
    unitPrice: 0,
    rcsPages: 0,
  });
  const tenantContext: any = useContext(TenantContext); //country

  useEffect(() => {
    if (props.data) {
      setData({ ...data, ...props.data });

      if (props.data.audience) {
        if (props.data.audience.phoneNumbers) {
          setMobileNUmbersList(props.data.audience.phoneNumbers);
        }
        if (props.data.audience.segmentId) {
          setSegmentId(props.data.audience.segmentId);
        }
      }
      if (props.data.selectedSegment) {
        setSelectedSegment(props.data.selectedSegment);
      }
      if (props.data.uploadType) {
        setUploadType(props.data.uploadType);
      } else {
        setUploadType(false);
        setSelectedSegment(props.data.selectedSegment);
      }
      if (props.data.manualType) {
        setManualType(props.data.manualType);
      }
      if (props.data.selectedFile) {
        setSelectedFile(props.data.selectedFile);
      }
      if (props.data.fileName) {
        setFileName(props.data.fileName);
      }
      if (props.data.profiles) {
        setProfilesAdded(props.data.profiles);
      }
      if (props.data.budget) {
        setProfiles(props.data.budget);
      }

      if (props.data.consent) {
        setIsConsentReceived(props.data.consent);
      }
    }
  }, [props.data]);

  let operatorProductIds = [36, 37, 38, 39];

  useEffect(() => {
    getAccountStat()
      .then((response) => {
        let tierId = JSON.parse(response?.subscriptions[0])[0]?.tier_id;
        setTierId(tierId);
      })
      .catch((error) => {
        setTierId(0);
        console.log(error);
      });

    getTopupPlans()
      .then((response) => {
        let item =
          response[
            response?.findIndex((item) => {
              return item.product_id === 41;
            })
          ];
        if (item) {
          setUnitPrice(item?.billing_scheme?.tiers[0]?.price);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getOperatorCost = () => {
    let min = Math.min(...operatorCostBreakdown.map((el) => el.cost));
    let max = Math.max(...operatorCostBreakdown.map((el) => el.cost));

    return min == max ? (
      <CurrencySymbol value={min} />
    ) : (
      <>
        From{" "}
        <span className="flex font-semibold text-gray-700">
          <div className="flex gap-1">
            <CurrencySymbol value={min} /> - {<CurrencySymbol value={max} />}
          </div>
        </span>
      </>
    );
  };

  const manualOptions = useRef(null);
  const tooltip = useRef(null);

  const closeOpenMenus = (e) => {
    if (
      segmentListInnerRef.current &&
      showSegmentSelector &&
      !segmentListInnerRef.current?.contains(e.target)
    ) {
      setShowSegmentSelector(false);
    }

    if (
      manualOptions.current &&
      showManualOptions &&
      !manualOptions.current.contains(e.target)
    ) {
      setShowManualOptions(false);
    }

    if (tooltip.current && showTooltip && !tooltip.current.contains(e.target)) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);

    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  });

  const manualTypeOnChange = () => {
    if (manualType === "Upload a file") {
      if (selectedFile) {
        processCsv(selectedFile);
      }
    } else {
      const profiles = getNumberOfValidphones(mobileNumbersList);
      setProfiles(profiles);
      setProfilesAdded(profiles);
    }
    setShowManualOptions(false);
  };

  useEffect(() => {
    getSegmentList(0, 20);
  }, [audienceSource]);

  useEffect(() => {
    if (profiles && profiles > 0) getCampaignCost();
  }, [profiles]);

  const getCampaignCost = () => {
    // if (profiles === 0) {
    //   setCampaignCost(0);
    //   return;
    // }
    if (profiles && profiles > 0) {
      setLoading((loading) => ({ ...loading, gettingApprxCost: true }));
      try {
        getApproximateCost(
          data?.rcsPages ? data?.rcsPages * profiles : profiles,
          41
        )
          .then((res) => {
            setLoading((loading) => ({ ...loading, gettingApprxCost: false }));
            if (res?.cost) setCampaignCost(res.cost);
          })
          .catch((res) => {
            console.log(res);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onBudgetChange = (value) => {
    setProfiles(value);
  };

  const handleBudgetChange = useCallback(debounce(onBudgetChange), [profiles]);

  const getSegmentList = (from, pageSize) => {
    setLoading((loading) => ({ ...loading, gettingAudience: true }));
    try {
      getSegments({ from, pageSize, audienceSource })
        .then((response) => {
          setLoading((loading) => ({ ...loading, gettingAudience: false }));
          let list: any[] = [];
          response.data.map((element) => {
            if (element.audience_id) {
              list.push(element);
            }
          });
          let previousList = from === 0 ? [] : [...segmentList];
          let newList = previousList.concat(list);
          setSegmentList(newList);
          setLastUpdatedSegmentListLength(newList.length);
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  const onInputChange = (event) => {
    if (event.code == "Enter") {
      if (isMobileNumberDuplicate(mobileNumber, mobileNumbersList)) {
        Notify({
          type: "error",
          heading: "Duplicate Mobile Number",
          message: `${mobileNumber} is already added. Add a different number.`,
        });
      } else {
        setNumberListError("");
        setNumberValidation(false);
        mobileNumbersList.push(mobileNumber);
        setMobileNumber("");
        let profiles = getNumberOfValidphones(mobileNumbersList);
        setProfiles(profiles);
        setProfilesAdded(profiles);
      }
    }
  };

  const onInputMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const popItemFromMobileList = (item) => {
    let list = [...mobileNumbersList];
    let index = list.lastIndexOf(item);
    if (index > -1) {
      list.splice(index, 1);
    }
    setMobileNUmbersList(list);
    let profiles: any = getNumberOfValidphones(list);
    setProfiles(profiles);
    setProfilesAdded(profiles);
  };

  const isPhoneNumberValid = (number: any) => {
    if (
      number[0] === "0" ||
      !isNumberValid(tenantContext.country, number.length)
    ) {
      return false;
    }
    return true;
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1000;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const dm = decimals < 0 ? 0 : decimals;

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const processCsv = (file) => {
    setProfiles(file.count);
    setProfilesAdded(file.count);
  };

  const onFileSelected = (event) => {
    setFileSizeValidator(false);
    if (event.target.files && event.target.files[0]) {
      if (
        parseFloat(
          (event.target.files[0].size / Math.pow(1000, 2)).toFixed(2)
        ) > 50
      ) {
        setFileSizeValidator(true);
        return;
      }
      setFileName("Uploading...");
      setFileUploadStatus(true);
      const file = event.target.files[0];
      setFileValidation(false);
      try {
        uploadSegmentCSV(file)
          .then((res) => {
            const { data } = res;
            if (data && !data?.error) {
              const { segment, email, phone_number } = data.data;

              // First, check the phone_number uploaded_profiles, then email if phone_number is 0
              const profilesData =
                phone_number.uploaded_profiles > 0 ? phone_number : email;

              const {
                total_profiles,
                duplicate,
                uploaded_profiles,
                invalid_profiles,
              } = profilesData;
              
              const breakdownMessage = `Uploaded: ${uploaded_profiles}, Duplicate: ${duplicate}, Invalid: ${invalid_profiles}, Total: ${total_profiles}`;

              postAudienceFromCampaign({
                segment: segment,
                name: file.name.split(".")[0],
                size: uploaded_profiles,
                description: "Campaign",
              })
                .then((audience: any) => {
                  let f = {
                    name: file.name,
                    size: file.size,
                    count: uploaded_profiles,
                  };
                  setSelectedFile(f);
                  setFileName(file.name);
                  processCsv(f);
                  setSegmentId(audience.data.audience_id);
                  setSelectedSegment({
                    audience_id: audience.data.audience_id,
                    audience_source: "csv",
                    segment_ids: data.data.segment,
                  });
                  Notify({
                    type: "success",
                    heading: "Import successful",
                    message: `${breakdownMessage}`,
                  });
                  setFileUploadStatus(false);
                })
                .catch((err) => {
                  err.response
                    ? Sentry.captureMessage("Error", err.response)
                    : Sentry.captureMessage("Error", err);
                  event.stopPropagation();
                  unselectFile();
                  setFileUploadStatus(false);
                  console.log(err);
                });
            } else {
              Sentry.captureMessage("Error", res);
              event.stopPropagation();
              unselectFile();
              setFileUploadStatus(false);
            }
          })
          .catch((err) => {
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            event.stopPropagation();
            unselectFile();
            setFileUploadStatus(false);
            console.log("File upload error", err);
          });
      } catch (err) {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        event.stopPropagation();
        unselectFile();
        setFileUploadStatus(false);
        console.log("File upload error", err);
      }
    } else {
      setFileUploadStatus(false);
      event.stopPropagation();
      unselectFile();
    }
  };

  const unselectFile = () => {
    setFileName("");
    setProfiles(0);
    setProfilesAdded(0);
    setSelectedFile(null);
  };

  const resetAudience = () => {
    setProfiles(0);
    setProfilesAdded(0);
    setMobileNUmbersList([]);
    setMobileNumber("");
    setNumberValidation(false);
    setNumberListError("");
    setSegmentError(false);
    setManualTypeError(false);
    setCheckValidation(false);
    setSelectedFile(null);
    setFileValidation(false);
    setFileName("");
    setManualType("Add numbers manually");
  };

  const isNumberListValid = () => {
    let status = true;
    mobileNumbersList.map((element, i) => {
      if (!isPhoneNumberValid(element)) {
        status = false;
      }
    });
    return status;
  };

  const getNumberOfValidphones = (numberList) => {
    let count = 0;
    numberList.map((element, i) => {
      if (isPhoneNumberValid(element)) {
        count++;
      }
    });
    return count;
  };

  const consentRecievedOnChange = (event) => {
    if (event.target.checked) setCheckValidation(false);
    setIsConsentReceived(event.target.checked);
  };

  const handleNotificationClose = () => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      show: false,
    }));
  };

  const [notificationData, setNotificationData] = useState({
    show: false,
    close: handleNotificationClose,
    type: "",
    message: "",
    heading: "",
  });

  const handleNotificationData = (data: any) => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      ...data,
    }));
    setTimeout(() => {
      handleNotificationClose();
    }, 4000);
  };

  const getCampaignData = () => {
    let audience: any;
    if (uploadType) {
      if (manualType == "Add numbers manually") {
        audience = {
          segmentId: "",
          phoneNumbers: mobileNumbersList,
        };
      } else if (manualType == "Upload a file") {
        audience = {
          segmentId: segmentId,
          phoneNumbers: [],
        };
      }
    } else {
      audience = {
        segmentId: selectedSegment.audience_id,
        phoneNumbers: [],
      };
    }
    const data = {
      segment: "",
      profiles: Number(profilesAdded),
      budget: Number(profiles),
      consent: isConsentReceived,
      uploadType,
      manualType,
      selectedFile,
      fileName,
      audience: audience,
      selectedSegment,
      operatorCostBreakdown: operatorCostBreakdown,
      unitPrice,
    };

    const campaignData = {
      ...props.data,
      ...data,
    };
    return campaignData;
  };

  const validateForm = () => {
    if (uploadType) {
      if (manualType === "") {
        setManualTypeError(true);
        return;
      }

      if (manualType === "Upload a file") {
        if (!selectedFile) {
          setFileValidation(true);
          return;
        }
      } else {
        if (!isNumberListValid()) {
          setNumberValidation(true);
          setNumberListError(
            "One or more of the inputted number is in a wrong format. Please ensure the phone number doesn’t include 0 or country code eg. 8066603063."
          );
        }

        if (mobileNumbersList.length === 0) {
          setNumberValidation(true);
          setNumberListError("Phone number list cannot be empty");
        }

        if (!isConsentReceived) {
          setCheckValidation(true);
        }

        if (
          !isNumberListValid() ||
          mobileNumbersList.length === 0 ||
          !isConsentReceived
        ) {
          return false;
        }
      }
    } else {
      if (!selectedSegment) {
        setSegmentError(true);
        return;
      }
    }
    if (profiles <= 0) return;

    return true;
  };

  const saveDraft = () => {
    Notify({
      type: "success",
      heading: "Saved successfully.",
      message: "Your campaign has been saved as draft.",
    });
    props.navigate("/engagements");
  };

  const handleSubmit = async (event, saveAsDraft) => {
    setLoading((loading) => ({ ...loading, submit: true }));
    let status = await cappingCheck(profiles, 41);
    if (!status) {
      setPlanUpgrade(true);
      setLoading((loading) => ({ ...loading, submit: false }));
      return;
    }
    event.preventDefault();
    setShowError(true);

    if (!validateForm()) {
      setLoading((loading) => ({ ...loading, submit: false }));
      return;
    }

    const campaignData = getCampaignData();
    props.setData(campaignData);
    props.setStep(2);

    // try {
    //   setLoading(true);
    //   updateMessage(campaignData, props.data.id)
    //     .then((res) => {
    //       const { error, response = {} } = res.data;
    //       if (error === false) {
    //         if (saveAsDraft) {
    //           saveDraft();
    //         } else {
    //           props.setData(campaignData);
    //           props.setStep(3);
    //         }
    //       } else {
    //         error.response
    //           ? Sentry.captureMessage("Error", error.response)
    //           : Sentry.captureMessage("Error", error);
    //       }

    //       setLoading(false);
    //     })
    //     .catch((err) => {
    //       err.response
    //         ? Sentry.captureMessage("Error", err.response)
    //         : Sentry.captureMessage("Error", err);
    //       console.error(err);
    //       setLoading(false);
    //     });
    // } catch (err) {
    //   err.response
    //     ? Sentry.captureMessage("Error", err.response)
    //     : Sentry.captureMessage("Error", err);
    //   console.error(err);
    //   return;
    // }
  };

  const segmentSelectorOnChange = () => {
    let segmentSelction = showSegmentSelector;
    setShowSegmentSelector(!segmentSelction);
  };

  const onSegmentSelectionChange = (segment) => {
    let selectedProfiles = segment.audience_size ? segment.audience_size : 0;
    setSelectedSegment(segment);
    setProfiles(selectedProfiles);
    setProfilesAdded(selectedProfiles);
    setShowSegmentSelector(false);
  };

  const onSegmentListScroll = () => {
    if (segmentListInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        segmentListInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (lastUpdatedSegmentListLength !== 0) {
          getSegmentList(lastUpdatedSegmentListLength + 1, 20);
        }
      }
    }
  };

  const handleSubscriptionModalClick = (status) => {
    if (status) props.navigate("/pricing");
    else setSubscriptionRredirectModalStatus(false);
  };

  return (
    <div className="py-12 px-12">
      <div className="text-gray-500 font-semibold text-sm">STEP 1 OF 3</div>
      <div className="flex justify-between items-center w-full">
        <div className="flex-wrap justify-start items-start">
          <div className="flex rounded-xl py-4">
            <div className="flex gap-6">
              <div className="flex justify-center items-center flex-shrink-0 w-16 bg-gray-100 rounded-full h-16">
                <img src={PeopleGroup} alt="" />
              </div>
              <div className="text-left py-2">
                <div className="text-gray-70 font-semibold text-base">
                  Recipients
                </div>
                <div className="text-gray-500 mt-1 font-normal text-sm whitespace-no-wrap">
                  Who are you targeting?
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-2 ml-auto">
          <button
            onClick={() => setShowDeletionModal(true)}
            className="px-5 py-3 rounded-md text-sm leading-6 text-slate-600 font-semibold border border-gray-200"
          >
            Cancel
          </button>
          <button
            onClick={(event) => {
              handleSubmit(event, false);
            }}
            disabled={loading.submit}
            className="px-5 py-3 w-24 rounded-md text-sm leading-6 bg-blue-75 disabled:bg-blue-75 hover:bg-green-75 text-white font-semibold border border-gray-200 flex justify-center items-center"
          >
            {loading.submit ? (
              <Spinner className={"w-4 h-4"} />
            ) : (
              <div className="flex gap-3 items-center">
                {"Next  "} <img src={ArrowRight} alt="" />
              </div>
            )}
          </button>
        </div>
      </div>

      <div className="mt-6 flex">
        <div className="w-[60%]">
          <div className="flex justify-between gap-4">
            <label
              htmlFor="audience"
              className={`mt-3 border flex rounded-md py-3 px-5 justify-between w-1/2 cursor-pointer ${
                !uploadType ? "border-gray-700" : "border-gray-40"
              }`}
              onClick={() => {
                setUploadType(false);
                resetAudience();
                setManualType("audience");
              }}
            >
              <div>Add from Audience</div>
              <input
                name="audience"
                className="accent-gray-700 cursor-pointer"
                type="radio"
                checked={!uploadType}
                onChange={() => {
                  setUploadType(false);
                  setManualType("audience");
                  resetAudience();
                }}
              />
            </label>

            <label
              htmlFor="manualUpload"
              className={`mt-3 border flex rounded-md py-3 px-5 justify-between w-1/2 cursor-pointer ${
                uploadType ? "border-gray-700" : "border-gray-40"
              }`}
              onClick={() => {
                setUploadType(true);
                resetAudience();
              }}
            >
              <div>Manual upload</div>
              <input
                name="manualUpload"
                className="accent-gray-700 cursor-pointer"
                type="radio"
                checked={uploadType}
                onChange={() => {
                  setUploadType(true);
                  resetAudience();
                }}
              />
            </label>
          </div>

          {!uploadType && (
            <div className="mt-6">
              <span className="text-sm text-[#475467] font-semibold">
                Audience
              </span>
              <div className="relative mt-1">
                <div
                  className="border rounded h-[40px] px-3 py-2 cursor-pointer"
                  onClick={() => {
                    segmentSelectorOnChange();
                  }}
                >
                  <div className="flex justify-between items-center h-full">
                    {selectedSegment ? (
                      <span>{selectedSegment?.audience_name}</span>
                    ) : (
                      <span className="text-gray-600 text-sm">
                        Select from your list of audience Audience
                      </span>
                    )}
                    <img src={DropDownArrow} alt="" />
                  </div>
                </div>
                {showSegmentSelector && (
                  <div
                    onScroll={onSegmentListScroll}
                    ref={segmentListInnerRef}
                    className="absolute w-full bg-white top-12 drop-shadow-md rounded max-h-[160px] overflow-y-auto"
                  >
                    <div className="text-xs px-4 py-3 sticky top-0 bg-white">
                      {[
                        { label: "All", value: "" },
                        { label: "Your audience", value: "csv" },
                        { label: "Terragon audience", value: "terragon" },
                      ].map((item, index) => (
                        <span
                          className={`mr-3 px-2 py-1 rounded cursor-pointer ${
                            audienceSource === item.value
                              ? "text-gray-700 bg-slate-200"
                              : "text-gray-500"
                          }`}
                          key={index}
                          onClick={() => {
                            setLastUpdatedSegmentListLength(0);
                            setSegmentList([]);
                            setAudienceSource(item.value);
                          }}
                        >
                          {item.label}
                        </span>
                      ))}
                    </div>
                    <div>
                      {segmentList.length === 0 ? (
                        <div className="py-3 flex justify-center">
                          {" "}
                          <Spinner className={"w-4 h-4"} />
                        </div>
                      ) : (
                        segmentList.map((ele, i) => {
                          return (
                            <div
                              className="px-4 py-3 cursor-pointer flex justify-between items-center hover:bg-[#F9FAFB]"
                              key={i}
                              onClick={() => {
                                tierId === 0 &&
                                ele.audience_source === "terragon"
                                  ? setSubscriptionRredirectModalStatus(true)
                                  : onSegmentSelectionChange(ele);
                              }}
                            >
                              <div className="flex gap-2 items-center text-sm leading-6">
                                <span className="text-gray-700">
                                  {ele.audience_name}
                                </span>
                                {tierId === 0 &&
                                  ele.audience_source === "terragon" && (
                                    <span className="bg-blue-50 text-blue-800 font-semibold text-xs rounded-full flex items-center px-3 py-1">
                                      Upgrade plan to use
                                    </span>
                                  )}
                              </div>
                              <span className="text-[#98A2B3]">
                                {ele.audience_size ? ele.audience_size : 0}{" "}
                                profiles
                              </span>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {uploadType && (
            <>
              <div className="mt-8">
                <div className="text-gray-70 font-semibold text-sm mb-1.5">
                  Manual upload types
                </div>
                <div className="relative w-full">
                  <button
                    name="surveyChannel"
                    className={`flex w-full justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-40 rounded-md focus:outline-none ${
                      manualType === "" && "text-gray-400"
                    } ${manualTypeError && "border-red-500"}`}
                    type="button"
                    onClick={() => {
                      setShowManualOptions(!showManualOptions);
                      setManualTypeError(false);
                    }}
                    disabled={loading.submit}
                  >
                    {manualType || "Select your manual upload option"}
                    <img src={Chevron} alt="" />
                  </button>

                  {showManualOptions && (
                    <div
                      ref={manualOptions}
                      className="absolute z-10 bg-white rounded-lg shadow w-full"
                    >
                      {["Add numbers manually", "Upload a file"].map(
                        (option, index) => (
                          <button
                            key={index}
                            className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                            role="menuitem"
                            onClick={() => {
                              setManualType(option);
                              setShowManualOptions(false);
                            }}
                          >
                            {option}
                          </button>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-6">
                <div>
                  {manualType == "Add numbers manually" && (
                    <>
                      <div>
                        <div>
                          Use ‘Enter’ to separate phone numbers. Do not add
                          country code or ‘0’ before the number.
                        </div>
                        <div>
                          <div
                            className={
                              numberValidation
                                ? "border border-red-500 rounded-lg text-gray-70"
                                : "border border-gray-40 rounded-lg text-gray-70"
                            }
                            style={{
                              marginTop: "8px",
                              width: "100%",
                              display: "inline-block",
                            }}
                          >
                            <div style={{ float: "left" }}>
                              {mobileNumbersList.map((element, index) => {
                                return (
                                  <span
                                    className={`border inline-block rounded-lg py-2 px-3 text-gray-700 text-sm mt-2 ml-2 ${
                                      isPhoneNumberValid(element)
                                        ? "border-gray-50"
                                        : "border-red-500"
                                    }`}
                                    key={index}
                                  >
                                    {element}
                                    <X
                                      className="text-gray-600 inline-block ml-2 -mt-1 cursor-pointer rounded-full hover:bg-gray-30 hover:p-0.5"
                                      size={14}
                                      onClick={() =>
                                        popItemFromMobileList(element)
                                      }
                                    />
                                  </span>
                                );
                              })}
                            </div>
                            <div>
                              <input
                                placeholder="Enter your number here."
                                value={mobileNumber}
                                onChange={($event) =>
                                  onInputMobileNumberChange($event)
                                }
                                className="h-[30px] outline-0 border-0 m-2"
                                style={{ width: "-webkit-fill-available" }}
                                type="number"
                                onKeyDown={($event) => onInputChange($event)}
                              />
                            </div>
                          </div>
                          {numberValidation ? (
                            <div className="text-red-500 text-sm mt-2">
                              {numberListError}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="flex items-center py-3 mt-3">
                        <input
                          id="consent"
                          type="checkbox"
                          checked={isConsentReceived}
                          className={`w-4 h-4 border border-gray-300 rounded cursor-pointer accent-blue-75`}
                          onChange={consentRecievedOnChange}
                        />
                        <label
                          htmlFor="consent"
                          className={`ml-2 text-gray-700 cursor-pointer  ${
                            checkValidation &&
                            "border border-red-500 rounded-[4px] p-0.5"
                          }`}
                        >
                          Numbers uploaded have given consent to receive
                          promotional messages from this business.
                        </label>
                      </div>
                    </>
                  )}

                  {manualType == "Upload a file" && (
                    <div>
                      <div className="mt-1">
                        For a lot of profiles. Upload a file with their numbers.{" "}
                        <span>
                          <a
                            href="https://mcassets.blob.core.windows.net/cdn/AudienceAssets/Audience_Sample_Template.csv"
                            download="Sample_Template.csv"
                            className="text-blue-75 font-semibold cursor-pointer"
                          >
                            Download sample file
                          </a>
                        </span>
                      </div>

                      <div className="relative">
                        {fileUploadStatus && (
                          <div className="absolute top-0 left-0 z-10 flex justify-center h-full w-full bg-[#ffffffb8] border rounded">
                            <Spinner className="w-8 h-8" />
                          </div>
                        )}
                        {fileName ? (
                          <div
                            className={`mt-3 py-3 bg-gray-25 border border-dashed border-gray-40 rounded-lg cursor-pointer relative ${
                              fileValidation
                                ? "border-red-500"
                                : "border-gray-40"
                            }`}
                          >
                            <div className="flex justify-between items-center px-4">
                              <div className="flex gap-4 items-center px-2">
                                <img src={SelectedFile} alt="" />
                                <div className="text-gray-70">
                                  <div className="text-base font-semibold">
                                    {selectedFile?.name}
                                  </div>
                                  <div>{formatBytes(selectedFile?.size)}</div>
                                </div>
                              </div>
                              <X
                                className="text-gray-600 cursor-pointer"
                                size="16"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  unselectFile();
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`mt-3 py-6 bg-gray-25 flex items-center justify-center border border-dashed border-gray-40 rounded-lg cursor-pointer relative ${
                              fileValidation
                                ? "border-red-500"
                                : "border-gray-40"
                            }`}
                          >
                            <div className="flex flex-col text-center items-center justify-center">
                              <img src={File} alt="" />
                              <div className="my-1">
                                <span className="text-blue-75 font-semibold cursor-pointer">
                                  Choose a file to upload
                                </span>
                              </div>
                              <div className="text-gray-500 text-sm">
                                or drag and drop it here
                              </div>
                              <p
                                className={`${
                                  fileSizeValidator
                                    ? "text-red-500"
                                    : "text-gray-500"
                                } text-xs mt-1`}
                              >
                                {fileSizeValidator
                                  ? "File exceeds the 50 MB upload limit. Please upload a smaller CSV or XLS file."
                                  : "Only CSV, XLS supported. Max file size: 50 MB"}
                              </p>
                            </div>
                            <input
                              className="absolute w-full h-full cursor-pointer opacity-0"
                              type="file"
                              accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              id="importFile"
                              onChange={onFileSelected}
                              required
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="mt-10">
            <div className="text-sm font-semibold mb-1 text-[#475467]">
              Set your budget
            </div>
            <div className="text-[#667085] text-sm">
              Enter the number of profiles you want to reach.
            </div>
            {profiles <= 0 && showError && (
              <div className="text-red-500 text-sm">
                please provide a valid number of profiles
              </div>
            )}
            <div className="mt-5">
              <div className="flex">
                <div className="border border-[#E4E7EC] h-[40px] grow flex rounded-l border-t-1 border-l-1 p-1">
                  <input
                    className="outline-0 border-0 w-full rounded-l"
                    defaultValue={profiles}
                    onChange={(event) => {
                      let profiles = Number(event.target.value);
                      handleBudgetChange(profiles);
                      if (!profiles || profiles === 0) {
                        setCampaignCost(0);
                      }
                    }}
                    type="number"
                    placeholder="Enter number"
                  />
                </div>
                <div className="border border-[#E4E7EC] border-l-0 py-0 px-3 flex justify-center items-center rounded-r">
                  Profiles
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[40%] self-start">
          <div className="mt-3 ml-6 border border-[#E2E8F0] bg-[#F9FBFE] rounded-lg">
            <>
              <div className="m-6 pb-2 border-b border-[#E2E8F0] text-gray-700 text-base font-semibold">
                Summary
              </div>

              <div className="mx-6 text-gray-700 text-base font-semibold">
                Audience
              </div>
              <div className="py-6 px-8 border-b border-[#E2E8F0]">
                <div className="text-gray-500 text-xs flex gap-1 items-center text-center">
                  <span className="text-xl text-gray-700 font-semibold">
                    {profilesAdded}{" "}
                  </span>{" "}
                  profiles matched
                </div>
                <div className="text-gray-500 text-sm mt-6">
                  Based on{" "}
                  {uploadType
                    ? "the numbers inputted"
                    : "your segment selection"}
                  , this is your potential target audience.
                </div>
                <div className="flex mt-6 justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <div className="text-gray-700 text-sm leading-6 font-semibold">
                      Audience budget
                    </div>
                    <div className="relative">
                      <button
                        className="inline-flex items-center py-2.5 text-sm font-semibold text-center text-gray-70"
                        type="button"
                        onClick={() => setShowTooltip(!showTooltip)}
                      >
                        <img src={InfoCircle} alt="" />
                      </button>

                      {/* Target audience tooltip */}
                      {showTooltip && (
                        <div ref={tooltip} className="absolute">
                          <div className="p-3 z-10 ml-7 -mt-8 bg-[#E6F0FA] text-[#143D7B] border border-[#B5D5F4] text-sm leading-6 rounded-lg shadow-lg w-[186px]">
                            <p>
                              This is the number of profiles you can target with
                              your budget.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-gray-700 text-sm font-semibold">
                    {profiles || 0} profiles
                  </div>
                </div>
              </div>
              <div className="my-6 mx-8">
                <div className="text-gray-700 text-base font-semibold">
                  Message
                </div>
                <div className="flex justify-between text-sm text-gray-500">
                  <div>Unit cost</div>
                  <span className="flex font-semibold text-gray-700">
                    {unitPrice}
                  </span>
                </div>
                <div className="flex justify-between px-4 py-3 bg-slate-100 mt-6">
                  <div className="flex items-center gap-1 text-sm leading-6 font-semibold">
                    Approximate pricing
                    <Popup
                      content="Approximate cost of running this RCS campaign"
                      style={{
                        fontSize: "12px",
                        fontWeight: 500,
                        lineHeight: "18px",
                      }}
                      trigger={
                        <div>
                          <img src={InfoCircle} alt="" />
                        </div>
                      }
                      on={"hover"}
                      inverted={true}
                    />
                  </div>
                  <div className="text-lg font-semibold">
                    {loading.gettingApprxCost ? (
                      <Spinner className={"w-4 h-4"} />
                    ) : (
                      <CurrencySymbol value={campaignCost} />
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>

      <NotificationModal data={notificationData} />
      {planUpdgrade && (
        <UpgradePlanModal close={setPlanUpgrade} navigate={props.navigate} />
      )}
      {subscriptionRredirectModalStatus && (
        <SubscriptionRedirectModal
          handleClick={(status) => handleSubscriptionModalClick(status)}
        />
      )}
      {showDeletionModal && (
        <DeletionModal
          step={1}
          navigate={props.navigate}
          closeModal={setShowDeletionModal}
        />
      )}
    </div>
  );
};

export default RcsStepTwo;
