import { api } from "../../../../../marketing-cloud-main/src/common/services/common-api-service";
import * as Sentry from "@sentry/react";

export const getTemplatesList = (id?: string) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/template/${id}?category=marketing&status=approved`;
  return api
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
};

export const sendTemplateMessage = (payload: any) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/message`;
  return api
    .post(url, payload, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
};
