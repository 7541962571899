import { useEffect, useState } from "react";
import NotificationModal from "../../../modals/notification/NotificationModal";
import { launchReward } from "../../../common/services/reward-services";
import { formatNumbers } from "../../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";
import * as Sentry from "@sentry/react";
import { getCampaignCost } from "../../../common/services/common-service";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import {
  PhoneRewardLaunch,
  SmsLaunchIconAudience,
  SmsLaunchIconChannel,
  SmsLaunchIconLaunch,
  SmsLaunchIconMessage,
  SmsLaunchIconName,
  SmsLaunchIconObjective,
  SmsLaunchIconProfile,
  SmsLaunchIconSchedule,
} from "../../../assets/icons/icons";

const RewardsStepThree = (props: any) => {
  const walletBalance = props.walletBalance;

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }

    props.getBalance();
  }, []);

  const [loading, setLoading] = useState(false);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [showTopupModal, setShowTopupModal] = useState(null);
  const [campaignCost, setCampaignCost] = useState(null);
  const [data, setData] = useState({
    name: "",
    schedule: {
      immediate: true,
      timeline: {
        start: new Date().getTime(),
      },
    },
    message: "",
    profiles: 0,
    rewardDate: "",
    rewardDetails: {
      rewardType: "",
    },
    rewardPerProfile: 0,
    rewardBudget: 0,
    budgetEstimate: 0,
    senderName: "",
    unitPrice: 0,
  });

  const handleNotificationClose = () => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      show: false,
    }));
  };

  const [notificationData, setNotificationData] = useState({
    show: false,
    close: handleNotificationClose,
    type: "",
    message: "",
    heading: "",
  });

  const handleNotificationData = (data: any) => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      ...data,
    }));
    setTimeout(() => {
      handleNotificationClose();
    }, 4000);
  };

  const saveDraft = () => {
    handleNotificationData({
      show: true,
      type: "success",
      heading: "Saved successfully.",
      message: "Your reward has been saved as draft.",
    });

    setTimeout(() => {
      props.navigate("/engagements/rewards");
    }, 4000);
  };

  const handleSubmit = () => {
    try {
      setLoading(true);
      if (data.rewardBudget > walletBalance) {
        const unitsReuquired = data.rewardBudget - walletBalance;
        setShowTopupModal(true);
        handleNotificationData({
          show: true,
          type: "error",
          heading: "Insufficient wallet balance.",
          message: `Please top up your wallet with at least ${
            props.currencySymbol
          }${formatNumbers(
            (unitsReuquired + (7.5 / 100) * unitsReuquired).toFixed(2)
          )} (7.5% VAT included) to continue.`,
        });
        setLoading(false);
        return;
      } else {
        launchReward(props.data, props.data.id.replaceAll("/", "%2F"))
          .then((res) => {
            const { error, response = {} } = res;
            if (error === false) {
              if (saveAsDraft) {
                saveDraft();
              } else {
                props.getBalance();
                Notify({
                  type: "success",
                  heading: "Campaign created successfully",
                  message:
                    "Your campaign has been created successfully and will go live at the scheduled time after it has been approved by our content and ad policy team.",
                });
                setTimeout(() => {
                  props.navigate("/engagements/rewards");
                }, 4000);
              }
            } else {
              error.response
                ? Sentry.captureMessage("Error", error.response)
                : Sentry.captureMessage("Error", error);
              handleNotificationData({
                show: true,
                type: "error",
                heading: "Failed to launch reward.",
                message: error,
              });
            }

            setLoading(false);
          })
          .catch((err) => {
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            console.error(err);
            setLoading(false);
          });
      }
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.error(err);
      return;
    }
  };

  const updateCampaignCost = async (productId) => {
    let cost = await getCampaignCost(props.data?.budget, productId);
    setCampaignCost(cost);
  };

  return (
    <div className="py-12 px-12">
      <div className="text-gray-500 font-semibold text-sm">STEP 3 OF 3</div>
      <div className="flex justify-between items-center w-full">
        <div className="flex-wrap justify-start items-start">
          <div className="flex rounded-xl py-4">
            <div className="flex gap-6">
              <div className="flex justify-center items-center flex-shrink-0">
                <img src={SmsLaunchIconLaunch} alt="" />
              </div>
              <div className="text-left py-2">
                <div className="text-gray-70 font-semibold">
                  Preview and Launch
                </div>
                <div className="text-gray-500 mt-1 font-normal text-sm whitespace-no-wrap">
                  Preview your reward and click “Launch” if everything checks
                  out.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ml-auto">
          <div className="flex gap-4">
            <button
              className="py-3 px-5 w-[88px] text-gray-90 text-sm font-semibold border rounded-[6px] bg-white border-gray-50 hover:border-gray-500"
              onClick={() => props.setStep(2)}
              disabled={loading}
            >
              Back
            </button>
            <button
              onClick={handleSubmit}
              disabled={loading}
              className="py-3 px-6 w-[103px] text-white text-sm font-semibold border rounded-[6px] bg-blue-75 hover:bg-green-75 disabled:bg-gray-400 disabled:text-slate-500 disabled:border-slate-200"
            >
              Launch
            </button>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-start gap-10">
        <div className="w-4/6 text-sm">
          <div>
            <div className="mb-6">
              <span className="text-gray-700 font-semibold mr-4">Reward</span>
              <span
                onClick={() => props.setStep(2)}
                className="text-green-15 cursor-pointer"
              >
                Edit
              </span>
            </div>
            <div className="flex gap-16 justify-start">
              <div className="flex gap-4 mb-6 w-1/2">
                <img src={SmsLaunchIconName} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Reward name
                  </span>
                  <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                    {data.name}
                  </span>
                </div>
              </div>
              <div className="flex gap-4 mb-6">
                <img src={SmsLaunchIconChannel} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Reward type
                  </span>
                  <span className="mt-1 inline-block break-words text-gray-500 capitalize">
                    {data?.rewardDetails?.rewardType?.toLowerCase()}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex gap-16 justify-start mt-2.5">
              <div className="flex gap-4 mb-6 w-1/2">
                <img src={SmsLaunchIconObjective} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Timeline
                  </span>
                  <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                    {data.schedule?.immediate
                      ? new Date().toLocaleDateString("en-GB")
                      : new Date(
                          data.schedule?.timeline.start
                        ).toLocaleDateString("en-GB")}
                  </span>
                </div>
              </div>
              <div className="flex gap-4 mb-6">
                <img src={SmsLaunchIconSchedule} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Push time
                  </span>
                  <span className="mt-1 inline-block break-words text-gray-500">
                    {data.schedule?.immediate
                      ? "Immediate"
                      : new Date(
                          data.schedule?.timeline.start
                        ).toLocaleTimeString("en-GB")}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex gap-16 justify-start mt-2.5">
              {props.data?.sendFollowUpMessage && (
                <div className="flex gap-4 mb-6 w-1/2">
                  <img src={SmsLaunchIconMessage} alt="" />
                  <div>
                    <span className="block font-semibold text-gray-70">
                      Message
                    </span>
                    <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                      {data.message}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mt-6">
            <div className="mb-6">
              <span className="text-gray-700 font-semibold mr-4">
                Participants
              </span>
              <span
                onClick={() => props.setStep(1)}
                className="text-green-15 cursor-pointer"
              >
                Edit
              </span>
            </div>
            <div className="flex gap-16 justify-start mt-2.5">
              <div className="flex gap-4 mb-6 w-1/2">
                <img src={SmsLaunchIconAudience} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Audience
                  </span>
                  <span className="mt-1 capitalize text-gray-500">
                    {props.data?.segment
                      ? props.data.segment?.audience_name
                      : "Custom audience"}
                  </span>
                </div>
              </div>
              <div className="flex gap-4 mb-6">
                <img src={SmsLaunchIconProfile} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Profiles
                  </span>
                  <span className="mt-1 inline-block break-words text-gray-500">
                    {formatNumbers(data?.profiles)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <div className="mb-6">
              <span className="text-gray-700 font-semibold mr-4">Pricing</span>
              <span
                onClick={() => props.setStep(2)}
                className="text-green-15 cursor-pointer"
              >
                Edit
              </span>
            </div>
            <div className="flex gap-16 justify-start mt-2.5">
              <div className="flex gap-4 mb-6 w-1/2">
                <img src={SmsLaunchIconProfile} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Budget
                  </span>
                  <span className="mt-1 text-gray-500">
                    {props.currencySymbol}
                    {formatNumbers(
                      data?.budgetEstimate || data?.rewardBudget || 0
                    )}
                  </span>
                </div>
              </div>
              <div className="flex gap-4 mb-6">
                <img src={SmsLaunchIconChannel} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Reward per profile
                  </span>
                  <span className="mt-1 inline-block break-words text-gray-500">
                    {props.currencySymbol}
                    {formatNumbers(data.rewardPerProfile || 0)}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex gap-16 justify-start mt-2.5">
              <div className="flex gap-4 mb-6">
                <img src={SmsLaunchIconProfile} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Total pricing
                  </span>
                  <span className="mt-1 inline-block break-words text-gray-500">
                    {props.currencySymbol}
                    {formatNumbers(data?.rewardBudget || 0)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {props.data?.sendFollowUpMessage && (
          <div className="relative">
            <div className="absolute w-[236px] h-5 text-gray-90 bg-[#F1F1F2] text-[7px] text-center font-semibold mt-[346px] ml-[52px]">
              {data.senderName}
            </div>
            <div className="absolute w-[180px] h-[68px] text-gray-90 bg-[#E5E5EA] text-[10px] rounded-lg leading-4 font-semibold mt-[404px] ml-[68px] pl-2 pr-2">
              <div className="line-clamp-5 leading-relaxed h-[61px] mt-1.5">
                {data.message}
              </div>
            </div>
            <img src={PhoneRewardLaunch} alt="" />
          </div>
        )}
      </div>

      <NotificationModal data={notificationData} />
      {/* {showTopupModal && (
        <div>
          <FundWalletModal
            topupModalState={"FUND_MODAL"}
            setTopupModalState={setTopupModalState}
            handleClose={handleClose}
          />
        </div>
      )} */}
    </div>
  );
};

export default RewardsStepThree;
