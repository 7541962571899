import { Modal } from "semantic-ui-react";
import {
  Close,
  Apple,
  LeftChevron,
  RightChevron,
} from "../../../assets/icons/icons";
import { useEffect, useState } from "react";

const UssdSurveyPreview = ({ previewToggle, question, setPreviewToggle }) => {
  const [previewObject, setPreviewObject] = useState([]);
  const [previewSelection, setPreviewSelection] = useState(0);

  useEffect(() => {
    toggleModal();
  }, [previewToggle]);

  const toggleModal = () => {
    if (previewToggle) {
      let preview = [];
      preview[0] = {
        type: "Introduction",
        value: question.introduction,
      };
      question.questions.map((q) => {
        preview.push({
          type: "Question",
          value: q,
        });
      });
      preview.push({
        type: "End Message",
        value: question.endMessage,
      });
      setPreviewObject(preview);
      setPreviewSelection(0);
      console.log(preview);
    }
  };

  const previewPrevious = () => {
    if (previewSelection != 0) {
      let p = previewSelection;
      p--;
      setPreviewSelection(p);
    }
  };

  const previewNext = () => {
    if (previewSelection != previewObject.length - 1) {
      let p = previewSelection;
      p++;
      setPreviewSelection(p);
    }
  };
  return (
    <div>
      <Modal open={previewToggle}>
        <div className="h-[651px] bg-[#F2F6FC] flex justify-center items-center relative">
          <img src={Apple} alt="" />
          <div className="absolute bg-none h-[562px] w-[309px]">
            <div className="bg-none absolute top-[45px] bottoom-[25px] left-[15px] right-[15px] h-[500px]">
              {previewToggle ? (
                <div>
                  <div className="p-3">
                    {previewObject[previewSelection]?.type ===
                    "Introduction" ? (
                      <div>
                        <div className="text-sm text-gray-700 opacity-80">
                          {previewObject[previewSelection]?.value}
                        </div>
                        <div className="mt-5 pl-5 text-sm text-gray-700">
                          <div className="flex gap-3">
                            <div>1.</div>{" "}
                            <div className="opacity-80">Accept</div>
                          </div>
                          <div className="flex gap-3 mt-3">
                            <div>2. </div>{" "}
                            <div className="opacity-80">Reject</div>
                          </div>
                        </div>
                        <div className="text-xs text-[#667085] opacity-80 mt-6 pt-3 pl-2">
                          Reply with 1 or 2
                        </div>
                      </div>
                    ) : null}
                    {previewObject[previewSelection]?.type === "Question" ? (
                      <div>
                        <div className="text-gray-700 p-2 text-lg">
                          {previewObject[previewSelection].value.q}
                        </div>
                        {previewObject[previewSelection]?.value?.choice
                          ?.length > 0 ? (
                          <div className="mt-3">
                            {previewObject[
                              previewSelection
                            ]?.value?.choice?.map((e, i) => {
                              return (
                                <div
                                  key={i}
                                  className="flex items-center gap-4 text-sm mt-2 text-gray-700 pl-1"
                                >
                                  <div>{i + 1}.</div>
                                  <div className="opacity-80">{e}</div>
                                </div>
                              );
                            })}
                            <div className="text-[#667085] pl-2 mt-7 text-xs flex gap-1">
                              <span>Reply with</span>
                              {previewObject[
                                previewSelection
                              ]?.value?.choice?.map((e, i) => {
                                return (
                                  <span key={i}>
                                    {i + 1}{" "}
                                    <span>
                                      {i !=
                                      previewObject[previewSelection]?.value
                                        ?.choice.length -
                                        1
                                        ? ","
                                        : null}
                                    </span>
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                        ) : null}
                        {previewObject[previewSelection]?.value?.choice
                          ?.length == 0 ? (
                          <div className="text-[#667085] opacity-80 mt-7 pl-2 text-xs">
                            Reply
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    {previewObject[previewSelection]?.type === "End Message" ? (
                      <div className="text-blue-75 text-xl p-2">
                        {previewObject[previewSelection].value}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className="absolute border-t w-full bottom-5 pt-5">
                {previewToggle ? (
                  <div className="flex justify-between items-center">
                    <div className="flex gap-1 text-gray-700 opacity-80 text-sm items-center ml-3">
                      <div>{previewObject[previewSelection]?.type}</div>
                      <div>
                        {previewObject[previewSelection]?.type == "Question" ? (
                          <div>
                            {previewObject[previewSelection]?.value?.index}/
                            {previewObject?.length - 2}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex items-center gap-7 mr-3">
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          previewPrevious();
                        }}
                      >
                        <img src={LeftChevron} alt="" />
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          previewNext();
                        }}
                      >
                        <img src={LeftChevron} alt="" />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div
            className="absolute right-[24px] top-[24px] cursor-pointer"
            onClick={() => {
              // toggleModal(false);
              setPreviewToggle(false);
            }}
          >
            <img src={Close} alt="" />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UssdSurveyPreview;
