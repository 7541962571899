import { useEffect, useReducer, useRef, useState } from "react";
import { ConfigProvider, DatePicker, TimePicker } from "antd";
import type { DatePickerProps } from "antd/es/date-picker";
import locale from "antd/es/date-picker/locale/en_GB";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import NotificationModal from "../../../modals/notification/NotificationModal";
import {
  getSenderIds,
  getTopupPlans,
} from "../../../common/services/message-services";
import {
  createReward,
  updateReward,
} from "../../../common/services/reward-services";
import { getCurrenySymbol } from "../../../common/utils/currenySymbol";
import * as Sentry from "@sentry/react";
import SubscriptionPromptDialog from "../../../../../marketing-cloud-main/src/common/components/subscription-prompt-dialog/SubscriptionPromptDialog";
import { getAccountStat } from "../../../../../marketing-cloud-main/src/common/apis/billing";
import ArrowRight from "../../../../../marketing-cloud-main/src/common/assets/icons/ArrowRight";
import {
  Airtel,
  Calendar,
  CalendarIcon,
  Chevron,
  Clock,
  Etisalat,
  Glo,
  InfoCircle,
  Mtn,
  Reward,
  RewardAirtime,
  RewardCalendar,
  RewardClock,
  RewardData,
  Telephone,
  UserOutline,
  Wifi,
} from "../../../assets/icons/icons";

const RewardsStepTwo = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [showScheduleMenu, setShowScheduleMenu] = useState(false);
  const [showSenderIDMenu, setShowSenderIDMenu] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [senderIDs, setSenderIDs] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [scheduleError, setScheduleError] = useState("");
  const [unitPrice, setUnitPrice] = useState(props.data?.unitPrice || 0);
  const [showNetworkMenu, setShowNetworkMenu] = useState(false);
  const [showDataMenu, setShowDataMenu] = useState(false);
  const [rewardExists, setRewardExists] = useState(false);
  const [showTooltip, setShowTooltip] = useState(null);
  const [subscriptionPromptDialogStatus, setSubscriptionPromptDialogStatus] =
    useState(false);

  const scheduleMenu = useRef(null);
  const senderIDMenu = useRef(null);
  const networkMenu = useRef(null);
  const dataMenu = useRef(null);
  const tooltip = useRef(null);

  let country = props.country;
  const currency = props.currency;
  const currencySymbol = getCurrenySymbol(country, currency);

  useEffect(() => {
    if (props.data.id) {
      setRewardExists(true);
    }

    fetchSenderIDs();
    getSmsCost();
  }, []);

  useEffect(() => {
    if (props.data.rewardDate && props.data.rewardTime) {
      setStartDate(
        new Date(props.data.rewardDate + "T" + props.data.rewardTime)
      );
    }
  }, [props.data]);

  useEffect(() => {
    getAccountStat()
      .then((response) => {
        let tierId = JSON.parse(response?.subscriptions[0])[0]?.tier_id;
        if (tierId === 0) setSubscriptionPromptDialogStatus(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getSmsCost = () => {
    try {
      getTopupPlans()
        .then((response) => {
          if (!response.error) {
            let item =
              response[
                response.findIndex((item) => {
                  return item.product_id === 8;
                })
              ];
            if (item) {
              setUnitPrice(item?.billing_scheme?.tiers[0]?.price);
            }
          } else {
            Sentry.captureMessage("Error", response);
          }
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log(error);
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
    }
  };

  const fetchSenderIDs = () => {
    try {
      getSenderIds()
        .then((res) => {
          const { error, response = {} } = res;
          if (error === false) {
            setSenderIDs(response);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
            handleNotificationData({
              show: true,
              type: "error",
              heading: "Failed to retrieve sender IDs.",
              message: error,
            });
            setSenderIDs([]);
          }
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log(error);
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
    }
  };

  dayjs.extend(customParseFormat);
  dayjs.extend(localizedFormat);

  const onStartTimeChange = (time: Dayjs, timeString: string) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "rewardTime",
        value: time.format("HH:mm:ss").split(" ")[0],
      },
    });
  };

  const onStartDateChange = (
    value: DatePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "rewardDate",
        value: dateString,
      },
    });
  };

  const onStartDateSave = () => {
    if (!formState.rewardDate) {
      formState.errors.rewardDate = true;
      return;
    }
    if (!formState.rewardTime) {
      formState.errors.rewardTime = true;
      return;
    }

    const startDate = new Date(
      formState.rewardDate + "T" + formState.rewardTime
    );
    if (startDate.getTime() < new Date().getTime()) {
      formState.errors.rewardTime = true;
      setScheduleError("Selected time has already passed");
      return;
    } else {
      setScheduleError("");
    }
    setStartDate(startDate);
    setShowCalendar(false);
  };

  const disabledDates = (current) => {
    return current && current < dayjs().endOf("day").subtract(1, "day");
  };

  const closeOpenMenus = (e) => {
    if (
      senderIDMenu.current &&
      showSenderIDMenu &&
      !senderIDMenu.current.contains(e.target)
    ) {
      setShowSenderIDMenu(false);
    }

    if (
      scheduleMenu.current &&
      showScheduleMenu &&
      !scheduleMenu.current.contains(e.target)
    ) {
      setShowScheduleMenu(false);
    }

    if (tooltip.current && showTooltip && !tooltip.current.contains(e.target)) {
      setShowTooltip(false);
    }
  };

  const mobileNetworks = [
    { name: "MTN", value: "mtn", icon: <img src={Mtn} alt="" /> },
    { name: "Airtel", value: "airtel", icon: <img src={Airtel} alt="" /> },
    { name: "Glo", value: "glo", icon: <img src={Glo} alt="" /> },
    { name: "9mobile", value: "9mobile", icon: <img src={Etisalat} alt="" /> },
  ];

  document.addEventListener("mousedown", closeOpenMenus);

  const handleNotificationClose = () => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      show: false,
    }));
  };

  const [notificationData, setNotificationData] = useState({
    show: false,
    close: handleNotificationClose,
    type: "",
    message: "",
    heading: "",
  });

  const handleNotificationData = (data: any) => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      ...data,
    }));
    setTimeout(() => {
      handleNotificationClose();
    }, 4000);
  };

  const getRewardBudget = () => {
    let addedCosts = Number(formState.rewardPerProfile);
    if (props.data?.sendFollowUpMessage) {
      addedCosts += unitPrice;
    }
    return addedCosts * Number(formState.profiles);
  };

  const formInitValue = {
    rewardName: props.data.name || "",
    rewardDate: props.data.rewardDate || "",
    rewardTime: props.data.rewardTime || "",
    senderID: props.data.senderName || "",
    message: props.data.message || "",
    rewardType: props.data.rewardType || "AIRTIME",
    rewardPerProfile: props.data.rewardPerProfile || "",
    profiles: props.data.profiles || "",
    schedule: props.data?.schedule
      ? props.data?.schedule?.immediate
        ? "now"
        : "later"
      : "",
    sendFollowUpMessage:
      props.data?.sendFollowUpMessage === true ? true : false,
    saveAsDraft: false,
    errors: {
      rewardName: false,
      rewardDate: false,
      rewardTime: false,
      rewardType: false,
      rewardPerProfile: false,
      senderID: false,
      message: false,
      schedule: false,
    },
  };

  useEffect(() => {
    dispatch({ type: "reinitialize", value: formInitValue });
    if (props.data.id) {
      setRewardExists(true);
    }
  }, [props.data]);

  const reducer = (state, action) => {
    switch (action.type) {
      case "CHANGE":
        return {
          ...state,
          [action.payload.name]: action.payload.value,
          errors: {
            ...state.errors,
            [action.payload.name]: "",
          },
        };
      case "SUBMIT":
        const errors: any = {};
        if (!state.rewardName.trim()) {
          errors.rewardName = true;
        }
        if (state.rewardType !== "AIRTIME") {
          errors.rewardType = true;
        }
        if (state.rewardType === "AIRTIME" && !state.rewardPerProfile) {
          errors.rewardPerProfile = true;
        }
        if (!state.schedule) {
          errors.schedule = true;
        }
        if (state.schedule === "later" && !startDate) {
          errors.rewardDate = true;
        }
        if (state.sendFollowUpMessage && !state.senderID) {
          errors.senderID = true;
        }
        if (state.sendFollowUpMessage && !state.message.trim()) {
          errors.message = true;
        }

        return {
          ...state,
          errors: {
            ...errors,
          },
        };

      case "reinitialize":
        return { ...state, ...action.value };
      default:
        return state;
    }
  };

  const [formState, dispatch] = useReducer(reducer, formInitValue);

  const handleFormChange = (e) => {
    let { name, value } = e.target;

    if (name === "rewardName") {
      value = value.replace(/[^a-z0-9 ]/gi, "");
    }

    dispatch({
      type: "CHANGE",
      payload: {
        name,
        value,
      },
    });
  };

  const validateForm = () => {
    const {
      rewardName,
      rewardType,
      rewardPerProfile,
      rewardOperator,
      rewardData,
      senderID,
      message,
      schedule,
      sendFollowUpMessage,
    } = formState;

    if (!schedule || (schedule === "later" && !startDate)) {
      return false;
    }

    if (!rewardName) {
      return false;
    }

    if (rewardType === "AIRTIME" && !rewardPerProfile) {
      return false;
    }

    if (rewardType === "DATA" && !(rewardOperator || rewardData)) {
      return false;
    }

    if (sendFollowUpMessage && !(senderID && message)) {
      return false;
    }

    return true;
  };

  const getRewardData = () => {
    const data = {
      id: "",
      name: formState.rewardName,
      sendFollowUpMessage: formState.sendFollowUpMessage,
      senderName: formState.senderID,
      schedule: {
        immediate: formState.schedule === "now" ? true : false,
        timeline: {
          start: startDate ? startDate.getTime() : "",
          end: new Date().setDate(new Date().getDate() + 1),
        },
      },
      unitPrice,
      message: formState.message,
      rewardDate: formState.rewardDate,
      rewardTime: formState.rewardTime,
      rewardPerProfile: Number(formState.rewardPerProfile),
      rewardBudget: getRewardBudget(),
    };

    if (formState.rewardType === "DATA") {
      data["rewardDetails"] = {
        rewardType: formState.rewardType,
        dataRewardDetails: [
          {
            operator: formState.rewardOperator.toLowerCase(),
            value: formState.rewardData.split(" ")[0],
            amount: Number(
              formState.rewardData.split(currencySymbol)[1].replace(",", "")
            ),
          },
        ],
      };
    } else if (formState.rewardType === "AIRTIME") {
      data["rewardDetails"] = {
        rewardType: formState.rewardType,
        airtimeRewardDetails: [
          {
            amount: Number(formState.rewardPerProfile),
            currency,
          },
        ],
      };
    }

    if (formState.sendFollowUpMessage) {
      data["followUpMessageDetails"] = {
        channel: "SMS",
        content: {
          type: "TEXT",
          text: formState.message.trim(),
        },
        senderName: formState.senderID,
      };
    }

    const rewardData = {
      ...props.data,
      ...data,
    };

    return rewardData;
  };

  const setRewardData = (data) => {
    const rewardData = {
      ...props,
      data: {
        ...props.data,
        ...data,
      },
    };

    return rewardData;
  };

  const saveDraft = () => {
    handleNotificationData({
      show: true,
      type: "success",
      heading: "Saved successfully.",
      message: "Reward details saved as draft.",
    });

    setTimeout(() => {
      props.navigate("/engagements/rewards");
    }, 4000);
  };

  const goBack = () => {
    const rewardData = getRewardData();
    props.setData(rewardData);
    props.setStep(1);
  };

  const handleSubmit = (event, saveAsDraft) => {
    event.preventDefault();

    const updatedState = reducer(formState, { type: "SUBMIT" });
    dispatch({ type: "SUBMIT", payload: updatedState });

    if (!validateForm()) {
      return;
    }

    const data = getRewardData();
    const rewardData = setRewardData(data);

    if (!rewardExists) {
      try {
        setLoading(true);
        createReward(rewardData.data)
          .then((res) => {
            const { error, response = {} } = res;
            if (error === false) {
              if (saveAsDraft) {
                saveDraft();
              } else {
                rewardData.data["id"] = response?.response;
                props.setData(rewardData.data);
                props.setStep(3);
              }
            } else {
              error.response
                ? Sentry.captureMessage("Error", error.response)
                : Sentry.captureMessage("Error", error);
              handleNotificationData({
                show: true,
                type: "error",
                heading: "Failed to save reward details.",
                message: error,
              });
            }

            setLoading(false);
          })
          .catch((err) => {
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            console.error(err);
            setLoading(false);
          });
      } catch (err) {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        console.error(err);
        return;
      }
    } else {
      rewardData.data["id"] = props.data.id;
      try {
        updateReward(rewardData.data, props.data.id.replaceAll("/", "%2F"))
          .then((res) => {
            if (!res.error) {
              if (saveAsDraft) {
                saveDraft();
              } else {
                props.setData(rewardData.data);
                props.setStep(3);
              }
            } else {
              Sentry.captureMessage("Error", res);
            }
          })
          .catch((err) => {
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            console.log("error", err);
          });
      } catch (err) {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
      }
    }
  };

  const subscriptionPromptDialogHandleClick = (status) => {
    setSubscriptionPromptDialogStatus(status);
    if (status) props.navigate("/pricing");
    else props.navigate("/");
  };

  return (
    <div className="py-12 px-12">
      <div className="text-gray-500 font-semibold text-sm">STEP 2 OF 3</div>
      <div className="flex justify-between items-center w-full">
        <div className="flex-wrap justify-start items-start">
          <div className="flex rounded-xl py-4">
            <div className="flex gap-6">
              <div className="flex justify-center items-center flex-shrink-0">
                <img src={Reward} alt="" />
              </div>
              <div className="text-left py-2">
                <div className="text-gray-70 font-semibold text-base">
                  Reward details
                </div>
                <div className="text-gray-500 mt-1 font-normal text-sm whitespace-no-wrap">
                  Tell us about your reward. Fill in your reward details below.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ml-auto">
          <div className="flex gap-4">
            <button
              className="py-3 px-5 w-[88px] text-gray-90 text-sm font-semibold border rounded-[6px] bg-white border-gray-50 hover:border-gray-500"
              onClick={goBack}
              disabled={loading}
            >
              Back
            </button>
            <button
              onClick={(event) => {
                handleSubmit(event, false);
              }}
              disabled={loading}
              className="flex items-center justify-center gap-2 py-3 px-5 w-[99px] text-white text-sm font-semibold border rounded-[6px] bg-blue-75 hover:bg-green-75 disabled:bg-gray-400 disabled:text-slate-500 disabled:border-slate-200"
            >
              <div>Next</div>
              <ArrowRight />
            </button>
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="py-4 w-[60%]">
          <div className="py-3">
            <div className="text-gray-70 font-semibold text-sm mb-1.5">
              Reward name
            </div>
            <input
              type="text"
              name="rewardName"
              placeholder="Enter the name of your reward"
              value={formState.rewardName}
              maxLength={50}
              className={`w-full h-12 px-3 py-3 mt-1 border hover:border-gray-300 rounded-md focus:outline-none ${
                formState.errors.rewardName && "border-red-500"
              }`}
              onChange={handleFormChange}
              disabled={loading}
            />
            <div
              className={`text-gray-70 font-normal text-sm mt-2 ${
                formState.rewardName.length === 50 && "text-red-500"
              }`}
            >
              {formState.rewardName.length + "/50 characters"}
            </div>

            <div className="py-3 mt-3">
              <div className="text-gray-70 font-semibold text-sm mb-2">
                Reward type
              </div>
              <div className="w-full flex gap-4">
                <div
                  className={`w-[49%] h-12 flex justify-between items-center px-6 border rounded-md hover:border-gray-300 cursor-pointer ${
                    formState.rewardType === "AIRTIME" && "border-blue-75"
                  } ${formState.errors.rewardType && "border-red-500"}`}
                  onClick={() => {
                    dispatch({
                      type: "CHANGE",
                      payload: {
                        name: "rewardType",
                        value: "AIRTIME",
                      },
                    });
                    dispatch({
                      type: "CHANGE",
                      payload: {
                        name: "rewardBudget",
                        value: "",
                      },
                    });
                  }}
                >
                  <div className="flex items-center">
                    <img className="-mt-1" src={RewardAirtime} alt="" />
                    <label
                      htmlFor="airtime-radio"
                      className="w-full py-4 ml-3 font-medium text-gray-900 dark:text-gray-300"
                    >
                      Airtime
                    </label>
                  </div>
                  <input
                    name="airtime-radio"
                    id="airtime-radio"
                    type="radio"
                    value=""
                    disabled={loading}
                    checked={formState.rewardType === "AIRTIME"}
                    onChange={() => {
                      dispatch({
                        type: "CHANGE",
                        payload: {
                          name: "rewardType",
                          value: "AIRTIME",
                        },
                      });
                      dispatch({
                        type: "CHANGE",
                        payload: {
                          name: "rewardBudget",
                          value: "",
                        },
                      });
                    }}
                    className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 cursor-pointer"
                  />
                </div>

                <div
                  className={`w-[49%] h-12 flex justify-between items-center px-6 border rounded-md dark:border-gray-700 cursor-not-allowed ${
                    formState.rewardType === "DATA" && "border-blue-75"
                  }`}
                  // onClick={() => {
                  //   dispatch({
                  //     type: "CHANGE",
                  //     payload: {
                  //       name: "rewardType",
                  //       value: "DATA",
                  //     },
                  //   });
                  //   dispatch({
                  //     type: "CHANGE",
                  //     payload: {
                  //       name: "rewardBudget",
                  //       value: "",
                  //     },
                  //   });
                  // }}
                >
                  <div className="flex items-center opacity-50">
                    <img className="-mt-1" src={RewardData} alt="" />
                    <label
                      htmlFor="data-radio"
                      className="w-full py-4 ml-3 font-medium text-gray-900 dark:text-gray-300"
                    >
                      Data
                    </label>
                  </div>
                  <input
                    name="data-radio"
                    id="data-radio"
                    type="radio"
                    value=""
                    disabled
                    // disabled={loading}
                    // checked={formState.rewardType === "DATA"}
                    // onChange={() => {
                    //   dispatch({
                    //     type: "CHANGE",
                    //     payload: {
                    //       name: "rewardType",
                    //       value: "DATA",
                    //     },
                    //   });
                    //   dispatch({
                    //     type: "CHANGE",
                    //     payload: {
                    //       name: "rewardBudget",
                    //       value: "",
                    //     },
                    //   });
                    // }}
                    className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 cursor-pointer"
                  />
                </div>
              </div>

              {formState.rewardType === "AIRTIME" && (
                <div className="mt-4 pt-3">
                  <div className="text-gray-70 font-semibold text-sm mb-2">
                    Reward per profile
                  </div>
                  <div
                    className={`flex w-full justify-between items-center px-4 py-3 text-center h-12 mt-1 border hover:border-gray-300 group rounded-md focus:outline-none ${
                      formState.errors.rewardPerProfile && "border-red-500"
                    }`}
                  >
                    <div
                      className={`border-r h-12 group-hover:border-gray-300 py-3.5 pr-4 ${
                        formState.errors.rewardPerProfile && "border-red-500"
                      }`}
                    >
                      {currencySymbol === "₦" ? "NGN" : "KSh"}
                    </div>
                    <input
                      type="number"
                      name="rewardPerProfile"
                      placeholder="Enter amount"
                      className="w-full px-3 focus:outline-none"
                      onChange={handleFormChange}
                      value={formState.rewardPerProfile}
                      disabled={loading}
                    />
                  </div>
                </div>
              )}

              {formState.rewardType === "DATA" && (
                <>
                  <div className="flex gap-4 mt-4">
                    <div className="py-3">
                      <div className="text-gray-70 font-semibold text-sm mb-4">
                        Data plans
                      </div>
                      <div className="w-[218px] bg-gray-10 p-4 border border-gray-300 rounded-xl">
                        <div className="relative w-full">
                          <button
                            className={`flex w-[186px] justify-between items-center px-4 py-3 text-center h-12 bg-white border border-gray-300 rounded-full focus:outline-none ${
                              formState.rewardOperator === "" && "text-gray-400"
                            } ${
                              formState.errors.rewardOperator &&
                              "border-red-500"
                            }`}
                            type="button"
                            disabled={loading}
                            onClick={() => setShowNetworkMenu(!showNetworkMenu)}
                          >
                            {formState.rewardOperator || "Select network"}
                            <img src={Chevron} alt="" />
                          </button>

                          {/* Network Dropdown menu */}
                          {showNetworkMenu && (
                            <div
                              ref={networkMenu}
                              className="absolute z-10 bg-white rounded-lg shadow w-[186px]"
                            >
                              {mobileNetworks.map((network, index) => (
                                <div
                                  key={index}
                                  className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left cursor-pointer"
                                  onClick={() => {
                                    dispatch({
                                      type: "CHANGE",
                                      payload: {
                                        name: "rewardOperator",
                                        value: network.name,
                                      },
                                    });
                                    setShowNetworkMenu(false);
                                  }}
                                >
                                  <div className="flex gap-4 items-center">
                                    {network.icon}
                                    {network.name}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>

                        <div className="text-gray-70 font-semibold text-sm mt-4 mb-1.5">
                          Data plan
                        </div>
                        <div className="relative w-full">
                          <button
                            className={`flex w-[186px] justify-between items-center px-4 py-3 text-center h-12 bg-white border border-gray-300 rounded-full focus:outline-none ${
                              formState.rewardData === "" && "text-gray-400"
                            } ${
                              formState.errors.rewardData && "border-red-500"
                            }`}
                            type="button"
                            disabled={loading}
                            onClick={() => setShowDataMenu(!showDataMenu)}
                          >
                            {formState.rewardData || "Select data plan"}
                            <img src={Chevron} alt="" />
                          </button>

                          {/* Data Dropdown menu */}
                          {showDataMenu && (
                            <div
                              ref={dataMenu}
                              className="absolute z-10 bg-white rounded-lg shadow w-[186px]"
                            >
                              {[
                                `500MB at ${currencySymbol}500`,
                                `1GB at ${currencySymbol}1,000`,
                                `2GB at ${currencySymbol}2,000`,
                                `3GB at ${currencySymbol}3,500`,
                                `10GB at ${currencySymbol}10,000`,
                                `15GB at ${currencySymbol}17,500`,
                              ].map((data, index) => (
                                <button
                                  key={index}
                                  className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                                  role="menuitem"
                                  onClick={() => {
                                    dispatch({
                                      type: "CHANGE",
                                      payload: {
                                        name: "rewardData",
                                        value: data,
                                      },
                                    });
                                    setShowDataMenu(false);
                                  }}
                                >
                                  {data}
                                </button>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="mb-4">
              <div className="flex gap-2">
                <div className="py-3 w-56 mt-3">
                  <label className="relative flex items-center mb-2 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={formState.sendFollowUpMessage === true}
                      className="sr-only peer"
                      disabled={loading}
                      onClick={() => {
                        dispatch({
                          type: "CHANGE",
                          payload: {
                            name: "sendFollowUpMessage",
                            value: !formState.sendFollowUpMessage,
                          },
                        });
                      }}
                    />
                    <div
                      className={`w-11 h-6 ${
                        formState.sendFollowUpMessage
                          ? "bg-gray-200"
                          : "bg-gray-300"
                      } rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-75`}
                    ></div>
                    <div className="ml-3 text-sm font-semibold text-gray-70 whitespace-nowrap flex">
                      Send follow-up message
                    </div>
                  </label>
                </div>
                <div className="relative mt-4 pt-0.5 group">
                  <button
                    className="inline-flex items-center py-2.5 text-sm font-semibold text-center text-gray-70"
                    type="button"
                  >
                    <img src={InfoCircle} alt="" />
                  </button>

                  {/* Target audience tooltip */}
                  <div
                    ref={tooltip}
                    className="absolute hidden group-hover:flex"
                  >
                    <div className="p-3 z-10 ml-7 -mt-10 bg-[#E6F0FA] text-[#143D7B] border border-[#B5D5F4] text-sm leading-6 rounded-lg shadow-lg w-[212px]">
                      <p>This will be charged per profile.</p>
                    </div>
                  </div>
                </div>
              </div>

              {formState.sendFollowUpMessage && (
                <>
                  <div className="py-3">
                    <div className="text-gray-70 font-semibold text-sm mb-1.5">
                      Sender ID
                    </div>
                    <div className="relative w-full">
                      <button
                        name="senderID"
                        className={`flex w-full justify-between items-center px-4 py-3 text-center h-12 mt-1 border hover:border-gray-300 rounded-md focus:outline-none ${
                          formState.senderID === "" && "text-gray-400"
                        } ${formState.errors.senderID && "border-red-500"}`}
                        type="button"
                        onClick={() => setShowSenderIDMenu(!showSenderIDMenu)}
                        disabled={loading}
                      >
                        {senderIDs.length
                          ? formState.senderID || "Select your sender ID"
                          : "No enabled Sender ID available!"}
                        <img src={Chevron} alt="" />
                      </button>

                      {/* SenderID Dropdown menu */}
                      {showSenderIDMenu && (
                        <div
                          ref={senderIDMenu}
                          className="absolute z-10 bg-white rounded-lg shadow w-full"
                        >
                          <button
                            className="block p-4 text-blue-75 hover:text-green-75 w-full text-left text-sm font-semibold"
                            role="menuitem"
                            onClick={() => {
                              props.navigate(
                                "/settings?tab=Engagement&item=Sender%20ID"
                              );
                            }}
                          >
                            Create Sender ID +
                          </button>
                          {senderIDs?.map((senderID, index) => (
                            <button
                              key={index}
                              className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                              role="menuitem"
                              onClick={() => {
                                dispatch({
                                  type: "CHANGE",
                                  payload: {
                                    name: "senderID",
                                    value: senderID?.name,
                                  },
                                });
                                setShowSenderIDMenu(false);
                              }}
                            >
                              {senderID?.name}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="pt-3">
                    <div className="text-gray-70 font-semibold text-sm my-2">
                      Message
                    </div>
                    <div
                      className={`border hover:border-gray-300 group rounded-md  ${
                        formState.errors.message && "border-red-500"
                      }`}
                    >
                      <textarea
                        name="message"
                        placeholder="Enter message here"
                        value={formState.message}
                        maxLength={160}
                        className={`w-full h-24 px-3 py-3 mt-1 focus:outline-none ${
                          formState.errors.message && "border-red-500"
                        }`}
                        onChange={handleFormChange}
                        disabled={loading}
                      />
                      <hr className="group-hover:border-gray-300" />
                      <div>
                        <div className="px-4 py-2 cursor-pointer">
                          <img src={UserOutline} alt="" />
                        </div>
                        <div className="text-gray-400 bg-[#F9FAFB] px-4 py-3 rounded-b-md">
                          {props.businessName
                            ? `Courtesy: ${props.businessName}`
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`text-gray-70 font-normal text-sm pt-2 ${
                        formState.message.length === 160 && "text-red-500"
                      }`}
                    >
                      {formState.message.length + "/160 characters"}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="py-3">
              <div>
                <div
                  className={`text-gray-70 font-semibold text-sm mb-2 ${
                    formState.errors.schedule && "text-red-500"
                  }`}
                >
                  Schedule
                </div>
                <div
                  className={`text-sm text-gray-500 ${
                    formState.errors.schedule && "text-red-500"
                  }`}
                >
                  When do you want to reward?
                </div>

                <div className="w-full flex gap-4 mt-4">
                  <div
                    className={`w-[49%] h-12 flex justify-between items-center px-6 border rounded-md hover:border-gray-300 cursor-pointer ${
                      formState.schedule === "now"
                        ? "border-blue-75 text-gray-900"
                        : "text-gray-300"
                    }`}
                    onClick={() => {
                      dispatch({
                        type: "CHANGE",
                        payload: {
                          name: "schedule",
                          value: "now",
                        },
                      });
                    }}
                  >
                    <div className="flex items-center">
                      <img className="-mt-1" src={RewardClock} alt="" />
                      <label
                        htmlFor="reward-now-radio"
                        className="w-full py-4 ml-3 font-medium dark:text-gray-300"
                      >
                        Reward now
                      </label>
                    </div>
                    <input
                      name="reward-now-radio"
                      id="reward-now-radio"
                      type="radio"
                      value=""
                      disabled={loading}
                      checked={formState.schedule === "now"}
                      onChange={() => {
                        dispatch({
                          type: "CHANGE",
                          payload: {
                            name: "schedule",
                            value: "now",
                          },
                        });
                      }}
                      className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 cursor-pointer"
                    />
                  </div>

                  <div
                    className={`w-[49%] h-12 flex justify-between items-center px-6 border rounded-md hover:border-gray-300 cursor-pointer ${
                      formState.schedule === "later"
                        ? "border-blue-75 text-gray-900"
                        : "text-gray-300"
                    }`}
                    onClick={() => {
                      dispatch({
                        type: "CHANGE",
                        payload: {
                          name: "schedule",
                          value: "later",
                        },
                      });
                    }}
                  >
                    <div className="flex items-center">
                      <img className="-mt-1" src={RewardCalendar} alt="" />
                      <label
                        htmlFor="reward-later-radio"
                        className="w-full py-4 ml-3 font-medium dark:text-gray-300"
                      >
                        Reward later
                      </label>
                    </div>
                    <input
                      name="reward-later-radio"
                      id="reward-later-radio"
                      type="radio"
                      value=""
                      disabled={loading}
                      checked={formState.schedule === "later"}
                      onChange={() => {
                        dispatch({
                          type: "CHANGE",
                          payload: {
                            name: "schedule",
                            value: "later",
                          },
                        });
                      }}
                      className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 cursor-pointer"
                    />
                  </div>
                </div>

                <div className="flex justify-between">
                  {formState.schedule === "later" && (
                    <ConfigProvider
                      theme={{
                        token: {
                          colorIcon: "#98A2B3",
                          colorBorder: "#D0D5DD",
                          colorPrimary: "#008138",
                          colorPrimaryHover: "#008138",
                          colorText: "#344054",
                          colorTextHeading: "#344054",
                          colorTextDisabled: "#D0D5DD",
                          colorTextLightSolid: "var(--main-color)",
                          colorTextPlaceholder: "#98A2B3",
                          controlItemBgActive: "#DAEEE1",
                          controlOutline: "#FFFFFF",
                        },
                      }}
                    >
                      <div className="w-full mt-6">
                        <div>
                          <div className="text-gray-70 font-semibold text-sm mb-1.5">
                            Push time
                            {startDate && (
                              <span className="font-light ml-1.5">
                                (on {dayjs(startDate).format("LLLL")})
                              </span>
                            )}
                          </div>

                          <div
                            onClick={() => {
                              if (!showCalendar) {
                                setShowCalendar(true);
                              }
                            }}
                          >
                            <DatePicker
                              className={`w-[49%] h-12 px-4 hover:border-gray-300 ${
                                (formState.errors.rewardDate ||
                                  formState.errors.rewardTime) &&
                                "border-red-500"
                              }`}
                              placeholder="Enter date"
                              disabled={loading}
                              value={
                                formState.rewardDate
                                  ? dayjs(formState.rewardDate, "YYYY-MM-DD")
                                  : undefined
                              }
                              defaultValue={
                                formState.rewardDate
                                  ? dayjs(formState.rewardDate, "YYYY-MM-DD")
                                  : undefined
                              }
                              format="YYYY-MM-DD"
                              onChange={onStartDateChange}
                              locale={locale}
                              open={showCalendar}
                              showNow={false}
                              showToday={false}
                              suffixIcon={<img src={CalendarIcon} />}
                              allowClear={false}
                              popupStyle={{ paddingBottom: "64px" }}
                              cellRender={(current) => {
                                const style: React.CSSProperties = {};
                                style.borderRadius = "50%";
                                style.width = "30px";
                                style.height = "30px";
                                style.lineHeight = "30px";
                                return (
                                  <div
                                    className="ant-picker-cell-inner"
                                    style={style}
                                  >
                                    {current.date()}
                                  </div>
                                );
                              }}
                              renderExtraFooter={() => {
                                return (
                                  <>
                                    <div className="flex justify-between items-end px-5">
                                      <input
                                        type="text"
                                        placeholder="Pick reward date"
                                        value={
                                          formState.rewardDate &&
                                          dayjs(formState.rewardDate).format(
                                            "MMM D, YYYY"
                                          )
                                        }
                                        className={`w-36 h-12 px-2 py-3 mt-3 text-[13.5px] border border-gray-300 rounded-md focus:outline-none ${
                                          formState.errors.rewardDate &&
                                          "border-red-500"
                                        }`}
                                        onChange={() => {}}
                                      />
                                      <TimePicker
                                        className={`h-12 ${
                                          formState.errors.rewardTime &&
                                          "border-red-500"
                                        }`}
                                        defaultValue={
                                          formState.rewardTime
                                            ? dayjs(
                                                formState.rewardTime,
                                                "HH:mm:ss"
                                              )
                                            : undefined
                                        }
                                        format="h:mm A"
                                        onChange={onStartTimeChange}
                                        use12Hours
                                        size="small"
                                        showNow={false}
                                        placement="bottomRight"
                                        inputReadOnly={true}
                                      />
                                    </div>
                                    <div className="pt-4 mt-80 bg-white rounded-b-lg drop-shadow-lg">
                                      <hr className="border-gray-300" />
                                      <div className="flex gap-4 p-4 rounded-b-lg">
                                        <button
                                          className="py-3 px-10 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
                                          onClick={() => setShowCalendar(false)}
                                        >
                                          Cancel
                                        </button>

                                        <button
                                          className="py-3 px-14 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75"
                                          onClick={() => {
                                            onStartDateSave();
                                          }}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                );
                              }}
                            />
                          </div>
                          {scheduleError && (
                            <div className="text-red-500 text-sm mt-2">
                              {scheduleError}
                            </div>
                          )}
                        </div>
                      </div>
                    </ConfigProvider>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[39%]">
          <div className="mt-3 ml-6 h-144 bg-gray-80 border border-gray-30 rounded-lg">
            <div className="pt-5 pb-3 px-6 text-gray-700 text-lg font-semibold">
              Preview
            </div>
            <hr className="mx-5 border-gray-50" />

            <div className="py-4 px-6">
              <div className="text-gray-400 text-3xl font-semibold flex gap-3 items-center">
                &bull;
                <div className="text-sm text-gray-70 font-bold">
                  {formState.rewardType === "AIRTIME"
                    ? "Airtime reward"
                    : "Data reward"}
                </div>
              </div>

              {formState.rewardPerProfile && (
                <div className="text-gray-400 text-3xl font-semibold flex gap-3 items-center">
                  &bull;
                  <div className="text-gray-700 text-xl font-semibold flex gap-1">
                    {currencySymbol} {formState.rewardPerProfile}
                    <div className="text-sm text-gray-500 mt-1 ml-1 font-normal">
                      per respondent
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <NotificationModal data={notificationData} />
      {subscriptionPromptDialogStatus && (
        <SubscriptionPromptDialog
          handleClick={(status) => {
            subscriptionPromptDialogHandleClick(status);
          }}
        />
      )}
    </div>
  );
};

export default RewardsStepTwo;
