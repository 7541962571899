import { Modal } from "semantic-ui-react";
import {
  Close,
  RightChevron,
  LeftChevron,
  Mobile,
  Desktop,
  SurveyLogoPlaceholder,
  TerragonLogo,
} from "../../../../assets/icons/icons";
import { useState } from "react";
import WebSurveyPreviewTypes from "./web-survey-preview-types/web-survey-preview-types";

const PREVIEW_PAGES = {
  introduction: "introduction",
  question: "question",
  exit: "exit",
};

const WebSurveyPreview = ({
  pages,
  introduction,
  imageUrl,
  exit,
  setStatus,
}) => {
  let questionCount = 1;
  pages.map((ele, i) => {
    ele.map((question, j) => {
      question.index = questionCount++;
    });
  });
  const [isMobile, setIsMobile] = useState(false);
  const [previewPage, setPreviewPage] = useState(PREVIEW_PAGES.introduction);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedPage, setSelectedPage] = useState(pages[0]);

  const isMobileOnChange = (status) => {
    setIsMobile(status);
  };

  const gotoPage = (pageNumber) => {
    setPageNumber(pageNumber);
    setSelectedPage(pages[pageNumber - 1]);
  };

  return (
    <div>
      <Modal open={true}>
        <div className="h-[651px] bg-[#F2F6FC] relative">
          <div className="flex justify-between items-center p-4">
            <div className="flex">
              <div
                onClick={() => isMobileOnChange(false)}
                className={
                  !isMobile
                    ? "h-[32px] w-[32px] bg-white border flex justify-center items-center"
                    : "h-[32px] w-[32px] flex justify-center items-center cursor-pointer"
                }
              >
                <img src={Desktop} alt="" />
              </div>
              <div
                onClick={() => isMobileOnChange(true)}
                className={
                  isMobile
                    ? "h-[32px] w-[32px] bg-white border flex justify-center items-center"
                    : "h-[32px] w-[32px] flex justify-center items-center cursor-pointer"
                }
              >
                <img src={Mobile} alt="" />
              </div>
            </div>
            <div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setStatus(false);
                }}
              >
                <img src={Close} alt="" />
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <div
              className={
                isMobile
                  ? "h-[575px] bg-white mt-4 mx-4 border border-b-0 rounded-t relative w-1/2 overflow-hidden"
                  : "h-[575px] bg-white mt-4 mx-4 border border-b-0 rounded-t relative w-full overflow-hidden"
              }
            >
              {previewPage === PREVIEW_PAGES.introduction ? (
                <div className="px-10 pt-4">
                  <div>
                    {imageUrl ? (
                      <img
                        className="h-[84px] w-[84px] rounded"
                        src={imageUrl}
                        alt=""
                      />
                    ) : (
                      <img src={SurveyLogoPlaceholder} alt="" />
                    )}
                    <div className="text-blue-75 text-3xl font-semibold mt-2">
                      {introduction.name ? introduction.name : "Survey Name"}
                    </div>
                    <div className="mt-2 text-blue-75 text-lg">
                      {introduction.description
                        ? introduction.description
                        : "Description"}
                    </div>
                    <div
                      onClick={() => setPreviewPage(PREVIEW_PAGES.question)}
                      className={
                        isMobile
                          ? "text-sm text-white bg-blue-75 h-[40px] w-full flex justify-center items-center mt-12 rounded font-semibold cursor-pointer"
                          : "text-sm text-white bg-blue-75 h-[40px] w-1/2 flex justify-center items-center mt-12 rounded font-semibold cursor-pointer"
                      }
                    >
                      {introduction.buttonLabel
                        ? introduction.buttonLabel
                        : "Click here"}
                    </div>
                  </div>
                </div>
              ) : null}
              {previewPage === PREVIEW_PAGES.question ? (
                <div className="h-[531px] overflow-auto pb-3 px-10">
                  {selectedPage.map((element, index) => {
                    return (
                      <div key={index} className="pt-11">
                        <div className="flex text-xl text-gray-700 gap-5">
                          <div>{element.index}.</div>
                          <div className="font-semibold">{element.q}</div>
                        </div>
                        <div className="text-base text-[#667085] mt-2">
                          {element.s}
                        </div>
                        <WebSurveyPreviewTypes question={element} />
                      </div>
                    );
                  })}
                  {pageNumber === pages.length ? (
                    <div className="flex justify-end mt-5">
                      <div
                        onClick={() => setPreviewPage(PREVIEW_PAGES.exit)}
                        className={
                          isMobile
                            ? "text-sm bg-blue-75 w-full font-semibold px-5 py-3 cursor-pointer rounded text-white flex justify-center"
                            : "text-sm bg-blue-75 font-semibold px-5 py-3 cursor-pointer rounded text-white"
                        }
                      >
                        Submit
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {previewPage === PREVIEW_PAGES.exit ? (
                <div className="flex justify-center items-center text-2xl text-blue-75 h-full">
                  {exit ? exit : "Thank you participating in this survey."}
                </div>
              ) : null}
              <div className="absolute left-0 bottom-0 flex justify-center w-full px-10">
                {previewPage === PREVIEW_PAGES.introduction ||
                previewPage === PREVIEW_PAGES.exit ? (
                  <div className="h-[44px] text-xs text-[#667085] flex items-center justify-center gap-2 border-t w-full">
                    Powered by <img src={TerragonLogo} alt="" /> Terragon Group
                  </div>
                ) : (
                  <div className="w-full h-[44px] flex justify-between items-center text-gray-700 text-sm border-t">
                    <div>Page {pageNumber}</div>
                    <div className="flex">
                      <div
                        onClick={
                          pageNumber === 1
                            ? null
                            : () => {
                                gotoPage(pageNumber - 1);
                              }
                        }
                        className="h-[32px] w-[32px] flex justify-center items-center cursor-pointer"
                      >
                        <img src={LeftChevron} alt="" />
                      </div>
                      <div
                        onClick={
                          pageNumber === pages.length
                            ? null
                            : () => {
                                gotoPage(pageNumber + 1);
                              }
                        }
                        className="h-[32px] w-[32px] flex justify-center items-center cursor-pointer"
                      >
                        <img src={RightChevron} alt="" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WebSurveyPreview;
