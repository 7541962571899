import { Close } from "../../../assets/icons/icons";

const DeletionModal = ({ closeModal, navigate, step }) => {
  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <div className="text-lg leading-6 font-semibold">
            {" "}
            Cancel campaign{" "}
          </div>
          <div onClick={() => closeModal(false)} className="cursor-pointer">
            {" "}
            <img src={Close} alt="" />
          </div>
        </div>
        <div className="text-sm leading-6 text-gray-500 max-w-sm mb-6">
          Are you sure you want to cancel the campaign?
          {step !== 1 &&
            `You can click on Save campaign to save the campaign to drafts instead, or ‘Cancel’ toproceed.`}
        </div>
        <div className="flex justify-end gap-4">
          {step !== 1 && (
            <button className="py-3 px-5 text-sm leading-4 rounded-md text-gray-600 font-semibold border border-slate-300">
              Save campaign
            </button>
          )}
          <button
            onClick={() => navigate("/engagements")}
            className="bg-red-200 py-3 px-5 text-sm leading-4 rounded-md text-white font-semibold"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletionModal;
