import { useCallback, useEffect, useRef, useState } from "react";
import { Provider } from "react-redux";
import {
  downloadRewardReport,
  emailRewardReport,
  getRewardRecepients,
  getRewardReport,
} from "../../common/services/reward-services";
import CurrencySymbol from "../../../../marketing-cloud-main/src/common/components/currency-symbol/currency-symbol";
import { debounce } from "../../common/utils/debounce";
import { ChevronDown, Search } from "react-feather";
import { colorCode } from "../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";
import ProfilesRewarded from "./profiles-rewarded";
import NetworkDistribution from "./network-distribution";
import Spinner from "../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import TelcoLogo from "../../assets/rewardReport/Telcos";
import { ConfigProvider, DatePicker } from "antd";
const { RangePicker } = DatePicker;
import locale from "antd/es/date-picker/locale/en_GB";
import OperatorDropdown from "./operatorDropdown";
import ApproveRejectCampaigns from "../../common/components/approve-reject-campaigns";
import { approveCampaign } from "../../common/services/message-services";
import { Notify } from "../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import * as Sentry from "@sentry/react";
import PaginationComp from "../../common/components/pagination";
import { downloadStringAsCSV } from "../../common/utils/fileDownload";
import {
  Balance,
  Budget,
  Clock,
  CsvDownload,
  DownArrow,
  EmailDownload,
  Info,
  Profiles,
  Schedule,
  Spent,
  Type,
} from "../../assets/icons/icons";
import loaderStore from "../../../../marketing-cloud-main/src/redux-loader/loader-store/loaderStore";

const RewardReports = (props) => {
  let {
    setRewardName,
    dispatch,
    setShowCommonLoader,
    country,
    hasSuperAdminAccess = false,
  } = props;
  const [report, setReport]: any = useState({});

  const [selectedTab, setSelectedTab] = useState("Successful");

  const [operator, setOperator] = useState("");
  const [msisdn, setMsisdn] = useState(null);
  const [loading, setLoading] = useState(false);
  const [recepientList, setRecepientList] = useState([]);
  const [totalListLength, setTotalListLength] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [resetPagination, setResetPagination] = useState(false);
  const [reportDetails, setReportDetails] = useState(null);
  const [showDownloadMenu, setShowDownloadMenu] = useState(false);
  const downloadMenu = useRef(null);

  const closeOpenMenus = (e) => {
    if (
      downloadMenu.current &&
      showDownloadMenu &&
      !downloadMenu.current.contains(e.target)
    ) {
      setShowDownloadMenu(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  const getReport = () => {
    dispatch(setShowCommonLoader(true));
    try {
      getRewardReport(props.id)
        .then((res) => {
          const { error, response = {} } = res;
          if (!error || error === "false") {
            dispatch(setShowCommonLoader(false));
            setReport(response);
            setRewardName(response.name);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })

        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          dispatch(setShowCommonLoader(false));
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      dispatch(setShowCommonLoader(false));
    }
  };

  const approveCampaigns = (statusApproval: String, reason: String) => {
    const payload = {
      approvalStatus: statusApproval,
      campaignType: "REWARD",
      campaignId: props.id,
      reason,
    };
    try {
      approveCampaign({ payload })
        .then((res) => {
          const { error, response } = res;
          if (!error) {
            Notify({
              type: "success",
              heading: "Reward Approved",
              message: `${report.name} reward approved. ${report?.name} was approved successfully for launch.`,
            });
            getReport();
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  const msisdnOnChange = (value) => {
    setMsisdn(value);
  };

  const handleMsisdnSearch = useCallback(debounce(msisdnOnChange), []);

  const getRecepients = (activePage, itemsPerPage) => {
    // dispatch(setShowCommonLoader(true));
    setLoading(true);
    try {
      getRewardRecepients(activePage, itemsPerPage, {
        campaignId: props.id,
        status: selectedTab,
        startDate: startDate,
        endDate: endDate,
        operator: operator.toLowerCase(),
        msisdn: msisdn,
      })
        .then((res) => {
          setLoading(false);
          const { error, response = [] } = res;
          if (!error || error === "false") {
            setRecepientList(response.data);
            setTotalListLength(response.totalRecipients);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })

        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          setLoading(false);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      setLoading(false);
    }
  };

  const downloadReportDetails = () => {
    setLoading(true);
    try {
      downloadRewardReport(props.id)
        .then((response) => {
          setLoading(false);
          setReportDetails(response);
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          setLoading(false);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      setLoading(false);
    }
  };

  const emailReportDetails = () => {
    setLoading(true);
    try {
      emailRewardReport(props.id)
        .then((response) => {
          setLoading(false);
          Notify({
            type: "success",
            heading: "Report sent to email",
            message: response?.message,
          });
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          setLoading(false);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      setLoading(false);
    }
    setShowDownloadMenu(false);
  };

  useEffect(() => {
    getReport();
    downloadReportDetails();
  }, []);

  useEffect(() => {
    setResetPagination(true);
    getRecepients(0, 10);
  }, [operator, startDate, endDate, msisdn, selectedTab]);

  const onRangeSelectionChange = (event) => {
    let sDate = new Date(event[0].$d);
    let eDate = new Date(event[1].$d);
    sDate.setHours(0, 0, 0, 0);
    eDate.setHours(0, 0, 0, 0);
    setStartDate(sDate.getTime().toString());
    setEndDate(eDate.getTime().toString());
  };

  const downloadReport = () => {
    if (!reportDetails) {
      return;
    }
    downloadStringAsCSV(reportDetails, report?.name);
    setShowDownloadMenu(false);
  };

  const downoadTooltip = () => {
    return (
      <div className="absolute hidden group-hover:flex">
        <div className="w-[206px] px-3 py-2 z-10 -mt-16 -ml-[212px] bg-gray-900 text-white text-[10px] font-normal rounded-lg shadow-lg">
          Reports with up to{" "}
          <span className="font-semibold">500 recipients</span> can be
          downloaded directly as CSV.
          <br />
          Larger rewards will be sent via email.
        </div>
        <div className="w-4 h-4 -mt-[30px] -ml-3 bg-gray-900 rotate-45"></div>
      </div>
    );
  };

  return (
    <Provider store={loaderStore}>
      <div className="px-10 py-8">
        {Object.keys(report).length === 0 ? (
          <div className="text-center p-3">No Data to show</div>
        ) : (
          <>
            <div className="mb-4">
              <div className="flex justify-between items-center mb-11">
                <div className="flex gap-3 items-center">
                  <div className="font-semibold text-xl">{report?.name}</div>
                  <div
                    className={`capitalize ${colorCode(
                      report?.status
                    )} px-2 py-0.5 rounded-2xl text-xs font-medium`}
                  >
                    {report.status?.toLowerCase()}
                  </div>
                  <ApproveRejectCampaigns
                    hasSuperAdminAccess={hasSuperAdminAccess}
                    status={report?.status}
                    approveCampaigns={approveCampaigns}
                  />
                </div>
                <div
                  className="w-[169px] flex relative gap-3 px-5 py-3 text-sm font-semibold border border-gray-40 hover:border-gray-200 rounded-md cursor-pointer"
                  onClick={() => setShowDownloadMenu(!showDownloadMenu)}
                >
                  <div>Download report</div>
                  <ChevronDown className="text-xl" size="18" />

                  {/* Download Report dropdown menu */}
                  {showDownloadMenu && (
                    <>
                      <div
                        ref={downloadMenu}
                        className="absolute z-10 mt-9 -ml-5 bg-white rounded-lg shadow"
                      >
                        {/* Downloading reports as CSV will be only available if the recipients count is 500 or less */}
                        <div className="group">
                          <button
                            className="w-[169px] flex justify-between items-center p-4 text-gray-700 hover:bg-gray-10 text-left"
                            role="menuitem"
                            disabled={totalListLength > 500}
                            onClick={downloadReport}
                          >
                            <div
                              className={`flex gap-2.5 items-center ${
                                totalListLength > 500 && "opacity-40"
                              }`}
                            >
                              <img src={CsvDownload} alt="" />
                              <div>As CSV</div>
                            </div>
                            {totalListLength > 500 && <img src={Info} alt="" />}
                          </button>

                          {totalListLength > 500 && downoadTooltip()}
                        </div>

                        {/* Emailing reports will be only available if the recipients count is more than 500 */}
                        <div className="group">
                          <button
                            className="w-[169px] flex justify-between items-center p-4 text-gray-700 hover:bg-gray-10 text-left"
                            role="menuitem"
                            disabled={totalListLength <= 500}
                            onClick={emailReportDetails}
                          >
                            <div
                              className={`flex gap-2.5 items-center ${
                                totalListLength <= 500 && "opacity-40"
                              }`}
                            >
                              <img src={EmailDownload} alt="" />
                              <div>To Email</div>
                            </div>
                            {totalListLength <= 500 && (
                              <img src={Info} alt="" />
                            )}
                          </button>
                          {totalListLength <= 500 && downoadTooltip()}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="mb-6">
                <div className="flex items-center justify-between text-base font-semibold text-gray-70">
                  <div>Reward details</div>
                  <hr className="w-[85%] h-1 mt-1.5" />
                </div>
              </div>
              <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 gap-y-8 py-8">
                <div className="flex gap-6">
                  <img src={Budget} alt="" />
                  <div className="text-sm leading-6">
                    <div className="font-semibold">Reward budget</div>
                    <div>
                      <CurrencySymbol value={report.rewardBudget} />{" "}
                    </div>
                  </div>
                </div>
                <div className="flex gap-6">
                  <img src={Type} alt="" />
                  <div className="text-sm leading-6">
                    <div className="font-semibold">Reward type</div>
                    <div>{report.rewardDetails?.rewardType}</div>
                  </div>
                </div>
                <div className="flex gap-6">
                  <img src={Spent} alt="" />
                  <div className="text-sm leading-6">
                    <div className="font-semibold">Amount spent</div>
                    <div>
                      <CurrencySymbol value={report.amountSpent} />{" "}
                    </div>
                  </div>
                </div>

                <div className="flex gap-6">
                  <img src={Balance} alt="" />
                  <div className="text-sm leading-6">
                    <div className="font-semibold">Reward balance</div>
                    <div>
                      <CurrencySymbol
                        value={report.rewardBalance ? report.rewardBalance : 0}
                      />{" "}
                    </div>
                  </div>
                </div>
                <div className="flex gap-6">
                  <img src={Balance} alt="" />
                  <div className="text-sm leading-6">
                    <div className="font-semibold">Reward per profiles</div>
                    <div>
                      <CurrencySymbol value={report.rewardPerProfile} />
                    </div>
                  </div>
                </div>
                <div className="flex gap-6">
                  <img src={Profiles} alt="" />
                  <div className="text-sm leading-6">
                    <div className="font-semibold">Profiles</div>
                    <div>{report.profiles}</div>
                  </div>
                </div>

                <div className="flex gap-6">
                  <img src={Schedule} alt="" />
                  <div className="text-sm leading-6">
                    <div className="font-semibold">Date created</div>
                    <div>
                      {new Date(report.createdAt).toDateString().substring(4)}
                    </div>
                  </div>
                </div>
                <div className="flex gap-6">
                  <img src={Schedule} alt="" />
                  <div className="text-sm leading-6">
                    <div className="font-semibold">Reward date</div>
                    <div>
                      {new Date(
                        report.schedule?.immediate
                          ? report.createdAt
                          : report.schedule?.timeline?.start
                      )
                        .toDateString()
                        .substring(4)}
                    </div>
                  </div>
                </div>
                <div className="flex gap-6">
                  <img src={Clock} alt="" />
                  <div className="text-sm leading-6">
                    <div className="font-semibold">Push time</div>
                    <div>
                      {new Date(
                        report.schedule?.immediate
                          ? report.createdAt
                          : report.schedule?.timeline?.start
                      ).toLocaleTimeString("en-US")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {report && (
              <div className="flex flex-col lg:flex-row justify-between items-center mb-8">
                <ProfilesRewarded profilesReport={report?.report} />
                <NetworkDistribution
                  networkReport={report?.report?.network_distribution}
                />
              </div>
            )}

            <div>
              <div className="flex gap-6 border-b border-[ #EBEFF3]">
                {["Successful", "Failed"].map((el, i) => (
                  <div
                    className={`text-sm leading-6 cursor-pointer py-2 ${
                      selectedTab === el
                        ? "border-b-2 border-blue-75 font-semibold text-blue-75"
                        : "text-gray-500"
                    }`}
                    key={i}
                    onClick={() => {
                      setSelectedTab(el);
                    }}
                  >
                    {el}
                  </div>
                ))}
              </div>
              <div className="my-7 flex justify-between">
                <div>
                  <div className="flex border border-gray-300 rounded-lg">
                    <input
                      type="text"
                      placeholder="Search MSISDN"
                      className="w-full h-10 pt-0.5 pr-7 ml-2 focus:border-none font-normal focus:outline-none "
                      onChange={(event) => {
                        handleMsisdnSearch(event.target.value);
                      }}
                    />
                    <div className="w-10 h-10 text-gray-400">
                      <Search className="text-xl m-3" size="16" />
                    </div>
                  </div>
                </div>
                <div className="flex gap-3">
                  <div>
                    <OperatorDropdown
                      selectedOperator={operator}
                      setSelectedOperator={setOperator}
                      country={country}
                    />
                  </div>

                  <div className="h-[40px] w-[100px] py-3 px-5 bg-[#F6F9FC] text-[#475467] font-semibold flex rounded-full items-center cursor-pointer relative">
                    <div className="absolute left-0 top-0 h-[40px] w-[100px] bg-[#F6F9FC] rounded-full z-10 flex items-center cursor-pointer gap-3 justify-center pointer-events-none">
                      <span className="cursor-pointer text-sm leading-4 font-semibold text-gray-70">
                        Date
                      </span>
                      <img src={DownArrow} alt="" />
                    </div>
                    <div>
                      {/* <RangePicker className="invisible" size={"middle"} /> */}
                      <ConfigProvider
                        theme={{
                          token: {
                            colorIcon: "#98A2B3",
                            colorBorder: "#D0D5DD",
                            colorPrimary: "#008138",
                            colorPrimaryHover: "#008138",
                            colorText: "#344054",
                            colorTextHeading: "#344054",
                            colorTextDisabled: "#D0D5DD",
                            colorTextLightSolid: "var(--main-color)",
                            colorTextPlaceholder: "#98A2B3",
                            controlItemBgActive: "#DAEEE1",
                            controlOutline: "#FFFFFF",
                            colorBgTextActive: "FFFFFF",
                            colorTextLabel: "#FFFFFF",
                          },
                        }}
                      >
                        <RangePicker
                          className="outline-none border-none bg-[#F6F9FC] text-[#F6F9FC] shadow-none focus:{outline-none border-none bg-[#F6F9FC] shadow-none text-[#F6F9FC]} placeholder:{text-[#F6F9FC]}"
                          onChange={onRangeSelectionChange}
                          locale={locale}
                          showNow={false}
                          allowClear={false}
                          cellRender={(current, info) => {
                            if (info.type !== "date") return info.originNode;
                            const style: React.CSSProperties = {};
                            style.borderRadius = "50%";
                            style.width = "30px";
                            style.height = "30px";
                            style.lineHeight = "30px";
                            return (
                              <div
                                className="ant-picker-cell-inner"
                                style={style}
                              >
                                {current.date()}
                              </div>
                            );
                          }}
                        />
                      </ConfigProvider>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 relative">
                {loading && (
                  <div className="absolute left-0 top-0 right-0 bottom-0 z-40 bg-black opacity-10 py-10 w-full flex justify-center">
                    <Spinner className={"w-6 h-6"} />
                  </div>
                )}
                {recepientList.length === 0 ? (
                  <div className="w-full p-24">
                    <div className="flex flex-col w-full bg-gray-25 text-gray-90 text-center items-center p-6 border border-gray-200 rounded-lg">
                      <div className="mt-2">No Data to Show.</div>
                    </div>
                  </div>
                ) : (
                  <table className="min-w-full">
                    <thead>
                      <tr>
                        {[
                          "Date rewarded",
                          "Operator",
                          "MSISDN",
                          "Reason",
                          "Attempts",
                        ].map((item, index) => (
                          <th
                            key={index}
                            className={`${
                              index === 2 ? "w-[35%]" : ""
                            } py-3 border-b border-gray-100 text-left text-gray-700 ${
                              selectedTab === "Successful" && index > 2
                                ? "hidden"
                                : ""
                            }`}
                          >
                            <div key={index}>{item}</div>
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {recepientList?.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className="border-b border-gray-100 text-gray-70 cursor-pointer"
                          >
                            <td className="py-7 text-base leading-6">
                              {new Date(item.timestamp)
                                .toDateString()
                                .substring(4)}
                            </td>
                            <td className="text-base leading-6">
                              <div className="flex items-center gap-2">
                                <TelcoLogo operator={item.operator} />
                                {item.operator}
                              </div>
                            </td>
                            <td>
                              <div
                                className={`${
                                  report?.segment?.audience_source ===
                                    "terragon" && "blur-sm"
                                }`}
                              >
                                {report?.segment?.audience_source === "terragon"
                                  ? "*********"
                                  : item.msisdn}
                              </div>
                            </td>
                            <td
                              className={`${
                                selectedTab === "Successful" ? "hidden" : ""
                              }`}
                            >
                              {item?.reason}
                            </td>
                            <td
                              className={`${
                                selectedTab === "Successful" ? "hidden" : ""
                              }`}
                            >
                              {item?.retry_count}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
                {recepientList.length !== 0 && (
                  <div className="mt-8">
                    <PaginationComp
                      onPageChange={(from, size) => {
                        if (resetPagination) setResetPagination(false);
                        getRecepients(from, size);
                      }}
                      reset={resetPagination}
                      totalCount={totalListLength}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Provider>
  );
};

export default RewardReports;
