import sanitizeHtml from "sanitize-html";
import { Close, LoudSpeaker, PhoneSms } from "../../../assets/icons/icons";

const CampaignPreviewModal = ({ text, url, closeModal, sender }) => {
  return (
    <div className="h-[100vh] w-[100vw] bg-gray-712 flex justify-center items-center absolute top-0 left-0">
      <div className="w-[404px] bg-white rounded-lg shadow-md p-8 h-[650px]">
        <div className="flex justify-between">
          <div className="flex items-center text-lg leading-7 font-semibold">
            Campaign preview
          </div>
          <div
            className="cursor-pointer flex items-center justify-center"
            onClick={closeModal}
          >
            <img src={Close} alt="" />
          </div>
        </div>
        <div className="mt-8 bg-green-708 border-gray-100 h-[542px] relative overflow-hidden rounded-lg">
          <div className="flex justify-end">
            <div className="h-[56px] w-[56px] rounded-full m-4 mb-0 bg-[#E7F3ED] flex justify-center items-center">
              <img src={LoudSpeaker} alt="" />
            </div>
          </div>
          <div className="absolute bg-[#12B76A17] h-[822px] w-[822px] top-[212px] left-[-267px] rounded-full z-0"></div>
          <div className="absolute bg-[#DBF2E9] h-[731px] w-[731px] top-[320px] left-[-328px] rounded-full z-10 opacity-80"></div>
          <div className="absolute z-20 w-full flex justify-center top-[100px]">
            <img src={PhoneSms} alt="" />
            <div className="absolute z-30 p-2 rounded-md bg-[#E5E5EA] top-[125px] left-[65px] w-[200px] overflow-hidden">
              <div
                className="text-xs text-black break-words"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(text),
                }}
              ></div>
              <div className="text-xs text-[#0068D9] break-words">{url}</div>
            </div>
            <div className="text-xs absolute top-[86px]">{sender}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignPreviewModal;
