import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useState } from "react";
import { LeftChevronReport } from "../../../assets/icons/icons";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PDFViewer = ({ pdfURL }) => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const onDocumentLoad = (event) => {
    console.log(event);
    let pageCount = event._pdfInfo.numPages;
    setPageCount(pageCount);
  };

  const updatePageNumber = (type) => {
    if (type) {
      let pageNumber = page + 1;
      if (pageNumber <= pageCount) setPage(pageNumber);
    } else {
      let pageNumber = page - 1;
      if (pageNumber > 0) setPage(pageNumber);
    }
  };

  return (
    <div className="flex justify-center">
      <div className="flex items-center gap-[60px]">
        {pageCount > 0 && (
          <div
            className="cursor-pointer"
            onClick={() => {
              updatePageNumber(false);
            }}
          >
            <img src={LeftChevronReport} alt="" />
          </div>
        )}
        <Document
          file={pdfURL}
          error="Failed to load data"
          onLoadSuccess={onDocumentLoad}
        >
          {/* {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((page) => ( */}
          <Page
            // key={page}
            pageNumber={page}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
          {/* ))} */}
        </Document>
        {pageCount > 0 && (
          <div
            className="cursor-pointer rotate-180"
            onClick={() => {
              updatePageNumber(true);
            }}
          >
            <img src={LeftChevronReport} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};
export default PDFViewer;
