import "./index.css";

import { Close } from "../../../assets/icons/icons";
import { ImageHolder } from "../../../assets/icons/icons";
import { DocumentPlaceholder } from "../../../assets/icons/icons";

const TemplatesList = (props: any) => {
  const {
    firstOpen,
    setFirstOpen,
    activeTemplate,
    setActivetemplate,
    switchScreens,
    activeNav,
    setActiveNav,
    template = [],
    navbarTitles,
    setText,
    loading,
  } = props;

  return (
    <div
      // onClose={() => setFirstOpen(false)}
      // onOpen={() => setFirstOpen(true)}
      // open={firstOpen}
      className="absolute top-0 left-0 h-full w-full flex justify-center items-center bg-dialog-overlay z-10"
      // centered
    >
      <div className="bg-white h-[500px] w-[700px] rounded-md z-10 p-8">
        <div className="flex justify-between align-items-center">
          <div className="template-heading font-semibold leading-7 text-xl  text-[ #344054]">
            Message Templates
          </div>
          <div>
            <div
              className="p-1 hover:rounded-full hover:bg-slate-100 hover:cursor-pointer"
              onClick={() => setFirstOpen(false)}
            >
              <img src={Close} alt="" />
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex items-center justify-center my-32">
            <div
              className="  inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : (
          <>
            <div className="mt-6">
              <ul className="flex gap-6">
                {navbarTitles.map((navbarTitle: any, index: any) => {
                  return (
                    <li
                      key={navbarTitle.text}
                      onClick={() => {
                        setActiveNav(index);
                      }}
                      className={`text-sm font-semibold leading-6 ${
                        index == activeNav
                          ? "text-primary-green border-b-2 border-primary-green cursor-default"
                          : "cursor-pointer text-gray-500"
                      }`}
                    >
                      {navbarTitle.text}
                    </li>
                  );
                })}
              </ul>
              <div>
                <div className="mt-6 border border-gray-40 rounded w-full h-[40px] p-1">
                  <input
                    type="text"
                    placeholder="Search template"
                    className="border-none outline-none w-full h-full text-sm"
                  />
                </div>
              </div>
              <div className=" mt-3 h-[250px]">
                {template.length === 0 ? (
                  <div className="h-full w-full flex justify-center items-center text-sm text-gray-500">
                    No {navbarTitles[activeNav].text} Templates found
                  </div>
                ) : (
                  <div className="flex h-full w-full">
                    <div className="w-1/4 overflow-hidden h-full">
                      <div className="h-full overflow-x-auto">
                        <ul>
                          {template.map((value: any, index: any) => {
                            return (
                              <li
                                className={`text-sm font-semibold py-3 px-4 rounded leading-6 ${
                                  index === activeTemplate
                                    ? "text-primary-green bg-overlay-green cursor-default"
                                    : "text-gray-700 cursor-pointer"
                                }`}
                                onClick={() => {
                                  setActivetemplate(index);
                                }}
                                key={index}
                              >
                                {value.name}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="w-3/4 px-6 py-2">
                      <div>
                        {template.length > 0 && template[activeTemplate].text}
                      </div>
                      {template[activeTemplate]?.type === "IMAGE" && (
                        <div className=" pt-[10px]">
                          <img src={ImageHolder} alt="" />
                          <button className=" mt-4 border px-6 py-2 rounded-md font-semibold text-xs text-gray-500">
                            Take survey
                          </button>
                        </div>
                      )}
                      {(template[activeTemplate]?.type === "DOCUMENT" ||
                        template[activeTemplate]?.type === "VIDEO") && (
                        <div className=" pt-[10px]">
                          <img src={DocumentPlaceholder} alt="" />
                          <button className=" mt-4 border px-6 py-2 rounded-md font-semibold text-xs text-gray-500">
                            Take survey
                          </button>
                          <button className=" mt-4 ml-4 border px-6 py-2 rounded-md font-semibold text-xs text-gray-500">
                            Go to Online Store
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-end gap-3 mt-6">
                <button
                  className="h-[40px] flex justify-center items-center rounded border border-gray-40 cursor-pointer text-gray-70 text-sm px-5"
                  onClick={() => {
                    setFirstOpen(false);
                    setText("");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn bg-blue-75 hover:bg-green-800 text-white h-[40px] cursor-pointer rounded text-sm px-5"
                  onClick={() => {
                    setFirstOpen(false);
                    setText(template[activeTemplate]);
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TemplatesList;
