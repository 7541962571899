import { useEffect, useRef, useState } from "react";
import { Provider } from "react-redux";
import DOMPurify from "dompurify";
import * as Sentry from "@sentry/react";
import {
  CalendarIcon,
  Cash,
  Channel,
  Clock,
  Fisend,
  Megaphone,
  MessageBox,
  Users,
  RewardName,
  PhoneFrame,
  MarketingIcon,
  SenderIcon,
} from "../../assets/icons/icons";
import {
  approveCampaign,
  getMessageDetails as getMessageDetail,
} from "../../common/services/message-services";
import EmailPreview from "./email-preview";
import { colorCode } from "../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";
import { Notify } from "../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import ApproveRejectCampaigns from "../../common/components/approve-reject-campaigns";
import loaderStore from "../../../../marketing-cloud-main/src/redux-loader/loader-store/loaderStore";

const CampaignReview = (props: any) => {
  const { dispatch, setShowCommonLoader, hasSuperAdminAccess = false } = props;

  const [messageData, setMessageData] = useState(null);

  const pathname = window.location.pathname;
  const id = pathname.split("engagement-review/")[1];

  useEffect(() => {
    getMessageDetails();
  }, [id]);

  const getMessageDetails = () => {
    try {
      dispatch(setShowCommonLoader(true));
      getMessageDetail(props.id)
        .then((res) => {
          const { error, response } = res;
          if (!error) {
            setMessageData(response);
            dispatch(setShowCommonLoader(false));
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
            dispatch(setShowCommonLoader(false));
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          dispatch(setShowCommonLoader(false));
          console.log(err);
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      dispatch(setShowCommonLoader(false));
      console.log(error);
    }
  };

  const imgRef = useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const imgElement = imgRef.current;
    if (imgElement) {
      const handleLoad = () => setLoaded(true);

      if (imgElement.complete) {
        handleLoad();
      } else {
        imgElement.addEventListener("load", handleLoad);
        return () => {
          imgElement.removeEventListener("load", handleLoad);
        };
      }
    }
  }, []);

  const approveCampaigns = (statusApproval: String, reason: String) => {
    const payload = {
      approvalStatus: statusApproval,
      campaignType: "MESSAGE",
      campaignId: props.id,
      reason,
    };

    try {
      approveCampaign({ payload })
        .then((res) => {
          const { error, response } = res;
          if (!error) {
            Notify({
              type: "success",
              heading: response,
              message: response.toLowerCase().includes("approve")
                ? `${messageData?.channel} campaign was approved successfully for launch.`
                : `${messageData?.channel} campaign was rejected successfully.`,
            });

            getMessageDetails();
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  const generatePreviewMessage = () => {
    if (!messageData?.sampleData || messageData?.sampleData?.length === 0) {
      return messageData?.content?.text.replace(/\n/g, "<br>");
    }

    const userdata = messageData?.sampleData[0];

    const previewMessage = messageData?.content?.text.replace(
      /{{(.*?)}}/g,
      (_, key) => {
        return userdata[key] || "";
      }
    );

    return previewMessage.replace(/\n/g, "<br>");
  };

  const sanitizedMessage = DOMPurify.sanitize(generatePreviewMessage());

  const capitalizeFirstLetter = (str: string) => {
    str = str.trim().toLowerCase();
    const status = str.charAt(0).toUpperCase() + str.slice(1);
    return status;
  };

  return (
    <Provider store={loaderStore}>
      <div>
        {messageData && (
          <div className="my-11 mx-10 grid grid-cols-3 gap-10">
            <div className="col-span-2 gap-8 flex flex-col">
              <div>
                <div className="flex w-full justify-between">
                  <div className="flex gap-7">
                    <div className="text-base text-gray-700">
                      {messageData?.name}
                    </div>
                    <div
                      className={`${colorCode(
                        messageData?.status
                      )} font-medium text-xs h-fit leading-5 px-3 py-1 rounded-2xl`}
                    >
                      <div>{capitalizeFirstLetter(messageData.status)}</div>
                    </div>
                  </div>

                  <ApproveRejectCampaigns
                    hasSuperAdminAccess={hasSuperAdminAccess}
                    status={messageData?.status}
                    approveCampaigns={approveCampaigns}
                    id={props?.id}
                  />
                </div>
              </div>
              <div className="py-4 flex flex-col gap-6">
                <div className="text-sm text-gray-700 weight font-semibold">
                  Message
                </div>

                <div className="w-full gap-6">
                  <div className="mt-6 w-full flex items-start gap-10">
                    <div className="w-full text-sm">
                      <div>
                        <div className="flex gap-16 justify-start">
                          <div className="flex gap-4 mb-6 w-1/2">
                            {messageData.channel == "EMAIL" ? (
                              <img src={Megaphone} alt="" />
                            ) : (
                              <img src={RewardName} alt="" />
                            )}
                            <div>
                              <span className="block font-semibold text-gray-70">
                                Campaign name
                              </span>
                              <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                                {messageData?.name}
                              </span>
                            </div>
                          </div>
                          <div className="flex gap-4 mb-6">
                            <div className="h-[36px] w-[36px] flex justify-center items-center rounded-full bg-gray-709">
                              {messageData.channel == "EMAIL" ? (
                                <img src={Channel} alt="" />
                              ) : (
                                <img src={Users} alt="" />
                              )}
                            </div>
                            <div>
                              <span className="block font-semibold text-gray-70">
                                Campaign channel
                              </span>
                              <span className="mt-1 inline-block break-words text-gray-500">
                                {messageData?.channel}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-16 justify-start mt-2.5">
                          <div className="flex gap-4 mb-6 w-1/2">
                            <div className="h-[36px] w-[36px] flex justify-center items-center rounded-full bg-gray-709">
                              {messageData.channel == "EMAIL" ? (
                                <img src={Fisend} alt="" />
                              ) : (
                                <img src={Fisend} alt="" />
                              )}
                            </div>
                            <div>
                              <span className="block font-semibold text-gray-70">
                                {messageData.channel == "EMAIL"
                                  ? "Sender email"
                                  : "Objective"}
                              </span>
                              {messageData.channel == "EMAIL" ? (
                                <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                                  {messageData?.senderID}
                                </span>
                              ) : (
                                <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                                  {messageData?.objective}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="flex gap-4 mb-6">
                            {messageData.channel == "EMAIL" ? (
                              <img src={MessageBox} alt="" />
                            ) : (
                              <img src={CalendarIcon} alt="" />
                            )}

                            <div>
                              <span className="block font-semibold text-gray-70">
                                Date
                              </span>
                              <span className="mt-1 inline-block break-words text-gray-500">
                                {messageData?.schedule?.immediate
                                  ? messageData?.createdAt
                                    ? new Date(
                                        messageData?.createdAt
                                      ).toLocaleDateString("en-GB")
                                    : "-"
                                  : new Date(
                                      messageData?.schedule?.timeline.start
                                    ).toLocaleDateString("en-GB")}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="flex gap-16 justify-start mt-2.5">
                          <div className="flex gap-4 mb-6 w-1/2">
                            <div className="flex items-start">
                              {messageData.channel == "EMAIL" ? (
                                <img src={MessageBox} alt="" />
                              ) : (
                                <img src={Fisend} alt="" />
                              )}
                            </div>
                            <div>
                              <span className="block font-semibold text-gray-70">
                                {messageData.channel == "EMAIL"
                                  ? " Email subject"
                                  : messageData.channel == "RCS"
                                  ? "Template"
                                  : "Message"}
                              </span>
                              {messageData.channel == "EMAIL" && (
                                <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                                  {messageData?.subject}
                                </span>
                              )}
                              {messageData.channel == "RCS" && (
                                <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                                  {messageData?.rcsTemplate}
                                </span>
                              )}
                              {messageData.channel !== "EMAIL" &&
                                messageData.channel !== "RCS" && (
                                  <div
                                    className="mt-1 inline-block text-gray-500 break-words max-w-[250px]"
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                        messageData?.content?.text?.replace(
                                          /\n/g,
                                          "<br>"
                                        )
                                      ),
                                    }}
                                  ></div>
                                )}
                            </div>
                          </div>
                          <div className="flex gap-4 mb-6">
                            <div className="flex items-start">
                              <img src={Clock} alt="" />
                            </div>
                            <div>
                              <span className="block font-semibold text-gray-70">
                                {messageData.channel == "EMAIL"
                                  ? "Campaign timeline"
                                  : "Push time"}
                              </span>
                              <span className="mt-1 inline-block break-words text-gray-500">
                                {messageData?.schedule?.immediate
                                  ? "Immediate"
                                  : new Date(
                                      messageData?.schedule?.timeline.start
                                    ).toLocaleTimeString()}
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* {messageData.channel === "EMAIL" && (
                    <div className="flex gap-16 justify-start mt-2.5">
                      <div className="flex gap-4 mb-6 w-1/2">
                        <MessageBox />

                        <div>
                          <span className="block font-semibold text-gray-70">
                            Email body
                          </span>
                          <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                            {messageData?.content?.text}
                          </span>
                        </div>
                      </div>
                    </div>
                  )} */}
                      </div>

                      <div className="mt-6">
                        <div className="mb-6">
                          <span className="text-gray-700 font-semibold mr-4">
                            Participants
                          </span>
                        </div>

                        <div className="flex gap-16 justify-start mt-2.5">
                          <div className="flex gap-4 mb-6 w-1/2">
                            <div className="h-[36px] w-[36px] flex justify-center items-center rounded-full bg-gray-709">
                              <img src={Users} alt="" />
                            </div>
                            <div>
                              <span className="block font-semibold text-gray-70">
                                Audience
                              </span>
                              <span className="mt-1 text-gray-500">
                                {messageData?.audience?.segmentId
                                  ? messageData?.audience?.segmentId === "/"
                                    ? "Custom Audience"
                                    : "Audience Segment"
                                  : "Custom Audience"}
                              </span>
                            </div>
                          </div>
                          <div className="flex gap-4 mb-6">
                            <div className="h-[36px] w-[36px] flex justify-center items-center rounded-full bg-gray-709">
                              <img src={Users} alt="" />
                            </div>
                            <div>
                              <span className="block font-semibold text-gray-70">
                                Profiles
                              </span>
                              <span className="mt-1 inline-block break-words text-gray-500">
                                {messageData?.budget}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-6">
                        <div className="mb-6">
                          <span className="text-gray-700 font-semibold mr-4">
                            Pricing
                          </span>
                        </div>
                        <div className="flex gap-16 justify-start mt-2.5">
                          <div className="flex gap-4 mb-6 w-1/2">
                            <div className="h-[36px] w-[36px] flex justify-center items-center rounded-full bg-gray-709">
                              <img src={Cash} alt="" />
                            </div>
                            <div>
                              <span className="block font-semibold text-gray-70">
                                Unit price
                              </span>
                              <span className="mt-1 text-gray-500">
                                {props.currencySymbol}
                                {messageData?.unitPrice || 0}
                              </span>
                            </div>
                          </div>
                          <div className="flex gap-4 mb-6">
                            <div className="h-[36px] w-[36px] flex justify-center items-center rounded-full bg-gray-709">
                              <img src={Cash} alt="" />
                            </div>
                            <div>
                              <span className="block font-semibold text-gray-70">
                                Total pricing
                              </span>
                              <span className="mt-1 inline-block break-words text-gray-500">
                                {props.currencySymbol}
                                {messageData?.spend || 0}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-1">
              <div className="relative flex flex-col justify-center items-center">
                {(messageData.channel.toLowerCase() == "sms" ||
                  messageData.channel.toLowerCase() == "whatsapp") && (
                  <div
                    className={`absolute top-16 flex-col items-center justify-center transition-opacity duration-250 ease-in-out ${
                      // loaded ? "flex" : "hidden"
                      loaded ? "flex" : "flex"
                    }`}
                  >
                    <img src={MarketingIcon} alt="" />
                    <img className="mt-28" src={SenderIcon} alt="" />

                    <div className="text-gray-90 bg-none text-[7px] text-center font-semibold">
                      <div>{messageData?.senderName}</div>
                    </div>
                    <div className="mt-4 -ml-4 w-[180px] text-gray-90 bg-[#E5E5EA] text-[10px] rounded-lg leading-4 font-semibold p-3 min-w-[205px] min-h-[100px]">
                      <div
                        className="text-gray-700"
                        dangerouslySetInnerHTML={{
                          __html: sanitizedMessage,
                        }}
                      ></div>
                      <div className="text-[#0068D9]">
                        {messageData?.landingPagesURL}
                      </div>
                    </div>
                  </div>
                )}

                {(messageData.channel.toLowerCase() == "sms" ||
                  messageData.channel.toLowerCase() == "whatsapp") && (
                  <img
                    ref={imgRef}
                    className="min-w-[362px]"
                    src={PhoneFrame}
                    loading="lazy"
                    alt=""
                  />
                )}

                {messageData.channel.toLowerCase() == "email" && (
                  <EmailPreview data={messageData} />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Provider>
  );
};

export default CampaignReview;
