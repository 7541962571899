export const template = {
    "page": {
        "rows": [
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "class": "center fixedwidth",
                                        "width": 187.5
                                    },
                                    "image": {
                                        "alt": "Image",
                                        "href": "https://beefree.io",
                                        "src": "img/placeholder_logo_3.png"
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px",
                                        "width": "100%"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-image"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "5px",
                            "border-top": "0px solid transparent",
                            "padding-top": "5px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    },
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "0px solid transparent",
                                            "width": "100%",
                                            "height": "36px"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "computedStyle": {
                                        "hideContentOnMobile": true,
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "20px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 18px;\" data-mce-style=\"font-size: 12px; line-height: 18px;\">\n<p style=\"font-size: 18px; line-height: 27px; text-align: center;\" data-mce-style=\"font-size: 18px; line-height: 27px; text-align: center;\"><span style=\"font-size: 15px; line-height: 22px;\" data-mce-style=\"font-size: 15px; line-height: 22px;\"><strong><span style=\"line-height: 22px; font-size: 15px;\" data-mce-style=\"line-height: 22px; font-size: 15px;\">Time for great email design</span></strong></span></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#7c4b96",
                                            "line-height": "150%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "5px",
                            "border-top": "0px solid transparent",
                            "padding-top": "5px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    }
                ],
                "locked": false,
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "635px"
                    }
                },
                "container": {
                    "style": {
                        "background-color": "#E5E5E5",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "type": "two-columns-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "0px solid transparent",
                                            "width": "100%",
                                            "height": "10px"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px"
                                    },
                                    "computedStyle": {
                                        "hideContentOnMobile": true,
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "class": "center  autowidth  fullwidth",
                                        "width": 500
                                    },
                                    "image": {
                                        "alt": "Image",
                                        "href": "https://beefree.io",
                                        "src": "img/placeholder_big_2.png"
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px",
                                        "width": "100%"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-image"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "0px solid transparent",
                                            "width": "100%",
                                            "height": "10px"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px"
                                    },
                                    "computedStyle": {
                                        "hideContentOnMobile": true,
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "0px",
                            "border-top": "0px solid transparent",
                            "padding-top": "0px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 12
                    }
                ],
                "locked": false,
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#000000",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "635px"
                    }
                },
                "container": {
                    "style": {
                        "background-color": "#f3f3f3",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "type": "one-column-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "10px solid transparent",
                                            "width": "100%"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px"
                                    },
                                    "computedStyle": {
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "5px",
                            "border-top": "0px solid transparent",
                            "padding-top": "5px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 12
                    }
                ],
                "locked": false,
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "635px"
                    }
                },
                "container": {
                    "style": {
                        "background-color": "transparent",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "type": "one-column-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "class": "center  autowidth ",
                                        "width": 180
                                    },
                                    "image": {
                                        "alt": "Image",
                                        "src": "img/placeholder_medium_1.png",
                                        "href": "https://beefree.io",
                                        "dynamicSrc": ""
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px",
                                        "width": "100%"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-image"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "20px",
                            "border-top": "0px solid transparent",
                            "padding-top": "20px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    },
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "0px solid transparent",
                                            "width": "100%",
                                            "height": "25px"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "computedStyle": {
                                        "hideContentOnMobile": false,
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 14px; line-height: 16px;\"><span style=\"font-size: 20px; line-height: 24px;\"><strong><span style=\"line-height: 24px; font-size: 20px;\">I'm a very important title</span></strong></span></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#2C2C2C",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "5px",
                                        "padding-right": "5px",
                                        "padding-top": "15px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\">I'm a block of text and I like latin. Pellentesque vel dui sed orci faucibus iaculis. Suspendisse dictum.</p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#aaaaaa",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "0px",
                                        "padding-top": "15px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\"><a href=\"https://beefree.io\" target=\"_blank\" rel=\"noopener\"><strong><span style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><span style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\" data-mce-style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\">Read More</span></span></strong></a></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#2C2C2C",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "10px",
                            "background-color": "transparent",
                            "padding-bottom": "30px",
                            "border-top": "0px solid transparent",
                            "padding-top": "30px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    }
                ],
                "locked": false,
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "635px"
                    }
                },
                "container": {
                    "style": {
                        "background-color": "transparent",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "type": "two-columns-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "class": "center  autowidth ",
                                        "width": 180
                                    },
                                    "image": {
                                        "alt": "Image",
                                        "href": "https://beefree.io",
                                        "src": "img/placeholder_medium_1.png"
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px",
                                        "width": "100%"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-image"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "20px",
                            "border-top": "0px solid transparent",
                            "padding-top": "20px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    },
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "0px solid transparent",
                                            "width": "100%",
                                            "height": "25px"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "computedStyle": {
                                        "hideContentOnMobile": false,
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><span style=\"font-size: 20px; line-height: 24px;\" data-mce-style=\"font-size: 20px; line-height: 24px;\"><strong><span style=\"line-height: 24px; font-size: 20px;\" data-mce-style=\"line-height: 24px; font-size: 20px;\">I'm a very important title</span></strong></span></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#2C2C2C",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "5px",
                                        "padding-right": "5px",
                                        "padding-top": "15px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\">I'm a block of text and I like latin. Pellentesque vel dui sed orci faucibus iaculis. Suspendisse dictum.</p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#aaaaaa",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "0px",
                                        "padding-top": "15px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\"><a href=\"https://beefree.io\" target=\"_blank\" rel=\"noopener\"><strong><span style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><span style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\" data-mce-style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\">Read More</span></span></strong></a></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#2C2C2C",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "10px",
                            "background-color": "transparent",
                            "padding-bottom": "30px",
                            "border-top": "0px solid transparent",
                            "padding-top": "30px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    }
                ],
                "locked": false,
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "635px"
                    }
                },
                "container": {
                    "style": {
                        "background-color": "transparent",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "type": "two-columns-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "class": "center  autowidth ",
                                        "width": 180
                                    },
                                    "image": {
                                        "alt": "Image",
                                        "href": "https://beefree.io",
                                        "src": "img/placeholder_medium_1.png"
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px",
                                        "width": "100%"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-image"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "20px",
                            "border-top": "0px solid transparent",
                            "padding-top": "20px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    },
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "0px solid transparent",
                                            "width": "100%",
                                            "height": "25px"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "computedStyle": {
                                        "hideContentOnMobile": false,
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><span style=\"font-size: 20px; line-height: 24px;\" data-mce-style=\"font-size: 20px; line-height: 24px;\"><strong><span style=\"line-height: 24px; font-size: 20px;\" data-mce-style=\"line-height: 24px; font-size: 20px;\">I'm a very important title</span></strong></span></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#2C2C2C",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "5px",
                                        "padding-right": "5px",
                                        "padding-top": "15px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\">I'm a block of text and I like latin. Pellentesque vel dui sed orci faucibus iaculis. Suspendisse dictum.</p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#aaaaaa",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "0px",
                                        "padding-top": "15px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\"><a href=\"https://beefree.io\" target=\"_blank\" rel=\"noopener\"><strong><span style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><span style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\" data-mce-style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\">Read more</span></span></strong></a></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#2C2C2C",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "10px",
                            "background-color": "transparent",
                            "padding-bottom": "30px",
                            "border-top": "0px solid transparent",
                            "padding-top": "30px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    }
                ],
                "locked": false,
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "635px"
                    }
                },
                "container": {
                    "style": {
                        "background-color": "transparent",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "type": "two-columns-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "10px solid transparent",
                                            "width": "100%"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px"
                                    },
                                    "computedStyle": {
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "5px",
                            "border-top": "0px solid transparent",
                            "padding-top": "5px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 12
                    }
                ],
                "locked": false,
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "635px"
                    }
                },
                "container": {
                    "style": {
                        "background-color": "transparent",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "type": "one-column-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "iconsDefaultWidth": 32,
                                        "padding": "0 5px 0 0",
                                        "width": 151,
                                        "height": 52
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px",
                                        "text-align": "center"
                                    },
                                    "iconsList": {
                                        "icons": [
                                            {
                                                "name": "facebook",
                                                "image": {
                                                    "alt": "Facebook",
                                                    "prefix": "https://www.facebook.com/",
                                                    "src": "repository_path/img/t-circle-white/facebook.png",
                                                    "href": "https://www.facebook.com/",
                                                    "title": "Facebook"
                                                },
                                                "type": "follow",
                                                "text": ""
                                            },
                                            {
                                                "name": "twitter",
                                                "image": {
                                                    "alt": "Twitter",
                                                    "prefix": "http://twitter.com/",
                                                    "src": "repository_path/img/t-circle-white/twitter.png",
                                                    "href": "http://twitter.com/",
                                                    "title": "Twitter"
                                                },
                                                "type": "follow",
                                                "text": ""
                                            },
                                            {
                                                "name": "instagram",
                                                "image": {
                                                    "alt": "Instagram",
                                                    "prefix": "https://instagram.com/",
                                                    "src": "repository_path/img/t-circle-white/instagram@2x.png",
                                                    "href": "https://instagram.com/",
                                                    "title": "Instagram"
                                                },
                                                "type": "follow",
                                                "text": ""
                                            }
                                        ]
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-social"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 18px; line-height: 21px; text-align: center;\" data-mce-style=\"font-size: 18px; line-height: 21px; text-align: center;\"><span style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\">This is a sample template from BEE free email editor</span><br><span style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\">Visit<span style=\"color: rgb(255, 255, 255); font-size: 14px; line-height: 16px;\" data-mce-style=\"color: rgb(255, 255, 255); font-size: 14px; line-height: 16px;\"> <span style=\"text-decoration: underline; line-height: 16px; font-size: 14px;\" data-mce-style=\"text-decoration: underline; line-height: 16px; font-size: 14px;\"><a style=\"color: #ffffff; text-decoration: underline;\" href=\"https://beefree.io\" target=\"_blank\" rel=\"noopener\">beefree.io</a></span>&nbsp;</span>to create beautiful and rich email messages at no cost.</span></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#bbbbbb",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "25px",
                            "border-top": "0px solid transparent",
                            "padding-top": "25px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 12
                    }
                ],
                "locked": false,
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "635px"
                    }
                },
                "container": {
                    "style": {
                        "background-color": "#2C2C2C",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "type": "one-column-empty"
            }
        ],
        "description": "",
        "title": "",
        "template": {
            "name": "template-base",
            "type": "basic",
            "version": "2.0.0"
        },
        "body": {
            "webFonts": [],
            "container": {
                "style": {
                    "background-color": "#FFFFFF"
                }
            },
            "content": {
                "computedStyle": {
                    "messageWidth": "635px",
                    "messageBackgroundColor": "transparent",
                    "linkColor": "#7c4b96"
                },
                "style": {
                    "color": "#000000",
                    "font-family": "Arial, 'Helvetica Neue', Helvetica, sans-serif"
                }
            },
            "type": "mailup-bee-page-properties"
        }
    }
}