import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useState } from "react";

const colors = ["#3DE18C", "#5AA0F1", "#F3D48E", "#EF8B8E", "#7699D4"];

const DropdownSurveySummary = ({ question }) => {
  const [status, setStatus] = useState(false);
  let xData = [];
  let yData = [];
  Object.keys(question.responseCounts[0].counts).map((key, i) => {
    let yd = {
      value: question.responseCounts[0].counts[key],
      itemStyle: { color: colors[i] },
    };
    yData.push(yd.value);
    xData.push(key);
  });

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value, index, ticks_array) {
            let characterLimit = 12;
            let label = this.getLabelForValue(value);
            if (label.length >= characterLimit) {
              return (
                label
                  .slice(0, label.length)
                  .substring(0, characterLimit - 1)
                  .trim() + "..."
              );
            }
            return label;
          },
        },
      },
      y: {
        grid: {
          display: true,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const labels = xData;

  const data = {
    labels,
    datasets: [
      {
        data: yData,
        backgroundColor: colors,
      },
    ],
  };

  const getWidthForSize = () => {
    if (labels.length < 5) {
      return "100%";
    }
    return (labels.length * 120).toString() + "px";
  };

  let interval = setInterval(() => {
    const containerBody = document.getElementById(
      `bar-chart-container-body${question.index}`
    );
    if (containerBody) {
      console.log(getWidthForSize());
      containerBody.style.width = getWidthForSize();
      setStatus(true);
      clearInterval(interval);
    }
  }, 500);

  return (
    <div>
      <div className="w-full overflow-x-auto">
        <div
          id={`bar-chart-container-body${question.index}`}
          className="h-[300px]"
        >
          {status && <Bar options={options} data={data} />}
        </div>
      </div>
    </div>
  );
};

export default DropdownSurveySummary;
