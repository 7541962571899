import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

const colors = {
  successful: "#12B76A",
  failed: "#FCD98D",
};

const NetworkDistribution = ({ networkReport }) => {
  const [option, setOption] = useState({});

  useEffect(() => {
    const yData = Object.keys(networkReport);
    const successData = [];
    const failData = [];

    yData.map((operator) => {
      const data = networkReport[operator];
      successData.push(data.success);
      failData.push(data.fail);
    });

    const chartOptions = {
      tooltip: {},
      legend: {
        left: "auto",
      },
      xAxis: {
        type: "value",
        name: "Profiles",
        nameLocation: "middle",
        nameGap: 30,
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: "category",
        data: yData,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        inverse: true,
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
          },
        },
      },
      series: [
        {
          name: "Successful",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: successData,
          color: [colors["successful"]],
          barWidth: "35%",
        },
        {
          name: "Failed",
          type: "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: failData,
          color: [colors["failed"]],
          barWidth: "35%",
          itemStyle: {
            barBorderRadius: [0, 5, 5, 0],
          },
        },
      ],
    };

    setOption(chartOptions);
  }, []);

  return (
    <div
      className={`border rounded p-6 mt-6 w-full lg:w-[calc(50%-10px)] h-[400px]`}
    >
      <div className="text-base text-gray-700 font-semibold mb-4">
        Network Distribution
      </div>

      <div>
        {option && <ReactECharts style={{ height: "350px" }} option={option} />}
      </div>
    </div>
  );
};

export default NetworkDistribution;
