import React, { lazy } from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";
import EngagementsMain from "./pages/engagements-main";
import MessageParent from "./pages/create-message/message-parent/message-parent";
import RewardsParent from "./pages/create-reward/rewards-parent/";
import SmsParent from "./pages/create-sms/sms-parent/";
import SurveyParent from "./pages/create-survey/survey-parent/survey-parent";
import CampaignInsights from "./pages/message-insights/campaign-insight/campaign-insights";
import EngagementsRewards from "./pages/engagements-rewards";
import EngagementsSurveys from "./pages/engagements-surveys";
import SurveyDetails from "./pages/survey-details";
import CampaignReview from "./pages/campaign-review/campaign-review";
import RewardReports from "./pages/reward-reports";
import EmailReports from "./pages/email-reports";
import EmailParent from "./pages/create-email/email-parent/email-parent";
import RcsParent from "./pages/create-rcs/rcs-parent/rcs-parent";

// const EngagementsMain = lazy(() => import("./pages/engagements-main"));
// const MessageParent = lazy(
//   () => import("./pages/create-message/message-parent/message-parent")
// );
// const RewardsParent = lazy(
//   () => import("./pages/create-reward/rewards-parent/")
// );
// const SmsParent = lazy(() => import("./pages/create-sms/sms-parent/"));
// const SurveyParent = lazy(
//   () => import("./pages/create-survey/survey-parent/survey-parent")
// );
// const CampaignInsights = lazy(
//   () => import("./pages/message-insights/campaign-insight/campaign-insights")
// );
// const EngagementsRewards = lazy(() => import("./pages/engagements-rewards"));
// const EngagementsSurveys = lazy(() => import("./pages/engagements-surveys"));
// const SurveyDetails = lazy(() => import("./pages/survey-details"));
// const CampaignReview = lazy(
//   () => import("./pages/campaign-review/campaign-review")
// );
// const RewardReports = lazy(() => import("./pages/reward-reports"));
// const EmailReports = lazy(() => import("./pages/email-reports"));
// const EmailParent = lazy(
//   () => import("./pages/create-email/email-parent/email-parent")
// );
// const RcsParent = lazy(
//   () => import("./pages/create-rcs/rcs-parent/rcs-parent")
// );

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
export {
  EngagementsMain,
  MessageParent,
  RewardsParent,
  SmsParent,
  SurveyParent,
  CampaignInsights,
  EngagementsRewards,
  EngagementsSurveys,
  SurveyDetails,
  CampaignReview,
  RewardReports,
  EmailParent,
  EmailReports,
  RcsParent,
};
