const Payment = (props) => {
  const { size = "48", color = "#344054" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="#77D7A6" />
      <path
        d="M36.1955 24.9797V27.7472C36.1955 28.4996 35.6309 29.1176 34.9313 29.1176H32.5626C31.2371 29.1176 30.022 28.0562 29.9116 26.6053C29.8379 25.7589 30.1325 24.9663 30.648 24.4155C31.1021 23.9049 31.728 23.6094 32.4153 23.6094H34.9313C35.6309 23.6094 36.1955 24.2274 36.1955 24.9797Z"
        fill="white"
      />
      <path
        d="M28.2128 26.7357C28.1024 25.4688 28.5684 24.226 29.5006 23.3211C30.2855 22.5369 31.377 22.0784 32.5544 22.0784H33.2535C33.5969 22.0784 33.879 21.8009 33.83 21.4631C33.4988 19.1223 31.4384 17.3125 28.9732 17.3125H16.7086C13.9981 17.3125 11.8027 19.4722 11.8027 22.1387V30.5846C11.8027 33.2511 13.9981 35.4109 16.7086 35.4109H28.9732C31.4506 35.4109 33.4988 33.601 33.83 31.2603C33.879 30.9225 33.5969 30.6449 33.2535 30.6449H32.7016C30.3713 30.6449 28.409 28.9316 28.2128 26.7357ZM25.2938 24.2502H17.935C17.4322 24.2502 17.0152 23.852 17.0152 23.3453C17.0152 22.8385 17.4322 22.4403 17.935 22.4403H25.2938C25.7966 22.4403 26.2136 22.8506 26.2136 23.3453C26.2136 23.8399 25.7966 24.2502 25.2938 24.2502Z"
        fill="white"
      />
      <path
        d="M26.6156 14.6648C26.939 14.9721 26.6529 15.4273 26.1803 15.4273H16.4418C15.0861 15.4273 13.8174 15.7914 12.7602 16.4173C12.2752 16.7018 11.616 16.3946 11.8523 15.9052C12.5488 14.4145 14.1781 13.3789 16.0562 13.3789H23.046C24.4888 13.3789 25.7699 13.8455 26.6156 14.6648Z"
        fill="white"
      />
    </svg>
  );
};

export default Payment;
