import { getCustomerId } from '../../../../marketing-cloud-main/src/common/constants/authToken';
import env from '../../../../marketing-cloud-main/env.json';
import {
  api,
  billingApi,
  audienceApi,
} from '../../../../marketing-cloud-main/src/common/services/common-api-service';
import * as Sentry from '@sentry/react';

//const uploadBaseURL = "http://staging-terras3v2.terragonbase.com";
const CLIENT_ID = env.CLIENT_ID;

export const getAccountsAndOverview = (channel = 'whatsapp') => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/account/insight?channel=${channel}`;
  return api
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken} `,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getAccounts = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/account?channel=whatsapp`;
  return api
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const postStepOne = (messageBody: any, id?: string) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  if (id) {
    return updateWhatsappCampaign(messageBody, id);
  }

  const url = `/message`;
  return api
    .post(url, messageBody, {
      headers: { Authorization: `Bearer ${loginAccessToken}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};
export const updateWhatsappCampaign = (messageBody: any, id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/message/${id}`;
  return api
    .put(url, messageBody, {
      headers: { Authorization: `Bearer ${loginAccessToken}` },
    })
    .then((response) => response.data)
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getSenderIds = (status = 'Enabled', offset = 0, size = 30) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/account?channel=sms&status=${status}&offset=${offset}&size=${size}`;
  return api
    .get(url, {
      headers: { Authorization: `Bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getObjectives = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/campaignobjectives`;
  return api
    .get(url, {
      headers: { Authorization: `Bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const updateMessage = (body, id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/message/${id}`;
  return api
    .put(url, body, {
      headers: { Authorization: `Bearer ${loginAccessToken}` },
    })
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};
export const getMessageDetails = (
  id,
  channel = '',
  additionalAttributes = ''
) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/message/${id}?channel=${channel}&additionalAttributes=${
    additionalAttributes ? additionalAttributes : ''
  }`;
  return api
    .get(url, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getDlr = (params, id?) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const {
    from = 0,
    pageSize = 20,
    startDate,
    endDate,
    status,
    phoneNumberId,
    channel = 'whatsapp',
  } = params;

  if (!channel) {
    return;
  }

  let url = `/dlr?channel=${channel}&size=${pageSize}`;

  if (id) {
    url = url + `&campaignId=${id}`;
  }
  if (from) {
    url = url + `&from=${from}`;
  }

  if (status) {
    url = url + `&status=${status}`;
  }
  if (startDate) {
    url = url + `&startDate=${startDate}`;
  }
  if (endDate) {
    url = url + `&endDate=${endDate}`;
  }
  if (phoneNumberId) {
    url = url + `&phoneNumber=${phoneNumberId}`;
  }
  return api
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken} `,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getMessageList = ({
  from = 0,
  startDate = '',
  endDate = '',
  dateRange = null,
  size = 10,
  channel = '',
  status = '',
  messageText = '',
}) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  let url = `/message?from=${from}&size=${size}`;

  if (channel) {
    url = url + `&channel=${channel}`;
  }
  if (status) {
    url = url + `&status=${status}`;
  }
  if (messageText) {
    url = url + `&search_query=${messageText}`;
  }
  if (startDate && endDate) {
    url = url + `&startDate=${startDate}&endDate=${endDate}`;
  }
  if (dateRange && dateRange.startDate && dateRange.endDate) {
    url =
      url + `&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
  }

  return api
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getTopupPlans = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/topup/plans`;
  return billingApi
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
        'client-id': CLIENT_ID,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const approveCampaign = ({ payload }) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/approval/campaign`;
  return api
    .post(url, payload, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getattributes = (source) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/v1/segment/metadata/${source}`;

  return audienceApi
    .get(url, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    });
};

export const getSegmentVariables = (segmentId) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/v1/upload/file/headers/${segmentId}`;

  return audienceApi
    .get(url, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    });
};

export const getRcsTemplates = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/rcs/template`;

  return api
    .get(url, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    });
};

export const getEmailTemplateList = (from, size, { name }) => {
  const url = `/email-template?from=${from}&size=${size}${
    name ? '&name=' + name : ''
  }`;
  let loginAccessToken = localStorage.getItem('loginAccessToken');

  return api
    .get(url, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const fetchSelectedTemplate = (id) => {
  const url = `/email-template/${id}`;
  let loginAccessToken = localStorage.getItem('loginAccessToken');

  return api
    .get(url, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};
