import { Modal } from "semantic-ui-react";
import { Close } from "../../../engagements/src/assets/icons/icons";
import { formatNumbers } from "../common/utils/UserInterfaceUtils";

const InsufficientWalletModal = ({
  unitsReuquired,
  currencySymbol,
  onPurchase,
  onDecline,
  type,
}) => {
  if (!unitsReuquired || unitsReuquired == 0) {
    return null;
  }

  return (
    <div>
      <Modal basic open={true} size="small">
        <div className="flex justify-center">
          <div className="p-8 bg-[#ffffff] w-[408px] rounded">
            <div className="flex justify-between">
              <div className="text-[16px] font-semibold text-[#3A4356]">
                Insufficient balance
              </div>
              <div className="cursor-pointer" onClick={onDecline}>
                <img src={Close} alt="" />
              </div>
            </div>
            <div className="mt-10 text-[14px] text-center leading-6 text-[#073763]">
              Insufficient wallet balance. Top up your wallet with{" "}
              <span className="text-[#000000] font-semibold">
                {currencySymbol}
                {formatNumbers(unitsReuquired)}
              </span>{" "}
              (7.5% VAT included) to continue.
            </div>
            <div
              onClick={onPurchase}
              className="mt-10 py-4 min-w-full bg-blue-75 text-white text-[14px] leading-4 font-semibold rounded-md text-center cursor-pointer"
            >
              Buy wallet balance
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InsufficientWalletModal;
