import { useState } from "react";
import SurveySummary from "./survey-summary/survey-summary";
import SurveyIndividual from "./survey-individual/survey-individual";
import SurveyRespondents from "./survey-respondents/survey-respondents";
import SurveyRespondentsLog from "./survey-respondents-log/survey-respondents-log";

const SurveyAnalytics = ({
  id,
  questionCount,
  surveyDuration,
  dispatch,
  setShowCommonLoader,
}) => {
  const TYPE_VALUES = { response: "RESPONSE", respondent: "RESPONDENT" };
  const RESPONSE_TYPES = {
    summary: "SUMMARY",
    questions: "QUESTIONS",
    individual: "INDIVIDUAL",
  };

  const [type, setType] = useState(TYPE_VALUES.response);
  const [responseType, setResponseType] = useState(RESPONSE_TYPES.summary);

  return (
    <div className="mt-12">
      <div className="flex gap-1">
        <div className="w-36 text-base font-semibold text-gray-70">
          Analytics
        </div>
        <div className="grow h-1 border-t mt-3 border-[#EBEFF3]"></div>
      </div>
      <div>
        <div className="flex mt-6">
          <div
            onClick={() => {
              setType(TYPE_VALUES.response);
            }}
            className={`text-sm font-semibold py-3 px-5 ${
              type === TYPE_VALUES.response
                ? "text-white bg-blue-75 rounded-lg"
                : "text-[#526475] cursor-pointer"
            }`}
          >
            Responses
          </div>
          <div
            onClick={() => {
              setType(TYPE_VALUES.respondent);
            }}
            className={`text-sm font-semibold py-3 px-5 ${
              type === TYPE_VALUES.respondent
                ? "text-white bg-blue-75 rounded-lg"
                : "text-[#526475] cursor-pointer"
            }`}
          >
            Respondents
          </div>
        </div>
      </div>
      {type === TYPE_VALUES.response ? (
        <div>
          <div className="flex mt-6">
            <div
              onClick={() => {
                setResponseType(RESPONSE_TYPES.summary);
              }}
              className={`text-sm p-2 pb-4 ${
                responseType === RESPONSE_TYPES.summary
                  ? "border-b-2 border-b-blue-75 font-semibold text-blue-75"
                  : "text-[#667085] cursor-pointer"
              }`}
            >
              Summary
            </div>
            <div
              onClick={() => {
                setResponseType(RESPONSE_TYPES.questions);
              }}
              className={`text-sm p-2 pb-4 ${
                responseType === RESPONSE_TYPES.questions
                  ? "border-b-2 border-b-blue-75 font-semibold text-blue-75"
                  : "text-[#667085] cursor-pointer"
              }`}
            >
              Questions
            </div>
            <div
              onClick={() => {
                setResponseType(RESPONSE_TYPES.individual);
              }}
              className={`text-sm p-2 pb-4 ${
                responseType === RESPONSE_TYPES.individual
                  ? "border-b-2 border-b-blue-75 font-semibold text-blue-75"
                  : "text-[#667085] cursor-pointer"
              }`}
            >
              Individual
            </div>
          </div>
          <div>
            {responseType === RESPONSE_TYPES.summary ? (
              <SurveySummary
                id={id}
                dispatch={dispatch}
                setShowCommonLoader={setShowCommonLoader}
              />
            ) : null}
            {/* {responseType === RESPONSE_TYPES.questions  ? <SurveySummary/> : null} */}
            {responseType === RESPONSE_TYPES.individual ? (
              <SurveyIndividual
                id={id}
                dispatch={dispatch}
                setShowCommonLoader={setShowCommonLoader}
              />
            ) : null}
          </div>
        </div>
      ) : null}
      {type === TYPE_VALUES.respondent && (
        <div>
          <div className="flex mt-6">
            <SurveyRespondents
              questionCount={questionCount}
              id={id}
              surveyDuration={surveyDuration}
              dispatch={dispatch}
              setShowCommonLoader={setShowCommonLoader}
            />
            <SurveyRespondentsLog />
          </div>
        </div>
      )}
    </div>
  );
};

export default SurveyAnalytics;
