const Copy = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 13.332H5.33333C4.8029 13.332 4.29419 13.1213 3.91912 12.7462C3.54405 12.3712 3.33333 11.8625 3.33333 11.332V4.66536C3.33333 4.48855 3.2631 4.31898 3.13807 4.19396C3.01305 4.06894 2.84348 3.9987 2.66667 3.9987C2.48986 3.9987 2.32029 4.06894 2.19526 4.19396C2.07024 4.31898 2 4.48855 2 4.66536V11.332C2 12.2161 2.35119 13.0639 2.97631 13.6891C3.60143 14.3142 4.44928 14.6654 5.33333 14.6654H10.6667C10.8435 14.6654 11.013 14.5951 11.1381 14.4701C11.2631 14.3451 11.3333 14.1755 11.3333 13.9987C11.3333 13.8219 11.2631 13.6523 11.1381 13.5273C11.013 13.4023 10.8435 13.332 10.6667 13.332ZM14 5.9587C13.9931 5.89745 13.9796 5.83712 13.96 5.7787V5.7187C13.9279 5.65015 13.8852 5.58714 13.8333 5.53203L9.83333 1.53203C9.77822 1.48018 9.71521 1.43742 9.64667 1.40536H9.58667L9.37333 1.33203H6.66667C6.13623 1.33203 5.62753 1.54274 5.25245 1.91782C4.87738 2.29289 4.66667 2.8016 4.66667 3.33203V9.9987C4.66667 10.5291 4.87738 11.0378 5.25245 11.4129C5.62753 11.788 6.13623 11.9987 6.66667 11.9987H12C12.5304 11.9987 13.0391 11.788 13.4142 11.4129C13.7893 11.0378 14 10.5291 14 9.9987V5.9987C14 5.9987 14 5.9987 14 5.9587ZM10 3.60536L11.7267 5.33203H10.6667C10.4899 5.33203 10.3203 5.26179 10.1953 5.13677C10.0702 5.01174 10 4.84218 10 4.66536V3.60536ZM12.6667 9.9987C12.6667 10.1755 12.5964 10.3451 12.4714 10.4701C12.3464 10.5951 12.1768 10.6654 12 10.6654H6.66667C6.48986 10.6654 6.32029 10.5951 6.19526 10.4701C6.07024 10.3451 6 10.1755 6 9.9987V3.33203C6 3.15522 6.07024 2.98565 6.19526 2.86063C6.32029 2.7356 6.48986 2.66536 6.66667 2.66536H8.66667V4.66536C8.66667 5.1958 8.87738 5.70451 9.25245 6.07958C9.62753 6.45465 10.1362 6.66536 10.6667 6.66536H12.6667V9.9987Z"
        fill="#94A3B8"
      />
    </svg>
  );
};

export default Copy;
