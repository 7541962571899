const NotificationIcon = () => {
  return (
    <svg
      width="353"
      height="300"
      viewBox="0 0 353 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="150"
        cy="150"
        r="150"
        transform="matrix(-1 0 0 1 327.777 0)"
        fill="#EEF9F4"
      />
      <path
        d="M0 186.112C0 183.043 2.48731 180.556 5.55556 180.556H111.111C114.179 180.556 116.667 183.043 116.667 186.112V225.001C116.667 228.069 114.179 230.556 111.111 230.556H5.55555C2.48731 230.556 0 228.069 0 225.001V186.112Z"
        fill="#12B76A"
      />
      <circle
        cx="13.8889"
        cy="13.8889"
        r="13.8889"
        transform="matrix(-1 0 0 1 38.8906 191.667)"
        fill="#EEF9F4"
      />
      <path
        d="M102.778 200C102.778 198.466 101.534 197.223 100 197.223H52.7778C51.2437 197.223 50 198.466 50 200C50 201.535 51.2437 202.778 52.7778 202.778H100C101.534 202.778 102.778 201.535 102.778 200Z"
        fill="white"
      />
      <path
        d="M86.1111 211.112C86.1111 209.577 84.8675 208.334 83.3333 208.334H52.7778C51.2437 208.334 50 209.577 50 211.112C50 212.646 51.2437 213.889 52.7778 213.889H83.3333C84.8675 213.889 86.1111 212.646 86.1111 211.112Z"
        fill="white"
      />
      <path
        d="M248.64 111.889C248.64 93.2651 241.205 75.4038 227.971 62.2346C214.738 49.0654 196.789 41.667 178.073 41.667C159.358 41.667 141.409 49.0654 128.175 62.2346C114.942 75.4038 107.507 93.2651 107.507 111.889C107.507 161.046 94.8042 189.135 84.6425 203.741C81.4995 208.259 85.1441 217.223 90.6473 217.223H265.499C271.003 217.223 274.647 208.259 271.504 203.741C261.343 189.135 248.64 161.046 248.64 111.889Z"
        fill="#AEE0C5"
      />
      <path
        d="M248.64 111.889C248.64 93.2651 241.205 75.4038 227.971 62.2346C214.738 49.0654 196.789 41.667 178.073 41.667C159.358 41.667 141.409 49.0654 128.175 62.2346C114.942 75.4038 107.507 93.2651 107.507 111.889C107.507 161.046 94.8042 189.135 84.6425 203.741C81.4995 208.259 85.1441 217.223 90.6473 217.223H265.499C271.003 217.223 274.647 208.259 271.504 203.741C261.343 189.135 248.64 161.046 248.64 111.889Z"
        fill="#AEE0C5"
      />
      <mask
        id="mask0_12647_172545"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="133"
        y="230"
        width="89"
        height="32"
      >
        <path
          d="M220.726 230.557H134.834C134.121 230.557 133.65 231.272 133.964 231.912C136.871 237.829 150.069 261.112 177.78 261.112C205.492 261.112 218.69 237.829 221.597 231.912C221.911 231.272 221.439 230.557 220.726 230.557Z"
          fill="#AEE0C5"
        />
      </mask>
      <g mask="url(#mask0_12647_172545)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M311.49 382.76L199.587 172.243L197.941 172.543L309.844 383.06L311.49 382.76Z"
          fill="#12B76A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M301.33 377.161L189.427 166.644L187.781 166.944L299.684 377.461L301.33 377.161Z"
          fill="#12B76A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M291.166 371.567L179.263 161.051L177.617 161.351L289.52 371.867L291.166 371.567Z"
          fill="#12B76A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M281.002 365.968L169.099 155.452L167.453 155.752L279.356 366.268L281.002 365.968Z"
          fill="#12B76A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M270.834 360.369L158.931 149.853L157.285 150.153L269.188 360.669L270.834 360.369Z"
          fill="#12B76A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M260.678 354.776L148.775 144.26L147.129 144.56L259.032 355.076L260.678 354.776Z"
          fill="#12B76A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M250.51 349.182L138.607 138.666L136.961 138.966L248.864 349.482L250.51 349.182Z"
          fill="#12B76A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M240.353 343.583L128.451 133.067L126.805 133.367L238.707 343.883L240.353 343.583Z"
          fill="#12B76A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M230.185 337.99L118.283 127.473L116.637 127.773L228.54 338.29L230.185 337.99Z"
          fill="#12B76A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M220.025 332.391L108.122 121.874L106.477 122.174L218.379 332.691L220.025 332.391Z"
          fill="#12B76A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M209.861 326.792L97.9584 116.275L96.3125 116.575L208.215 327.092L209.861 326.792Z"
          fill="#12B76A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M199.697 321.198L87.7943 110.682L86.1484 110.982L198.051 321.498L199.697 321.198Z"
          fill="#12B76A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M189.533 315.599L77.6302 105.083L75.9844 105.383L187.887 315.899L189.533 315.599Z"
          fill="#12B76A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M179.369 310.006L67.4662 99.4896L65.8203 99.7896L177.723 310.306L179.369 310.006Z"
          fill="#12B76A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M169.205 304.407L57.3021 93.8904L55.6562 94.1904L167.559 304.707L169.205 304.407Z"
          fill="#12B76A"
        />
      </g>
      <path
        d="M236.109 119.444C236.109 116.376 238.597 113.889 241.665 113.889H347.22C350.289 113.889 352.776 116.376 352.776 119.444V158.333C352.776 161.401 350.289 163.889 347.22 163.889H241.665C238.597 163.889 236.109 161.401 236.109 158.333V119.444Z"
        fill="#12B76A"
      />
      <circle
        cx="13.8889"
        cy="13.8889"
        r="13.8889"
        transform="matrix(-1 0 0 1 275 125)"
        fill="#EEF9F4"
      />
      <path
        d="M338.887 133.333C338.887 131.799 337.644 130.556 336.109 130.556H288.887C287.353 130.556 286.109 131.799 286.109 133.333C286.109 134.868 287.353 136.111 288.887 136.111H336.109C337.644 136.111 338.887 134.868 338.887 133.333Z"
        fill="white"
      />
      <path
        d="M322.22 144.445C322.22 142.91 320.977 141.667 319.443 141.667H288.887C287.353 141.667 286.109 142.91 286.109 144.445C286.109 145.979 287.353 147.222 288.887 147.222H319.443C320.977 147.222 322.22 145.979 322.22 144.445Z"
        fill="white"
      />
      <path
        d="M68.5151 92.4038C65.0071 91.9288 62.5483 88.6999 63.0234 85.1919C63.4984 81.6839 66.7273 79.2252 70.2353 79.7002L91.4081 82.5673C94.9161 83.0423 97.3748 86.2712 96.8998 89.7792C96.4247 93.2872 93.1958 95.7459 89.6878 95.2709L68.5151 92.4038Z"
        fill="#FCB416"
      />
      <path
        d="M111.991 24.4108C110.84 21.0631 112.621 17.4162 115.969 16.2653C119.317 15.1144 122.963 16.8953 124.114 20.243L131.061 40.4484C132.211 43.7961 130.431 47.4429 127.083 48.5938C123.735 49.7447 120.088 47.9638 118.937 44.6161L111.991 24.4108Z"
        fill="#FCB416"
      />
      <path
        d="M74.357 45.8456C71.6582 43.5547 71.3275 39.5098 73.6183 36.8109C75.9092 34.1121 79.9541 33.7813 82.653 36.0722L98.942 49.8987C101.641 52.1896 101.972 56.2346 99.6807 58.9334C97.3898 61.6323 93.3449 61.963 90.646 59.6721L74.357 45.8456Z"
        fill="#FCB416"
      />
      <circle
        cx="22.2222"
        cy="22.2222"
        r="22.2222"
        transform="matrix(-1 0 0 1 250 33.333)"
        fill="#FCB416"
      />
    </svg>
  );
};

export default NotificationIcon;
