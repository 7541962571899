import { useEffect, useState } from "react";
import CurrencySymbol from "../../../../../marketing-cloud-main/src/common/components/currency-symbol/currency-symbol";
import { formatNumbers } from "../../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";
import { getSurveyLink } from "../../../common/services/survey-services";
import {
  SurveyLaunchIconAudience,
  SurveyLaunchIconBudget,
  SurveyLaunchIconMegaphone,
  SurveyLaunchIconName,
  SurveyLaunchIconTimeline,
} from "../../../assets/icons/icons";

const SurveyData = (props) => {
  let { data, edit } = props;

  const [surveyType, setSurveyType] = useState("");
  let surveyData = data;

  useEffect(() => {
    surveyData?.audienceType === "general"
      ? setSurveyType("OPEN")
      : setSurveyType("CLOSED");
  }, []);

  return (
    <div className="flex items-start">
      <div className="w-full">
        <div>
          <div className="flex gap-12 justify-start">
            <div className="flex gap-4 mb-6 w-1/2">
              <img src={SurveyLaunchIconName} alt="" />
              <div>
                <span className="block font-semibold text-base leading-6 text-gray-70">
                  Survey name
                </span>
                <span className="inline-block text-gray-500 break-words max-w-[250px] text-sm">
                  {surveyData?.name}
                </span>
              </div>
            </div>
            <div className="flex gap-4 mb-6">
              <img src={SurveyLaunchIconAudience} alt="" />
              <div>
                <span className="block font-semibold text-base leading-6 text-gray-70">
                  Survey channel
                </span>
                <span className="inline-block break-words text-gray-500 text-sm">
                  {surveyData?.channel}
                </span>
              </div>
            </div>
          </div>
          <div className="flex gap-12 justify-start">
            <div className="flex gap-4 mb-6 w-1/2">
              <img src={SurveyLaunchIconName} alt="" />
              <div>
                <span className="block font-semibold text-base leading-6 text-gray-70">
                  {surveyData?.channel === "WEB" ? "Survey link" : "Short code"}
                </span>
                <span className="inline-block text-gray-500 break-words max-w-[250px] text-sm">
                  {surveyData?.channel === "WEB"
                    ? getSurveyLink(surveyData)
                    : surveyData?.shortCode}
                </span>
              </div>
            </div>
            <div className="flex gap-4 mb-6 items-start">
              <img
                width={37}
                height={37}
                src={SurveyLaunchIconTimeline}
                alt=""
              />
              <div>
                <span className="block font-semibold text-base leading-6 text-gray-70">
                  Timeline
                </span>
                <span className="inline-block break-words text-gray-500 text-sm">
                  {new Date(
                    surveyData?.schedule?.timeline?.start
                  ).toLocaleDateString("en-GB")}
                  {",  "}
                  {new Date(
                    surveyData?.schedule?.timeline?.start
                  ).toLocaleTimeString("en-US")}
                  - <br />
                  {new Date(
                    surveyData?.schedule?.timeline?.end
                  ).toLocaleDateString("en-GB")}
                  {",  "}
                  {new Date(
                    surveyData?.schedule?.timeline?.end
                  ).toLocaleTimeString("en-US")}
                </span>
              </div>
            </div>
          </div>

          <div className="flex gap-4 mb-6">
            <img src={SurveyLaunchIconAudience} alt="" />
            <div className="mb-6">
              <span className="block font-semibold text-base leading-6 text-gray-70">
                Survey objective
              </span>
              <span className="inline-block text-gray-500 break-words max-w-[300px] text-sm">
                {surveyData?.objective}
              </span>
            </div>
          </div>
        </div>
        {/* {surveyType === "CLOSED" && (
          <div className="mb-12">
            <div className="mb-4">
              <span className="text-gray-700 font-semibold mr-4">
                Survey invite
              </span>
              {edit && (
                <span
                  onClick={() => {
                    props.setStep(1);
                  }}
                  className="text-green-15 cursor-pointer"
                >
                  Edit
                </span>
              )}
            </div>
            <div className="flex gap-12 justify-start">
              <div className="flex gap-4 w-1/2">
                <SurveyLaunchIcon type={"audience"} />
                <div>
                  <span className="block font-semibold text-base leading-6 text-gray-70">
                    Message
                  </span>
                  <span className="inline-block break-words max-w-[250px] text-gray-500 text-sm">
                    {surveyData?.invitationMessageDetails?.content?.text}
                  </span>
                </div>
              </div>
              <div className="flex gap-4">
                <SurveyLaunchIcon type={"audience"} />
                <div>
                  <span className="block font-semibold text-base leading-6 text-gray-70">
                    Sender Id
                  </span>
                  <span className="inline-block break-words text-gray-500 text-sm">
                    {surveyData?.invitationMessageDetails?.senderName}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )} */}
        <div className="mb-12">
          <div className="mb-4">
            <span className="text-gray-700 font-semibold mr-4">Audience</span>
            {edit && (
              <span
                onClick={() => {
                  props.setStep(3);
                }}
                className="text-green-15 cursor-pointer"
              >
                Edit
              </span>
            )}
          </div>
          {surveyType === "CLOSED" && (
            <div className="flex gap-12 justify-start">
              <div className="flex gap-4 mb-6 w-1/2">
                <img src={SurveyLaunchIconMegaphone} alt="" />
                <div>
                  <span className="block font-semibold text-base leading-6 text-gray-70">
                    Audience type
                  </span>
                  <span className="text-gray-500 text-sm capitalize">
                    {surveyData?.audienceType}
                  </span>
                </div>
              </div>
              <div className="flex gap-4 mb-6">
                <img src={SurveyLaunchIconMegaphone} alt="" />
                <div>
                  <span className="block font-semibold text-base leading-6 text-gray-70">
                    Segment name
                  </span>
                  <span className="inline-block break-words text-gray-500 max-w-[250px] text-sm capitalize">
                    {/* {surveyData.segmentName} */}
                    Custom
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className="flex gap-12 justify-start">
            <div className="flex gap-4 w-1/2">
              <img src={SurveyLaunchIconBudget} alt="" />
              <div>
                <span className="block font-semibold text-base leading-6 text-gray-70">
                  Target
                </span>
                <span className="inline-block text-gray-500 break-words max-w-[250px] text-sm">
                  {formatNumbers(surveyData?.budget)}
                </span>
              </div>
            </div>
            {surveyType === "CLOSED" && (
              <div className="flex gap-4">
                <img src={SurveyLaunchIconMegaphone} alt="" />
                <div>
                  <span className="block font-semibold text-base leading-6 text-gray-70">
                    Control numbers
                  </span>

                  <span className="inline-block break-words max-w-[250px] text-gray-500 text-sm">
                    {surveyData?.controlNumbers?.join(", ")}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="mb-4">
            <span className="text-gray-700 font-semibold mr-4">Pricing</span>
            {edit && (
              <span
                onClick={() => {
                  props.setStep(3);
                }}
                className="text-green-15 cursor-pointer"
              >
                Edit
              </span>
            )}
          </div>
          <div className="flex flex-wrap gap-x-12 w-full">
            <div className="flex gap-4 mb-6 w-1/2">
              <img src={SurveyLaunchIconBudget} alt="" />
              <div>
                <span className="block font-semibold text-base leading-6 text-gray-70">
                  Survey unit cost
                </span>
                <div className="text-gray-500 text-sm flex gap-1">
                  <CurrencySymbol />
                  {formatNumbers(surveyData?.unitPrice)}
                </div>
              </div>
            </div>
            {surveyData?.rewardPerProfile && (
              <div className="flex gap-4 mb-6">
                <img src={SurveyLaunchIconBudget} alt="" />
                <div>
                  <span className="block font-semibold text-base leading-6 text-gray-70">
                    Reward per profile
                  </span>
                  <div className="text-gray-500 text-sm flex gap-1">
                    <CurrencySymbol />
                    {formatNumbers(surveyData?.rewardPerProfile)}
                  </div>
                </div>
              </div>
            )}
            {surveyData?.rewardBudget && (
              <div className="flex gap-4 mb-6 w-1/2">
                <img src={SurveyLaunchIconBudget} alt="" />
                <div>
                  <span className="block font-semibold text-base leading-6 text-gray-70">
                    Reward budget
                  </span>
                  <div className="text-gray-500 text-sm flex gap-1">
                    <CurrencySymbol />
                    {formatNumbers(surveyData?.rewardBudget)}
                  </div>
                </div>
              </div>
            )}
            <div className="flex gap-4 mb-6">
              <img src={SurveyLaunchIconBudget} alt="" />
              <div>
                <span className="block font-semibold text-base leading-6 text-gray-70">
                  Total pricing
                </span>
                <div className="break-words text-gray-500 text-sm flex gap-1">
                  <CurrencySymbol />{" "}
                  {formatNumbers(
                    Number(surveyData?.budget * surveyData?.unitPrice) +
                      Number(surveyData?.rewardBudget)
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyData;
