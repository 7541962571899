import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import HelpCircle from "../../../common/assets/icons/HelpCircle";
import { getAccountStatus, getTopupPlans } from "../../../common/apis/billing";
import { topupModalStates } from "../constants/fundModalTypes";
import PaymentOptionModal from "../../../modals/payment-option-modal";
import { formatEvent } from "../../../common/constants/billingEvents";
import Wallet from "../../../common/assets/icons/Walltet";
import CurrencySymbol from "../../../common/components/currency-symbol/currency-symbol";
import Spinner from "../../../common/components/spinner/Spinner";
import Tick from "../../../common/assets/icons/Tick";
import { Link } from "react-router-dom";
import SubWallet from "../../../common/assets/icons/SubWallet";
import { monthNames } from "../../../common/utils/billing";
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoaderAccountStatus,
  setTopupPlans,
} from "../../../redux-loader/loader-slice/loaderSlice";

interface AccountStatusResponse {
  account_status?: any[];
  subscriptions?: string[];
}

var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const Summary = (props) => {
  let { walletBalance } = props;
  const dispatch = useDispatch();
  const loaderAccountStatus = useSelector(
    (state: any) => state.loaderAccountStatus
  );
  const topupPlans = useSelector((state: any) => state.topupPlans);

  const [loading, setLoading] = useState(false);
  const [summaryError, setSummaryError] = useState(false);
  const [usageData, setUsageData] = useState([]);
  const [showAudiencePopover, setShowAudiencePopover] = useState(false);
  const [showEventsPopover, setShowEventsPopover] = useState(false);
  const [showCurrentPlanPopover, setCurrentPlanPopover] = useState(false);
  const [unitModal, setUnitModal] = useState(false);
  const [topupEventUnits, setTopupEventUnits] = useState(0);
  const [topupEventPriceTiers, setTopupEventPriceTiers] = useState([]);
  const [topupEventType, setTopupEventType] = useState("");
  const [topupModalState, setTopupModalState] = useState(
    topupModalStates.SELECTED_UNIT_MODAL
  );
  const [accountStatus, setAccountStatus] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [paymentModalStatus, setPaymentModalStatus] = useState(false);

  const handleTopupUnitsChange = (event) => {
    setTopupEventUnits(event.target.value);
  };

  const showUnitModal = (eventType, eventUnitPrice) => {
    setTopupEventType(eventType);
    setTopupEventPriceTiers(eventUnitPrice);
    setUnitModal(true);
  };

  const audiencePopover = useRef(null);
  const eventsPopover = useRef(null);
  const currentPlanPopover = useRef(null);

  const closeOpenMenus = (e) => {
    if (
      audiencePopover.current &&
      showAudiencePopover &&
      !audiencePopover.current.contains(e.target)
    ) {
      setShowAudiencePopover(false);
    }

    if (
      eventsPopover.current &&
      showEventsPopover &&
      !eventsPopover.current.contains(e.target)
    ) {
      setShowEventsPopover(false);
    }

    if (
      currentPlanPopover.current &&
      showCurrentPlanPopover &&
      !currentPlanPopover.current.contains(e.target)
    ) {
      setShowEventsPopover(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  useEffect(() => {
    fetchData();
  }, []);

  const closeDialog = () => {
    setUsageData([]);
    fetchData();
    setUnitModal(false);
  };

  const fetchData = async () => {
    if (
      loaderAccountStatus?.account_status?.length > 0 &&
      loaderAccountStatus?.subscriptions?.length > 0 &&
      topupPlans?.length > 0
    ) {
      performPostApiActions(
        loaderAccountStatus.account_status,
        loaderAccountStatus.subscriptions,
        topupPlans
      );
      return;
    }

    try {
      setLoading(true);

      const accountStatusRes: AccountStatusResponse = await getAccountStatus();
      if (accountStatusRes) {
        dispatch(setLoaderAccountStatus(accountStatusRes));
      }

      const { account_status = [], subscriptions = [] } = accountStatusRes;

      const topupPlansRes = await getTopupPlans();
      if (topupPlansRes) {
        dispatch(setTopupPlans(topupPlansRes));
      }

      performPostApiActions(account_status, subscriptions, topupPlansRes);

      setLoading(false);
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
      setSummaryError(true);
      setLoading(false);
    }
  };

  const performPostApiActions = (account_status, subscriptions, topupPlans) => {
    if (subscriptions && subscriptions.length) {
      const subs = subscriptions[0];
      const subsJson = JSON.parse(subs) || [];
      setSubscription(subsJson);
      if (subsJson && subsJson.length) {
        const { plan_id = "" } = subsJson[0];
        localStorage.setItem("billingPlanId", plan_id);
      }
    }

    const productUsage = [];
    setAccountStatus(account_status);
    account_status?.forEach((status) => {
      const matchingPlan = topupPlans.find(
        (plan) => plan.product_id === status.product_id
      );

      if (matchingPlan) {
        const { units, product_id, threshold } = status;

        const {
          product_name,
          currency,
          description,
          billing_scheme: { tiers },
        } = matchingPlan;

        const mergedProduct = {
          units,
          product_id,
          threshold,
          product_name,
          currency,
          description,
          tiers,
        };

        productUsage.push(mergedProduct);
      }
    });

    setUsageData(productUsage);
    setTopupModalState(topupModalStates.SELECTED_UNIT_MODAL);
    if (props.buyUnits) {
      let matchingProduct = productUsage.find(
        (el) => el.product_id === +props.buyUnits
      );
      if (matchingProduct) {
        showUnitModal(
          formatEvent(matchingProduct.product_name),
          matchingProduct.tiers
        );
      }
    }
  };

  const getDaysLeft = (day) => {
    let t2 = new Date().getTime();
    let t1 = new Date(day).getTime();
    let diff = t1 - t2;
    return diff < 0 ? 0 : Math.floor(diff / (24 * 3600 * 1000));
  };

  const successCallBack = (status) => {
    setPaymentModalStatus(false);
    if (status) {
      fetchData();
    }
  };

  return (
    <div>
      <div>
        <span className="text-gray-700 text-xl	font-semibold py-2">
          Account usage summary
        </span>
        <p className="text-gray-500 py-2">
          Welcome to Marketing Cloud billing console. Account usage summary
          helps you get a better overview of how you use Marketing Cloud
          products.
        </p>

        <div className="border rounded p-7 flex">
          <div className="w-1/2 flex gap-6">
            <div className="flex justify-start items-start">
              <Wallet />
            </div>
            <div>
              <div className="text-sm font-semibold text-[#667085]">
                Wallet balance{" "}
              </div>
              <div className="text-3xl text-blue-75 font-semibold mt-1 mb-6">
                {walletBalance !== null ? (
                  <CurrencySymbol value={walletBalance} />
                ) : (
                  <Spinner className="w-8 h-8" />
                )}
              </div>
              <div
                className="text-sm text-gray-700 font-semibold cursor-pointer"
                onClick={() => {
                  props.switchTab("Logs");
                }}
              >
                View logs
              </div>
            </div>
          </div>
          <div className="w-1/2 flex gap-6 border-l pl-12">
            <div className="flex justify-start items-start">
              <SubWallet />
            </div>
            <div>
              <div className="text-sm font-semibold text-[#667085]">
                Next payment due{" "}
              </div>
              {props.subscriptionPlan &&
                props?.subscriptionPlan?.tier_no !== 0 && (
                  <div className="text-3xl text-blue-75 font-semibold mt-1 mb-6">
                    {walletBalance !== null ? (
                      <div>
                        <div className="text-base text-blue-75 font-semibold">
                          {subscription
                            ? `${
                                monthNames[
                                  new Date(subscription[0].end_date).getMonth()
                                ]
                              }
                        ${new Date(subscription[0].end_date).getDate()},
                        ${new Date(subscription[0].end_date).getFullYear()}`
                            : ""}
                        </div>
                        {subscription ? (
                          <div className="text-xs text-[#98A2B3] mt-1">
                            {subscription
                              ? `${getDaysLeft(
                                  subscription[0].end_date
                                )} days left`
                              : ""}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <Spinner className="w-8 h-8" />
                    )}
                  </div>
                )}
              {props.subscriptionPlan &&
                props?.subscriptionPlan?.tier_no === 0 && (
                  <div className="text-sm text-[#98A2B3] mt-2">
                    No payments are due
                  </div>
                )}
              {/* <div
                className="text-sm text-gray-700 font-semibold cursor-pointer"
                onClick={() => {
                  props.switchTab("Logs");
                }}
              >
                View subscription history
              </div> */}
            </div>
          </div>
        </div>

        {parent === window && (
          <div>
            {props.subscriptionPlan && (
              <div className="mt-10">
                <div className="flex gap-2 items-center">
                  <span className="text-lg font-semibold text-gray-700">
                    Current Plan
                  </span>
                  <div className="relative">
                    <button
                      className="inline-flex items-center py-3 text-sm font-semibold text-center text-gray-70"
                      type="button"
                      // onClick={() => setCurrentPlanPopover(!showCurrentPlanPopover)}
                    >
                      <HelpCircle />
                    </button>

                    {/* {showCurrentPlanPopover && (
                <div ref={currentPlanPopover} className="absolute -mt-[96px]">
                  <div className="p-3 z-10 -ml-[130px] bg-gray-900 text-white text-sm leading-6 rounded-lg shadow-lg w-[265px]">
                    <p>Track available events based on your current plan.</p>
                  </div>
                  <div className="w-3 h-3 bg-gray-900 rotate-45 -mt-2 ml-1"></div>
                </div>
              )} */}
                  </div>
                </div>
                <div className="border rounded mt-4">
                  <div className="flex justify-between items-center p-6 border-b">
                    <div>
                      {" "}
                      <div className="flex gap-1">
                        <span className="text-2xl font-semibold text-[#475467]">
                          {props.subscriptionPlan.tier_name}
                        </span>
                        {subscription ? (
                          <div className="flex items-center">
                            <span className="bg-[#ECFDF3] text-xs text-[#008138] rounded-full px-2 py-1 flex justify-center items-center">
                              Active
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="text-sm text-[#667085] mt-1">
                        {props.subscriptionPlan.subscription_cost ? (
                          props.subscriptionPlan.subscription_cost[0].cost ===
                          -1 ? (
                            ""
                          ) : props.subscriptionPlan.subscription_cost[0]
                              .cost === 0 ? (
                            "Free"
                          ) : (
                            <CurrencySymbol
                              value={
                                props.subscriptionPlan.subscription_cost[0].cost
                              }
                            />
                          )
                        ) : (
                          "Free"
                        )}
                      </div>
                    </div>
                    {props.subscriptionPlan.tier_no !== 3 && (
                      <div className="flex gap-2">
                        {props.subscriptionPlan.tier_no !== 0 && (
                          <div>
                            {" "}
                            <span
                              onClick={() => {
                                setPaymentModalStatus(true);
                              }}
                              className="text-sm font-semibold cursor-pointer text-[#667085]"
                            >
                              Renew subscription
                            </span>
                          </div>
                        )}
                        <div>
                          <Link
                            className="text-sm text-[#FFFFFF] bg-blue-75 font-semibold px-5 py-3 rounded cursor-pointer"
                            to="/pricing"
                          >
                            Upgrade plan
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="py-8 px-7">
                    {props.subscriptionPlan.description
                      .split("\n")
                      .map((ele, i) => {
                        return (
                          <div
                            key={i}
                            className={`flex gap-4 text-sm text-[#667085] items-center ${
                              i !== 0 ? "mt-6" : ""
                            }`}
                          >
                            <Tick />
                            <span>{ele}</span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {/* <div>
          <div className="flex gap-3 items-center mt-8 mb-4">
            <div className="text-gray-700 text-lg font-semibold">
              Events breakup
            </div>
            <div className="relative">
              <button
                className="inline-flex items-center py-3 text-sm font-semibold text-center text-gray-70"
                type="button"
                onClick={() => setShowEventsPopover(!showEventsPopover)}
              >
                <HelpCircle />
              </button>

              {showCurrentPlanPopover && (
                <div ref={eventsPopover} className="absolute -mt-[96px]">
                  <div className="p-3 z-10 -ml-[130px] bg-gray-900 text-white text-sm leading-6 rounded-lg shadow-lg w-[265px]">
                    <p>Track available events based on your current plan.</p>
                  </div>
                  <div className="w-3 h-3 bg-gray-900 rotate-45 -mt-2 ml-1"></div>
                </div>
              )}
            </div>
          </div>

          {loading ? (
            <div className="mt-24">
              <Spinner className="w-8 h-8" />
            </div>
          ) : (
            <>
              {summaryError ? (
                <div className="flex flex-col bg-red-50 text-red-500 text-center items-center p-6 border border-red-500 rounded-lg">
                  <AlertCircle size={40} strokeWidth={1.5} />
                  <div className="mt-2">
                    An error occurred while fetching your billing summary.
                    Please reload the page or contact the administrator.
                  </div>
                </div>
              ) : (
                <>
                  {usageData?.map((product, index) => {
                    const { product_name, units, tiers } = product;

                    return (
                      <div
                        key={index}
                        className="p-6 border border-gray-100 rounded-md mb-8"
                      >
                        <div className="flex justify-between items-center">
                          <div>
                            <div className="text-gray-70 text-xl font-semibold">
                              {formatEvent(product_name)}
                            </div>
                            <div className="text-gray-500 font-medium mt-1">
                              {units.toLocaleString()} units available
                            </div>
                          </div>

                          <button
                            className="py-3.5 px-6 text-gray-90 font-semibold border rounded-lg bg-white border-gray-500 hover:border-gray-50"
                            onClick={() => {
                              showUnitModal(formatEvent(product_name), tiers);
                            }}
                          >
                            Buy units
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </div> */}
      </div>
      {unitModal && (
        <PaymentOptionModal
          topupModalState={topupModalState}
          handleClose={() => {
            closeDialog();
          }}
          setTopupModalState={setTopupModalState}
          selectedTopupEventType={topupEventType}
          topupEventUnits={topupEventUnits}
          topupEventPriceTiers={topupEventPriceTiers}
          handleTopupUnitsChange={handleTopupUnitsChange}
          accountStatus={accountStatus}
          handleSuccess={() => {
            closeDialog();
          }}
          amount={0}
        />
      )}
      {paymentModalStatus && (
        <PaymentOptionModal
          topupModalState="SUBSCRIPTION_MODAL"
          handleClose={() => {
            setPaymentModalStatus(false);
          }}
          handleSuccess={() => {
            successCallBack(true);
          }}
          topupEventPriceTiers={[]}
          setTopupModalState=""
          selectedTopupEventType={props.subscriptionPlan.tier_name}
          topupEventUnits={props.subscriptionPlan.subscription_cost[0].cost}
          handleTopupUnitsChange=""
          accountStatus=""
          tierId={props.subscriptionPlan.tier_no}
          planId={subscription[0].plan_id}
          productId=""
          amount={0}
        />
      )}
    </div>
  );
};

export default Summary;
