import { useState } from "react";
import { Close } from "../../assets/icons/icons";

const RejectPopUp = (props: any) => {
  const [openOtherInput, setOpenOtherInput] = useState(false);
  const [hideSave, setHideSave] = useState(true);
  const [error, setError] = useState("");
  const {
    setSelectReason,
    setOthertReason,
    otherReason,
    approveCampaigns,
    resetForm,
  } = props;

  const selectRejectReason = (e: any) => {
    if (e.target.value === "Other") {
      setOpenOtherInput(true);
    } else {
      setSelectReason(e.target.value);
      setHideSave(false);
    }
  };
  const selectOtherReason = (e: any) => {
    setOthertReason(e.target.value);
    if (e.target.value.length === 0) {
      setError("Reason can't be empty");
      setHideSave(true);
    } else {
      setHideSave(false);
    }
  };

  const rejectCampaign = () => {
    approveCampaigns("Rejected");
    resetForm();
  };

  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 flex items-center justify-center"
      onClick={() => {
        props.setOpenPopup(false);
        resetForm();
      }}
    >
      <div
        className="bg-white rounded-xl p-8"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <div className="flex justify-between mb-2">
            <div className="font-semibold text-gray-800 text-lg leading-7">
              Reject campaign
            </div>
            <div
              className="font-black cursor-pointer text-sm"
              onClick={() => {
                props.setOpenPopup(false);
                resetForm();
              }}
            >
              <img src={Close} alt="" />
            </div>
          </div>
          <div className="text-sm leading-6 text-gray-500 mb-6">
            <div>Select a reason for rejecting this campaign</div>
          </div>
          <div>
            <label
              htmlFor="Political content"
              className="mb-6 flex flex-col justify-center items-start"
            >
              <div className="flex gap-3 justify-center items-center">
                <input
                  onChange={selectRejectReason}
                  value="Political content"
                  type="radio"
                  name="reason"
                  className="mb-1.5"
                />
                <span className="font-semibold text-base text-gray-700 leading-6">
                  Political content{" "}
                </span>
              </div>
              <span className="text-gray-500 ml-6 text-sm leading-6">
                Includes promotion of political content
              </span>
            </label>
            <label
              htmlFor="Sexually explicit content"
              className="mb-6 flex flex-col justify-center items-start"
            >
              <div className="flex gap-3 justify-center items-center">
                <input
                  onChange={selectRejectReason}
                  value="Sexually explicit content"
                  type="radio"
                  name="reason"
                  className="mb-1.5"
                />
                <span className="font-semibold text-base text-gray-700 leading-6">
                  Sexually explicit content{" "}
                </span>
              </div>
              <span className="text-gray-500 ml-6 text-sm leading-6">
                Campaign contains sexually explicit content{" "}
              </span>
            </label>
            <label
              htmlFor="Violence, weapons and ammunition promotion"
              className="mb-6 flex flex-col justify-center items-start"
            >
              <div className="flex gap-3 justify-center items-center">
                <input
                  onChange={selectRejectReason}
                  value="Violence, weapons and ammunition promotion"
                  type="radio"
                  name="reason"
                  className="mb-1.5"
                />
                <span className="font-semibold text-base text-gray-700 leading-6">
                  Violence, weapons and ammunition promotion{" "}
                </span>
              </div>
              <span className="text-gray-500 ml-6 text-sm leading-6">
                Includes promotion of violence, weapons and ammunition{" "}
              </span>
            </label>
            <label
              htmlFor="Ponzi scheme promotion"
              className="mb-6 flex flex-col justify-center items-start"
            >
              <div className="flex gap-3 justify-center items-center">
                <input
                  onChange={selectRejectReason}
                  value="Ponzi scheme promotion"
                  type="radio"
                  name="reason"
                  className="mb-1.5"
                />
                <span className="font-semibold text-base text-gray-700 leading-6">
                  Ponzi scheme promotion{" "}
                </span>
              </div>
              <span className="text-gray-500 ml-6 text-sm leading-6">
                Includes promotion of ponzi schemes{" "}
              </span>
            </label>
            <label
              htmlFor="Promotion of illegal substances"
              className="mb-6 flex flex-col justify-center items-start"
            >
              <div className="flex gap-3 justify-center items-center">
                <input
                  onChange={selectRejectReason}
                  value="Promotion of illegal substances"
                  type="radio"
                  name="reason"
                  className="mb-1.5"
                />
                <span className="font-semibold text-base text-gray-700 leading-6">
                  Promotion of illegal substances{" "}
                </span>
              </div>
              <span className="text-gray-500 ml-6 text-sm leading-6">
                Includes promotion of banned and illegal substances{" "}
              </span>
            </label>
            <label
              htmlFor="Other"
              className="mb-6 flex flex-col justify-center items-start"
            >
              <div className="flex gap-3 justify-center items-center">
                <input
                  onChange={selectRejectReason}
                  value="Other"
                  type="radio"
                  name="reason"
                  className="mb-1.5"
                />
                <span className="font-semibold text-base text-gray-700 leading-6">
                  Other{" "}
                </span>
              </div>
              <span className="text-gray-500 ml-6 text-sm leading-6">
                Select to add other reasons for rejection{" "}
              </span>
            </label>
            {openOtherInput && (
              <>
                <textarea
                  onChange={selectOtherReason}
                  value={otherReason}
                  className={`py-2 px-3 w-full border ${
                    error ? "border-red-400" : "border-green-75"
                  } text-sm leading-6 rounded outline-0 text-gray-700`}
                  placeholder="Type a reason for rejecting this campaign. "
                />
                <div className="text-sm leading-6 text-gray-70 mt-1">
                  {error && <div className="text-red-400">{error}</div>}
                </div>
              </>
            )}
          </div>
          <div className="flex justify-end">
            <div
              className="border border-gray-50 rounded-lg mr-4 py-3 px-5 bg-white text-gray-90 font-semibold text-sm leading-4 cursor-pointer"
              onClick={() => {
                props.setOpenPopup(false);
              }}
            >
              Cancel
            </div>

            <div>
              <button
                disabled={hideSave}
                className={`rounded-lg py-3 px-5 ${
                  !hideSave && "hover:bg-green-75 hover:border-green-75"
                } bg-blue-75 border-blue-75 text-white font-semibold text-sm leading-4 cursor-pointer`}
                onClick={rejectCampaign}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectPopUp;
