import { getCost } from '../../../../marketing-cloud-main/src/common/apis/billing';
import { api } from '../../../../marketing-cloud-main/src/common/services/common-api-service';
import * as Sentry from '@sentry/react';

export const getStatus = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/campaign/status`;
  return api
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getChannels = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/channel`;
  return api
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getCampaignCost = (units, productId) => {
  return new Promise((resolve, reject) => {
    getCost(units, productId)
      .then((response) => {
        try {
          resolve(response?.cost ?? 0);
        } catch (error) {
          reject(error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getApproximateCost = (units, productId) => {
  return getCost(units, productId);
};
