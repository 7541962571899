import { Search } from "semantic-ui-react";
import { useRef, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import Bell from "../../assets/icons/Bell";
import "./header.css";
import NotificationListModal from "../../../modals/notification-list";
import { useSelector } from "react-redux";

const Header = (props: any) => {
  const { keycloak } = useKeycloak();
  const { pageName = "Dashboard" } = props;
  const [showNotificationListModal, setShowNotificationListModal] =
    useState(false);
  const notificationCount = useSelector((state: any) => state.totalCount);
  const notificationList = useSelector((state: any) => state.notificationList);

  const notificationPane = useRef(null);

  const closeOpenMenus = (e) => {
    if (
      notificationPane.current &&
      showNotificationListModal &&
      !notificationPane.current.contains(e.target)
    ) {
      setShowNotificationListModal(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  return (
    <div className="header-main h-[56px] w-full bg-white">
      <div className="header-wrapper mx-10 my-6 flex justify-between items-center">
        <div className="font-semibold	text-xl	text-gray-700 mb-0">{pageName}</div>
        <div className="header-right flex items-center relative">
          <Search
            input={{ icon: "search", iconPosition: "left" }}
            placeholder="Find"
            className="search-bar"
          />
          {keycloak.authenticated && (
            <div
              className="decoration-0 relative flex cursor-pointer"
              onClick={() => {
                setShowNotificationListModal(!showNotificationListModal);
              }}
            >
              <span className="ml-4">
                <Bell />
              </span>
              {notificationCount ? (
                <div className="absolute text-white w-5 h-5 top-[-8px] right-[-5px] rounded-full bg-rose-600 pt-0.5 text-center text-xs">
                  <span className="font-bold">
                    {notificationCount > 9 ? "9+" : notificationCount}
                  </span>
                </div>
              ) : null}
            </div>
          )}
          {showNotificationListModal && (
            <div className="absolute top-4 right-0 z-50" ref={notificationPane}>
              <NotificationListModal
                handleClose={() => {
                  setShowNotificationListModal(false);
                }}
                notificationList={notificationList}
              ></NotificationListModal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Header;
