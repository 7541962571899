import { useEffect, useState } from "react";
import {
  RatingStar,
  SurveyLogoPlaceholder,
} from "../../../../../assets/icons/icons";

const WebSurveyPreviewTypes = (element) => {
  const question = element.question;
  const [dummyArr, setDummyArr] = useState([]);

  useEffect(() => {
    if (question.type === "rating") {
      let c = Array.from(
        Array(
          Number(question.property.rating ? question.property.rating : 5)
        ).keys()
      );
      setDummyArr(c);
    }
  }, []);

  return (
    <div className="mt-3">
      {question.type === "text" ? (
        <div>
          <div className="text-[#D0D5DD] h-[40px] w-full py-2 px-3 border rounded text-sm flex items-center">
            Type your answer here
          </div>
        </div>
      ) : null}
      {question.type === "dropdown" ? (
        <div className="border rounded p-1 h-[40px] cursor-pointer">
          <select
            className="w-full h-full border-0 outline-0 texr-[#98A2B3] text-sm cursor-pointer"
            value={
              question.property.options[0].optionText === ""
                ? ""
                : question.property.options[0].optionText
                ? question.property.options[0].optionText
                : question.property.options[0]
            }
          >
            {question.property.options.map((ele, i) => {
              return (
                <option
                  key={i}
                  value={
                    ele.optionText === ""
                      ? ""
                      : ele.optionText
                      ? ele.optionText
                      : ele
                  }
                >
                  {ele.optionText === ""
                    ? ""
                    : ele.optionText
                    ? ele.optionText
                    : ele}
                </option>
              );
            })}
          </select>
        </div>
      ) : null}
      {question.type === "rating" ? (
        <div>
          <div className="flex gap-4">
            {dummyArr.map((element, index) => {
              return (
                <div key={index}>
                  <img src={RatingStar} alt="" />
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      {question.type === "linear scale" ? (
        <div className="flex gap-6">
          {(question.property.input
            ? question.property.input
            : question.property.options
          ).map((element, index) => {
            return (
              <div key={index}>
                <div>
                  <div className="h-[48px] w-[80px] flex justify-center items-center border rounded">
                    {index + 1}
                  </div>
                  <div className="text-gray-700 text-xs mt-1">
                    {element.optionText === ""
                      ? ""
                      : element.optionText
                      ? element.optionText
                      : element}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
      {question.type === "image choice" ? (
        <div className="flex gap-4 mt-4">
          {question.property.options.map((element, index) => {
            return (
              <div className="p-2 border rounded w-fit" key={index}>
                <div>
                  {element.imageURL || element.url ? (
                    <img
                      className="w-[167px] h-[113px] rounded"
                      src={element.url ? element.url : element.imageURL}
                      alt=""
                    />
                  ) : (
                    <div className="w-[167px] h-[113px] rounded flex justify-center items-center bg-[#d0d5dd]">
                      <img src={SurveyLogoPlaceholder} alt="" />
                    </div>
                  )}
                </div>
                <div className="flex gap-3 mt-3">
                  <div className="w-[16px] h-p16px] border rounded-full"></div>
                  <div className="text-sm text-[#667085">
                    {element.optionText === ""
                      ? ""
                      : element.optionText
                      ? element.optionText
                      : element.choice}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
      {question.type === "matrix" ? (
        <div>
          <div className="flex justify-end">
            <div className="flex justify-end mt-3 gap-3">
              {question?.property?.matrix?.column?.map((column, i) => {
                let index = i + 1;
                return (
                  <div key={i}>
                    <div className="w-[70px] outline-none border-none flex justify-center text-[#98A2B3] text-sm">
                      {question.property.matrix.column[i]}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            {question?.property?.matrix?.row?.map((c, i) => {
              let index = i + 1;
              return (
                <div key={i} className="flex items-center gap-[10px] mt-4">
                  <div className="w-full">
                    <div className="border-b h-[40px] w-full flex items-center">
                      <div className="border-0 outline-0 w-full h-full flex items-center text-[#98A2B3]">
                        {question.property.matrix.row[i]}
                      </div>
                      <div className="flex gap-3">
                        {question?.property?.matrix?.column?.map(
                          (column, i) => {
                            return (
                              <div
                                key={i}
                                className="w-[70px] h-full flex justify-center items-center"
                              >
                                <div className="h-[20px] w-[20px] border rounded-full"></div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      {question.type === "form" ? (
        <div>
          {question?.property?.options?.map((c, i) => {
            let index = i + 1;
            return (
              <div key={i} className="mt-4">
                <div className="flex gap-6">
                  <div>
                    <div className="w-full h-full text-[#475467] font-semibold flex items-center">
                      {c.optionText === ""
                        ? ""
                        : c.optionText
                        ? c.optionText
                        : c}
                    </div>
                  </div>
                </div>
                <div className="h-[40px]  w-[100%] border rounded text-[#98A2B3] text-sm opacity-80 py-2 px-3 flex items-center mt-2"></div>
              </div>
            );
          })}
        </div>
      ) : null}
      {question.type === "multiple choice" ? (
        <div>
          {question.property.options.map((element, index) => {
            return (
              <div key={index} className="flex gap-4 items-center mt-2">
                <div className="h-[20px] w-[20px] border rounded-full"></div>
                <div className="border-b h-[40px] w-full flex items-center text-[#98A2B3]">
                  {element.optionText === ""
                    ? ""
                    : element.optionText
                    ? element.optionText
                    : element}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default WebSurveyPreviewTypes;
