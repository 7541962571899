import { useEffect, useState } from "react";

const FormProperty = (props: any) => {
  const [question, setQuestion] = useState(props.question);

  useEffect(() => {
    setQuestion(props.question);
  });

  const onSelectMultipleChange = () => {
    let value = question.property?.selectMultiple
      ? question.property.selectMultiple
      : false;
    value = !value;
    let q = question;
    q.property.selectMultiple = value;
    props.setRating(q);
  };

  return (
    <div className="mt-6">
      <div className="flex gap-4 items-center">
        <div>
          <div
            onClick={() => {
              onSelectMultipleChange();
            }}
            className={
              question.property?.selectMultiple
                ? "bg-blue-75 h-[20px] w-[36px] rounded-xl p-1 flex justify-end cursor-pointer"
                : "bg-[#F2F4F7] h-[20px] w-[36px] rounded-xl p-1 flex justify-start cursor-pointer"
            }
          >
            <div className="bg-[#FFFFFF] rounded-full h-[14px] w-[14px] drop-shadow-md"></div>
          </div>
        </div>
        <div className="text-sm font-semibold text-[#475467]">
          Select multiple
        </div>
      </div>
    </div>
  );
};

export default FormProperty;
