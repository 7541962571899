import { useState, createContext, useEffect } from "react";
import EmailStepOne from "../email-step-one/email-step-one";
import EmailStepTwo from "../email-step-two/email-step-two";
import EmailStepThree from "../email-step-three/email-step-three";
import { getMessageDetails } from "../../../common/services/message-services";
import { getWalletDetails } from "../../../../../marketing-cloud-main/src/common/apis/billing";
import * as Sentry from "@sentry/react";

export const TenantContext = createContext({});

const EmailParent = (props: any) => {
  let { dispatch, setShowCommonLoader, setWalletBalance, walletBalance } =
    props;
  let emailBody: any = {
    id: "",
    conversationId: "",
    name: "",
    channel: "EMAIL",
    content: "",
    phonenumberId: "",
    messageType: "PUSH",
    chatChannel: "",
    senderName: "",
    landingPagesURL: "",
    status: "DRAFT",
    objective: "",
    businessId: "",
    budget: 0,
    pricing: 0,
    schedule: {
      immediate: "",
      timeline: {
        start: "",
        end: "",
      },
    },
    audience: {
      segmentId: "",
      phoneNumbers: [],
    },
  };
  const [step, setStep] = useState(1);
  const [emailData, setSmsData] = useState(emailBody);

  const setStepFromPage = (step) => {
    setStep(step);
  };

  const setSmsDataFromPage = (data) => {
    setSmsData(data);
  };

  useEffect(() => {
    if (props.id) {
      dispatch(setShowCommonLoader(true));
      try {
        getMessageDetails(props.id)
          .then((res) => {
            dispatch(setShowCommonLoader(false));
            const { error, response = {} } = res;
            if (!error || error === "false") {
              setSmsData(response);
            } else {
              error.response
                ? Sentry.captureMessage("Error", error.response)
                : Sentry.captureMessage("Error", error);
            }
          })

          .catch((err) => {
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            console.log(err);
          });
      } catch (err) {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
      }
    }
  }, []);

  const getWalletBalance = () => {
    try {
      getWalletDetails()
        .then((response) => {
          if (response) {
            if (response?.data[0]?.balance) {
              dispatch(setWalletBalance(response.data[0].balance));
            }
          }
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log(error);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  return (
    <TenantContext.Provider value={props.tenantContext}>
      <div>
        <div>
          {step == 1 ? (
            <EmailStepOne
              data={emailData}
              setStep={setStepFromPage}
              setData={setSmsDataFromPage}
              country={props.country}
              businessName={props.businessName}
              currencySymbol={props.currencySymbol}
              navigate={props.navigate}
            />
          ) : null}
          {step == 2 ? (
            <EmailStepTwo
              data={emailData}
              setStep={setStepFromPage}
              setData={setSmsDataFromPage}
              country={props.country}
              currencySymbol={props.currencySymbol}
              navigate={props.navigate}
            />
          ) : null}
          {step == 3 ? (
            <EmailStepThree
              data={emailData}
              setStep={(step) => {
                setStepFromPage(step);
              }}
              setData={(data) => {
                setSmsDataFromPage(data);
              }}
              getBalance={() => getWalletBalance()}
              walletBalance={walletBalance}
              country={props.country}
              currencySymbol={props.currencySymbol}
              navigate={props.navigate}
            />
          ) : null}
        </div>
        <div></div>
      </div>
    </TenantContext.Provider>
  );
};

export default EmailParent;
