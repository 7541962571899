import { getCustomerId } from '../constants/billingConstants';
import { api } from '../../../../marketing-cloud-main/src/common/services/common-api-service';
import * as Sentry from '@sentry/react';
import { SURVEY_URL } from '../../../../marketing-cloud-main/src/common/constants/baseUrl';

export const createSurvey = (data) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/survey`;
  return api
    .post(url, data, {
      headers: { Authorization: `Bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getSurveyDetails = (id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/survey/${id}`;
  return api
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const uploadLogo = (file: any) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/media`;
  const data = new FormData();
  data.append('file', file);
  data.append('mediaType', 'image');

  return api
    .post(url, data, {
      headers: { Authorization: `Bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const updateSurvey = (data, id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/survey/${id}`;
  return api
    .put(url, data, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getUssdCodes = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/shortcodes`;
  const customerId = getCustomerId();
  return api
    .get(url, {
      headers: { Authorization: `Bearer ${loginAccessToken}` },
      params: {
        bid: customerId,
        country: 'kenya',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
      console.log(error);
    });
};

export const getQuestionTypes = (surveyType) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/question-types?surveyType=${surveyType}`;
  return api
    .get(url, {
      headers: { Authorization: `Bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getSurveyReportDetails = (id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  id = id.replaceAll('~', '%2F');
  const url = `/survey-reports?reportType=survey-details&surveyId=${id}`;
  return api
    .get(url, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getSurveySummary = (
  type,
  id,
  phoneNumber?,
  startDate = '',
  endDate = '',
  from = '',
  size = '',
  completeFlag: any = '',
  questionIndex: any = '',
  timeZone: any = ''
) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/survey-reports?reportType=${type}&surveyId=${id}&phoneNumber=${phoneNumber}&startDate=${startDate ? startDate.substring(0, 10) : ''
    }&endDate=${endDate ? endDate.substring(0, 10) : ''
    }&from=${from}&size=${size}&completeFlag=${completeFlag}&questionIndex=${questionIndex}&timezone=${timeZone}`;
  return api
    .get(url, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getSurveyList = (params: any) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const {
    startDate = '',
    endDate = '',
    from = 0,
    size = 10,
    channel = '',
    audience = '',
    status = '',
    pageNumber = 0,
    searchSurveys = '',
  } = params;
  let url = `/surveys?from=${from}&size=${size}&businessId=${getCustomerId()}`;

  if (startDate) {
    url = url + `&startDate=${startDate}`;
  }
  if (endDate) {
    url = url + `&endDate=${endDate}`;
  }
  if (audience) {
    url = url + `&audience=${audience}`;
  }
  if (channel) {
    url = url + `&channel=${channel}`;
  }
  if (status) {
    url = url + `&status=${status}`;
  }
  if (searchSurveys) {
    url = url + `&search_query=${searchSurveys}`; // TODO: To update the payload for search_query
  }

  return api
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const downloadSurveyReportCSV = (id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/survey/download/report?surveyId=${id}`;
  return api
    .get(url, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      err.response
        ? Sentry.captureMessage('Error', err.response)
        : Sentry.captureMessage('Error', err);
      console.log(err);
    });
};

export const getSurveyRulesMetaData = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/survey/web/operators`;
  return api
    .get(url, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      err.response
        ? Sentry.captureMessage('Error', err.response)
        : Sentry.captureMessage('Error', err);
      console.log(err);
    });
};

export const getSurveyLink = (surveyData) => {
  if (surveyData.surveyLink) {
    return surveyData.surveyLink;
  } else {
    let id: any = surveyData?.id;
    id = id.replaceAll('/', '%2F');
    return `${SURVEY_URL}${id}?live=true`;
  }
};
