import { useEffect, useState } from "react";
import { Close, PhoneSms, User } from "../../assets/icons/icons";
import Spinner from "../../../../marketing-cloud-main/src/common/components/spinner/Spinner";

const SurveyInviteMessage = (props) => {
  const [characterCount, setCharacterCount] = useState(0);
  const [edit, setEdit] = useState(false);
  let MAX_CHARACTER_COUNT = 240;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    text: "",
    personalisation: false,
    copyId: "",
  });

  useEffect(() => {
    if (props.selectedIndex !== null) {
      setEdit(true);
      setFormData({
        ...formData,
        text: props.selectedMessage?.text,
        personalisation: false,
      });
    }
  }, []);

  const addMessage = () => {
    edit
      ? props.editInviteMessage(props.selectedIndex, formData)
      : props.addInviteMessage(formData);
    props.closeModal();
  };

  return (
    <div className="fixed left-0 right-0 bottom-0 top-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg w-3/5 p-7 text-base text-gray-600 font-semibold max-h-[80%] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <span>{edit ? "Edit " : "Add "}survey message</span>
          <div onClick={props.closeModal} className="cursor-pointer">
            <img src={Close} alt="" />
          </div>
        </div>
        <div className="flex items-start justify-between gap-6">
          <div className="grow">
            <label className="block text-sm font-semibold mb-8">
              <div className="mb-1">Message body</div>
              <div className="border rounded-lg">
                <textarea
                  placeholder="message goes here"
                  value={formData.text}
                  onChange={(event) => {
                    setFormData({ ...formData, text: event.target.value });
                  }}
                  className="border-b  w-full text-sm font-normal rounded-t-lg p-3 outline-0 min-h-[150px]"
                  maxLength={MAX_CHARACTER_COUNT}
                ></textarea>
                <div className="rounded-b-lg">
                  <div className="py-2 px-4">
                    {" "}
                    <img src={User} alt="" />
                  </div>
                  {props.senderName && (
                    <div className="bg-gray-60 py-2 px-4 rounded-b-lg">
                      Courtesy: {props.senderName}
                    </div>
                  )}
                </div>
              </div>
              <div className="font-normal text-xs leading-6 mt-1">
                {characterCount + "/160 characters"}
              </div>
            </label>
            {props.url && (
              <label className="block mb-8">
                <div className="text-sm text-gray-600 font-semibold mb-1">
                  Destination URL
                </div>
                <input
                  value={props.url}
                  className="outline-0 p-3 rounded-lg w-full border text-sm font-normal"
                  disabled
                  placeholder="Enter URL"
                ></input>
              </label>
            )}
            <div className="flex justify-end gap-4 text-sm leading-6 font-semibold mt-7">
              <button
                className="px-5 py-3 border rounded-lg"
                disabled={loading}
                onClick={props.closeModal}
              >
                Cancel
              </button>
              <button
                className="px-20 py-3 bg-blue-75 text-white rounded-lg disabled:bg-blue-75"
                disabled={loading}
                onClick={addMessage}
              >
                {loading ? (
                  <Spinner className={"w-4 h-4"} />
                ) : edit ? (
                  "Update invite"
                ) : (
                  "Add invite"
                )}
              </button>
            </div>
          </div>
          <div className="relative">
            <img src={PhoneSms} alt="" />
            <div className="absolute bottom-24 left-8 bg-gray-50 text-xs rounded-lg p-2 w-[150px] min-h-[80px] break-words">
              {" "}
              {formData.text}
              {props.url && <div className="text-blue-50">{props.url}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyInviteMessage;
