import ReactECharts from "echarts-for-react";
import { useEffect, useState } from "react";

const colors = {
  successful: "#12B76A",
  failed: "#FCD98D",
};

const ProfilesRewarded = ({ profilesReport }) => {
  const [option, setOption] = useState({});

  useEffect(() => {
    const xData = ["Successful", "Failed"];
    const yData = [];

    yData.push({
      value: profilesReport?.total_success,
      itemStyle: { color: colors["successful"], barBorderRadius: [5, 5, 0, 0] },
    });
    yData.push({
      value: profilesReport?.total_fail,
      itemStyle: { color: colors["failed"], barBorderRadius: [5, 5, 0, 0] },
    });

    const chartOptions = {
      tooltip: {},
      xAxis: {
        type: "category",
        data: xData,
      },
      yAxis: {
        type: "value",
        name: "Profiles",
        nameLocation: "middle",
        nameGap: 45,
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
          },
        },
      },
      series: [
        {
          data: yData,
          barWidth: "60%",
          type: "bar",
        },
      ],
    };

    setOption(chartOptions);
  }, []);

  return (
    <div
      className={`border rounded p-6 mt-6 w-full lg:w-[calc(50%-10px)] h-[400px]`}
    >
      <div className="text-base text-gray-700 font-semibold">
        Profiles rewarded
      </div>
      <div className="mt-4 text-base font-semibold text-gray-70">
        <span className="text-sm font-normal">Total: </span>
        {profilesReport?.total_profiles || 0}
      </div>

      <div className="-mt-5">
        {option && <ReactECharts style={{ height: "350px" }} option={option} />}
      </div>
    </div>
  );
};

export default ProfilesRewarded;
