import { useParams } from "react-router-dom";
import Header from "../../common/components/header";
import { importModule } from "../../common/utils/importModule";
import { useDispatch } from "react-redux";
import { setShowCommonLoader } from "../../redux-loader/loader-slice/loaderSlice";
import { useContext, useEffect } from "react";
import { pageLoadEvent } from "../../../../marketing-cloud-container/src/ga-events";
import { TenantContext } from "../home";

const Conversation = importModule("@tg/conversations", "ConversationsOverview");

const ConversationsOverview = () => {
  useEffect(() => {
    pageLoadEvent("conversations_overview_page");
  }, []);

  const dispatch = useDispatch();
  const tenantContext: any = useContext(TenantContext);
  let { id } = useParams();
  return (
    <div>
      <Header pageName="Conversations - Overview" />
      <div>
        <Conversation
          dispatch={dispatch}
          setShowCommonLoader={setShowCommonLoader}
          tenantContext={tenantContext}
        />
      </div>
    </div>
  );
};

export default ConversationsOverview;
