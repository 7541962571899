const ThreeDotsMenu = () => {
  return (
    <div className="flex gap-1">
      <div
        style={{
          backgroundColor: " #667085",
          width: "4px",
          height: "4px",
          borderRadius: "50%",
        }}
      ></div>
      <div
        style={{
          backgroundColor: " #667085",
          width: "4px",
          height: "4px",
          borderRadius: "50%",
        }}
      ></div>
      <div
        style={{
          backgroundColor: " #667085",
          width: "4px",
          height: "4px",
          borderRadius: "50%",
        }}
      ></div>
    </div>
  );
};
export default ThreeDotsMenu;
