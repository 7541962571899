import { useContext } from "react";
import { TenantContext } from "../../../pages/home";
import { getCurrenySymbol } from "../../utils/currencySymbol";
import { formatNumbers } from "../../utils/UserInterfaceUtils";

const CurrencySymbol = (props) => {
  const tenantContext: any = useContext(TenantContext);
  let currencySymbol = getCurrenySymbol(
    tenantContext ? tenantContext.country : "Nigeria",
    tenantContext ? tenantContext.currency : "NGN"
  );

  const getFixedValue = (value) => {
    try {
      return value.toFixed(2);
    } catch (error) {
      return value;
    }
  };

  return (
    <span>
      {currencySymbol}{" "}
      {props.value
        ? formatNumbers(getFixedValue(props.value))
        : props.value === 0
        ? props.value
        : ""}
    </span>
  );
};
export default CurrencySymbol;
