import { createContext, useEffect, useState } from "react";
import StepOne from "../step-one/step-one";
import MesssagingStepThree from "../step-three/step-three";
import { getCustomerId } from "../../../common/constants/billingConstants";
import { getMessageDetails } from "../../../common/services/message-services";
import { getWalletDetails } from "../../../../../marketing-cloud-main/src/common/apis/billing";
import * as Sentry from "@sentry/react";
import StepTwo from "../step-two/step-two";

export const TenantContext = createContext({});

const MessageParent = (props) => {
  let {
    country,
    currency,
    id,
    dispatch,
    setShowCommonLoader,
    setWalletBalance,
    walletBalance,
  } = props;

  let messageBody = {
    id: "",
    conversationId: "",
    name: "",
    channel: "WHATSAPP",
    content: {
      type: "TEMPLATE",
      templateInfo: {
        name: "",
        language: "en",
      },
      params: [""],
      media: {
        type: "IMAGE",
        url: "",
      },
    },
    phonenumberId: "",
    messageType: "PUSH",
    chatChannel: "484575796253066_917909199817",
    senderName: "Terragon",
    landingPagesURL: "",
    status: "DRAFT",
    objective: "",
    businessId: getCustomerId(),
    budget: 0,
    pricing: 0,
    schedule: {
      immediate: "true",
      timeline: {
        start: "",
        end: "",
      },
    },
    audience: {
      segmentId: "",
      phoneNumbers: [],
    },
    audienceName: "",
  };
  const [step, setStep] = useState(1);
  const [messageData, setMessageData] = useState(messageBody);

  useEffect(() => {
    if (id) {
      dispatch(setShowCommonLoader(true));
      try {
        getMessageDetails(id)
          .then((res) => {
            const { error, response = {} } = res;
            if (!error || error === "false") {
              setMessageData(response);
              dispatch(setShowCommonLoader(false));
            } else {
              error.response
                ? Sentry.captureMessage("Error", error.response)
                : Sentry.captureMessage("Error", error);
              dispatch(setShowCommonLoader(false));
            }
          })

          .catch((err) => {
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            console.log(err);
            dispatch(setShowCommonLoader(false));
          });
      } catch (err) {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        dispatch(setShowCommonLoader(false));
      }
    }
  }, []);

  useEffect(() => {
    const states = props.location;
    if (states.state) {
      setMessageData(states.state);
    }
  }, []);

  const setStepFromPage = (step) => {
    setStep(step);
  };

  const setMessageDataFromPage = (data) => {
    setMessageData(data);
  };

  const getWalletBalance = () => {
    try {
      getWalletDetails()
        .then((response) => {
          if (response) {
            if (response?.data[0]?.balance) {
              dispatch(setWalletBalance(response.data[0].balance));
            }
          }
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log(error);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  return (
    <TenantContext.Provider value={props.tenantContext}>
      <div>
        <div>
          {step == 1 ? (
            <StepOne
              data={messageData}
              setStep={(step) => {
                setStepFromPage(step);
              }}
              setData={(data) => {
                setMessageDataFromPage(data);
              }}
              country={country}
              currency={currency}
              navigate={props.navigate}
            />
          ) : null}
        </div>
        <div>
          {step == 2 ? (
            <StepTwo
              id={id}
              data={messageData}
              setStep={(step) => {
                setStepFromPage(step);
              }}
              setData={(data) => {
                setMessageData(data);
              }}
              country={country}
              currency={currency}
              navigate={props.navigate}
            />
          ) : null}
        </div>
        <div>
          {step == 3 ? (
            <MesssagingStepThree
              data={messageData}
              setStep={(step) => {
                setStepFromPage(step);
              }}
              setData={(data) => {
                setMessageData(data);
              }}
              getBalance={() => getWalletBalance()}
              walletBalance={walletBalance}
              country={country}
              currency={currency}
              navigate={props.navigate}
            />
          ) : null}
        </div>
      </div>
    </TenantContext.Provider>
  );
};

export default MessageParent;
