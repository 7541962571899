import { useEffect, useState } from "react";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import {
  getSurveyLink,
  updateSurvey,
} from "../../../common/services/survey-services";
import UnitValidation from "../../../../../marketing-cloud-main/src/common/components/unit-validation/unit-validation";
import * as Sentry from "@sentry/react";
import { getCampaignCost } from "../../../common/services/common-service";
import { getCurrenySymbol } from "../../../../../marketing-cloud-main/src/common/utils/currencySymbol";
import SurveyData from "./surveyData";
import { postAudience } from "../../../../../audience/src/pages/audience/apis/segmentApis";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import {
  ArrowRight,
  SurveyLaunchIconLaunch,
  SurveyLaunchIconSidebar,
} from "../../../assets/icons/icons";

const SurveyLaunch = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [unitModalState, setUnitModalState] = useState(false);

  const [surveyType, setSurveyType] = useState("");
  const [campaignCost, setCampaignCost] = useState(null);
  let surveyData = props.data;

  useEffect(() => {
    surveyData?.audienceType === "general"
      ? setSurveyType("OPEN")
      : setSurveyType("CLOSED");
    updateCampaignCost(1);
  }, []);

  const country = props.country;
  const currency = props.currency;
  const currencySymbol = getCurrenySymbol(country, currency);

  // const getSurveyLink = () => {
  //   let id: any = surveyData?.surveyId || surveyData?.id;
  //   id = id.replaceAll("/", "%2F");
  //   return `${SURVEY_URL}${id}?live=true`;
  // };

  const createAudience = () => {
    let payload = {
      audience_type: "static",
      audience_name: `${surveyData.name}-custom audience`, // required
      audience_description: `audience created for survey ${surveyData.name}`,
      client_id: "data_team",
      audience_size: surveyData.profiles,
      immediate: false,
      ...surveyData.audienceQuery,
    };
    postAudience(payload)
      .then((audience: any) => {
        let audience_id = audience.data.audience_id;

        surveyData.audience = {
          segmentId: audience_id,
          phoneNumbers: [],
        };
        updateSurveyData();
      })
      .catch((err) => {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
      });
  };

  const validateWallet = () => {
    setLoading(true);
    setUnitModalState(true);
  };

  function launchSurvey() {
    setUnitModalState(false);
    setLoading(true);

    surveyData.status = "RUNNING";
    if (surveyType === "OPEN") {
      surveyData.surveyType = "OPEN";
    }

    surveyData.audienceType === "segment" &&
    surveyData.manualType === "customAudience" &&
    surveyData.audienceQuery
      ? createAudience()
      : updateSurveyData();
  }

  const updateSurveyData = () => {
    try {
      let id: any = surveyData?.surveyId || surveyData?.id;
      id = id.replaceAll("/", "%2F");
      surveyData?.channel === "WEB"
        ? (surveyData.surveyLink = getSurveyLink(surveyData))
        : null;
      updateSurvey(surveyData, id)
        .then((res) => {
          const { error, response = [] } = res;
          if (!error || error === "false") {
            setLoading(false);
            props.getBalance();
            Notify({
              type: "success",
              heading: "Campaign created successfully",
              message:
                "Your campaign has been created successfully and will go live at the scheduled time after it has been approved by our content and ad policy team.",
            });
            props.navigate("/engagements/surveys");
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
            setLoading(false);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          setLoading(false);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
    }
  };

  const updateCampaignCost = async (productId) => {
    setLoading(true);
    let cost = Number(await getCampaignCost(props.data?.budget, productId));
    if (props.data?.rewardBudget) {
      cost += Number(props.data?.rewardBudget);
    }
    setCampaignCost(cost);
    setLoading(false);
  };

  return (
    <div className="mt-10 ml-10">
      <span className="inline-block font-semibold text-sm leading-6 text-gray-500 mb-2">
        STEP 4 OF 4
      </span>
      <div className="flex items-center justify-between gap-6 mb-8">
        <div className="flex items-center gap-6">
          <img src={SurveyLaunchIconLaunch} alt="" />
          <div>
            <span className="block font-semibold text-base leading-6 text-gray-70">
              Preview and Launch
            </span>
            <span className="text-gray-500 text-sm">
              Preview your survey details and click "Launch" if everything
              checks out
            </span>
          </div>
        </div>
        {/* <div className="w-[20%] ml-auto mr-10">
          <div className="bg-[#EBEFF3] max-w-sm mx-auto mt-4 rounded-lg overflow-hidden">
            <div className="bg-green-500 text-xs leading-none py-0.5 w-[100%]"></div>
          </div>
          <span className="flex items-end justify-end text-right py-2 text-xs font-medium text-gray-500">
            Final step!
          </span>
        </div> */}
        {/* <div className="flex justify-end py-10 mr-9"> */}
        <div className="flex gap-4 mr-9">
          <button
            onClick={() => props.setStep(3)}
            className="py-3 px-6 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
          >
            Back
          </button>
          <button
            onClick={validateWallet}
            disabled={loading}
            className="py-3 px-5 w-28 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75 disabled:bg-blue-75 flex justify-center items-center"
          >
            {loading ? (
              <Spinner className={"w-4 h-4"} />
            ) : (
              <div className="flex gap-3 items-center">
                {"Launch  "} <img src={ArrowRight} alt="" />
              </div>
            )}
          </button>
        </div>
        {/* </div> */}
      </div>
      <div className="flex justify-between">
        <div className="grow">
          <div className="mb-4">
            <span className="text-gray-700 font-semibold mr-4">Survey</span>

            <span
              onClick={() => {
                props.setStep(1);
              }}
              className="text-green-15 cursor-pointer"
            >
              Edit
            </span>
          </div>
          <SurveyData data={surveyData} edit={true} setStep={props.setStep} />
        </div>
        <div className="mr-10">
          <img src={SurveyLaunchIconSidebar} alt="" />
        </div>
      </div>
      {unitModalState && (
        <UnitValidation
          productId={1}
          requiredUnits={campaignCost}
          currencySymbol={currencySymbol}
          successCallBack={(status) => {
            if (status) {
              launchSurvey();
            } else {
              setLoading(false);
              setUnitModalState(false);
            }
          }}
          type="Survey"
        />
      )}
    </div>
  );
};
export default SurveyLaunch;
