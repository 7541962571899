import env from '../../../env.json';
import { getCustomerId } from '../constants/authToken';
import { api, billingApi } from '../services/common-api-service';
import * as Sentry from '@sentry/react';

const clientId = env.CLIENT_ID;

export const getUsageLogs = (params: any) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const { from = '', size = 10, dateRange } = params;

  let url = `/wallet/transactions?size=${size}`;

  if (from) {
    url = url + `&from=${from}`;
  }

  if (dateRange && dateRange.startDate && dateRange.endDate) {
    url =
      url + `&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
  }

  return billingApi
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
        'client-id': clientId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const downloadBillingReport = (params: any) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const { dateRange } = params;

  let url = `/message/download/report`;

  if (dateRange && dateRange.startDate && dateRange.endDate) {
    url =
      url + `?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
  }

  return api
    .get(url, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getWalletTransactions = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const customerId = getCustomerId();
  const url = `/wallet/transactions`;
  return billingApi
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
        'client-id': clientId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getAccountStatus = async () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  return new Promise((resolve, reject) => {
    Promise.all([getProducts(), getAccountStat()])
      .then((response: any) => {
        let accountStatus = getProductStatMap(response[1], response[0]);
        resolve(accountStatus);
      })
      .catch((error) => {
        error.response
          ? Sentry.captureMessage('Error', error.response)
          : Sentry.captureMessage('Error', error);
        console.log(error);
      });
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getAccountStat = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  //const customerId = getCustomerId();
  const customerId = localStorage.getItem('businessId');
  const url = `/account/status/details`;
  return billingApi
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
        'client-id': clientId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getSubscriptionPlans = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const customerId = getCustomerId();
  const url = `/subscription/plans`;
  return billingApi
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
        'client-id': clientId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getTopupPlans = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const customerId = getCustomerId();
  const url = `/topup/plans`;
  return billingApi
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
        'client-id': clientId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const verifyPayment = (
  payment_reference_id: any,
  payment_gateway: string,
  type: string,
  planId,
  tierId,
  productId,
  units
) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/payments/verify`;
  const customerId = getCustomerId();
  let payload: any = {};
  if (type === 'subscription') {
    payload = {
      reference: `${payment_reference_id}`,
      user_id: customerId,
      do_subscription: true,
      subscription: {
        plan_id: planId,
        tier_id: tierId,
        customer_id: customerId,
        service: 'packaged',
        recurring: {
          interval: 'monthly',
          interval_count: 1,
        },
      },
      payment_gateway: payment_gateway,
    };
  } else {
    payload = {
      reference: `${payment_reference_id}`,
      user_id: customerId,
      do_topup: true,
      topup: {
        plan_id: planId,
        customer_id: customerId,
        product_id: productId,
        units: units,
      },
      payment_gateway: payment_gateway,
    };
  }

  return billingApi
    .post(url, payload, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
        'client-id': clientId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getWalletDetails = (id = 0) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const customerId = getCustomerId();
  const url = `/wallet/transactions`;
  return billingApi
    .get(url, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
        'client-id': `${clientId}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getPricingDetails = (id = 0) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/topup/plans`;
  return billingApi
    .get(url, {
      headers: {
        Authorization: `Bearer ${loginAccessToken}`,
        'client-id': `${clientId}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getProducts = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/products`;
  return billingApi
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
        'client-id': clientId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

const getProductStatMap = (stats, products) => {
  let productStat = [];
  stats.account_status.map((stat) => {
    let product =
      products[
        products.findIndex((x) => {
          return x.id === stat.product_id;
        })
      ];
    let p = {
      units: stat.units,
      product_id: stat.product_id,
      label: product.label,
      product_name: product.product_name,
      threshold: stat.threshold,
      product_category: product.product_category,
      description: product.description,
    };
    productStat.push(p);
  });
  // products.map((product) => {
  //   console.log("product", product);
  //   let status = stats.account_status[stats.account_status.findIndex((x) => {
  //     return x.product_id === product.id
  //   })]
  //   let p = { units: status?.units ? status.units : 0, product_id: product.id, label: product.label, product_name: product.product_name, threshold: status?.threshold ? status.threshold : 0, product_category: product.product_category, description: product.description }
  //   productStat.push(p)
  // })
  stats.account_status = productStat;
  return stats;
};

export const getCost = (units, productId) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/cost?productId=${productId}&units=${units}`;
  return billingApi
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
        'client-id': clientId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getTransactions = (params: any) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const {
    transactionType = '',
    transactionListFrom = 1,
    transactionListSize = 10,
    businessId,
  } = params;

  let url = `/admin/wallet/transaction?customer_id=${businessId}&page=${transactionListFrom}&page_size=${transactionListSize}`;

  if (transactionType) {
    url = url + `&transaction_type=${transactionType}`;
  }

  return billingApi
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
        'client-id': clientId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const manualTopup = (amount, id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/admin/wallet/fund`;
  let body = {
    amount: amount,
    customer_id: id,
    source: 'manual',
  };
  return billingApi
    .post(url, body, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
        'client-id': clientId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getMessageList = ({
  from = 0,
  startDate = '',
  endDate = '',
  size = 10,
  channel = '',
  status = '',
  messageText = '',
  businessId = '',
}) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  let url = `/message?from=${from}&size=${size}&channel=${channel}&status=${status}&business_id=${businessId}`;

  if (startDate || endDate) {
    url = url + `&startDate=${startDate}&endDate=${endDate}`;
  }
  if (messageText) {
    url = url + `&search_query=${messageText}`;
  }

  return api
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};
