import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import {
  Add,
  Ellipsis,
  RightArrow,
  Play,
  ChevronBack,
  Plus,
  SurveyLogoPlaceholder,
  CrossSmall,
  ArrowRight,
} from "../../../assets/icons/icons";
import {
  getQuestionTypes,
  getSurveyRulesMetaData,
  updateSurvey,
  uploadLogo,
} from "../../../common/services/survey-services";
import { QuestionFile } from "../../../assets/icons/icons";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import MultipleChoice from "./web-survey-questions/multiple-choice";
import Rating from "./web-survey-questions/rating";
import LinearScale from "./web-survey-questions/linear-scale";
import Text from "./web-survey-questions/text";
import TextProperty from "./web-survey-question-property/text-property";
import MultipleChoiceProperty from "./web-survey-question-property/multiple-choice-property";
import RatingProperty from "./web-survey-question-property/rating-property";
import LinearScaleProperty from "./web-survey-question-property/linear-scale-property";
import Loader from "../../../../../marketing-cloud-main/src/pages/settingsComponents/Engagements/whatsappTemplateTab/Loader";
import Dropdown from "./web-survey-questions/dropdown";
import Matrix from "./web-survey-questions/matrix";
import DropdownProperty from "./web-survey-question-property/dropdown-property";
import MatrixProperty from "./web-survey-question-property/matrix-property";
import FormProperty from "./web-survey-question-property/form-property";
import Form from "./web-survey-questions/form";
import ImageChoice from "./web-survey-questions/image-choice";
import ImageChoiceProperty from "./web-survey-question-property/image-choice-propert";
import WebSurveyPreview from "./web-survey-preview/web-survey-preview";
import WebSurveyRules from "./web-survey-rules/web-survey-rules";
import * as Sentry from "@sentry/react";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";

const SurveyStepTwoWeb = (props: any) => {
  useEffect(() => {
    if (props?.surveyQuestionData) {
      setPages(props.surveyQuestionData);
    } else if (props?.data?.content?.questions) {
      let questions = [];
      props.data.content.questions.map((page) => {
        let pageQuestions = [];
        page.elements.map((question) => {
          let q: any = {};
          q.q = question?.text;
          q.s = question?.subQuestion;
          q.index = question?.index;
          q.type = question?.type;
          q.property = question?.property;
          q.rules = question?.rules;
          pageQuestions.push(q);
        });
        questions.push(pageQuestions);
      });
      setPages(questions);
    }
    if (props?.data?.content?.endMessage)
      setEndMessage(props.data.content.endMessage.questionText);
    if (props?.data?.name) {
      setIntroduction(() => ({
        description: props?.data?.content?.surveyDetails
          ? props.data.content.surveyDetails
          : "",
        buttonLabel: props?.data?.content?.buttonLabel
          ? props.data.content.buttonLabel
          : "",
        name: props?.data?.name ? props.data.name : "",
      }));
      setSurveyLogoUrl(
        props?.data?.content?.logoUrl ? props.data.content.logoUrl : ""
      );
    }
    try {
      getQuestionTypes("web")
        .then((res) => {
          const { error, response } = res;
          if (error === false) {
            setQuestionTypes(response);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
    }
    getSurveyRulesMetaData()
      .then((response) => {
        setRuleOperators(response.response);
      })
      .catch((err) => {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        console.log(err);
      });
  }, []);

  const [introduction, setIntroduction] = useState({
    name: "",
    description: "",
    buttonLabel: "",
  });
  const [endMessage, setEndMessage] = useState("");
  const [type, setType] = useState("introduction");
  const [questionType, setQuestionType] = useState("text");
  const [gIndex, setGIndex] = useState(0);
  const [pages, setPages] = useState([[]]);
  const [currentPage, setCurrentPage] = useState(0);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [surveyLogoUrl, setSurveyLogoUrl] = useState("");
  const [pageDescription, setPageDescription] = useState([""]);
  const [previewToggle, setPreviewToggle] = useState(false);
  const [ruleModalStatus, setRuleModalStatus] = useState(false);
  const [ruleOperators, setRuleOperators] = useState([]);

  const onEndMessageChange = (event) => {
    if (event.target.value.length > 120) return;
    setEndMessage(event.target.value);
  };

  function uploadSurveyLogo(file) {
    setLoading(true);
    try {
      uploadLogo(file)
        .then((res) => {
          const { error, response = {} } = res;
          if (!error || error === "false") {
            setSurveyLogoUrl(response?.url);
            setLoading(false);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
            setLoading(false);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          setLoading(false);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      setLoading(false);
    }
  }

  function validateLogo(e: any) {
    if (e.target.files?.length > 0) {
      const file = e.target.files[0];
      if (!file) return;
      let fileType = file?.type.split("/")[1];
      let acceptedFileTypes = ["png", "jpeg", "jpg"];

      if (!acceptedFileTypes.includes(fileType)) {
        Notify({
          type: "error",
          heading: "Logo upload failed",
          message: `The file could not be uploaded <br/>
      Allowed types: .png .jpeg .jpg`,
        });
        e.target.value = "";
        return;
      }

      if (!(file?.size <= 200000)) {
        Notify({
          type: "error",
          heading: "Logo upload failed",
          message: `The file could not be uploaded <br/>
      maximum file size: 200 KB`,
        });
        e.target.value = "";
        return;
      }
      uploadSurveyLogo(file);
      e.target.value = "";
    }
  }

  function resetLogo() {
    setSurveyLogoUrl("");
    let logoInput = document.getElementById("logo-upload") as HTMLInputElement;
    logoInput.value = null;
  }

  const selectType = (type) => {
    setType(type);
  };

  const addQuestion = (page) => {
    setQuestionType("text");
    selectType("edit");
    let q = [...(pages[page] ?? [])];
    setGIndex(q.length);

    let t = {
      q: "",
      s: "",
      index: 0,
      type: "text",
    };

    const newList = pages.map((el, i) => {
      if (i === page) {
        return [...(pages?.[page] ?? []), t];
      } else return el;
    });
    setPages(newList);
    nextStep(0);
  };

  const editMessage = (index, page) => {
    let q = pages[page][index];
    setQuestionType(q.type);
    setCurrentPage(page);
    setGIndex(index);
    setType("edit");
  };

  const addPage = () => {
    let currentPageCount = pages.length;

    setCurrentPage(currentPageCount);

    let questionsArray = pages;
    questionsArray.push([]);
    setPageDescription([...pageDescription, ""]);
    setPages(questionsArray);
    nextStep(0);
    addQuestion(currentPageCount);
  };

  const duplicateMessage = (index, page) => {
    let question = pages[page][index];
    let duplicateQuestion = {
      q: question.q,
      s: question.s,
      index: question.index,
      property: question.property,
      type: question.type,
    };

    const newList = pages.map((el, i) => {
      if (i === page) {
        return [...pages[page], duplicateQuestion];
      } else return el;
    });

    setPages(newList);
    nextStep(0);
  };

  const deleteMessage = (index, page) => {
    let qList = [...pages[page]];
    qList.splice(index, 1);

    let questionsArray = pages;
    questionsArray[page] = qList;

    if (qList.length === 0 && questionsArray.length !== 1) {
      questionsArray.splice(page, 1);
      setPageDescription(pageDescription.filter((a, i) => i !== page));
    }

    setType("message");

    if (questionsArray.length === 1 && questionsArray[0].length === 0) {
      setType("introduction");
    }

    setCurrentPage(0);
    setGIndex(0);
    if (pages[0][0]) setQuestionType(pages[0][0].type);

    //setPages((pages) => ({ ...pages, [page]: qList }));
    setPages([...questionsArray]);
    nextStep(0);
  };

  const updatePageDescription = (e: any) => {
    let newValue = e.target.value;
    let newDescriptionArray = pageDescription.map((el, i) => {
      if (i === currentPage) {
        return newValue;
      } else return el;
    });

    setPageDescription(newDescriptionArray);
  };

  const messageTypeOnChange = (event) => {
    let q = [...pages[currentPage]];
    // if (event.target.value === "text") q[gIndex].choice = [];
    // else q[gIndex].choice = ["", ""];
    q[gIndex].type = event.target.value;
    q[gIndex].property = {};
    let questionsArray = pages;
    questionsArray[currentPage] = q;
    setPages([...questionsArray]);
    setQuestionType(event.target.value);
  };

  const currentQuestionNumber = (index, page) => {
    let count = 0;
    pages.map((el, index) =>
      index < page ? (count = count + pages[index].length) : null
    );
    count = count + index + 1;
    return count;
  };

  const gotoToList = () => {
    if (pages[currentPage][gIndex]?.q === "") {
      deleteMessage(gIndex, currentPage);
      return;
    }
    setType("message");
    nextStep(0);
  };

  const selectQuestion = (index, page) => {
    let q = pages[page][index];
    setGIndex(index);
    setCurrentPage(page);
    setQuestionType(q.type);
    setType("message");
  };

  const toggleModal = (status) => {
    let questions = [];
    pages.map((pageArray: any[]) =>
      pageArray.map((question) => questions.push(question))
    );
    if (status) {
      let preview = [];
      preview[0] = {
        type: "Introduction",
        value: introduction,
      };
      preview.push(questions);
      preview.push({
        type: "End Message",
        value: endMessage,
      });
    }
  };

  const isQuestionsValid = () => {
    if (introduction.name.length === 0) {
      Notify({
        type: "error",
        heading: "Failed",
        message: "Introduction Message is empty",
      });
      return true;
    }

    let count = 0;
    pages.map((item: []) => (count += item.length));
    if (count === 0) {
      Notify({
        type: "error",
        heading: "Failed",
        message: "Questions are empty",
      });
      return true;
    }
  };

  const nextStep = (goNext) => {
    setLoading(true);
    if (goNext)
      if (isQuestionsValid()) {
        setLoading(false);
        return;
      }
    const data: any = {
      ...props.data,
      status: "DRAFT",
      content: {},
      surveyId: props.data.surveyId,
    };
    let id =
      props.data.surveyId?.replaceAll("/", "%2F") ||
      props.data.id?.replaceAll("/", "%2F");
    let content = {
      endMessage: {
        questionText: endMessage,
      },
      surveyDetails: introduction.description,
      buttonLabel: introduction.buttonLabel,
      logoUrl: surveyLogoUrl,
      questions: [],
    };
    let page = [];
    let questionCount = 1;
    pages.map((ele, i) => {
      let p: any = {};
      let questions = [];
      ele.map((question, j) => {
        let element = {
          // index: j + 1,
          index: questionCount++,
          type: question.type,
          text: question.q,
          subQuestion: question.s,
          property: getPropertyForQuestion(
            question.type,
            question.property,
            question.mandatory
          ),
          rules: question.rules,
          style: {},
        };
        questions.push(element);
      });
      p.pageNumber = i + 1;
      p.title = props.data.name;
      p.description = pageDescription[i] ? pageDescription[i] : "";
      p.elements = questions;
      page.push(p);
    });
    content.questions = page;
    data.content = content;
    try {
      updateSurvey(data, id)
        .then((response) => {
          setLoading(false);
          data.surveyStartDate = props.data.surveyStartDate;
          data.surveyStartTime = props.data.surveyStartTime;
          data.surveyEndDate = props.data.surveyEndDate;
          data.surveyEndTime = props.data.surveyEndTime;
          props.setData((propsData) => ({
            ...propsData,
            ...data,
          }));
          props.setSurveyQuestionData(pages);
          if (goNext === 4) props.setStep(4);
          else if (goNext === 2)
            Notify({
              type: "success",
              heading: "Success",
              message: "Survey saved successfully",
            });
          else if (goNext === 0) return;
        })
        .catch((err) => {
          setLoading(false);
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (err) {
      setLoading(false);
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };
  const addNewQuestionFromView = () => {
    if (pages[currentPage][gIndex].q != "") {
      addQuestion(currentPage);
    }
  };

  const goBack = (step) => {
    let content = {
      endMessage: {
        questionText: endMessage,
      },
      surveyDetails: introduction.description,
      buttonLabel: introduction.buttonLabel,
      logoUrl: surveyLogoUrl,
      questions: [],
    };
    const data: any = {
      //content: content,
      surveyId: props.data.surveyId,
    };
    data.surveyStartDate = props.data.surveyStartDate;
    data.surveyStartTime = props.data.surveyStartTime;
    data.surveyEndDate = props.data.surveyEndDate;
    data.surveyEndTime = props.data.surveyEndTime;
    props.setData((propsData) => ({
      ...propsData,
      ...data,
    }));
    props.setSurveyQuestionData(pages);
    props.setStep(step);
  };

  const onIntroductoryMessagePaste = (event) => {
    if (event.target.value.length > 120) {
      event.preventDefault();
      return;
    }
  };

  const setQuestionProperty = (question) => {
    let q = [...pages[currentPage]];
    q[gIndex] = question;
    let questionsArray = pages;
    questionsArray[currentPage] = q;
    setPages([...questionsArray]);
  };

  const toggleMandatoryField = () => {
    let page = pages;
    let value = page[currentPage][gIndex].mandatory
      ? page[currentPage][gIndex].mandatory
      : false;
    page[currentPage][gIndex].mandatory = !value;
    setPages(page);
  };

  const getPropertyForQuestion = (
    questionType,
    property: any = {},
    mandatory
  ) => {
    let prop: any = {};
    mandatory ? (prop.manadatory = mandatory) : (prop.manadatory = false);
    switch (questionType) {
      case "dropdown":
        if (property.options.length > 0) {
          let options = [];
          property.options.map((ele, i) => {
            let option: any = {};
            option.optionId = i + 1;
            option.optionText = ele.optionText ? ele.optionText : ele;
            options.push(option);
          });
          prop.options = options;
        }
        return prop;
      case "rating":
        property.rating
          ? (prop.maxValue = property.rating)
          : (prop.maxValue = 5);
        return prop;
      case "linear scale":
        property.minScale
          ? (prop.minValue = property.minScale)
          : (prop.minValue = 1);
        property.maxScale
          ? (prop.maxValue = property.maxScale)
          : (prop.maxValue = 5);
        if (property.input) {
          if (property.input.length > 0) {
            let options = [];
            property.input.map((ele, i) => {
              let option: any = {};
              option.optionId = i + 1;
              option.optionText = ele.optionText ? ele.optionText : ele;
              options.push(option);
            });
            prop.options = options;
          }
        } else {
          let options = [];
          for (let i = 0; i < prop.maxValue - prop.minValue + 1; i++) {
            let option: any = {};
            option.optionId = i + 1;
            option.optionText = "";
            options.push(option);
          }
          prop.options = options;
        }
        return prop;
      case "image choice":
        property.selectMultiple
          ? (prop.selectMultiple = property.selectMultiple)
          : (prop.selectMultiple = false);
        if (property.options.length > 0) {
          let options = [];
          property.options.map((ele, i) => {
            let option: any = {};
            option.optionId = i + 1;
            option.optionText = ele.choice
              ? ele.choice
              : ele.optionText
              ? ele.optionText
              : `choice${i}`;
            option.url = ele.imageURL ? ele.imageURL : ele.url ? ele.url : "";
            options.push(option);
          });
          prop.options = options;
        }
        return prop;
      case "matrix":
        property.selectMultiple
          ? (prop.selectMultiple = property.selectMultiple)
          : (prop.selectMultiple = false);
        prop.matrix = property.matrix;
        return prop;
      case "form":
        property.selectMultiple
          ? (prop.selectMultiple = property.selectMultiple)
          : (prop.selectMultiple = false);
        if (property.options.length > 0) {
          let options = [];
          property.options.map((ele, i) => {
            let option: any = {};
            option.optionId = i + 1;
            option.optionText = ele.optionText ? ele.optionText : ele;
            options.push(option);
          });
          prop.options = options;
        }
        return prop;
      case "text":
        property.minValue
          ? (prop.minValue = property.minValue)
          : (prop.minValue = 30);
        property.maxValue
          ? (prop.maxValue = property.maxValue)
          : (prop.maxValue = 600);
        return prop;
      case "multiple choice":
        property.otherOptions
          ? (prop.otherOption = property.otherOptions)
          : (prop.otherOption = false);
        property.selectMultiple
          ? (prop.selectMultiple = property.selectMultiple)
          : (prop.selectMultiple = false);
        if (property.options.length > 0) {
          let options = [];
          property.options.map((ele, i) => {
            let option: any = {};
            option.optionId = i + 1;
            option.optionText = ele.optionText ? ele.optionText : ele;
            options.push(option);
          });
          prop.options = options;
        }
        return prop;
    }
  };

  const closeRuleModal = (value) => {
    if (!value) setRuleModalStatus(false);
    else {
      let q = [...pages[currentPage]];
      q[gIndex].rules = value.length === 0 ? null : value;
      let questionsArray = pages;
      questionsArray[currentPage] = q;
      setPages([...questionsArray]);
      setRuleModalStatus(false);
      nextStep(0);
    }
  };

  const isRule = () => {
    let count = 0;
    pages.map((p, i) => {
      p.map((q, j) => {
        count++;
      });
    });
    if (count > 1) return true;
    else return false;
  };

  const getRuleOperatorName = (operator, visibility) => {
    for (let key in ruleOperators[visibility.toLowerCase()]) {
      if (ruleOperators[visibility.toLowerCase()][key] === operator) {
        return key;
      }
    }
  };

  const setRule = () => {
    let dPages = [...pages];
    let index = 1;
    dPages.map((p) => {
      p.map((q) => {
        q.index = index;
        index++;
      });
    });
    setPages(dPages);
    setRuleModalStatus(true);
  };

  return (
    <div>
      <div className="p-10 pb-0">
        <div className="text-[#667085] text-sm font-semibold mb-2">
          STEP 2 OF 4
        </div>
        <div className="flex items-center justify-between">
          <div className="flex gap-6 items-center">
            <div className="bg-[#F1FAF5] h-[61px] w-[61px] rounded-[50%] flex items-center justify-center">
              <img src={QuestionFile} alt="" />
            </div>
            <div>
              <div className="text-[#475467] font-semibold text-base">
                Survey questions
              </div>
              <div className="text-[#667085] text-sm">
                Set your survey questions seamlessly with the survey builder.
              </div>
            </div>
          </div>
          {/* <div>
            <div className="h-1 w-[200px] rounded-md bg-[#EBEFF3]">
              <div className="w-[50%] bg-[#12B76A] h-full rounded-t-sm rounded-l-sm"></div>
            </div>
            <div className="flex justify-end mt-2 text-xs text-[#667085]">
              <div>Just getting started</div>
            </div>
          </div> */}
          <div className="flex gap-4">
            <button
              className="py-3 px-6 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
              //onClick={() => props.navigate("/engagements/surveys")}
              onClick={() => goBack(2)}
              disabled={loading}
            >
              Back
            </button>
            <button
              onClick={() => nextStep(4)}
              disabled={loading}
              className="py-3 px-5 w-24 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75 disabled:bg-blue-75 flex justify-center items-center"
            >
              {loading ? (
                <Spinner className={"w-4 h-4"} />
              ) : (
                <div className="flex gap-3 items-center">
                  {"Next  "} <img src={ArrowRight} alt="" />
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="border h-[535px] rounded m-10 flex">
        <div className="h-full w-[30%] p-4 border-r">
          <div>
            {type !== "edit" ? (
              <div>
                <div className="h-[460px]">
                  <div className="flex border rounded py-2 px-3 justify-between">
                    <div className="flex items-center text-[#475467]">List</div>
                    <div className="h-[31px] w-[31px] bg-[#98A2B3] justify-center items-center flex cursor-pointer rounded">
                      <Popup
                        arrow={false}
                        trigger={
                          <div className="h-[31px] w-[31px] bg-[#98A2B3] justify-center items-center flex cursor-pointer rounded">
                            <button>
                              <img src={Add} alt="" />
                            </button>
                          </div>
                        }
                        position="right top"
                        offsetX={10}
                        contentStyle={{
                          padding: "18px 16px",
                          border: "none",
                          borderRadius: "8px",
                          boxShadow:
                            "0px 0px 8px rgba(16, 24, 40, 0.04), 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                          width: "auto",
                        }}
                      >
                        <div className="text-sm text-gray-700">
                          <div
                            className="cursor-pointer"
                            onClick={() => addQuestion(currentPage)}
                          >
                            New question
                          </div>

                          <button
                            disabled={pages[0].length === 0}
                            onClick={() => addPage()}
                            className="mt-5 cursor-pointer outline-none border-none bg-white disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white"
                          >
                            New page
                          </button>
                        </div>
                      </Popup>
                    </div>
                  </div>
                  <div
                    className={
                      type === "introduction"
                        ? "h-[44px] flex gap-2 mt-6 bg-[#F2F4F7]"
                        : "h-[44px] flex gap-2 mt-6 cursor-pointer"
                    }
                    onClick={() => selectType("introduction")}
                  >
                    <div
                      className={
                        type === "introduction"
                          ? "h-full w-[48px] flex justify-center items-center font-semibold"
                          : "border rounded h-full w-[48px] flex justify-center items-center"
                      }
                    >
                      ST
                    </div>
                    <div className="flex items-center">Survey Title</div>
                  </div>
                  {/* <div className="border mt-6"></div> */}
                  <div className="mt-6 h-[300px] overflow-hidden border-t pt-4">
                    <div className="h-full overflow-y-overlay overflow-x-hidden">
                      {pages.map((pel: any, pageArrayIndex) => {
                        return (
                          <div
                            key={pageArrayIndex}
                            // onClick={() => {
                            //   setCurrentPage(pageArrayIndex);
                            //   setGIndex(pages[pageArrayIndex].length);
                            // }}
                          >
                            {" "}
                            {pages.length !== 1 && (
                              <div
                                className={`mb-3 p-3 ${
                                  currentPage === +pageArrayIndex &&
                                  (type === "message" ||
                                    type === "pageDescription")
                                    ? "bg-[#F2F4F7]"
                                    : "cursor-pointer"
                                }`}
                                onClick={() => {
                                  setType("pageDescription");
                                  setCurrentPage(+pageArrayIndex);
                                  setGIndex(pages[+pageArrayIndex].length);
                                }}
                              >
                                {" "}
                                Page {+pageArrayIndex + 1}
                              </div>
                            )}
                            {pages[+pageArrayIndex].map((q, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    //pages[currentPage][gIndex].index === index + 1 &&
                                    +pageArrayIndex === currentPage &&
                                    gIndex === index &&
                                    type === "message"
                                      ? "flex items-center h-[44px] mb-4 justify-between bg-[#F2F4F7]"
                                      : "flex items-center h-[44px] mb-4 justify-between cursor-pointer"
                                  }
                                  onClick={() => {
                                    selectQuestion(index, +pageArrayIndex);
                                  }}
                                >
                                  <div className="flex gap-2 items-center h-full">
                                    <div
                                      className={
                                        +pageArrayIndex === currentPage &&
                                        gIndex === index &&
                                        type === "message"
                                          ? " h-full min-w-[48px] flex justify-center items-center font-semibold"
                                          : "border rounded h-full min-w-[48px] flex justify-center items-center font-semibold"
                                      }
                                    >
                                      Q
                                      {currentQuestionNumber(
                                        index,
                                        +pageArrayIndex
                                      )}
                                    </div>
                                    <div className="max-h-[44px] overflow-hidden">
                                      {q.q}
                                    </div>
                                  </div>
                                  <div className="mr-4 cursor-pointer">
                                    <Popup
                                      arrow={false}
                                      trigger={
                                        <button>
                                          <img src={Ellipsis} alt="" />
                                        </button>
                                      }
                                      position="right top"
                                      offsetX={10}
                                      contentStyle={{
                                        padding: "18px 16px",
                                        border: "none",
                                        borderRadius: "8px",
                                        boxShadow:
                                          "0px 0px 8px rgba(16, 24, 40, 0.04), 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                                        width: "auto",
                                      }}
                                    >
                                      <div className="text-sm text-gray-700">
                                        <div
                                          className="cursor-pointer"
                                          onClick={() => {
                                            editMessage(index, +pageArrayIndex);
                                          }}
                                        >
                                          Edit
                                        </div>
                                        <div
                                          className="mt-5 mb-5 cursor-pointer"
                                          onClick={() =>
                                            duplicateMessage(
                                              index,
                                              +pageArrayIndex
                                            )
                                          }
                                        >
                                          Duplicate
                                        </div>
                                        <div
                                          className="cursor-pointer"
                                          onClick={() =>
                                            deleteMessage(
                                              index,
                                              +pageArrayIndex
                                            )
                                          }
                                        >
                                          Delete
                                        </div>
                                      </div>
                                    </Popup>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div
                  className="h-[50px]"
                  onClick={() => selectType("endMessage")}
                >
                  {/* <div className="border mt-4"></div> */}
                  <div
                    className={
                      type === "endMessage"
                        ? "flex py-2 px-3 justify-between bg-[#F2F4F7] border-t"
                        : "flex py-2 px-3 justify-between border-t cursor-pointer"
                    }
                  >
                    <div className="flex items-center text-[#475467] font-semibold">
                      Exit page
                    </div>
                    <div className="h-[31px] w-[31px] bg-[#98A2B3] justify-center items-center flex rounded">
                      <img src={RightArrow} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {type === "edit" ? (
            <div className="h-full">
              <div className="h-full">
                <div className="py-6 px4 relative h-full">
                  <div className="bg-[#F2F4F7] flex justify-center items-center relative h-[38px]">
                    <div
                      className="absolute left-[24px] cursor-pointer"
                      onClick={() => {
                        gotoToList();
                      }}
                    >
                      <img src={ChevronBack} alt="Back" />
                    </div>
                    <div className="text-[##475467] text-sm">
                      Question {currentQuestionNumber(gIndex, currentPage)}
                    </div>
                  </div>
                  <div className="mt-6 border-t pt-6">
                    <div className="flex gap-2 mb-6">
                      <input
                        style={{ accentColor: "var(--main-color)" }}
                        type="checkbox"
                        checked={pages[currentPage][gIndex].mandatory}
                        onChange={() => toggleMandatoryField()}
                      />
                      <div className="text-sm text-gray-700">
                        This is a required question
                      </div>
                    </div>
                    <div className="text-sm font-semibold">Type</div>
                    <div className="mt-1">
                      <div className="border rounded p-1 h-[40px] cursor-pointer">
                        <select
                          value={questionType}
                          onChange={(event) => {
                            messageTypeOnChange(event);
                          }}
                          className="w-full h-full border-0 outline-0 texr-[#98A2B3] text-sm cursor-pointer"
                        >
                          {questionTypes.map((ele, i) => {
                            return (
                              <option key={i} value={ele.value}>
                                {ele.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div>
                      <div>
                        {questionType === "text" ? (
                          <TextProperty
                            question={pages[currentPage][gIndex]}
                            setRating={(value) => {
                              setQuestionProperty(value);
                            }}
                          />
                        ) : null}
                      </div>
                      <div>
                        {questionType === "multiple choice" ? (
                          <MultipleChoiceProperty
                            question={pages[currentPage][gIndex]}
                            setRating={(value) => {
                              setQuestionProperty(value);
                            }}
                          />
                        ) : null}
                      </div>
                      <div>
                        {questionType === "rating" ? (
                          <RatingProperty
                            setRating={(value) => {
                              setQuestionProperty(value);
                            }}
                            question={pages[currentPage][gIndex]}
                          />
                        ) : null}
                      </div>
                      <div>
                        {questionType === "linear scale" ? (
                          <LinearScaleProperty
                            setRating={(value) => {
                              setQuestionProperty(value);
                            }}
                            question={pages[currentPage][gIndex]}
                          />
                        ) : null}
                      </div>
                      <div>
                        {questionType === "dropdown" ? (
                          <DropdownProperty
                            setRating={(value) => {
                              setQuestionProperty(value);
                            }}
                            question={pages[currentPage][gIndex]}
                          />
                        ) : null}
                      </div>
                      <div>
                        {questionType === "matrix" ? (
                          <MatrixProperty
                            setRating={(value) => {
                              setQuestionProperty(value);
                            }}
                            question={pages[currentPage][gIndex]}
                          />
                        ) : null}
                      </div>
                      <div>
                        {questionType === "form" ? (
                          <FormProperty
                            setRating={(value) => {
                              setQuestionProperty(value);
                            }}
                            question={pages[currentPage][gIndex]}
                          />
                        ) : null}
                      </div>
                      <div>
                        {questionType === "image choice" ? (
                          <ImageChoiceProperty
                            setRating={(value) => {
                              setQuestionProperty(value);
                            }}
                            question={pages[currentPage][gIndex]}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="absolute bottom-0 w-full">
                      <div className="border rounded flex items-center justify-center cursor-pointer h-[40px] w-full">
                        <div className="flex gap-3 items-center">
                          <img src={Plus} alt="" />
                          <div
                            onClick={() => {
                              addNewQuestionFromView();
                            }}
                            className="text-sm font-semibold text-[#475467]"
                          >
                            New question
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="w-[70%] h-full bg-[#F9FAFB]">
          <div className="m-4 mb-0 mt-0">
            <div className="h-[50px] flex justify-end items-center cursor-pointer">
              {" "}
              <div
                onClick={() => {
                  setPreviewToggle(true);
                }}
              >
                <img src={Play} alt="" />
              </div>{" "}
            </div>
            <div className="h-[483px] overflow-hidden bg-[#ffffff] border border-b-0 rounded rounded-b-none relative">
              <div>
                {type === "endMessage" ? (
                  <div>
                    <div className="m-10">
                      {/* <div className="text-[#475467 font-semibold text-sm mb-1">
                        End Message
                      </div> */}
                      <div>
                        <div className="border border-[#E4E7EC] rounded px-2 py-3 mb-1">
                          <input
                            onChange={(event) => {
                              onEndMessageChange(event);
                            }}
                            value={endMessage}
                            onPaste={(event) => {
                              onIntroductoryMessagePaste(event);
                            }}
                            className="text-base w-full border-0 outline-0"
                            placeholder="Message goes here"
                          />
                        </div>
                      </div>
                      <div className="text-sm">
                        {endMessage.length}/120 Characters
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div>
                {type === "introduction" ? (
                  <div>
                    <div className="m-10">
                      <div>
                        <div className="mb-4">
                          {surveyLogoUrl === "" ? (
                            <div className="flex justify-center items-center w-24 h-24">
                              {loading ? (
                                <div className="">
                                  <Loader className="w-8 h-8" />
                                </div>
                              ) : (
                                <label htmlFor="logo-upload">
                                  <img className="cursor-pointer" src={SurveyLogoPlaceholder} alt="Select logo" />
                                </label>
                              )}
                            </div>
                          ) : (
                            <div className="relative flex items-start justify-start w-fit">
                              <img
                                src={surveyLogoUrl}
                                width={100}
                                height={100}
                                className="block"
                                alt="logoPreview"
                              />
                              <div
                                onClick={() => {
                                  resetLogo();
                                }}
                                className="absolute -top-2 -right-2 cursor-pointer "
                              >
                                {/* ✕ */}
                                <img src={CrossSmall} alt="" />
                              </div>
                            </div>
                          )}
                        </div>

                        <input
                          id="logo-upload"
                          type="file"
                          accept={".png, .jpg, .jpeg"}
                          onChange={validateLogo}
                          className="hidden"
                        ></input>
                      </div>

                      <div className="border border-[#E4E7EC] rounded mb-4">
                        <input
                          className="w-full text-[28px] leading-[38px] h-14 text-gray-500 bg-[#F9FAFB] rounded px-3 py-2 border-0 outline-0 font-semibold cursor-default"
                          value={introduction.name}
                          placeholder="Survey name"
                          readOnly
                        />
                      </div>
                      <div className="border border-[#E4E7EC] rounded mb-6">
                        <textarea
                          className="text-lg w-full text-gray-70 border-0 outline-0 pt-2 pb-6 px-3 max-h-28"
                          onChange={(event) => {
                            setIntroduction((introduction) => ({
                              ...introduction,
                              description: event.target.value,
                            }));
                          }}
                          value={introduction.description}
                          onPaste={(event) => onIntroductoryMessagePaste(event)}
                          maxLength={140}
                          placeholder="Enter Survey Description"
                        />
                      </div>

                      <label className="text-gray-70 font-semibold">
                        Accept button label
                        <div className="border border-[#E4E7EC] rounded mt-1">
                          <input
                            className="w-full font-normal text-sm leading-6 h-14 text-gray-700 px-3 py-2 border-0 outline-0 "
                            onChange={(event) => {
                              setIntroduction((introduction) => ({
                                ...introduction,
                                buttonLabel: event.target.value,
                              }));
                            }}
                            value={introduction.buttonLabel}
                            maxLength={50}
                            placeholder="Enter your preferred button label"
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                ) : null}
              </div>

              <div>
                {type === "pageDescription" ? (
                  <div>
                    <div className="m-10">
                      <div className="mb-3">Page {currentPage + 1}</div>
                      <div className="border border-[#E4E7EC] rounded mb-4">
                        <input
                          className="w-full h-14 text-gray-70 px-3 py-2 rounded border-0 outline-0 font-normal"
                          onChange={(event) => {
                            updatePageDescription(event);
                          }}
                          maxLength={200}
                          value={pageDescription?.[currentPage]}
                          placeholder="Enter Page Descrition (optional)"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="overflow-auto h-full">
                {type === "message" || type === "edit" ? (
                  <div className="min-h-[367px]">
                    <div className="m-10">
                      <div>
                        {questionType === "text" ? (
                          <Text
                            setRating={(value) => {
                              setQuestionProperty(value);
                            }}
                            question={pages[currentPage][gIndex]}
                          />
                        ) : null}
                      </div>
                      <div>
                        {questionType === "multiple choice" ? (
                          <MultipleChoice
                            setRating={(value) => {
                              setQuestionProperty(value);
                            }}
                            question={pages[currentPage][gIndex]}
                          />
                        ) : null}
                      </div>
                      <div>
                        {questionType === "rating" ? (
                          <Rating
                            setRating={(value) => {
                              setQuestionProperty(value);
                            }}
                            question={pages[currentPage][gIndex]}
                            countprop={
                              pages[currentPage][gIndex]?.property?.rating
                                ? pages[currentPage][gIndex].property.rating
                                : 5
                            }
                          />
                        ) : null}
                      </div>
                      <div>
                        {questionType === "linear scale" ? (
                          <LinearScale
                            question={pages[currentPage][gIndex]}
                            minScale={
                              pages[currentPage][gIndex]?.property?.minScale
                                ? pages[currentPage][gIndex].property.minScale
                                : 1
                            }
                            maxScale={
                              pages[currentPage][gIndex]?.property?.maxScale
                                ? pages[currentPage][gIndex].property.maxScale
                                : 5
                            }
                            setRating={(value) => {
                              setQuestionProperty(value);
                            }}
                          />
                        ) : null}
                      </div>
                      <div>
                        {questionType === "dropdown" ? (
                          <Dropdown
                            question={pages[currentPage][gIndex]}
                            minScale={
                              pages[currentPage][gIndex]?.property?.minScale
                                ? pages[currentPage][gIndex].property.minScale
                                : 1
                            }
                            maxScale={
                              pages[currentPage][gIndex]?.property?.maxScale
                                ? pages[currentPage][gIndex].property.maxScale
                                : 5
                            }
                            setRating={(value) => {
                              setQuestionProperty(value);
                            }}
                          />
                        ) : null}
                      </div>
                      <div>
                        {questionType === "matrix" ? (
                          <Matrix
                            question={pages[currentPage][gIndex]}
                            minScale={
                              pages[currentPage][gIndex]?.property?.minScale
                                ? pages[currentPage][gIndex].property.minScale
                                : 1
                            }
                            maxScale={
                              pages[currentPage][gIndex]?.property?.maxScale
                                ? pages[currentPage][gIndex].property.maxScale
                                : 5
                            }
                            setRating={(value) => {
                              setQuestionProperty(value);
                            }}
                          />
                        ) : null}
                      </div>
                      <div>
                        {questionType === "form" ? (
                          <Form
                            question={pages[currentPage][gIndex]}
                            minScale={
                              pages[currentPage][gIndex]?.property?.minScale
                                ? pages[currentPage][gIndex].property.minScale
                                : 1
                            }
                            maxScale={
                              pages[currentPage][gIndex]?.property?.maxScale
                                ? pages[currentPage][gIndex].property.maxScale
                                : 5
                            }
                            setRating={(value) => {
                              setQuestionProperty(value);
                            }}
                          />
                        ) : null}
                      </div>
                      <div>
                        {questionType === "image choice" ? (
                          <ImageChoice
                            question={pages[currentPage][gIndex]}
                            minScale={
                              pages[currentPage][gIndex]?.property?.minScale
                                ? pages[currentPage][gIndex].property.minScale
                                : 1
                            }
                            maxScale={
                              pages[currentPage][gIndex]?.property?.maxScale
                                ? pages[currentPage][gIndex].property.maxScale
                                : 5
                            }
                            setRating={(value) => {
                              setQuestionProperty(value);
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
                {isRule() && (type === "message" || type === "edit") ? (
                  <div className="w-full h-[80px] flex items-center border-t-2">
                    <div className="flex justify-between w-full">
                      <div className="h-full flex items-center">
                        {pages[currentPage][gIndex].rules && (
                          <div className="ml-10">
                            <div className="text-[#475467] text-sm font-semibold">
                              Rule:{" "}
                            </div>
                            <div className="text-[#667085] text-sm">
                              {pages[currentPage][gIndex].rules[0].visibility}{" "}
                              this Question if Q
                              {pages[currentPage][gIndex].rules[0].operand}{" "}
                              {getRuleOperatorName(
                                pages[currentPage][gIndex].rules[0].operator,
                                pages[currentPage][gIndex].rules[0].visibility
                              )}{" "}
                              {pages[currentPage][gIndex].rules[0].value}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="h-full flex items-center mr-10">
                        <div
                          className="border rounded text-sm font-semibold text-[#475467] px-10 py-3 cursor-pointer"
                          onClick={() => {
                            setRule();
                          }}
                        >
                          Set rule
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mx-10 mb-6">
        <div className="flex justify-between">
          <button
            onClick={(e) => {
              nextStep(1);
            }}
            disabled={loading}
            className="text-gray-70 font-semibold text-sm px-6 py-3 border border-white hover:border-gray-50 rounded-lg"
          >
            Save as draft
          </button>
          <div className="flex gap-4">
            <button
              className="py-3 px-6 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
              onClick={() => {
                goBack(1);
              }}
              disabled={loading}
            >
              Back
            </button>
            <button
              onClick={() => nextStep(2)}
              disabled={loading}
              className="py-3 px-24 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75 disabled:bg-gray-400 disabled:text-slate-500 disabled:border-slate-200"
            >
              Continue
            </button>
          </div>
        </div>
      </div> */}
      <div>
        {previewToggle ? (
          <WebSurveyPreview
            pages={pages}
            introduction={introduction}
            imageUrl={surveyLogoUrl}
            exit={endMessage}
            setStatus={(status) => {
              setPreviewToggle(status);
            }}
          />
        ) : null}
      </div>
      {ruleModalStatus && (
        <WebSurveyRules
          close={(value) => closeRuleModal(value)}
          operators={ruleOperators}
          pages={pages}
          selectedQuestion={pages[currentPage][gIndex].index}
          currentRules={pages[currentPage][gIndex].rules}
        />
      )}
    </div>
  );
};
export default SurveyStepTwoWeb;
