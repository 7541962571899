export const getCurrenySymbol = (country = "", currency = "") => {
  if (currency) {
    switch (currency.toLowerCase()) {
      case "ksh":
        return "KSh";
      case "usd":
        return "$";
      case "ngn":
      default:
        return "₦";
    }
  }

  if (country?.toLowerCase() === 'kenya') {
    return 'KSh';
  }
  return "₦"
}
