import { EmptyChart } from "../../../assets/icons/icons";

const ChartEmptyView = (items) => {
  return (
    <div className="relative mt-6 mb-6">
      <div className="absolute h-[367px] text-xs text-[#64748B]">
        <div className="absolute top-[0%]">5000</div>
        <div className="absolute top-[20%]">4000</div>
        <div className="absolute top-[40%]">3000</div>
        <div className="absolute top-[60%]">2000</div>
        <div className="absolute top-[80%]">1000</div>
        {/* <div className="absolute top-[100%]">0</div> */}
      </div>
      <div className="h-[367px] flex justify-center items-center">
        <div>
          <div className="flex justify-center">
            <img src={EmptyChart} alt="" />
          </div>
          <div className="text-sm font-semibold text-[#4B5563] flex justify-center">
            There aren’t any metrics to display.
          </div>
          <div className="text-[#9CA3AF] text-xs flex justify-center">
            There aren’t any metrics available to display for this engagement.
          </div>
        </div>
      </div>
      <div className="h-[2px] bg-[#F1F5F9]"></div>
      <div className="flex justify-around mt-2">
        {items.items.map((item, i) => {
          return (
            <div className="text-xs text-[#475569]" key={i}>
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChartEmptyView;
