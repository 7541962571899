import { useEffect } from "react";
import {
  addGAEventListener,
  getPageName,
} from "../../../../../marketing-cloud-container/src/ga-events";

const TypeMenu = (props) => {
  const {
    showTypeMenu,
    typeMenu,
    setType,
    type,
    setFilter,
    setPageNum,
    setPageSize,
    setFrom,
    audience,
    status,
    checkFlag,
  } = props;

  useEffect(() => {
    addGAEventListener();
  }, [showTypeMenu]);

  const handleSetChannel = (e: any) => {
    setPageNum(0);
    setPageSize(10);
    setFrom(0);
    setType(e.target.value);
    setFilter(true);
    if (type === e.target.value) {
      setType("");
      if (audience || status) {
        return setFilter(true);
      } else {
        return setFilter(false);
      }
    }
  };

  return (
    showTypeMenu && (
      <div
        ref={typeMenu}
        className="absolute mt-1 z-10 bg-white rounded-lg shadow w-40"
      >
        <ul className="p-2 text-sm text-gray-70">
          <li
            className="triggerGA"
            data-event-category={`${getPageName()}-list-page`}
            data-event-label={`${getPageName()}-list_type-option_click`}
            data-event-action="filter_click"
            data-event-value="airtime"
          >
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={type.toLowerCase() === "airtime"}
                id="airtime"
                type="checkbox"
                value="AIRTIME"
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                onChange={handleSetChannel}
              />
              <label
                htmlFor="airtime"
                className="w-full  ml-3 text-sm leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                AIRTIME
              </label>
            </div>
          </li>
          <li
            className="triggerGA"
            data-event-category={`${getPageName()}-list-page`}
            data-event-label={`${getPageName()}-list_type-option_click`}
            data-event-action="filter_click"
            data-event-value="data"
          >
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={type.toLowerCase() === "data"}
                id="data"
                type="checkbox"
                value="DATA"
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                onChange={handleSetChannel}
              />
              <label
                htmlFor="data"
                className="w-full  ml-3 text-sm leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                DATA
              </label>
            </div>
          </li>
        </ul>
      </div>
    )
  );
};

export default TypeMenu;
