import { Close } from "../../assets/icons/icons";

const NoURLPrompt = ({ close }) => {
  return (
    <div className="absolute flex w-full h-full justify-center items-center bg-dialog-overlay left-0 top-0">
      <div className="w-[480px] bg-white rounded-md p-8">
        <div className="flex justify-between items-center">
          <div className="font-semibold text-lg leading-6 text-gray-800">
            Destination URL missing
          </div>
          <div className="cursor-pointer">
            <img src={Close} alt="Close" />
          </div>
        </div>
        <div className="mt-3 text-sm leading-6 text-gray-500">
          Adding destination URLs are not mandatory. However, selecting
          Objectives without URLs gives you 160 characters for your message
          body.
        </div>
        <div className="mt-6 flex justify-end gap-2">
          <div
            onClick={() => close(false)}
            className="py-3 px-5 text-sm leading-4 font-semibold border border-gray-40 rounded-md text-gray-70 cursor-pointer"
          >
            Edit message details
          </div>
          <div
            onClick={() => close(true)}
            className="py-3 px-5 text-sm leading-4 font-semibold border border-blue-75 rounded-md bg-blue-75 text-white cursor-pointer"
          >
            Skip and continue
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoURLPrompt;
