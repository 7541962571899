export const isNumberValid = (country: string, digitCount) => {
    country = country.toLowerCase();
    switch (country) {
        case 'kenya':
            return (digitCount === 9);
        case 'nigeria':
            return (digitCount === 10);
        default: return false;
    }
}

export const isMobileNumberDuplicate = (number: any, mobileNumbersList: any) => {
    let status = false;
    mobileNumbersList.map((ele) => {
        if (ele === number) {
            status = true;
        }
    });
    return status;
};