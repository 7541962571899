import { useContext } from "react";
import { PaystackButton } from "react-paystack";
import { useDispatch } from "react-redux";
import { TenantContext as HomeTenantContext } from "../pages/home";
import { TenantContext as SmsTenantContext } from "../../../engagements/src/pages/create-sms/sms-parent";
import { TenantContext as SurveyTenantContext } from "../../../engagements/src/pages/create-survey/survey-parent/survey-parent";
import { TenantContext as WhatsappTenantContext } from "../../../engagements/src/pages/create-message/message-parent/message-parent";
import { verifyPayment } from "../common/apis/billing";
import { PAYSTACK_PUBLIC_KEY } from "../common/constants/authToken";
import { Notify } from "../common/components/Notify/Notify";
import * as Sentry from "@sentry/react";
import { setLoaderAccountStatus, setWalletBalance } from "../redux-loader/loader-slice/loaderSlice";
interface PaymentRef {
  message: string;
  redirecturl: string;
  reference: number;
  status: string;
  trans: number;
  transaction: number;
  trxref: number;
}

const PaystackModal = ({
  amount,
  type,
  planId,
  tierId,
  productId,
  units,
  success,
}) => {
  const homeTenantContext: any = useContext(HomeTenantContext);
  const smsTenantContext: any = useContext(SmsTenantContext);
  const surveyTenantContext: any = useContext(SurveyTenantContext);
  const whatsappTenantContext: any = useContext(WhatsappTenantContext);
  const tenantContext: any = homeTenantContext.id
    ? homeTenantContext
    : smsTenantContext.id
    ? smsTenantContext
    : surveyTenantContext.id
    ? surveyTenantContext
    : whatsappTenantContext;
  const email = tenantContext?.email;

  const config = {
    reference: `${new Date().getTime().toString()}_${tenantContext.id}`,
    email,
    amount: amount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: PAYSTACK_PUBLIC_KEY,
  };

  const dispatch = useDispatch();

  const onSuccess = (reference: PaymentRef) => {
    try {
      verifyPayment(
        reference?.trxref,
        "paystack",
        type,
        planId,
        tierId,
        productId,
        units
      )
        .then((res) => {
          if (res && res !== "error") {
            // console.log("Verified", res);
            if (res.balance) {
              dispatch(setWalletBalance(res.balance));
              dispatch(setLoaderAccountStatus({}));
            }
          }
          Notify({
            type: "success",
            message:
              res.status && res.status === "success"
                ? "Payment Successful"
                : "Payment Failed",
            heading:
              res.status && res.status === "success" ? "Successful" : "Failed",
          });
          success();
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
    }
  };

  const onClose = () => {
    // console.log("Paystack window closed");
  };

  const componentProps = {
    ...config,
    text: "Pay with Paystack",
    onSuccess: (reference: PaymentRef) => onSuccess(reference),
    onClose: onClose,
  };

  return (
    <div>
      <PaystackButton className="paystack_btn hidden" {...componentProps} />
    </div>
  );
};

export default PaystackModal;
