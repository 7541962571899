export const isEmailValid = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

export const isEmailDuplicate = (email: any, emailList: any) => {
    let status = false;
    emailList.map((ele) => {
        if (ele === email) {
            status = true;
        }
    });
    return status;
};