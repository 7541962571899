import DOMPurify from "dompurify";
import { Search } from "react-feather";
import ReactECharts from "echarts-for-react";
import {
  getDlr,
  getMessageDetails,
} from "../../../common/services/message-services";
import { useCallback, useEffect, useRef, useState } from "react";
import CurrencySymbol from "../../../../../marketing-cloud-main/src/common/components/currency-symbol/currency-symbol";
import { ConfigProvider, DatePicker } from "antd";
import { debounce } from "../../../common/utils/debounce";
import {
  Airtel,
  Cash,
  Clock,
  Close,
  DeliveryRateDown,
  DeliveryRateUp,
  DownArrow,
  EmailInsight,
  Etisalat,
  Eye,
  Fisend,
  Glo,
  InfoCircle,
  Message,
  Mtn,
  OneTick,
  Optout,
  Peopleshadow,
  RefundInitiated,
  SmsInsight,
  Tick,
  WhatsappInsight,
} from "../../../assets/icons/icons";
import locale from "antd/es/date-picker/locale/en_GB";
import MessagesStatusMenu from "../../../common/components/messages-status-menu";
import PaginationComponent from "../../../common/components/pagination-common";
import {
  downloadCampaignReport,
  downloadZip,
} from "../../../common/utils/fileDownload";
import * as Sentry from "@sentry/react";
import ChartEmptyView from "../../../common/components/chart-empty-view/chart-empty-view";
import {
  colorCode,
  formatNumbers,
} from "../../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";
import PDFViewer from "./pdf-viewer";
import CampaignPreviewModal from "../../../common/components/campaign-preview-modal/campaign-preview-modal";
import { useContext } from "react";
import { TenantContext } from "../../create-sms/sms-parent/index";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import { dateTimeFormatter } from "../../../../../marketing-cloud-main/src/common/utils/DateUtils";

const { RangePicker } = DatePicker;
const CampaignInsights = ({ dispatch, setShowCommonLoader, businessId }) => {
  const [options, setOptions] = useState(null);
  const [phoneNumberId, setPhoneNumberId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  const [showDateMenu, setShowDateMenu] = useState(false);
  const [status, setStatus] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const [pageNum, setPageNum] = useState(0);
  const [from, setFrom] = useState(0);
  const [loading, setLoading] = useState({
    downloadingReport: false,
    gettingDlrs: false,
  });
  // const [dateKey, setDateKey] = useState("");
  // const [prevDateKey, setPrevDateKey] = useState("");
  const [audienceList, setAudienceList] = useState({
    data: [],
    totalDlrs: 0,
    audienceSource: "",
  });
  const [tooltipVisibility, setTooltipVisibility] = useState(false);
  const [dndTooltipVisibility, setDndTooltipVisibility] = useState(false);
  const [deliveryRate, setDeliveryRate] = useState(null);
  const [campaignPreviewModalState, setCampaignPreviewModalState] =
    useState(false);

  const tenantContext: any = useContext(TenantContext);

  const [response, setResponse] = useState({
    name: "",
    channel: "",
    wabaDetials: { verifiedName: "" },
    audience: { segmentId: "" },
    budget: 0,
    schedule: {
      immediate: false,
      timeline: { start: "", end: "" },
    },
    spend: 0,
    analaytics: {
      delivered: 0,
      read: 0,
      sent: 0,
      undelivered: 0,
      clicks: 0,
      dnd: 0,
      unsent: 0,
    },
    status: "",
    senderName: "",
    refunds: null,
    content: { text: "" },
    landingPagesURL: "",
    sampleData: [],
  });

  const pendingDlrs = Math.max(
    0,
    (response?.analaytics?.sent || 0) -
      (response?.analaytics?.delivered || 0) -
      (response?.analaytics?.undelivered || 0) -
      (response?.analaytics?.dnd || 0) -
      (response?.analaytics?.unsent || 0)
  );

  const statusMenu = useRef(null);
  const datepicker = useRef(null);

  const closeOpenMenus = (e) => {
    if (
      statusMenu.current &&
      showStatusMenu &&
      !statusMenu.current.contains(e.target)
    ) {
      setShowStatusMenu(false);
    }

    if (
      datepicker.current &&
      showDateMenu &&
      !datepicker.current.contains(e.target)
    ) {
      setShowDateMenu(false);
    }
  };

  const setPage = (num) => {
    setPageNum(num);
    setFrom(num * pageSize);
  };

  useEffect(() => {
    fetchMessageDetails();
    updateGraphValues({
      sent: 0,
      delivered: 0,
      undelivered: 0,
      dnd: 0,
      unsent: 0,
      clicks: 0,
    });
  }, [response.channel, status]);

  useEffect(() => {
    const params = {
      channel: response?.channel?.toLowerCase(),
      startDate,
      endDate,
      status,
      pageSize,
      from,
      phoneNumberId,
    };
    fetchAudienceList(params);
  }, [
    status,
    startDate,
    endDate,
    pageNum,
    pageSize,
    from,
    phoneNumberId,
    response?.channel,
  ]);

  const updateGraphValues = (analytics) => {
    const pendingDlrs = analytics.sent
      ? Math.max(
          0,
          analytics.sent -
            (analytics.delivered || 0) -
            (analytics.undelivered || 0) -
            (analytics.dnd || 0) -
            (analytics.unsent || 0)
        )
      : 0;

    let columns = ["Delivered", "Clicks", "Pending DLRs", "Undelivered"];
    let seriesData = [
      {
        value: analytics?.delivered || 0,
        itemStyle: { color: "#12B76A", borderRadius: [10, 10, 0, 0] },
      },

      {
        value: analytics?.clicks || 0,
        itemStyle: { color: "#FCB89B", borderRadius: [10, 10, 0, 0] },
      },
      {
        value: pendingDlrs || 0,
        itemStyle: { color: "#AC5803", borderRadius: [10, 10, 0, 0] },
      },
      {
        value: analytics?.undelivered || 0,
        itemStyle: { color: "#FCD98D", borderRadius: [10, 10, 0, 0] },
      },
    ];

    if (response?.channel.toLowerCase() == "sms") {
      columns.push("DND");
      seriesData.push({
        value: analytics?.dnd || 0,
        itemStyle: { color: "#A3C6FB", borderRadius: [10, 10, 0, 0] },
      });
    }

    const options = {
      grid: {
        height: "80%",
        bottom: "15%",
        width: "85%",
        // left: "40px",
      },
      xAxis: {
        type: "category",
        data: columns,
        axisTick: false,
      },
      yAxis: {
        type: "value",
        splitNumber: 4,
      },
      series: [
        {
          data: seriesData,
          type: "bar",

          smooth: 0.6,
          backgroundStyle: {
            borderRadius: 10,
          },
        },
      ],
    };
    setOptions(options);
  };

  var icon;

  const colorCodes = (status) => {
    switch (status?.toLowerCase()) {
      case "read":
        icon = <img src={Eye} alt="" />;
        return "bg-green-5 text-primary-green";
      case "opt-out":
        icon = <img src={Optout} alt="" />;
        return "bg-error-50 text-error-700";
      case "sent":
      case "pending_dlr":
        icon = <img src={OneTick} alt="" />;
        return "bg-green-60 text-green-710";
      case "delivered":
        icon = <img src={Tick} alt="" />;
        return "bg-gray-711 text-gray-710";
      default:
        return "bg-gray-100 text-gray-700";
    }
  };

  const getStatusName = (status) => {
    if (!status) return "";
    switch (status?.toLowerCase()) {
      case "sent":
      case "pending_dlr":
        return "Pending DLRs";
      case "dnd":
        return "DND";
      case "unsent":
        return "Invalid Numbers";
      default:
        return status;
    }
  };

  const fetchMessageDetails = () => {
    let path = location.pathname.split("/");
    let id = path[path.length - 1];
    dispatch(setShowCommonLoader(true));

    try {
      getMessageDetails(
        id,
        response?.channel?.toLowerCase(),
        "spend,wabaDetails"
      )
        .then((res) => {
          if (!res.error) {
            setResponse(res.response);
            updateGraphValues(res.response.analaytics);
            dispatch(setShowCommonLoader(false));
          } else {
            Sentry.captureMessage("Error", res);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          dispatch(setShowCommonLoader(false));
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      dispatch(setShowCommonLoader(false));
    }
  };

  const generatePreviewMessage = () => {
    if (!response?.sampleData || response?.sampleData?.length === 0) {
      return response.content?.text.replace(/\n/g, "<br>");
    }

    const userdata = response?.sampleData[0];

    const previewMessage = response.content?.text.replace(
      /{{(.*?)}}/g,
      (_, key) => {
        return userdata[key] || "";
      }
    );

    return previewMessage.replace(/\n/g, "<br>");
  };

  const sanitizedMessage = DOMPurify.sanitize(generatePreviewMessage());

  const fetchAudienceList = (params = {}) => {
    setLoading((loading) => ({ ...loading, gettingDlrs: true }));
    let path = location.pathname.split("/");
    let id = path[path.length - 1];

    if (!response?.channel) {
      return;
    }
    dispatch(setShowCommonLoader(true));

    try {
      getDlr(params, id)
        .then((res: any) => {
          setLoading((loading) => ({ ...loading, gettingDlrs: false }));
          const response = res.data.response;
          if (!res.error) {
            setAudienceList(response);
            dispatch(setShowCommonLoader(false));
          } else {
            Sentry.captureMessage("Error", res);
          }
        })
        .catch((err) => {
          setLoading((loading) => ({ ...loading, gettingDlrs: false }));
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          dispatch(setShowCommonLoader(false));
        });
    } catch (err) {
      setLoading((loading) => ({ ...loading, gettingDlrs: false }));
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      dispatch(setShowCommonLoader(false));
    }
  };

  const phoneNumberIdOnChange = (value) => {
    setPhoneNumberId(value);
  };

  const handlePhoneNumberChange = useCallback(
    debounce(phoneNumberIdOnChange),
    []
  );

  const onRangeSelectionChange = (event) => {
    let sDate = new Date(event[0].$d);
    let eDate = new Date(event[1].$d);
    sDate.setHours(0, 0, 0, 0);
    eDate.setHours(0, 0, 0, 0);
    setStartDate(sDate.getTime());
    setEndDate(eDate.getTime());
  };
  const capitalizeFirstLetter = (str: string) => {
    str = str.trim().toLowerCase();
    const status = str.charAt(0).toUpperCase() + str.slice(1);
    return status;
  };

  const downloadReport = () => {
    setLoading((loading) => ({ ...loading, downloadingReport: true }));
    let path = location.pathname.split("/");
    let id = path[path.length - 1];

    try {
      downloadCampaignReport(id)
        .then((res: any) => {
          setLoading((loading) => ({ ...loading, downloadingReport: false }));
          if (res.status === 200) {
            downloadZip(res.data, response?.name || "");
          }
        })
        .catch((err) => {
          setLoading((loading) => ({ ...loading, downloadingReport: false }));
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      setLoading((loading) => ({ ...loading, downloadingReport: false }));
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
    }
  };

  const insightIcon = (channel) => {
    switch (channel?.toLowerCase()) {
      case "sms":
        return <img src={SmsInsight} alt="" />;
      case "email":
        return <img src={EmailInsight} alt="" />;
      case "whatsapp":
        return <img src={WhatsappInsight} alt="" />;
    }
  };

  /*
  const handleNext = () => {
    setPrevDateKey(dateKey);
    setDateKey(audienceList.data[audienceList.data.length - 1]?.date_key);
  };

  const handlePrev = () => {
    setDateKey(prevDateKey);
    setPrevDateKey("");
  }; */

  document.addEventListener("mousedown", closeOpenMenus);

  const getDeliveryRate = (sent, delivered, dnd) => {
    //delivery rate = delivered / sent - dnd
    let rate = 0;
    if (sent > 0) {
      rate = ((delivered ? delivered : 0) / (sent - (dnd ? dnd : 0))) * 100;
    }
    setDeliveryRate(Math.round((rate + Number.EPSILON) * 100) / 100);
  };

  useEffect(() => {
    getDeliveryRate(
      response?.analaytics?.sent,
      response?.analaytics?.delivered,
      response?.analaytics?.dnd
    );
  }, [response]);

  const previewCampaign = () => {
    setCampaignPreviewModalState(true);
  };

  const getOperatorIcons = (index) => {
    switch (index) {
      case "ng_airtel":
        return <img src={Airtel} alt="" />;
      case "ng_mtn":
        return <img src={Mtn} alt="" />;
      case "ng_glo":
        return <img src={Glo} alt="" />;
      case "ng_9mobile":
        return <img src={Etisalat} alt="" />;
      default:
        return "-";
    }
  };

  const getPdfUrlForBusinessId = () => {
    switch (businessId) {
      case 629:
        return "https://mcassets.blob.core.windows.net/cdn/Reports/EcoBank_Supersaver_Campaign_Report.pdf";
      case 759:
        return "https://s3.eu-west-1.amazonaws.com/cdn.terragonbase.com/759/insights.pdf";
      case 760:
        return "https://mcassets.blob.core.windows.net/cdn/Reports/BETBONANZA_CAMPAIGN_REPORT.pdf";
    }
  };

  return (
    <>
      <div className="mt-[30px] mx-10  mb-[42px]">
        <div className="flex justify-between">
          <div className="flex justify-center items-center">
            <div>{insightIcon(response?.channel)}</div>
            <div className="flex">
              <div className=" ml-2.5 text-xl font-semibold text-gray-700">
                {response?.name || ""}
              </div>
            </div>
            <div>
              <div
                className={`${colorCode(
                  response.status
                )} ml-3 rounded-2xl py-0.5 px-2 text-xs lowercase first-letter:capitalize`}
              >
                {response?.status}
              </div>
            </div>
          </div>
          <div className="flex gap-4">
            <div>
              <button
                className="inline-flex justify-center w-full rounded-md border px-5 py-3"
                onClick={previewCampaign}
              >
                <span className="mr-2 leading-4 text-sm font-semibold text-gray-700">
                  Preview campaign
                </span>
              </button>
            </div>
            <div>
              <button
                className="inline-flex justify-center w-full rounded-md border px-5 py-3"
                disabled={loading.downloadingReport}
                onClick={downloadReport}
              >
                <span className="mr-2 leading-4 text-sm font-semibold text-gray-700">
                  {loading.downloadingReport
                    ? "Downloading report"
                    : "Download report"}
                </span>
                <div className="py-1">
                  {loading.downloadingReport ? (
                    <Spinner className={"w-3 h-3"} />
                  ) : (
                    <img src={DownArrow} alt="" />
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-10">
        <div className="flex gap-9">
          <div className="w-36 text-base font-semibold text-gray-70">
            Message details
          </div>
          <div className="grow h-1 border-t mt-2.5 border-[#EBEFF3]"></div>
        </div>
        <div className="py-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            <div className="flex rounded-xl">
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                  <img src={Message} alt="" />
                </div>
                <div className="ml-3">
                  <span className="text-gray-700 font-semibold text-base leading-6 whitespace-no-wrap">
                    Messages channel
                  </span>
                  <p className="text-gray-600 font-normal pt-1 text-sm whitespace-no-wrap lowercase first-letter:uppercase">
                    {response?.channel}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex rounded-xl">
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                  <img src={Fisend} alt="" />
                </div>
                <div className="ml-3">
                  <span className="text-gray-700 font-semibold text-base leading-6 whitespace-no-wrap">
                    {response?.channel === "SMS"
                      ? "Sender ID"
                      : response?.channel === "WHATSAPP"
                      ? "WhatsApp Business Account"
                      : "Sender email"}
                  </span>
                  <p className="text-gray-600 pt-1 font-normal text-sm whitespace-no-wrap">
                    {response.channel === "SMS"
                      ? response.senderName
                      : response.channel === "WHATSAPP"
                      ? response.wabaDetials?.verifiedName
                      : "Sender email"}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex rounded-xl">
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                  <img src={Peopleshadow} alt="" />
                </div>
                <div className="ml-3">
                  <span className="text-gray-700 font-semibold text-base leading-6 whitespace-no-wrap">
                    Audience
                  </span>
                  <p className="text-gray-600 pt-1 font-normal text-sm whitespace-no-wrap">
                    {response.audience?.segmentId ? "Segment" : "Custom"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            <div className="flex rounded-xl">
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                  <img src={Peopleshadow} alt="" />
                </div>
                <div className="ml-3">
                  <span className="text-gray-700 font-semibold text-base leading-6 whitespace-no-wrap">
                    Profiles
                  </span>
                  <p className="text-gray-600 pt-1 font-normal text-sm whitespace-no-wrap">
                    {formatNumbers(response.budget)}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex rounded-xl">
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                  <img src={Clock} alt="" />
                </div>
                <div className="ml-3">
                  <span className="text-gray-700 font-semibold text-base leading-6 whitespace-no-wrap">
                    Message Timeline
                  </span>
                  <p className="text-gray-600 pt-1 font-normal text-sm whitespace-no-wrap">
                    {response.schedule?.immediate
                      ? dateTimeFormatter(response.schedule?.timeline?.start)
                      : `${dateTimeFormatter(
                          response.schedule?.timeline?.start
                        )} - ${dateTimeFormatter(
                          response.schedule?.timeline?.end
                        )}`}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex rounded-xl">
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                  <img src={Cash} alt="" />
                </div>
                <div className="ml-3">
                  <span className="text-gray-700 font-semibold text-base leading-6 whitespace-no-wrap">
                    Spend
                  </span>
                  <p className="text-gray-600 pt-1 font-normal text-sm whitespace-no-wrap">
                    {
                      <CurrencySymbol
                        value={response.spend ? response.spend : 0}
                      />
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {response.refunds && (
          <div className="py-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
              <div className="flex rounded-xl">
                <div className="flex justify-center items-center">
                  <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                    <img src={RefundInitiated} alt="" />
                  </div>
                  <div className="ml-3">
                    <span className="text-gray-700 font-semibold text-base leading-6 whitespace-no-wrap">
                      Refund initiated
                    </span>
                    <p className="text-gray-600 pt-1 font-normal text-sm whitespace-no-wrap">
                      {" "}
                      {response.refunds.dndRefunds?.units ??
                        response.refunds.unsentRefunds?.units}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mx-10">
        <div className="flex gap-9">
          <div className="w-36 text-base font-semibold text-gray-70">
            Statistics
          </div>
          <div className="grow h-1 border-t mt-2.5 border-[#EBEFF3]"></div>
        </div>
        <div className="pt-6 flex gap-1.5">
          <div className="text-lg leading-6 font-semibold text-gray-800">
            {formatNumbers(Math.max(0, response?.analaytics?.sent || 0))}
          </div>
          <div className="text-[14px] leading-6 ml-1 text-gray-70">
            {response?.analaytics?.sent === 1
              ? "Sent message"
              : "Sent messages"}
          </div>
        </div>
        <div className="pt-10 pb-2">
          <div className="flex w-full">
            <div className="flex-1 py-2.5 px-3.5">
              <div className="flex flex-col justify-center items-center border-r border-[#98A2B3]">
                <div className="flex items-center">
                  <div className="bg-[#12B76A] w-2 h-2 -mt-0.5 rounded-full"></div>
                  <div className="text-[12px] leading-[18px] text-gray-70 pl-1">
                    Delivered
                  </div>
                </div>
                <div className="text-xl leading-7  font-semibold text-gray-700 pt-1">
                  {formatNumbers(
                    Math.max(0, response?.analaytics?.delivered || 0)
                  )}
                </div>
              </div>
            </div>

            {response?.channel.toLowerCase() == "whatsapp" && (
              <div className="flex-1 py-2.5 px-3.5">
                <div className="flex justify-center border-r border-[#98A2B3]">
                  <div className="flex flex-col justify-center items-center">
                    <div className="flex items-center">
                      <div className="bg-[#A3C6FB] w-2 h-2 -mt-0.5 rounded-full"></div>
                      <div className="text-[12px] leading-[18px] text-gray-70 pl-1">
                        Read
                      </div>
                    </div>
                    <div className="text-xl leading-7 font-semibold text-gray-700 pt-1">
                      {formatNumbers(
                        Math.max(0, response?.analaytics?.read || 0)
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex-1 py-2.5 px-3.5">
              <div className="flex justify-center border-r border-[#98A2B3]">
                <div className="flex flex-col justify-center items-center">
                  <div className="flex items-center">
                    <div className="text-[12px] leading-[18px] text-gray-70">
                      <span className="flex gap-2 relative">
                        Delivery rate
                        <span
                          onMouseOver={() => setTooltipVisibility(true)}
                          onMouseOut={() => setTooltipVisibility(false)}
                          className="cursor-pointer"
                        >
                          <img src={InfoCircle} alt="" />
                        </span>
                        {tooltipVisibility && (
                          <div className="absolute p-3 z-10 ml-7 -mt-10 bg-tooltip-black text-white border text-sm leading-6 rounded-lg w-[212px] left-[-93%] top-[-60px]">
                            This is the percentage of sent messages that
                            successfully reached your target audience.
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="text-xl leading-7 font-semibold text-gray-700 pt-1 flex items-center gap-1">
                    {deliveryRate ?? "-"}%
                    {deliveryRate >= 50 ? (
                      <img src={DeliveryRateUp} alt="" />
                    ) : (
                      <img src={DeliveryRateDown} alt="" />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 py-2.5 px-3.5">
              <div className="flex justify-center border-r border-[#98A2B3]">
                <div className="flex flex-col justify-center items-center">
                  <div className="flex items-center">
                    <div className="bg-[#FCB89B] w-2 h-2 -mt-0.5 rounded-full"></div>
                    <div className="text-[12px] leading-[18px] text-gray-70 pl-1">
                      Clicks
                    </div>
                  </div>
                  <div className="text-xl leading-7 font-semibold text-gray-700 pt-1">
                    {formatNumbers(
                      Math.max(0, response?.analaytics?.clicks || 0)
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 py-2.5 px-3.5">
              <div className="flex justify-center border-r border-[#98A2B3]">
                <div className="flex flex-col justify-center items-center">
                  <div className="flex items-center">
                    <div className="bg-[#AC5803] w-2 h-2 -mt-0.5 rounded-full"></div>
                    <div className="text-[12px] leading-[18px] text-gray-70 pl-1">
                      Pending DLRs
                    </div>
                  </div>
                  <div className="text-xl leading-7 font-semibold text-gray-700 pt-1">
                    {formatNumbers(pendingDlrs) || 0}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 py-2.5 px-3.5">
              <div className="flex justify-center">
                <div className="flex flex-col justify-center items-center">
                  <div className="flex items-center">
                    <div className="bg-[#FCD98D] w-2 h-2 -mt-0.5 rounded-full"></div>
                    <div className="text-[12px] leading-[18px] text-gray-70 pl-1">
                      Undelivered
                    </div>
                  </div>
                  <div className="text-xl leading-7 font-semibold text-gray-700 pt-1">
                    {formatNumbers(
                      Math.max(0, response?.analaytics?.undelivered || 0)
                    )}
                  </div>
                </div>
              </div>
            </div>

            {response?.channel.toLowerCase() == "sms" && (
              <div className="flex-1 py-2.5 px-3.5">
                <div className="flex justify-center border-l border-[#98A2B3]">
                  <div className="flex flex-col justify-center items-center">
                    <div className="flex items-center">
                      <div className="bg-[#A3C6FB] w-2 h-2 -mt-0.5 rounded-full"></div>
                      <div className="text-[12px] leading-[18px] text-gray-70 pl-1">
                        <span className="flex gap-2 relative">
                          Rejected
                          <span
                            onMouseOver={() => setDndTooltipVisibility(true)}
                            onMouseOut={() => setDndTooltipVisibility(false)}
                            className="cursor-pointer"
                          >
                            <img src={InfoCircle} alt="" />
                          </span>
                          {dndTooltipVisibility && (
                            <div className="absolute p-3 z-10 ml-7 -mt-10 bg-tooltip-black text-white border text-sm leading-6 rounded-lg w-[250px] left-[-170%] top-[-85px]">
                              Total messages that were rejected due to DND or
                              gateway rejections.
                              <div className="mt-1">
                                <div className="flex gap-1 items-center">
                                  <span className="bg-[#A3C6FB] w-2 h-2 rounded-full"></span>
                                  <span>
                                    {formatNumbers(
                                      Math.max(
                                        0,
                                        response?.analaytics?.dnd || 0
                                      )
                                    )}{" "}
                                    DND recipients
                                  </span>
                                </div>
                                <div className="flex gap-1 items-center">
                                  <span className="bg-[#A3C6FB] w-2 h-2 rounded-full"></span>
                                  <span>
                                    {formatNumbers(
                                      Math.max(
                                        0,
                                        response?.analaytics?.unsent || 0
                                      )
                                    )}{" "}
                                    Invalid numbers
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="text-xl leading-7 font-semibold text-gray-700 pt-1">
                      {formatNumbers(
                        Math.max(
                          0,
                          response?.analaytics?.dnd +
                            response?.analaytics?.unsent
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {response.analaytics === undefined ||
        (response?.analaytics?.sent === 0 &&
          response?.analaytics?.clicks === 0 &&
          response?.analaytics?.delivered === 0 &&
          response?.analaytics?.undelivered === 0 &&
          response?.analaytics?.dnd === 0) ? (
          <div>
            <ChartEmptyView
              items={[
                "Clicks",
                "Delivered",
                "Pending DLRs",
                "Undelivered",
                "DND",
              ]}
            />
          </div>
        ) : (
          options && <ReactECharts option={options} />
        )}
      </div>

      {(businessId === 629 || businessId === 759 || businessId === 760) && (
        <div className="mx-10 mt-5">
          <div className="text-base font-semibold text-gray-70 mb-8">
            Post Campaign Report
          </div>
          <div className="h-[500px]">
            <PDFViewer pdfURL={getPdfUrlForBusinessId()} />
          </div>
        </div>
      )}

      <div className="mx-10">
        <div className="border-b border-[ #EBEFF3]">
          <div className="text-sm font-semibold leading-6">Message log</div>
        </div>
        <div className="pt-7 flex justify-between">
          {audienceList?.audienceSource === "csv" ? (
            <div>
              <div className="flex border border-gray-50 rounded-lg">
                <div className="w-10 h-10 text-gray-400">
                  <Search className="text-xl m-3" size="16" />
                </div>
                <input
                  type="text"
                  placeholder="Search MSISDNs"
                  className="w-full h-10 pt-0.5 pr-28 mr-2 focus:border-none font-normal focus:outline-none "
                  onChange={(event) =>
                    handlePhoneNumberChange(event.target.value)
                  }
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div>
            <div className="flex gap-2.5">
              {status && (
                <button className="inline-flex gap-1.5 capitalize items-center px-4 py-3 text-[14px] leading-4 font-semibold text-center text-gray-70 bg-error-50 rounded-full hover:text-error-700">
                  {getStatusName(status)}
                  <div className="-mt-0.5" onClick={() => setStatus("")}>
                    <img src={Close} alt="" />
                  </div>
                </button>
              )}
              <div className="relative">
                <button
                  className="h-[40px] w-[100px] flex gap-2 justify-center items-center text-[14px] leading-4 font-semibold text-center text-gray-70 bg-gray-10 rounded-full hover:bg-gray-100"
                  type="button"
                  onClick={() => {
                    setShowStatusMenu((showStatusMenu) => !showStatusMenu);
                  }}
                >
                  Status <img src={DownArrow} alt="" />
                </button>
                {showStatusMenu && (
                  <MessagesStatusMenu
                    showStatusMenu={showStatusMenu}
                    statusMenu={statusMenu}
                    setStatus={setStatus}
                    status={status}
                    setPageSize={setPageSize}
                    setPageNum={setPageNum}
                    setFrom={setFrom}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              </div>
              <div className="h-[40px] w-[100px] px-1 bg-[#F6F9FC] text-[#475467] font-semibold flex rounded-full items-center cursor-pointer relative">
                <div className="absolute left-0 top-0 h-[40px] w-[100px] bg-[#F6F9FC] rounded-full z-10 flex items-center cursor-pointer gap-3 justify-center pointer-events-none">
                  <span className="cursor-pointer">Date</span>{" "}
                  <img src={DownArrow} alt="" />
                </div>
                <div>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorIcon: "#98A2B3",
                        colorBorder: "#D0D5DD",
                        colorPrimary: "#008138",
                        colorPrimaryHover: "#008138",
                        colorText: "#344054",
                        colorTextHeading: "#344054",
                        colorTextDisabled: "#D0D5DD",
                        colorTextLightSolid: "var(--main-color)",
                        colorTextPlaceholder: "#98A2B3",
                        controlItemBgActive: "#DAEEE1",
                        controlOutline: "#FFFFFF",
                        colorBgTextActive: "FFFFFF",
                        colorTextLabel: "#FFFFFF",
                      },
                    }}
                  >
                    <RangePicker
                      className="outline-none border-none bg-[#F6F9FC] text-[#F6F9FC] shadow-none focus:{outline-none border-none bg-[#F6F9FC] shadow-none text-[#F6F9FC]} placeholder:{text-[#F6F9FC]}"
                      onChange={onRangeSelectionChange}
                      locale={locale}
                      showNow={false}
                      allowClear={false}
                      cellRender={(current, info) => {
                        if (info.type !== "date") return info.originNode;
                        const style: React.CSSProperties = {};
                        style.borderRadius = "50%";
                        style.width = "30px";
                        style.height = "30px";
                        style.lineHeight = "30px";
                        return (
                          <div className="ant-picker-cell-inner" style={style}>
                            {current.date()}
                          </div>
                        );
                      }}
                    />
                  </ConfigProvider>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-10 relative">
          <table className="min-w-full table-auto border-collapse border-b border-gray-100">
            <thead>
              <tr>
                {[
                  "MSISDN",
                  "Operator",
                  "Sent time",
                  "Delivery time",
                  "Status",
                ].map((item, index) => (
                  <th
                    key={index}
                    className="text-sm text-left text-gray-710 font-semibold py-4 px-2 border-b border-gray-100"
                  >
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading.gettingDlrs ? (
                <tr>
                  <td colSpan={5} className="py-10 text-center">
                    <div className="flex justify-center items-center">
                      <Spinner className="w-6 h-6" />
                    </div>
                  </td>
                </tr>
              ) : audienceList?.data.length !== 0 ? (
                audienceList?.data?.map((item, index) => (
                  <tr
                    key={index}
                    className="text-slate-600 border-b border-gray-100"
                  >
                    <td
                      className={`text-sm leading-6 py-4 px-2 ${
                        audienceList?.audienceSource === "csv" ? "" : "blur-sm"
                      }`}
                    >
                      {audienceList?.audienceSource === "csv"
                        ? `${item.msisdn}`
                        : "**************"}
                    </td>
                    <td className="text-sm leading-6 py-4 px-2">
                      <div>{getOperatorIcons(item.operator)}</div>
                    </td>
                    <td className="text-sm leading-6 py-4 px-2">
                      <div>{dateTimeFormatter(item.sentTime)}</div>
                    </td>
                    <td className="text-sm leading-6 py-4 px-2">
                      <div>
                        {item.delivertTime
                          ? dateTimeFormatter(item.delivertTime)
                          : "-"}
                      </div>
                    </td>
                    <td className="flex py-4 px-2">
                      <div
                        className={`${colorCodes(
                          item.status
                        )} font-medium text-xs leading-5 py-1 px-3 rounded-2xl flex items-center justify-center gap-1`}
                      >
                        <div>{icon}</div>
                        <div className="first-letter:capitalize">
                          {item.status ? getStatusName(item.status) : "Pending"}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="py-10 text-center">
                    <div className="bg-gray-25 text-sm text-gray-90 p-6 border border-gray-50 rounded-lg">
                      No Data available
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {pageNum === 0 && audienceList?.totalDlrs === 0 ? null : (
            <div className="flex my-10">
              <PaginationComponent
                setPageSize={setPageSize}
                pageSize={pageSize}
                setPageNum={setPage}
                pageNum={pageNum}
                setFrom={setFrom}
                ListLength={audienceList?.data.length}
                totalListLength={audienceList.totalDlrs}
              />
            </div>
          )}
        </div>
      </div>
      {campaignPreviewModalState && (
        <CampaignPreviewModal
          text={sanitizedMessage}
          url={response.landingPagesURL}
          closeModal={() => setCampaignPreviewModalState(false)}
          sender={
            response.wabaDetials
              ? response.wabaDetials.verifiedName
              : response.senderName
          }
        />
      )}
    </>
  );
};

export default CampaignInsights;
