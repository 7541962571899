import { api, billingApi } from '../services/common-api-service';
import { tenantApi } from '../services/common-api-service';
import * as Sentry from '@sentry/react';
import env from '../../../env.json';
import { CLIENT_ID } from '../constants/authToken';
const clientId = env.CLIENT_ID;

export const addUser = (payload, xTenantId) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/mcuser`;
  return tenantApi
    .post(url, payload, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getUsersData = (activePage, itemsPerPage, xTenantId) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/mcuser?pagenum=${activePage}&pagesize=${itemsPerPage}&additionalAttributes=role`;
  return tenantApi
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      //(error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
      return error;
    });
};

export const getApiKeys = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/api/keys`;
  return api
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const resetKey = (type: string) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/api/keys/reset?type=${type}`;
  return api
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const updateKeyStatus = (status: string) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/api`;
  return api
    .put(
      url,
      { state: status },
      {
        headers: {
          Authorization: `bearer ${loginAccessToken}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getRoles = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/mcroles`;
  return tenantApi
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getTenentDetails = (userId) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/tenant/${userId}`;
  return tenantApi
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getTenants = (page, industry, tenantName) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/tenants?page=${page}${
    tenantName !== '' ? `&businessName=${tenantName}` : ''
  }${industry !== '' ? `&industry=${industry}` : ''}`;
  return tenantApi
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getCurrentExchangeRate = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = '/rates';
  return billingApi
    .get(url, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
        'client-id': CLIENT_ID,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const updateExchangeRate = (payload) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/admin/fix-rates`;
  return billingApi
    .post(url, payload, {
      headers: {
        Authorization: `bearer ${loginAccessToken}`,
        'client-id': CLIENT_ID,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};
