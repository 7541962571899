import { useEffect, useRef, useState } from "react";
import TelcoLogo from "../../assets/rewardReport/Telcos";
import { getTelcoOperators } from "../../common/services/reward-services";
import * as Sentry from "@sentry/react";
import { DownArrow } from "../../assets/icons/icons";

const OperatorDropdown = (props) => {
  let { selectedOperator, setSelectedOperator, country } = props;
  const [showNetworkMenu, setShowNetworkMenu] = useState(false);
  const [operatorList, setOperatorList] = useState([]);
  const networkMenu = useRef(null);
  const closeOpenMenus = (e) => {
    if (
      networkMenu.current &&
      networkMenu &&
      !networkMenu.current.contains(e.target)
    ) {
      setShowNetworkMenu(false);
    }
  };

  const getOperators = (country) => {
    try {
      getTelcoOperators(country)
        .then((res) => {
          const { error, response = [] } = res;
          if (!error || error === "false") {
            setOperatorList(response);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })

        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  useEffect(() => {
    getOperators(country);
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);

    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  });
  return (
    <div className="relative w-full">
      <button
        className={`flex gap-2.5 justify-between items-center px-4 py-3 text-center h-12 bg-gray-10 rounded-full focus:outline-none text-sm leading-4 font-semibold text-gray-70`}
        type="button"
        //disabled={loading}
        onClick={() => setShowNetworkMenu(!showNetworkMenu)}
      >
        <div className="first-letter:capitalize">
          {selectedOperator || "operator"}
        </div>
        <img src={DownArrow} alt="" />
      </button>

      {/* Network Dropdown menu */}
      {showNetworkMenu && (
        <div
          ref={networkMenu}
          className="absolute z-10 bg-white rounded-lg shadow w-[186px]"
        >
          <div
            className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left cursor-pointer"
            onClick={() => {
              setSelectedOperator("");
              setShowNetworkMenu(false);
            }}
          >
            <div className="flex gap-4 items-center">All</div>
          </div>
          {operatorList.map((network, index) => (
            <div
              key={index}
              className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left cursor-pointer"
              onClick={() => {
                setSelectedOperator(network);
                setShowNetworkMenu(false);
              }}
            >
              <div className="flex gap-4 items-center">
                <TelcoLogo operator={network.toLowerCase()} />
                {network}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OperatorDropdown;
