import { useEffect, useState } from "react";
import {
  Channel,
  Clock,
  Fisend,
  Gift,
  Megaphone,
  MegaphoneSVG,
  Peopleshadow,
  Phone,
  Rocket,
  TickCircle,
} from "../../../assets/icons/icons";
import { updateMessage } from "../../../common/services/message-services";
import { Close } from "../../../assets/icons/icons";
import { getCurrenySymbol } from "../../../common/utils/currenySymbol";
import {
  getAccountStatus,
  getTopupPlans,
} from "../../../../../marketing-cloud-main/src/common/apis/billing";
import UnitValidation from "../../../../../marketing-cloud-main/src/common/components/unit-validation/unit-validation";
import * as Sentry from "@sentry/react";
import { getCampaignCost } from "../../../common/services/common-service";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";

const MesssagingStepThree = (props: any) => {
  const [showNotification, setShowNotification] = useState(false);
  const [accountStatus, setAccountStatus] = useState([]);
  const [unitModalState, setUnitModalState] = useState(false);
  const [topupEventUnits, setTopupEventUnits] = useState(0);
  const [loading, setLoading] = useState(false);
  const [unitPrice, setUnitPrice] = useState(0);
  const [campaignCost, setCampaignCost] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    try {
      setLoading(true);
      getAccountStatus()
        .then((res: any) => {
          setLoading(false);
          const { account_status = [], subscriptions = [] } = res;
          setAccountStatus(account_status);
          if (subscriptions && subscriptions.length) {
            const subs = subscriptions[0];
            const subsJson = JSON.parse(subs) || [];
            if (subsJson && subsJson.length) {
              const { plan_id = "" } = subsJson[0];
              localStorage.setItem("billingPlanId", plan_id);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log("error");
        });
    } catch (err) {
      setLoading(false);
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log("error");
    }
    try {
      setLoading(true);
      getTopupPlans()
        .then((response) => {
          setLoading(false);
          if (!response.error) {
            let priceList = response;
            let productId = getProductIdForCategory(
              props.data.content.templateInfo.category
            );
            updateCampaignCost(productId);
            let item =
              priceList[
                priceList.findIndex((ele) => {
                  return ele.product_id === productId;
                })
              ];
            setUnitPrice(item.billing_scheme.tiers[0].price);
          } else {
            Sentry.captureMessage("Error", response);
          }
        })
        .catch((err) => {
          setLoading(false);
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (err) {
      setLoading(false);
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  const country = props.country;
  const currency = props.currency;
  const currencySymbol = getCurrenySymbol(country, currency);

  const handleTopupUnitsChange = (event) => {
    setTopupEventUnits(event.target.value);
  };

  // useEffect(() => {
  //   fetchPricingDetails();
  // }, []);

  const goBack = (step) => {
    props.setStep(step);
  };
  // const handleChange = () => {
  //   setUnitModal(true);
  //   setSufficientWallet(true);
  // };
  const launchCampaign = (status) => {
    setLoading(true);
    setUnitModalState(false);
    if (!status) {
      setLoading(false);
      return;
    }
    const data: any = props.data;
    data.status = "RUNNING";
    let id = data.id.replaceAll("/", "~");
    try {
      updateMessage(data, id)
        .then((res = {}) => {
          setLoading(false);
          const { error, response } = res;
          props.setData(data);
          if (!error) {
            props.getBalance();
            Notify({
              type: "success",
              heading: "Campaign created successfully",
              message:
                "Your campaign has been created successfully and will go live at the scheduled time after it has been approved by our content and ad policy team.",
            });
            props.navigate("/engagements");
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          setLoading(false);
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (err) {
      setLoading(false);
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  const validateWallet = () => {
    setLoading(true);
    setUnitModalState(true);
  };

  const getProductIdForCategory = (category) => {
    switch (category) {
      case "AUTHENTICATION":
        return 18;
      case "MARKETING":
        return 9;
      case "UTILITY":
        return 19;
    }
  };

  const getDate = (timestamp) => {
    let date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  const getTime = (timestamp) => {
    let date = new Date(timestamp);
    return date.toLocaleTimeString();
  };

  const closeNotification = (event) => {
    setShowNotification(false);
  };

  const updateCampaignCost = async (productId) => {
    setLoading(true);
    let cost = await getCampaignCost(props.data?.budget, productId);
    setCampaignCost(cost);
    setLoading(false);
  };

  return (
    <div className="py-8 px-10">
      <div className="text-gray-500 text-sm font-semibold">STEP 3 OF 3</div>
      <div className="flex justify-between items-center mt-3">
        <div className="flex items-center gap-4">
          <div className="bg-green-707 rounded-full w-12 h-12 flex justify-center items-center">
            <img src={Rocket} alt="" />
          </div>
          <div>
            <div className="text-base font-semibold text-gray-70 leading-6">
              Preview and Launch
            </div>
            <div className="text-sm text-gray-500 leading-6">
              Preview your message and click “Launch” if everything checks out.
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-2 items-center">
          <button
            onClick={(event) => {
              goBack(2);
            }}
            className="border border-button-border rounded-md py-2 px-5 text-slate-600 font-semibold text-sm leading-6"
          >
            Back
          </button>
          <button
            disabled={loading}
            onClick={(event) => validateWallet()}
            className="border bg-blue-75 disabled:bg-blue-75 rounded-md py-2 px-5 font-semibold text-sm text-white hover:text-white hover:bg-green-75 leading-6"
          >
            Launch
          </button>
        </div>
      </div>
      <div className="flex mt-8">
        <div className="w-[60%]">
          <div className="flex items-center gap-4">
            <div className="text-gray-700 text-sm font-semibold leading-6">
              Audiences
            </div>{" "}
            <div
              className="text-green-15 text-sm cursor-pointer leading-6"
              onClick={(event) => {
                goBack(2);
              }}
            >
              Edit
            </div>
          </div>
          <div className="flex justify-between items-start mt-4">
            <div className="flex gap-4 w-[50%]">
              <div className="bg-gray-709 h-9 w-9 flex justify-center items-center rounded-full">
                <img src={Megaphone} alt="" />
              </div>
              <div>
                <div className="text-gray-700 text-sm font-semibold leading-6">
                  Audience
                </div>
                <div className="text-sm leading-6 text-gray-70">Custom</div>
              </div>
            </div>
            <div className="w-[50%]">
              <div className="flex items-center gap-4">
                <div className="bg-gray-709 h-9 w-9 flex justify-center items-center rounded-full">
                  <img src={Channel} alt="" />
                </div>
                <div>
                  <div className="text-gray-700 text-sm font-semibold leading-6">
                    Profiles
                  </div>
                  <div className="text-sm leading-6 text-gray-70">
                    {props?.data?.profiles}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4 mt-12">
            <div className="text-gray-700 text-sm font-semibold leading-6">
              Message
            </div>{" "}
            <div
              className="text-green-15 text-sm cursor-pointer leading-6"
              onClick={(event) => {
                goBack(2);
              }}
            >
              Edit
            </div>
          </div>
          <div className="flex justify-between items-start mt-4">
            <div className="flex gap-4 w-[50%]">
              <div className="bg-gray-709 h-9 w-9 flex justify-center items-center rounded-full">
                <img src={Gift} alt="" />
              </div>
              <div>
                <div className="text-gray-700 text-sm font-semibold leading-6">
                  Message name
                </div>
                <div className="text-sm leading-6 text-gray-70">
                  {props?.data?.name}
                </div>
              </div>
            </div>
            <div className="flex w-[50%]">
              <div className="flex items-center gap-4">
                <div className="bg-gray-709 h-9 w-9 flex justify-center items-center rounded-full">
                  <img src={Peopleshadow} alt="" />
                </div>
                <div>
                  <div className="text-gray-700 text-sm font-semibold leading-6">
                    Channel
                  </div>
                  <div className="text-sm leading-6 text-gray-70">
                    {props?.data?.channel}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-start mt-4">
            <div className="flex gap-4 w-[50%]">
              <div className="bg-gray-709 h-9 w-9 flex justify-center items-center rounded-full">
                <img src={Fisend} alt="" />
              </div>
              <div>
                <div className="text-gray-700 text-sm font-semibold leading-6">
                  WhatsApp Business Account
                </div>
                <div className="text-sm leading-6 text-gray-70">
                  {props?.data?.senderName}
                </div>
              </div>
            </div>
            <div className="flex w-[50%]">
              <div className="flex items-center gap-4">
                <div className="bg-gray-709 h-9 w-9 flex justify-center items-center rounded-full">
                  <img src={Clock} alt="" />
                </div>
                <div>
                  <div className="text-gray-700 text-sm font-semibold leading-6">
                    Date
                  </div>
                  <div className="text-sm leading-6 text-gray-70">
                    {getDate(props?.data?.schedule?.timeline?.start)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-start mt-4">
            <div className="flex gap-4 w-[50%]">
              <div className="bg-gray-709 h-9 w-9 flex justify-center items-center rounded-full">
                <img src={Fisend} alt="" />
              </div>
              <div>
                <div className="text-gray-700 text-sm font-semibold leading-6">
                  Message
                </div>
                <div className="text-sm leading-6 text-gray-70">
                  {props?.data?.content?.text}
                </div>
              </div>
            </div>
            <div className="flex w-[50%]">
              <div className="flex items-center gap-4">
                <div className="bg-gray-709 h-9 w-9 flex justify-center items-center rounded-full">
                  <img src={Peopleshadow} alt="" />
                </div>
                <div>
                  <div className="text-gray-700 text-sm font-semibold leading-6">
                    Push time
                  </div>
                  <div className="text-sm leading-6 text-gray-70">
                    {getTime(props?.data?.schedule?.timeline?.start)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4 mt-12">
            <div className="text-gray-700 text-sm font-semibold leading-6">
              Pricing
            </div>{" "}
            <div
              className="text-green-15 text-sm cursor-pointer leading-6"
              onClick={(event) => {
                goBack(2);
              }}
            >
              Edit
            </div>
          </div>
          <div className="flex justify-between items-start mt-4">
            <div className="flex gap-4 w-[50%]">
              <div className="bg-gray-709 h-9 w-9 flex justify-center items-center rounded-full">
                <img src={Channel} alt="" />
              </div>
              <div>
                <div className="text-gray-700 text-sm font-semibold leading-6">
                  Unit price
                </div>
                <div className="text-sm leading-6 text-gray-70">
                  {currencySymbol}
                  {unitPrice}
                </div>
              </div>
            </div>
            <div style={{ width: "50%" }}>
              <div className="flex items-center gap-4">
                <div className="bg-gray-709 h-9 w-9 flex justify-center items-center rounded-full">
                  <img src={Channel} alt="" />
                </div>
                <div>
                  <div className="text-gray-700 text-sm font-semibold leading-6">
                    Total pricing
                  </div>
                  <div className="text-sm leading-6 text-gray-70">
                    {currencySymbol}
                    {campaignCost ?? 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center w-[40%] bg-green-708 relative overflow-hidden h-[600px] rounded-lg border border-gray-100">
          <div>
            <div className="flex justify-center mt-12">
              <div className="h-[105px] w-[105px] bg-green-707 rounded-full flex justify-center items-center p-4">
                <img src={MegaphoneSVG} alt="" />
              </div>
            </div>
            <div className="absolute left-0 right-0 ml-auto mr-auto w-[280px] mt-2 z-20">
              <img src={Phone} alt="" />
              <div className="absolute top-[112px] bg-green-708 mr-11 ml-8 text-xs leading-4 py-1 px-2 rounded shadow-md">
                <div
                  className="mb-1"
                  style={{ borderRadius: "3px 3px 0px 0px" }}
                >
                  {props?.data?.content?.media?.type == "IMAGE" ? (
                    <div>
                      <img
                        src={props?.data?.content?.media?.url}
                        alt="Preview"
                      />
                    </div>
                  ) : null}
                </div>
                <div>{props?.data?.content?.text}</div>
              </div>
            </div>
            <div className="absolute h-[821px] w-[821px] bg-green-707 rounded-full right-[-38%] top-[36%] z-0 opacity-40"></div>
            <div className="absolute h-[731px] w-[731px] bg-green-709 opacity-80 rounded-full top-[50%] right-[-1%] z-10"></div>
          </div>
        </div>
      </div>
      {unitModalState && (
        <UnitValidation
          productId={getProductIdForCategory(
            props.data.content.templateInfo.category
          )}
          requiredUnits={campaignCost}
          currencySymbol={currencySymbol}
          successCallBack={(status) => {
            if (status) {
              launchCampaign(status);
            } else {
              setLoading(false);
              setUnitModalState(false);
            }
          }}
          type="Whatsapp"
        />
      )}
      {showNotification && (
        <div>
          <div className="absolute top-[100px] right-[50px] h-[145px] w-[363px] bg-green-10 text-green-25 border border-green-50 rounded-lg p-4 z-30">
            <div className="flex items-center text-base leading-6 font-semibold mb-3 gap-4">
              <img src={TickCircle} alt="" />
              <div className="flex justify-between w-full">
                <div>Campaign Launched successfully</div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    closeNotification(event);
                  }}
                >
                  <img src={Close} alt="" />
                </div>
              </div>
            </div>
            <div style={{ padding: "0px 30px" }}>
              Your WhatsApp campaign has been created successfully and will go
              live at the scheduled time after it has been approved.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MesssagingStepThree;
