const NotificationIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill="#F1FAF5" />
      <g clip-path="url(#clip0_12412_163377)">
        <path
          d="M26 11.4171C25.9992 11.2358 25.9552 11.0573 25.8717 10.8963C25.7883 10.7354 25.6677 10.5966 25.52 10.4914C25.3712 10.3853 25.199 10.3164 25.0181 10.2905C24.8371 10.2647 24.6526 10.2826 24.48 10.3428L11.1429 14.9143C10.8103 15.0336 10.5224 15.2521 10.318 15.5403C10.1136 15.8284 10.0026 16.1724 10 16.5257V17.7486C10.0026 18.1018 10.1136 18.4458 10.318 18.734C10.5224 19.0222 10.8103 19.2407 11.1429 19.36L11.7714 19.5771C11.8058 19.5883 11.8377 19.6062 11.8651 19.6297C11.8926 19.6533 11.9151 19.682 11.9314 19.7143C11.96 19.7838 11.96 19.8618 11.9314 19.9314C11.774 20.4144 11.7006 20.9208 11.7143 21.4285C11.7165 22.2891 11.9776 23.129 12.4638 23.839C12.95 24.549 13.6386 25.0963 14.4401 25.4095C15.2416 25.7227 16.1188 25.7873 16.9576 25.5951C17.7964 25.4028 18.5578 24.9625 19.1429 24.3314C19.5572 23.8667 19.8722 23.3222 20.0686 22.7314C20.0879 22.6883 20.1181 22.6509 20.1561 22.6229C20.1942 22.5948 20.2388 22.5771 20.2857 22.5714C20.3553 22.5428 20.4333 22.5428 20.5029 22.5714L24.5371 23.9543C24.6588 23.9943 24.7863 24.0136 24.9143 24.0114C25.1512 24.0132 25.3829 23.9413 25.5771 23.8057C25.7248 23.7005 25.8454 23.5617 25.9289 23.4008C26.0123 23.2398 26.0563 23.0613 26.0571 22.88L26 11.4171ZM17.4629 22.8457C17.2769 23.0482 17.0508 23.2097 16.7989 23.3199C16.547 23.4301 16.2749 23.4866 16 23.4857C15.7334 23.4827 15.47 23.4271 15.2249 23.3219C14.9799 23.2168 14.7581 23.0642 14.5722 22.8731C14.3863 22.6819 14.2401 22.4558 14.1419 22.2079C14.0437 21.96 13.9955 21.6952 14 21.4285C14.0009 21.1869 14.0434 20.9472 14.1257 20.72C14.1358 20.6842 14.1533 20.6509 14.1772 20.6224C14.2011 20.5938 14.2308 20.5707 14.2643 20.5545C14.2978 20.5383 14.3343 20.5294 14.3715 20.5284C14.4087 20.5273 14.4457 20.5342 14.48 20.5485L17.7486 21.6914C17.8163 21.7149 17.8721 21.7638 17.9041 21.8279C17.9362 21.892 17.9419 21.966 17.92 22.0343C17.8271 22.3172 17.6707 22.5752 17.4629 22.7885V22.8457Z"
          fill="#77D7A6"
        />
      </g>
      <defs>
        <clipPath id="clip0_12412_163377">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NotificationIcon;
