const TextSurveySummary = ({ question }) => {
  return (
    <div className="mt-5 h-[316px] overflow-hidden">
      <div className="h-full overflow-hidden hover:overflow-auto">
        {" "}
        {Object.keys(question.responseCounts[0].counts).map((key, i) => (
          <div
            key={i}
            className="mt-3 bg-[#F8F9FA] rounded p-2 text-sm text-[#475467]"
          >
            {key}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TextSurveySummary;
