import {
  Airtel,
  Equitel,
  Etisalat,
  Glo,
  Mtn,
  Safaricom,
  Telkom,
} from "../icons/icons";

const TelcoLogo = (props) => {
  let { operator } = props;
  switch (operator) {
    case "airtel":
      return <img src={Airtel} alt="Airtel" />;
    case "glo":
      return <img src={Glo} alt="Glo" />;
    case "mtn":
      return <img src={Mtn} alt="Mtn" />;
    case "9mobile":
      return <img src={Etisalat} alt="" />;
    case "telkom":
      return <img src={Telkom} alt="" />;
    case "safaricom":
      return <img src={Safaricom} alt="" />;
    case "equitel":
      return <img src={Equitel} alt="" />;
    default:
      return <div>TelcoLogo</div>;
  }
};

export default TelcoLogo;
