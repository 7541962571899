import { useEffect, useRef, useState } from "react";
import {
  addGAEventListener,
  getPageName,
} from "../../../../../marketing-cloud-container/src/ga-events";
import { Chevron, LeftArrow, RightArrow } from "../../../assets/icons/icons";

const PaginationCommon = (props: any) => {
  const [openSizeBar, setOpenSizeBar] = useState(false);
  const {
    setPageNum,
    pageNum,
    ListLength,
    totalListLength,
    setPageSize,
    setFrom,
    pageSize,
    filter,
    hidePageNums,
    hidePageSize,
    handleNext,
    handlePrev,
  } = props;

  useEffect(() => {
    addGAEventListener();
  }, [openSizeBar]);

  const handleSetPageSize = (e) => {
    setPageSize(Number(e.target.value));
    setFrom(0);
    setPageNum(0);
    setOpenSizeBar(false);
  };

  const sizeBar = useRef(null);

  const handleSelectPage = (e) => {
    setPageNum(Number(e.target.value));
  };

  const closeOpenMenus = (e: any) => {
    if (sizeBar.current && !sizeBar.current?.contains(e.target)) {
      setOpenSizeBar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);

    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  });

  return (
    <div className="flex justify-center items-center gap-10">
      {!hidePageSize && (
        <div className="flex gap-1 justify-center items-center">
          <div className="py-2.5 font-medium text-gray-800">Items per page</div>
          <div className="relative">
            <button
              disabled={(filter || pageNum > 0) && ListLength === 0}
              onClick={() => setOpenSizeBar(!openSizeBar)}
              className="flex text-sm px-2 py-3 gap-2 font-medium leading-5 justify-center items-center w-14 h-10 bg-white border border-gray-200 rounded triggerGA"
              data-event-category={`${getPageName()}-list-page`}
              data-event-label="items-per-page-dropdown_click"
              data-event-action="button_click"
            >
              {pageSize}
              <div className={openSizeBar ? "rotate-180" : "rotate-0"}>
                <img src={Chevron} alt="" />
              </div>
            </button>
            {openSizeBar && (
              <div
                ref={sizeBar}
                className="absolute bottom-10 text-gray-70 text-center mt-2 z-10 bg-white rounded shadow border border-gray-200"
              >
                <ul className="p-2 text-sm text-gray-700 z-10 shadow-xl">
                  {[5, 10, 15, 20, 25, 30, 50, 100].map((el, index) => (
                    <li
                      key={index}
                      value={el}
                      id={`${el}`}
                      onClick={handleSetPageSize}
                      className="px-3 py-2 rounded w-full hover:bg-gray-100 text-base font-medium text-gray-700 cursor-pointer triggerGA"
                      data-event-category={`${getPageName()}-list-page`}
                      data-event-label="items-per-page-dropdown_page-num_click"
                      data-event-action="list_item_click"
                      data-event-value={`${el}`}
                    >
                      {el}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="flex items-center">
        <button
          disabled={pageNum < 1}
          onClick={() => {
            handlePrev ? handlePrev() : setPageNum(pageNum - 1);
          }}
          className="flex justify-center box-border items-center gap-4 border rounded-l-md px-4 py-2 triggerGA"
          data-event-category={`${getPageName()}-list-page`}
          data-event-label={`${getPageName()}-list-pagination_click`}
          data-event-action="button_click"
          data-event-value="Previous"
        >
          <div className="pl-1">
            <img src={LeftArrow} alt="" />
          </div>
          <div className="text-sm font-semibold text-gray-800">Previous</div>
        </button>

        {!hidePageNums && (
          <div
            className="triggerGA"
            data-event-category={`${getPageName()}-list-page`}
            data-event-label={`${getPageName()}-list-pagination_click`}
            data-event-action="button_click"
          >
            {pageNum + 1 < Math.ceil(totalListLength / pageSize) && (
              <button
                disabled={ListLength === 0}
                value={pageNum}
                onClick={handleSelectPage}
                className="border px-4 py-2.5 bg-gray-100 font-semibold text-gray-700"
              >
                {pageNum + 1}
              </button>
            )}

            {pageNum + 2 < Math.ceil(totalListLength / pageSize) && (
              <button
                disabled={ListLength == 0}
                onClick={handleSelectPage}
                value={pageNum + 1}
                className="border px-4 py-2.5 bg-gray-100 font-semibold text-gray-700"
              >
                {pageNum + 2}
              </button>
            )}
            {pageNum + 3 < Math.ceil(totalListLength / pageSize) && (
              <button
                disabled={ListLength == 0}
                onClick={handleSelectPage}
                value={pageNum + 2}
                className="border px-4 py-2.5 bg-gray-100 font-semibold text-gray-700"
              >
                {pageNum + 3}
              </button>
            )}

            <button
              disabled={ListLength == 0}
              className="border px-4 py-2.5 font-semibold  text-gray-700"
            >
              ...
            </button>
            {totalListLength && pageSize && (
              <button
                disabled={ListLength == 0}
                onClick={handleSelectPage}
                value={Math.ceil(totalListLength / pageSize) - 1}
                className="border px-4 py-2.5 font-semibold  text-gray-700"
              >
                {Math.ceil(totalListLength / pageSize)}
              </button>
            )}
          </div>

          // <div>
          //   <button onClick={handlePrev} disabled={pageNum === 1}>
          //     <LeftArrow />
          //   </button>
          //   {paginationRange.map((pageNumber) => {
          //     if (pageNumber === "DOTS") {
          //       return <li className="pagination-item dots">&#8230;</li>;
          //     }

          //     return (
          //       <button value={pageNumber} onClick={handleSelectPage}>
          //         {pageNumber}
          //       </button>
          //     );
          //   })}
          //   <button disabled={pageNum === lastPage} onClick={handleNext}>
          //     <RightArrow />
          //   </button>
          // </div>
        )}
        <button
          disabled={
            ListLength == 0 ||
            pageNum === Math.ceil(totalListLength / pageSize) - 1
          }
          onClick={() => {
            handleNext ? handleNext() : setPageNum(pageNum + 1);
          }}
          className="flex box-border items-center justify-center gap-4 border rounded-r-md py-2 px-4 triggerGA"
          data-event-category={`${getPageName()}-list-page`}
          data-event-label={`${getPageName()}-list-pagination-next_click`}
          data-event-action="button_click"
          data-event-value="Next"
        >
          <div className="text-sm font-semibold text-gray-800">Next</div>
          <div>
            <img src={RightArrow} alt="" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default PaginationCommon;
