import { useContext, useEffect, useState } from "react";
import NotificationModal from "../../../modals/notification/NotificationModal";
import { updateMessage } from "../../../common/services/message-services";
import UnitValidation from "../../../../../marketing-cloud-main/src/common/components/unit-validation/unit-validation";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import * as Sentry from "@sentry/react";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import { getCampaignCost } from "../../../common/services/common-service";
import { TenantContext } from "../email-parent/email-parent";
import EmailPreview from "../../campaign-review/email-preview";
import {
  SmsLaunchIconAudience,
  SmsLaunchIconChannel,
  SmsLaunchIconLaunch,
  SmsLaunchIconMessage,
  SmsLaunchIconName,
  SmsLaunchIconObjective,
  SmsLaunchIconProfile,
  SmsLaunchIconSchedule,
} from "../../../assets/icons/icons";

const EmailStepThree = (props: any) => {
  useEffect(() => {
    if (props.data) {
      setData(props.data);
      updateCampaignCost();
    }
  }, []);

  const tenantContext: any = useContext(TenantContext);

  const [loading, setLoading] = useState(false);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [unitModalState, setUnitModalState] = useState(false);
  const [campaignCost, setCampaignCost] = useState(null);
  const [data, setData] = useState({
    name: "",
    senderID: "",
    objective: "",
    content: {
      jsonContent: "",
      htmlContent: "",
    },
    landingPagesURL: "",
    schedule: {
      immediate: true,
      timeline: {
        start: new Date().getTime(),
      },
    },
    profiles: 0,
    unitPrice: 0,
    campaignStartDate: "",
    campaignStartTime: "",
    subject: "",
    senderName: "",
  });

  const handleNotificationClose = () => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      show: false,
    }));
  };

  const [notificationData, setNotificationData] = useState({
    show: false,
    close: handleNotificationClose,
    type: "",
    message: "",
    heading: "",
  });

  const handleNotificationData = (data: any) => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      ...data,
    }));
    setTimeout(() => {
      handleNotificationClose();
    }, 4000);
  };

  const saveDraft = () => {
    Notify({
      type: "success",
      heading: "Saved successfully.",
      message: "Your campaign has been saved as draft.",
    });

    props.navigate("/engagements");
  };

  const validateWallet = () => {
    setLoading(true);
    setUnitModalState(true);
  };

  const handleSubmit = () => {
    setUnitModalState(false);

    try {
      setLoading(true);
      updateMessage({ ...props.data, status: "RUNNING" }, props.data.id)
        .then((res) => {
          const { error, response = {} } = res.data;
          if (error === false) {
            if (saveAsDraft) {
              saveDraft();
            } else {
              props.getBalance();
              Notify({
                type: "success",
                heading: "Campaign created successfully",
                message:
                  "Your campaign has been created successfully and will go live at the scheduled time after it has been approved by our content and ad policy team.",
              });
              props.navigate("/engagements");
            }
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }

          setLoading(false);
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.error(err);
          setLoading(false);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.error(err);
      return;
    }
  };

  const updateCampaignCost = async () => {
    setLoading(true);
    let cost = await getCampaignCost(props.data?.budget, 10);
    setCampaignCost(cost);
    setLoading(false);
  };

  return (
    <div className="py-12 px-12">
      <div className="text-gray-500 font-semibold text-sm">STEP 3 OF 3</div>
      <div className="flex justify-between items-center w-full">
        <div className="flex-wrap justify-start items-start">
          <div className="flex rounded-xl py-4">
            <div className="flex gap-6">
              <div className="flex justify-center items-center flex-shrink-0 w-16 bg-gray-100 rounded-full h-16">
                <img src={SmsLaunchIconLaunch} alt="" />
              </div>
              <div className="text-left py-2">
                <div className="text-gray-70 font-semibold">
                  Preview and Launch
                </div>
                <div className="text-gray-500 mt-1 font-normal text-sm whitespace-no-wrap">
                  Preview your message and click “Launch” if everything checks
                  out.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <button
            className="px-5 py-2 rounded-md text-sm leading-6 text-slate-600 font-semibold border border-gray-40 hover:border-gray-200"
            onClick={() => props.setStep(2)}
            disabled={loading}
          >
            Back
          </button>
          <button
            onClick={validateWallet}
            disabled={loading}
            className="px-5 py-2 w-24 rounded-md text-sm leading-6 bg-blue-75 disabled:bg-blue-75 hover:bg-green-75 text-white font-semibold flex justify-center items-center"
          >
            {loading ? <Spinner className={"w-4 h-4"} /> : "Launch"}
          </button>
        </div>
      </div>

      <div className="mt-6 flex items-start gap-10">
        <div className="w-4/6 text-sm">
          <div className="mt-6">
            <div className="mb-6">
              <span className="text-gray-700 font-semibold mr-4">
                Audiences
              </span>
              <span
                onClick={() => props.setStep(1)}
                className="text-green-15 cursor-pointer"
              >
                Edit
              </span>
            </div>
            <div className="flex gap-16 justify-start mt-2.5">
              <div className="flex gap-4 mb-6 w-1/2">
                <img src={SmsLaunchIconAudience} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Audience
                  </span>
                  <span className="mt-1 text-gray-500">
                    {/* {data?.audience} */}Custom audience
                  </span>
                </div>
              </div>
              <div className="flex gap-4 mb-6">
                <img src={SmsLaunchIconProfile} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Profiles
                  </span>
                  <span className="mt-1 inline-block break-words text-gray-500">
                    {props.data?.budget}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-6">
              <span className="text-gray-700 font-semibold mr-4">Message</span>
              <span
                onClick={() => props.setStep(2)}
                className="text-green-15 cursor-pointer"
              >
                Edit
              </span>
            </div>
            <div className="flex gap-16 justify-start">
              <div className="flex gap-4 mb-6 w-1/2">
                <img src={SmsLaunchIconName} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Campaign name
                  </span>
                  <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                    {data.name}
                  </span>
                </div>
              </div>
              <div className="flex gap-4 mb-6">
                <img src={SmsLaunchIconChannel} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Campaign channel
                  </span>
                  <span className="mt-1 inline-block break-words text-gray-500">
                    Email
                  </span>
                </div>
              </div>
            </div>
            <div className="flex gap-16 justify-start mt-2.5">
              <div className="flex gap-4 mb-6 w-1/2">
                <img src={SmsLaunchIconObjective} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Email subject
                  </span>
                  <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                    {data.subject}
                  </span>
                </div>
              </div>
              <div className="flex gap-4 mb-6">
                <img src={SmsLaunchIconSchedule} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">Date</span>
                  <span className="mt-1 inline-block break-words text-gray-500">
                    {data.schedule?.immediate
                      ? new Date().toLocaleDateString("en-GB")
                      : new Date(
                          data.schedule?.timeline.start
                        ).toLocaleDateString("en-GB")}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex gap-16 justify-start mt-2.5">
              <div className="flex gap-4 mb-6 w-1/2">
                <img src={SmsLaunchIconMessage} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Sender email
                  </span>
                  <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                    {data.senderName}
                  </span>
                </div>
              </div>
              <div className="flex gap-4 mb-6">
                <img src={SmsLaunchIconMessage} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Sender name
                  </span>
                  <span className="mt-1 inline-block break-words text-gray-500">
                    {tenantContext.business_name}
                    {/* {data.schedule?.immediate
                      ? "Immediate"
                      : new Date(
                          data.schedule?.timeline.start
                        ).toLocaleTimeString()} */}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <div className="mb-6">
              <span className="text-gray-700 font-semibold mr-4">Pricing</span>
              <span
                onClick={() => props.setStep(1)}
                className="text-green-15 cursor-pointer"
              >
                Edit
              </span>
            </div>
            <div className="flex gap-16 justify-start mt-2.5">
              <div className="flex gap-4 mb-6 w-1/2">
                <img src={SmsLaunchIconProfile} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Unit price per email
                  </span>
                  <span className="mt-1 text-gray-500">
                    {props.currencySymbol}
                    {data?.unitPrice || 0}
                  </span>
                </div>
              </div>
              <div className="flex gap-4 mb-6">
                <img src={SmsLaunchIconProfile} alt="" />
                <div>
                  <span className="block font-semibold text-gray-70">
                    Total pricing
                  </span>
                  <span className="mt-1 inline-block break-words text-gray-500">
                    {props.currencySymbol}
                    {campaignCost ?? 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[40%]">
          <EmailPreview
            data={{
              subject: data.subject,
              businessName: tenantContext.business_name,
              content: data.content,
            }}
          />
        </div>
      </div>

      {unitModalState && (
        <UnitValidation
          productId={8}
          requiredUnits={campaignCost}
          currencySymbol={props.currencySymbol}
          successCallBack={(status) => {
            if (status) {
              handleSubmit();
            } else {
              setLoading(false);
              setUnitModalState(false);
            }
          }}
          type="email"
        />
      )}
      <NotificationModal data={notificationData} />
    </div>
  );
};

export default EmailStepThree;
