import { useEffect, useRef, useState } from "react";
import { Popup } from "semantic-ui-react";
import DOMPurify from "dompurify";
import * as Sentry from "@sentry/react";
import { updateMessage } from "../../../common/services/message-services";
import { getCampaignCost } from "../../../common/services/common-service";
import NotificationModal from "../../../modals/notification/NotificationModal";
import UnitValidation from "../../../../../marketing-cloud-main/src/common/components/unit-validation/unit-validation";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import { formatNumbers } from "../../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";
import {
  Cash,
  InfoCircle,
  LoudSpeaker,
  PhoneSms,
  SmsLaunchIconAudience,
  SmsLaunchIconLaunch,
  SmsLaunchIconMessage,
  SmsLaunchIconObjective,
  SmsLaunchIconProfile,
  SmsLaunchIconSchedule,
} from "../../../assets/icons/icons";

const SmsStepThree = (props: any) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const imgElement = imgRef.current;
    if (imgElement) {
      const handleLoad = () => setLoaded(true);

      if (imgElement.complete) {
        handleLoad();
      } else {
        imgElement.addEventListener("load", handleLoad);
        return () => {
          imgElement.removeEventListener("load", handleLoad);
        };
      }
    }
  }, []);

  useEffect(() => {
    if (props.data) {
      updateCampaignCost();
    }
  }, [props]);

  const [loading, setLoading] = useState(false);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [unitModalState, setUnitModalState] = useState(false);
  const [campaignCostPerCycle, setCampaignCostPerCycle] = useState(null);
  const [campaignTotalCost, setCampaignTotalCost] = useState(null);
  const [data, setData] = useState(props.data ? props.data : null);

  const handleNotificationClose = () => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      show: false,
    }));
  };

  const getOperatorCostBreakdown = () => {
    let colors = ["#00A1E1", "#E53E51", "#1DBA71", "#EE46BC"];
    if (data.operatorCostBreakdown?.length !== 0) {
      return (
        <div>
          {data.operatorCostBreakdown?.map((el, index) => (
            <div key={index} className="flex justify-between">
              <div className="flex gap-1 items-center">
                <div
                  className={`rounded-full h-2 w-2 bg-[${colors[index]}]`}
                ></div>
                {el.operator}
              </div>
              <div>{props.currencySymbol + " " + el.cost}</div>
            </div>
          ))}
        </div>
      );
    } else return null;
  };

  const getOperatorCost = () => {
    let min = Math.min(...data.operatorCostBreakdown.map((el) => el.cost));
    let max = Math.max(...data.operatorCostBreakdown.map((el) => el.cost));

    return min == max ? (
      // <CurrencySymbol value={min} />
      props.currencySymbol + " " + min
    ) : (
      <>
        From{" "}
        <span className="flex font-semibold text-gray-500">
          <div className="flex gap-1">
            {/* <CurrencySymbol value={min} /> - {<CurrencySymbol value={max} />} */}
            {`${props.currencySymbol + " " + min} - ${
              props.currencySymbol + " " + max
            }`}
          </div>
        </span>
      </>
    );
  };

  const checkCostDIfferrence = () => {
    let min = Math.min(...data.operatorCostBreakdown?.map((el) => el.cost));
    let max = Math.max(...data.operatorCostBreakdown?.map((el) => el.cost));

    return min === max;
  };

  const [notificationData, setNotificationData] = useState({
    show: false,
    close: handleNotificationClose,
    type: "",
    message: "",
    heading: "",
  });

  const getTimeline = () => {
    const {
      campaignStartDate,
      campaignStartTime,
      campaignEndDate,
      campaignEndTime,
      schedule,
    } = data;

    const startDate = new Date(`${campaignStartDate}T${campaignStartTime}`);
    const startDateString = startDate.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    const endDate = new Date(`${campaignEndDate}T${campaignEndTime}`);
    const endDateString = endDate.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    let timeline = startDateString;
    if (schedule.isRecurring) {
      timeline += ` - ${endDateString}`;
    }

    return timeline;
  };

  const getCampaignFrequency = () => {
    const { campaignFrequency, campaignInterval, intervalFrequency } = data;
    let frequencySummary;

    switch (campaignFrequency) {
      case "Custom Intervals":
        const interval = parseInt(campaignInterval, 10);
        const frequency =
          interval === 1
            ? intervalFrequency.replace("(s)", "")
            : intervalFrequency.replace("(s)", "s");
        frequencySummary = `Every ${interval} ${frequency}`;
        break;
      default:
        frequencySummary = campaignFrequency;
        break;
    }

    return frequencySummary;
  };

  const getPricingInfo = () => {
    const { campaignFrequency, campaignInterval, intervalFrequency } = data;
    let frequencySummary = campaignFrequency?.toLowerCase();

    if (campaignFrequency === "Custom Intervals") {
      const interval = parseInt(campaignInterval, 10);
      const frequency =
        interval === 1
          ? intervalFrequency.replace("(s)", "")
          : intervalFrequency.replace("(s)", "s");
      frequencySummary = `every ${interval} ${frequency}`;
    }

    let pricingInfo = `This is a frequency based campaign and the messages will be sent out ${data.cronFires} times. Sending this message ${frequencySummary} over the campaign period results in ${data.cronFires} cycles.`;

    return pricingInfo;
  };

  const saveDraft = () => {
    Notify({
      type: "success",
      heading: "Saved successfully.",
      message: "Your campaign has been saved as draft.",
    });

    props.navigate("/engagements");
  };

  const validateWallet = () => {
    setLoading(true);
    setUnitModalState(true);
  };

  const handleSubmit = () => {
    setUnitModalState(false);

    try {
      setLoading(true);
      updateMessage({ ...props.data, status: "RUNNING" }, props.data.id)
        .then((res) => {
          const { error, response = {} } = res.data;
          if (error === false) {
            if (saveAsDraft) {
              saveDraft();
            } else {
              props.getBalance();
              Notify({
                type: "success",
                heading: "Campaign created successfully",
                message:
                  "Your campaign has been created successfully and will go live at the scheduled time after it has been approved by our content and ad policy team.",
              });
              props.navigate("/engagements");
            }
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }

          setLoading(false);
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.error(err);
          setLoading(false);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.error(err);
      return;
    }
  };

  const generatePreviewMessage = () => {
    if (!props.data?.sampleData || props.data?.sampleData?.length === 0) {
      return data.content?.text.replace(/\n/g, "<br>");
    }

    const userdata = props.data?.sampleData[0];

    const previewMessage = data.content?.text.replace(
      /{{(.*?)}}/g,
      (_, key) => {
        return userdata[key] || "";
      }
    );

    return previewMessage.replace(/\n/g, "<br>");
  };

  const sanitizedMessage = DOMPurify.sanitize(generatePreviewMessage());

  const updateCampaignCost = async () => {
    setLoading(true);
    let cost = Number(
      await getCampaignCost(data?.smsPages * props.data?.budget, 8)
    );
    setCampaignCostPerCycle(cost);

    if (data.cronFires > 0) {
      cost *= data.cronFires;
    }
    setCampaignTotalCost(cost);
    setLoading(false);
  };

  return (
    <div>
      {data && (
        <div className="py-12 px-12">
          <div className="text-gray-500 font-semibold text-sm">STEP 3 OF 3</div>
          <div className="flex justify-between items-center w-full">
            <div className="flex-wrap justify-start items-start">
              <div className="flex rounded-xl py-4">
                <div className="flex gap-6">
                  <div className="flex justify-center items-center flex-shrink-0 w-16 bg-gray-100 rounded-full h-16">
                    <img src={SmsLaunchIconLaunch} alt="" />
                  </div>
                  <div className="text-left py-2">
                    <div className="text-gray-70 font-semibold">
                      Preview and Launch
                    </div>
                    <div className="text-gray-500 mt-1 font-normal text-sm whitespace-no-wrap">
                      Preview your message and click “Launch” if everything
                      checks out.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex gap-4">
              <button
                className="px-5 py-2 rounded-md text-sm leading-6 text-slate-600 font-semibold border border-gray-40 hover:border-gray-200"
                onClick={() => props.setStep(2)}
                disabled={loading}
              >
                Back
              </button>
              <button
                onClick={validateWallet}
                disabled={loading}
                className="px-5 py-2 w-24 rounded-md text-sm leading-6 bg-blue-75 disabled:bg-blue-75 hover:bg-green-75 text-white font-semibold flex justify-center items-center"
              >
                {loading ? <Spinner className={"w-4 h-4"} /> : "Launch"}
              </button>
            </div>
          </div>

          <div className="mt-6 flex items-start gap-10">
            <div className="w-4/6 text-sm">
              <div className="mt-6">
                <div className="mb-6">
                  <span className="text-gray-700 font-semibold mr-4">
                    Audiences
                  </span>
                  <span
                    onClick={() => props.setStep(1)}
                    className="text-green-15 cursor-pointer"
                  >
                    Edit
                  </span>
                </div>
                <div className="flex gap-16 justify-start mt-2.5">
                  <div className="flex gap-4 mb-6 w-1/2">
                    <img src={SmsLaunchIconAudience} alt="" />
                    <div>
                      <span className="block font-semibold text-gray-70">
                        Audience
                      </span>
                      <span className="mt-1 text-gray-500">
                        {/* {data?.audience} */}Custom audience
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-4 mb-6">
                    <img src={SmsLaunchIconProfile} alt="" />
                    <div>
                      <span className="block font-semibold text-gray-70">
                        Profiles
                      </span>
                      <span className="mt-1 inline-block break-words text-gray-500">
                        {formatNumbers(props.data?.budget)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <div className="mb-6">
                  <span className="text-gray-700 font-semibold mr-4">
                    Message
                  </span>
                  <span
                    onClick={() => props.setStep(2)}
                    className="text-green-15 cursor-pointer"
                  >
                    Edit
                  </span>
                </div>
                <div className="flex gap-16 justify-start">
                  <div className="flex gap-4 mb-6 w-1/2">
                    <img src={SmsLaunchIconObjective} alt="" />
                    <div>
                      <span className="block font-semibold text-gray-70">
                        Name
                      </span>
                      <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                        {data.name}
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-4 mb-6">
                    <img src={SmsLaunchIconProfile} alt="" />
                    <div>
                      <span className="block font-semibold text-gray-70">
                        Channel
                      </span>
                      <span className="mt-1 inline-block break-words text-gray-500">
                        SMS
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex gap-16 justify-start mt-2.5">
                  <div className="flex gap-4 mb-6 w-1/2">
                    <div className="flex items-start">
                      <img src={SmsLaunchIconMessage} alt="" />
                    </div>
                    <div>
                      <span className="block font-semibold text-gray-70">
                        Message
                      </span>
                      <div
                        className="mt-1 inline-block text-gray-500 break-words max-w-[250px]"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            data.content?.text?.replace(/\n/g, "<br>")
                          ),
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className="flex gap-4 mb-6">
                    <img
                      src={SmsLaunchIconObjective}
                      className="max-h-10"
                      alt=""
                    />
                    <div>
                      <span className="block font-semibold text-gray-70">
                        Objective
                      </span>
                      <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                        {data.objective}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex gap-16 justify-start mt-2.5">
                  <div className="flex gap-4 mb-6 w-1/2">
                    <img src={SmsLaunchIconSchedule} alt="" />
                    <div>
                      <span className="block font-semibold text-gray-70">
                        Timeline
                      </span>
                      <span className="mt-1 inline-block break-words text-gray-500">
                        {getTimeline()}
                      </span>
                    </div>
                  </div>

                  <div className="flex gap-4 mb-6">
                    <div className="flex items-start">
                      <img src={SmsLaunchIconSchedule} alt="" />
                    </div>
                    <div>
                      <span className="block font-semibold text-gray-70">
                        Push time
                      </span>
                      <span className="mt-1 inline-block break-words text-gray-500">
                        {data.schedule?.immediate
                          ? "Immediate"
                          : new Date(
                              data.schedule?.timeline.start
                            ).toLocaleTimeString()}
                      </span>
                    </div>
                  </div>
                </div>

                {data?.schedule?.isRecurring && (
                  <div className="flex gap-16 justify-start mt-2.5">
                    <div className="flex gap-4 mb-6 w-1/2">
                      <img src={SmsLaunchIconSchedule} alt="" />
                      <div>
                        <span className="block font-semibold text-gray-70">
                          Send Frequency
                        </span>
                        <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                          {getCampaignFrequency()}
                        </span>
                      </div>
                    </div>

                    <div className="flex gap-4 mb-6">
                      <img src={SmsLaunchIconSchedule} alt="" />
                      <div>
                        <div className="flex gap-2 font-semibold text-gray-70">
                          Send Cycle
                          <button
                            className="inline-flex items-center text-sm font-semibold text-center text-gray-70 relative group"
                            type="button"
                          >
                            <img src={InfoCircle} alt="" />

                            {/* Send Cycle tooltip */}
                            <div className="absolute hidden group-hover:flex">
                              <div className="w-[320px] p-3 z-10 ml-7 -mt-8 bg-gray-900 text-white text-xs leading-6 rounded-lg shadow-lg">
                                <p>
                                  This is the number of times the message is
                                  sent out over the specified timeline.
                                </p>
                              </div>
                            </div>
                          </button>
                        </div>
                        <span className="mt-1 inline-block text-gray-500 break-words max-w-[250px]">
                          {data.cronFires}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-6">
                <div className="mb-6">
                  <div className="text-gray-700 font-semibold">Pricing</div>
                  {data?.schedule?.isRecurring && (
                    <div className="text-gray-500 mt-1 text-sm">
                      {getPricingInfo()}
                    </div>
                  )}
                </div>
                <div className="flex gap-16 justify-start mt-2.5">
                  <div className="flex gap-4 mb-6 w-1/2">
                    <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                      <img src={Cash} alt="" />
                    </div>
                    <div>
                      <div className="flex gap-1 font-semibold text-gray-70 mb-1">
                        Unit price per SMS
                        {!checkCostDIfferrence() && (
                          <Popup
                            content={getOperatorCostBreakdown()}
                            style={{
                              width: "130px",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "18px",
                            }}
                            trigger={
                              data.operatorCostBreakdown?.length !== 0 && (
                                <div>
                                  <img src={InfoCircle} alt="" />
                                </div>
                              )
                            }
                            on={"hover"}
                            inverted={true}
                          />
                        )}
                      </div>

                      {data.operatorCostBreakdown?.length !== 0 ? (
                        <div className="flex gap-2 text-gray-500">
                          {getOperatorCost()}
                        </div>
                      ) : (
                        <div className="text-gray-500">{data.unitPrice}</div>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-4 mb-6">
                    <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                      <img src={Cash} alt="" />
                    </div>
                    <div>
                      <div className="flex gap-2 font-semibold text-gray-70 whitespace-nowrap">
                        {data?.schedule?.isRecurring
                          ? "Approx. pricing per cycle"
                          : "Total pricing"}

                        {data?.schedule?.isRecurring && (
                          <button
                            className="inline-flex items-center text-sm font-semibold text-center text-gray-70 relative group"
                            type="button"
                          >
                            <img src={InfoCircle} alt="" />

                            {/* Approx pricing per cycle tooltip */}
                            <div className="absolute hidden group-hover:flex">
                              <div className="w-[320px] p-3 z-10 ml-7 -mt-8 bg-gray-900 text-white text-xs leading-6 rounded-lg shadow-lg">
                                <p>
                                  Approximate cost of running this SMS campaign.
                                </p>
                              </div>
                            </div>
                          </button>
                        )}
                      </div>
                      <div className="mt-1 break-words text-gray-500 flex gap-1">
                        <div>{props.currencySymbol}</div>
                        <div>{formatNumbers(campaignCostPerCycle) ?? 0}</div>
                      </div>
                    </div>
                  </div>
                </div>

                {data?.schedule?.isRecurring && (
                  <div className="flex gap-16 justify-start mt-2.5">
                    <div className="flex gap-4 mb-6 w-1/2">
                      <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                        <img src={Cash} alt="" />
                      </div>
                      <div>
                        <div className="flex gap-2 font-semibold text-gray-70">
                          <div className="whitespace-nowrap">{`Approx. pricing for ${data.cronFires} cycles`}</div>
                          <button
                            className="inline-flex items-center text-sm font-semibold text-center text-gray-70 relative group"
                            type="button"
                          >
                            <img src={InfoCircle} alt="" />

                            {/* Approx pricing tooltip */}
                            <div className="absolute hidden group-hover:flex">
                              <div className="w-[320px] p-3 z-10 ml-7 -mt-8 bg-gray-900 text-white text-xs leading-6 rounded-lg shadow-lg">
                                <p>
                                  {`Approximate cost of running this SMS campaign for ${data.cronFires} cycles.`}
                                </p>
                              </div>
                            </div>
                          </button>
                        </div>
                        <div className="mt-1 break-words text-gray-500 flex gap-1">
                          <div>{props.currencySymbol}</div>
                          <div>{formatNumbers(campaignTotalCost) ?? 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="w-[404px] h-[650px]">
              <div className="mt-8 bg-green-708 border-gray-100 h-[542px] relative overflow-hidden rounded-lg">
                <div className="flex justify-center">
                  <div className="h-[56px] w-[56px] rounded-full m-4 mb-0 bg-[#E7F3ED] flex justify-center items-center">
                    <img src={LoudSpeaker} alt="" />
                  </div>
                </div>
                <div className="absolute bg-[#12B76A17] h-[822px] w-[822px] top-[212px] left-[-267px] rounded-full z-0"></div>
                <div className="absolute bg-[#DBF2E9] h-[731px] w-[731px] top-[320px] left-[-328px] rounded-full z-10 opacity-80"></div>
                <div className="absolute z-20 w-full flex justify-center top-[100px]">
                  <img ref={imgRef} src={PhoneSms} alt="" />
                  <div
                    className={`${
                      loaded ? "absolute" : "hidden"
                    } font-medium z-30 p-2 rounded-md bg-[#E5E5EA] top-[118px] left-[88px] w-[208px] overflow-hidden`}
                  >
                    <div
                      className="text-xs text-black break-words"
                      dangerouslySetInnerHTML={{
                        __html: sanitizedMessage,
                      }}
                    ></div>
                    <div className="text-xs text-[#0068D9] break-words">
                      {data.landingPagesURL}
                    </div>
                  </div>
                  <div className="text-xs absolute top-[86px]">
                    {data.senderID}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {unitModalState && (
            <UnitValidation
              productId={8}
              requiredUnits={campaignTotalCost}
              currencySymbol={props.currencySymbol}
              successCallBack={(status) => {
                if (status) {
                  handleSubmit();
                } else {
                  setLoading(false);
                  setUnitModalState(false);
                }
              }}
              type="SMS"
            />
          )}
          <NotificationModal data={notificationData} />
        </div>
      )}
    </div>
  );
};

export default SmsStepThree;
