import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { ConfigProvider, DatePicker, TimePicker } from "antd";
import type { DatePickerProps } from "antd/es/date-picker";
import locale from "antd/es/date-picker/locale/en_GB";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { CUSTOMER_ID } from "../../../common/constants/billingConstants";
import NotificationModal from "../../../modals/notification/NotificationModal";
import {
  postStepOne,
  updateMessage,
  getTopupPlans,
} from "../../../common/services/message-services";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import * as Sentry from "@sentry/react";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import DeletionModal from "../../../common/components/campaign-deletion-modal/campaignDeletionModal";
import { TenantContext } from "../email-parent/email-parent";
import { getEmailId } from "../../../../../marketing-cloud-main/src/pages/settingsComponents/Engagements/emailId/apis/emailIdService";
import ChooseTemplateModal from "./chooseTemplateModal";
import {
  ArrowRight,
  CalendarIcon,
  Chevron,
  Sms,
} from "../../../assets/icons/icons";

const EmailStepTwoInputs = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [showScheduleMenu, setShowScheduleMenu] = useState(false);
  const [showEmailIDMenu, setShowEmailIDMenu] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showObjectiveMenu, setShowObjectiveMenu] = useState(false);
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [scheduleError, setScheduleError] = useState("");
  const [unitPrice, setUnitPrice] = useState(0);
  const [emailExists, setEmailExists] = useState(false);
  const [emailNameError, setEmailNameError] = useState("");
  const [pushTimeError, setPushTimeError] = useState("");
  const [defaultName, setDefaultName] = useState([]);
  const scheduleMenu = useRef(null);
  const emailIDMenu = useRef(null);
  const objectiveMenu = useRef(null);
  const [attributesList, setAttributesList] = useState([]);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [emailIdList, setEmailIdList] = useState([]);
  const [templateName, setTemplateName] = useState("Blank Template");
  const [showTemplateModal, setShowTemplateModal] = useState(false);

  const tenantContext: any = useContext(TenantContext);

  useEffect(() => {
    if (props.emailContent.htmlContent.length > 0) handleSubmit();
  }, [props.emailContent]);

  useEffect(() => {
    if (props.data.content?.default?.length) {
      setDefaultName(props.data.content.default);
      return;
    }

    if (attributesList && attributesList.length) {
      // const params = attributeMatches.map((attr) => attr.column_name);
      // setParams(params);

      const defaultNames = attributesList.map((attr) => {
        return {
          fieldName: attr.column_name,
          defaultName: attr.display_name?.substring(0, 10),
        };
      });
      setDefaultName([...defaultNames]);
    }
  }, [attributesList, props.data]);

  useEffect(() => {
    if (props.data.id) {
      setEmailExists(true);
    }

    if (props.data.campaignStartDate && props.data.campaignStartTime) {
      setStartDate(
        new Date(
          props.data.campaignStartDate + "T" + props.data.campaignStartTime
        )
      );
    }
    getEmailCost();
    getEmailList();
  }, []);

  const getEmailCost = () => {
    try {
      getTopupPlans()
        .then((response) => {
          if (!response.error) {
            let item =
              response[
                response.findIndex((item) => {
                  return item.product_id === 10;
                })
              ];
            if (item) {
              setUnitPrice(item?.billing_scheme?.tiers[0]?.price);
            }
          } else {
            Sentry.captureMessage("Error", response);
          }
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log(error);
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
    }
  };

  dayjs.extend(customParseFormat);
  dayjs.extend(localizedFormat);

  const onStartTimeChange = (time: Dayjs, timeString: string) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "campaignStartTime",
        value: time.format("HH:mm:ss").split(" ")[0],
      },
    });
  };

  const onStartDateChange = (
    value: DatePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "campaignStartDate",
        value: dateString,
      },
    });
  };

  const onStartDateSave = () => {
    if (!formState.campaignStartDate) {
      formState.errors.campaignStartDate = true;
      return;
    }
    if (!formState.campaignStartTime) {
      formState.errors.campaignStartTime = true;
      return;
    }

    // if (
    //   Number(formState.campaignStartTime.replaceAll(":", "")) < 80000 ||
    //   Number(formState.campaignStartTime.replaceAll(":", "")) > 190000
    // ) {
    //   formState.errors.campaignStartTime = true;
    //   setScheduleError("Select an active time (8 AM - 7 PM WAT)");
    //   return;
    // }

    const startDate = new Date(
      formState.campaignStartDate + "T" + formState.campaignStartTime
    );
    if (startDate.getTime() < new Date().getTime()) {
      formState.errors.campaignStartTime = true;
      setScheduleError("Selected time has already passed");
      return;
    } else {
      setScheduleError("");
    }
    setStartDate(startDate);
    setShowCalendar(false);
  };

  const disabledDates = (current) => {
    return current && current < dayjs().endOf("day").subtract(1, "day");
  };

  const copyToClipboard = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setShowCopyTooltip(true);
        setTimeout(() => {
          setShowCopyTooltip(false);
        }, 4000);
      })
      .catch((error) => {
        error.response
          ? Sentry.captureMessage("Error", error.response)
          : Sentry.captureMessage("Error", error);
        console.error("Failed to copy message body:", error);
      });
  };

  const closeOpenMenus = (e) => {
    if (
      emailIDMenu.current &&
      showEmailIDMenu &&
      !emailIDMenu.current.contains(e.target)
    ) {
      setShowEmailIDMenu(false);
    }

    if (
      objectiveMenu.current &&
      showObjectiveMenu &&
      !objectiveMenu.current.contains(e.target)
    ) {
      setShowObjectiveMenu(false);
    }

    if (
      scheduleMenu.current &&
      showScheduleMenu &&
      !scheduleMenu.current.contains(e.target)
    ) {
      setShowScheduleMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);

    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  });

  const handleNotificationClose = () => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      show: false,
    }));
  };

  const [notificationData, setNotificationData] = useState({
    show: false,
    close: handleNotificationClose,
    type: "",
    message: "",
    heading: "",
  });

  const handleNotificationData = (data: any) => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      ...data,
    }));
    setTimeout(() => {
      handleNotificationClose();
    }, 4000);
  };

  const formInitValue = {
    campaignName: props.data.name || "",
    campaignStartDate: props.data.campaignStartDate || "",
    campaignStartTime: props.data.campaignStartTime || "",
    emailID: props.data.senderName || "",
    message: props.data.content || "",
    objective: props.data.objective || "",
    subject: props.data.subject || "",
    schedule: props.data?.schedule
      ? props.data?.schedule?.immediate
        ? "Send now"
        : "Send later"
      : "",
    saveAsDraft: false,

    errors: {
      campaignName: false,
      campaignStartDate: false,
      campaignStartTime: false,
      emailID: false,
      message: false,
      objective: false,
      schedule: false,
    },
  };

  useEffect(() => {
    dispatch({ type: "reinitialize", value: formInitValue });
    if (props.data.id) {
      setEmailExists(true);
    }
  }, [props.data]);

  const reducer = (state, action) => {
    switch (action.type) {
      case "CHANGE":
        return {
          ...state,
          [action.payload.name]: action.payload.value,
          errors: {
            ...state.errors,
            [action.payload.name]: "",
          },
        };
      case "SUBMIT":
        const errors: any = {};
        if (!state.campaignName.trim()) {
          errors.campaignName = true;
        }
        if (!state.schedule) {
          errors.schedule = true;
        }
        if (state.schedule === "Send later" && !startDate) {
          errors.campaignStartDate = true;
        }
        if (!state.emailID) {
          errors.emailID = true;
        }
        if (!state.subject) {
          errors.subject = true;
        }

        return {
          ...state,
          errors: {
            ...errors,
          },
        };

      case "reinitialize":
        return { ...state, ...action.value };
      default:
        return state;
    }
  };
  const [formState, dispatch] = useReducer(reducer, formInitValue);

  const handleFormChange = (e) => {
    let { name, value } = e.target;

    if (name === "campaignName") {
      if (emailNameError) {
        setEmailNameError("");
      }
      value = value.replace(/[^a-z0-9 ]/gi, "");
    }

    if (name === "subject") {
      try {
        e.preventDefault();
      } catch (error) {
        error.response
          ? Sentry.captureMessage("Error", error.response)
          : Sentry.captureMessage("Error", error);
      }
    }

    dispatch({
      type: "CHANGE",
      payload: {
        name,
        value,
      },
    });
  };

  const validateForm = () => {
    const { campaignName, emailID, objective, message, schedule, subject } =
      formState;

    if (!schedule) {
      return false;
    }

    if (schedule === "Send later" && !startDate) {
      return false;
    }

    if (!campaignName || !subject || !emailID) {
      return false;
    }

    return true;
  };

  const getCampaignData = () => {
    const data = {
      id: "",
      name: formState.campaignName,
      channel: "EMAIL",
      senderID: formState.emailID,
      objective: formState.objective,
      content: { default: props.defaultVariables, ...props.emailContent },
      messageType: "PUSH",
      chatChannel: "",
      senderName: formState.emailID,
      subject: formState.subject,
      status: "DRAFT",
      businessId: CUSTOMER_ID,
      budget: props.data?.budget,
      schedule: {
        immediate: formState.schedule === "Send now" ? true : false,
        timeline: {
          start: startDate ? startDate.getTime() : "",
        },
      },
      audience: props.data.audience || {
        segmentId: "",
        phoneNumbers: [],
      },
      unitPrice,
      message: formState.message,
      campaignStartDate: formState.campaignStartDate,
      campaignStartTime: formState.campaignStartTime,
    };

    return data;
  };

  const setCampaignData = (data) => {
    const campaignData = {
      ...props,
      data: {
        ...props.data,
        ...data,
      },
    };

    return campaignData;
  };

  const saveDraft = () => {
    Notify({
      type: "success",
      heading: "Saved successfully.",
      message: "Your campaign has been saved as draft.",
    });
    props.navigate("/engagements");
  };

  const saveCampaign = () => {
    const updatedState = reducer(formState, { type: "SUBMIT" });
    dispatch({ type: "SUBMIT", payload: updatedState });

    if (!validateForm()) {
      return;
    }

    try {
      props.beeEmailBuilder.save();
    } catch (error) {
      Notify({
        type: "error",
        heading: "Please wait.",
        message: "The email builder is getting initialised",
      });
    }
  };

  const handleSubmit = () => {
    const data = getCampaignData();

    const campaignData = setCampaignData(data);

    if (!emailExists) {
      try {
        setLoading(true);
        campaignData.data.timezone =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
        postStepOne(campaignData.data)
          .then((res) => {
            const { error, response = {} } = res;
            if (error === false) {
              campaignData.data = response;
              props.setData(campaignData.data);
              props.setStep(3);
            } else {
              error.response
                ? Sentry.captureMessage("Error", error.response)
                : Sentry.captureMessage("Error", error);
            }

            setLoading(false);
          })
          .catch((err) => {
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            if (
              err?.response?.data?.response ===
              "Message name already exists, please provide a different name"
            ) {
              setEmailNameError(err.response.data.response);
            } else if (
              err?.response?.data?.response ===
              "Push time should be before 19:00 if start date is today."
            ) {
              setPushTimeError(err.response.data.response);
            }
            setLoading(false);
          });
      } catch (err) {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        setLoading(false);
        return;
      }
    } else {
      campaignData.data["id"] = props.data.id;
      try {
        setLoading(true);
        updateMessage(campaignData.data, props.data.id.replaceAll("/", "%2F"))
          .then((res) => {
            setLoading(false);
            if (!res.error) {
              // const campaignData = setCampaignData(data);
              props.setData(campaignData.data);
              props.setStep(3);
            } else {
              Sentry.captureMessage("Error", res);
            }
          })
          .catch((err) => {
            setLoading(false);
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            console.log("error", err);
          });
      } catch (err) {
        setLoading(false);
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        Notify({
          type: "error",
          heading: "Error",
          message:
            err?.response?.data?.response ||
            err?.message ||
            `${err} Please try after some time`,
        });
      }
    }
  };

  const getEmailList = () => {
    try {
      let params = { status: "verified" };
      getEmailId(params)
        .then((res) => {
          const { error, response } = res;
          if (!error) {
            setEmailIdList(response.data);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
    }
  };

  return (
    <div className="py-12 px-12">
      <div className="text-gray-500 font-semibold text-sm">STEP 2 OF 3</div>
      <div className="flex justify-between items-center w-full">
        <div className="flex-wrap justify-start items-start">
          <div className="flex rounded-xl py-4">
            <div className="flex gap-6">
              <div className="flex justify-center items-center flex-shrink-0 w-16 bg-gray-100 rounded-full h-16">
                <img src={Sms} alt="" />
              </div>
              <div className="text-left py-2">
                <div className="text-gray-70 font-semibold text-base">
                  Campaign details
                </div>
                <div className="text-gray-500 mt-1 font-normal text-sm whitespace-no-wrap">
                  Compose your message.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-4 ml-auto">
          <button
            onClick={() => props.setStep(1)}
            className="px-5 py-2 rounded-md text-sm leading-6 text-slate-600 font-semibold border border-gray-40 hover:border-gray-200"
          >
            Back
          </button>
          <button
            onClick={(event) => {
              saveCampaign();
            }}
            disabled={loading}
            className="px-5 py-2 w-24 rounded-md text-sm leading-6 bg-blue-75 disabled:bg-blue-75 hover:bg-green-75 text-white font-semibold flex justify-center items-center"
          >
            {loading ? (
              <Spinner className={"w-4 h-4"} />
            ) : (
              <div className="flex gap-3 items-center">
                {"Next  "} <img src={ArrowRight} alt="" />
              </div>
            )}
          </button>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="mt-4 w-[600px]">
          <div>
            <div className="flex justify-between gap-6">
              <label className="text-gray-70 w-full text-sm mb-1.5">
                <span className="font-semibold">Campaign name</span>
                <input
                  type="text"
                  name="campaignName"
                  placeholder="Enter campaign name"
                  value={formState.campaignName}
                  maxLength={50}
                  className={`block w-full h-12 px-3 py-3 mt-1 border border-gray-200 rounded-md focus:outline-none ${
                    formState.errors.campaignName && "border-red-500"
                  }`}
                  onChange={handleFormChange}
                  disabled={loading}
                />
                {emailNameError && (
                  <div className="text-red-500 text-sm mt-2">
                    {emailNameError}
                  </div>
                )}
              </label>
            </div>

            <div className="mt-3">
              <div className="py-3">
                <div className="flex justify-between gap-6">
                  <div className="w-full">
                    <div className="text-gray-70 font-semibold text-sm">
                      Email subject
                    </div>
                    <input
                      type="text"
                      name="subject"
                      placeholder="Enter Subject"
                      value={formState.subject}
                      className={`h-12 px-3 py-3 text-sm w-full mt-1 border border-gray-200 rounded-md focus:outline-none ${
                        formState.errors.subject && "border-red-500"
                      }`}
                      onChange={handleFormChange}
                      maxLength={500}
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="mt-3">
              <div className="py-3">
                <div className="flex justify-between gap-6">
                  <div className="w-full">
                    <div className="text-gray-70 font-semibold text-sm">
                      Sender email
                    </div>
                    <input
                      type="text"
                      name="subject"
                      value={tenantContext.email}
                      className={`h-12 px-3 py-3 text-sm w-full mt-1 border border-gray-200 rounded-md focus:outline-none`}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="text-gray-70  text-sm my-3">
              <span className="font-semibold">Sender email</span>
              <div className="relative w-full">
                <button
                  name="emailID"
                  className={`flex w-full justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-200 rounded-md focus:outline-none ${
                    formState.emailID === "" && "text-gray-400"
                  } ${formState.errors.emailID && "border-red-500"}`}
                  type="button"
                  onClick={() => setShowEmailIDMenu(!showEmailIDMenu)}
                  disabled={loading}
                >
                  {emailIdList.length
                    ? formState.emailID || "Select your email ID"
                    : "No enabled email ID available!"}
                  <img src={Chevron} alt="" />
                </button>

                {showEmailIDMenu && (
                  <div
                    ref={emailIDMenu}
                    className="absolute z-10 bg-white rounded-lg shadow w-full"
                  >
                    {emailIdList?.map((emailID, index) => (
                      <button
                        key={index}
                        className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                        role="menuitem"
                        onClick={() => {
                          dispatch({
                            type: "CHANGE",
                            payload: {
                              name: "emailID",
                              value: emailID?.email,
                            },
                          });
                          setShowEmailIDMenu(false);
                        }}
                      >
                        {emailID?.email}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div className="text-gray-70 font-semibold text-sm">
          Schedule
          {startDate && (
            <span className="font-light ml-1.5">
              (Send on {dayjs(startDate).format("LLLL")})
            </span>
          )}
        </div>
        <div className="flex gap-6">
          <div className="relative">
            <button
              name="schedule"
              className={`flex w-[292px] justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-200 rounded-md focus:outline-none ${
                formState.schedule === "" && "text-gray-400"
              } ${formState.errors.schedule && "border-red-500"}`}
              type="button"
              onClick={() => setShowScheduleMenu(!showScheduleMenu)}
              disabled={loading}
            >
              {formState.schedule || "Select schedule"}
              <img src={Chevron} alt="" />
            </button>

            {/* Schedule Dropdown menu */}
            {showScheduleMenu && (
              <div
                ref={scheduleMenu}
                className="absolute z-10 bg-white rounded-lg shadow w-[292px]"
              >
                {["Send now", "Send later"]?.map((schedule, index) => (
                  <button
                    key={index}
                    className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                    role="menuitem"
                    onClick={() => {
                      dispatch({
                        type: "CHANGE",
                        payload: {
                          name: "schedule",
                          value: schedule,
                        },
                      });
                      setPushTimeError("");
                      setShowScheduleMenu(false);
                    }}
                  >
                    {schedule}
                  </button>
                ))}
              </div>
            )}
            {formState.schedule === "Send now" && !pushTimeError && (
              <div className="mt-2 text-sm">
                Message is sent immediately after review
              </div>
            )}
            {pushTimeError && (
              <div className="text-red-500 text-sm mt-2">{pushTimeError}</div>
            )}
          </div>

          {formState.schedule === "Send later" && (
            <ConfigProvider
              theme={{
                token: {
                  colorIcon: "#98A2B3",
                  colorBorder: "#D0D5DD",
                  colorPrimary: "#008138",
                  colorPrimaryHover: "#008138",
                  colorText: "#344054",
                  colorTextHeading: "#344054",
                  colorTextDisabled: "#D0D5DD",
                  colorTextLightSolid: "var(--main-color)",
                  colorTextPlaceholder: "#98A2B3",
                  controlItemBgActive: "#DAEEE1",
                  controlOutline: "#FFFFFF",
                },
              }}
            >
              <div className="mt-1">
                <div
                  onClick={() => {
                    if (!showCalendar) {
                      setShowCalendar(true);
                    }
                  }}
                >
                  <DatePicker
                    className={`w-[292px] h-12 px-4 hover:border-gray-300 ${
                      (formState.errors.campaignStartDate ||
                        formState.errors.campaignStartTime) &&
                      "border-red-500"
                    }`}
                    placeholder="Enter date"
                    disabled={loading}
                    value={
                      formState.campaignStartDate
                        ? dayjs(formState.campaignStartDate, "YYYY-MM-DD")
                        : undefined
                    }
                    defaultValue={
                      formState.campaignStartDate
                        ? dayjs(formState.campaignStartDate, "YYYY-MM-DD")
                        : undefined
                    }
                    format="YYYY-MM-DD"
                    onChange={onStartDateChange}
                    disabledDate={disabledDates}
                    locale={locale}
                    open={showCalendar}
                    showNow={false}
                    showToday={false}
                    suffixIcon={<img src={CalendarIcon} />}
                    allowClear={false}
                    popupStyle={{ paddingBottom: "64px" }}
                    cellRender={(current) => {
                      const style: React.CSSProperties = {};
                      style.borderRadius = "50%";
                      style.width = "30px";
                      style.height = "30px";
                      style.lineHeight = "30px";
                      return (
                        <div className="ant-picker-cell-inner" style={style}>
                          {current.date()}
                        </div>
                      );
                    }}
                    renderExtraFooter={() => {
                      return (
                        <>
                          <div className="flex justify-between items-end px-5">
                            <input
                              type="text"
                              placeholder="Pick a start date"
                              value={
                                formState.campaignStartDate &&
                                dayjs(formState.campaignStartDate).format(
                                  "MMM D, YYYY"
                                )
                              }
                              className={`w-36 h-12 px-2 py-3 mt-3 text-[13.5px] border border-gray-300 rounded-md focus:outline-none ${
                                formState.errors.campaignStartDate &&
                                "border-red-500"
                              }`}
                              onChange={() => {}}
                            />
                            <TimePicker
                              className={`h-12 ${
                                formState.errors.campaignStartTime &&
                                "border-red-500"
                              }`}
                              defaultValue={
                                formState.campaignStartTime
                                  ? dayjs(
                                      formState.campaignStartTime,
                                      "HH:mm:ss"
                                    )
                                  : undefined
                              }
                              format="h:mm A"
                              onChange={onStartTimeChange}
                              use12Hours
                              size="small"
                              showNow={false}
                              placement="bottomRight"
                              inputReadOnly={true}
                            />
                          </div>
                          <div className="pt-4 mt-80 bg-white rounded-b-lg drop-shadow-lg">
                            <hr className="border-gray-300" />
                            <div className="flex gap-4 p-4 rounded-b-lg">
                              <button
                                className="py-3 px-10 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
                                onClick={() => setShowCalendar(false)}
                              >
                                Cancel
                              </button>

                              <button
                                className="py-3 px-14 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75"
                                onClick={() => {
                                  onStartDateSave();
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </>
                      );
                    }}
                  />
                </div>
                {/* {!startDate && !scheduleError && (
                  <div className="mt-2 text-sm">
                    Select a time between 8 AM - 7 PM WAT
                  </div>
                )} */}
                {scheduleError && (
                  <div className="text-red-500 text-sm mt-2">
                    {scheduleError}
                  </div>
                )}
              </div>
            </ConfigProvider>
          )}
        </div>
      </div>
      <div className="w-[600px] mt-6">
        <label>
          <div className="text-sm text-gray-70 font-semibold mb-2">
            Email body
          </div>
          <div className="flex border border-gray-200 text-sm rounded">
            <div className="grow leading-6 p-3">{templateName}</div>
            <div
              onClick={() => {
                setShowTemplateModal(true);
              }}
              className="border-l p-3 border-gray-200 leading-6 font-semibold text-green-15 cursor-pointer"
            >
              Choose template
            </div>
          </div>
        </label>
      </div>

      <NotificationModal data={notificationData} />
      {showDeletionModal && (
        <DeletionModal
          step={2}
          navigate={props.navigate}
          closeModal={setShowDeletionModal}
        />
      )}

      {showTemplateModal && (
        <ChooseTemplateModal
          // selectTemplate={(emailContent) =>
          //   props.setData({ ...props.data, content: emailContent })
          // }
          selectTemplate={(emailContent) =>
            props.setSavedTemplate(emailContent)
          }
          setTemplateName={setTemplateName}
          closeModal={() => {
            setShowTemplateModal(false);
          }}
        />
      )}
    </div>
  );
};

export default EmailStepTwoInputs;
