import { useEffect, useState } from "react";
import ChannelModal from "../../modals/channel-selection-modal/channel";
import CampaignList from "../message-insights/campaign-list/campaign-list";
import { getMessageList } from "../../common/services/message-services";
import * as Sentry from "@sentry/react";
import { pageLoadEvent } from "../../../../marketing-cloud-container/src/ga-events";
import { MESSAGE } from "../../../../marketing-cloud-main/src/common/constants/AccessControlMessage";
import { MarketingMix } from "../../assets/icons/icons";

const EngagementsMain = ({
  navigate,
  country,
  currency,
  hideFilters,
  dateRange,
  defaultChannel,
  dispatch,
  setShowCommonLoader,
  hasSuperAdminAccess,
  tenantContext,
}) => {
  const [showModal, setShowChannelModal] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [totalListLength, setTotalListLength] = useState(0);
  const [filter, setFilter] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [channel, setChannel] = useState(defaultChannel || "");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [messageText, setMessageText] = useState("");

  useEffect(() => {
    setFrom(Number(size * pageNumber));
    const params = {
      startDate,
      endDate,
      dateRange,
      from,
      size,
      channel,
      status,
      messageText,
    };

    fetchMessageList(params);
    pageLoadEvent("engagements-messaging_page");
  }, [
    startDate,
    endDate,
    dateRange,
    size,
    from,
    channel,
    status,
    pageNumber,
    messageText,
  ]);

  const fetchMessageList = (params = {}) => {
    dispatch(setShowCommonLoader(true));
    try {
      getMessageList(params)
        .then((res) => {
          const { error, response } = res;
          if (!error) {
            setMessageList(response);
            setTotalListLength(res.total);
            dispatch(setShowCommonLoader(false));
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          dispatch(setShowCommonLoader(false));
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      dispatch(setShowCommonLoader(false));
    }
  };

  const navigateToCreate = (channel: string) => {
    navigate(`/engagements/${channel}`);
  };

  return (
    <>
      {!hideFilters &&
      !filter &&
      pageNumber === 0 &&
      messageList?.length === 0 ? (
        <div className="mt-48 px-16">
          <div className="grid grid-cols-2 gap-6 justify-between grid-flow-row grid-">
            <div className="py-12 px-20 leading-10">
              <span className="w-[411px] text-gray-800 font-semibold text-[36px] m-0">
                Create your first message
              </span>
              <p className=" max-w-[300px] text-gray-800 font-normal text-sm py-4">
                Create targeted and personalised messages to communicate with
                your customers.
              </p>
              <div className="flex flex-row gap-4">
                <div className="group">
                  <button
                    onClick={() => setShowChannelModal(true)}
                    className="py-3 px-6 text-white text-sm font-semibold border rounded-lg bg-blue-75 relative"
                    disabled={
                      !tenantContext?.productList["create_message_policy"]
                    }
                  >
                    Create message
                    {!tenantContext?.productList["create_message_policy"] && (
                      <div className="absolute hidden group-hover:flex bg-black p-3 text-xs rounded-lg w-[300px] right-[-306px] top-[-6px] z-20">
                        {MESSAGE}
                      </div>
                    )}
                  </button>
                </div>
                <button className="bg-white border border-gray-50 hover:border-gray-500 py-3 px-6 font-semibold text-sm rounded-lg text-gray-90">
                  Learn more
                </button>
              </div>
            </div>
            <img src={MarketingMix} alt="" />
          </div>
          <div>
            <div>
              {showModal ? (
                <ChannelModal
                  handleNavigate={navigateToCreate}
                  handleClose={() => {
                    setShowChannelModal(false);
                  }}
                  tenantContext={tenantContext}
                  navigate={navigate}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <CampaignList
            country={country}
            currency={currency}
            messageList={messageList}
            totalListLength={totalListLength}
            navigate={navigate}
            setFilter={setFilter}
            filter={filter}
            setSize={setSize}
            size={size}
            setChannel={setChannel}
            channel={channel}
            setStatus={setStatus}
            status={status}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            setFrom={setFrom}
            messageText={messageText}
            setMessageText={setMessageText}
            hideFilters={hideFilters}
            hasSuperAdminAccess={hasSuperAdminAccess}
            tenantContext={tenantContext}
          />
        </div>
      )}
    </>
  );
};

export default EngagementsMain;
