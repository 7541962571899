import { useEffect, useState } from "react";

const TextProperty = (props: any) => {
  const [question, setQuestion] = useState(props.question);

  useEffect(() => {
    let q = props.question;
    setQuestion(q);
  });

  const minCharOnChange = (event) => {
    if (event.target.value < 30) return;
    let q = question;
    q.property
      ? (q.property.minValue = event.target.value)
      : (q.property = { minValue: event.target.value });
    props.setRating(question);
  };

  const maxCharOnChange = (event) => {
    if (event.target.value > 600) return;
    let q = question;
    q.property
      ? (q.property.maxValue = event.target.value)
      : (q.property = { maxValue: event.target.value });
    props.setRating(question);
  };

  const updateCount = (check, type) => {
    let q = question;
    if (type === "min") {
      let value = q.property?.minValue ? q.property.minValue : 30;
      check ? value++ : value--;
      value >= 30
        ? q.property
          ? (q.property.minValue = value)
          : (q.property = { minValue: value })
        : null;
      props.setRating(question);
    } else if (type === "max") {
      let value = q.property?.maxValue ? q.property.maxValue : 600;
      check ? value++ : value--;
      value <= 600
        ? q.property
          ? (q.property.maxValue = value)
          : (q.property = { maxValue: value })
        : null;
      props.setRating(question);
    }
  };

  return (
    <div className="mt-6">
      <div>
        <div className="text-sm font-semibold">Min characters</div>
        <div className="mt-1 border rounded flex h-[32px]">
          <div
            onClick={() => updateCount(false, "min")}
            className="flex-1 border-r flex items-center justify-center cursor-pointer"
          >
            -
          </div>
          <div className="flex-1 h-full">
            <div className="relative">
              <input
                type="number"
                value={
                  question.property?.minValue ? question.property.minValue : 30
                }
                onChange={(event) => minCharOnChange(event)}
                className="border-0 outline-0 h-full text-center"
              />
            </div>
          </div>
          <div
            onClick={() => updateCount(true, "min")}
            className="flex-1 border-l flex items-center justify-center cursor-pointer"
          >
            +
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="text-sm font-semibold">Max characters</div>
        <div className="mt-1 border rounded flex h-[32px]">
          <div
            onClick={() => updateCount(false, "max")}
            className="flex-1 border-r flex items-center justify-center cursor-pointer"
          >
            -
          </div>
          <div className="flex-1 h-full">
            <input
              type="number"
              value={
                question.property?.maxValue ? question.property.maxValue : 600
              }
              onChange={(event) => maxCharOnChange(event)}
              className="border-0 outline-0 h-full text-center"
            />
          </div>
          <div
            onClick={() => updateCount(true, "max")}
            className="flex-1 border-l flex items-center justify-center cursor-pointer"
          >
            +
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextProperty;
