import {
  api,
  audienceApi,
} from "../../../../../marketing-cloud-main/src/common/services/common-api-service";
import * as Sentry from "@sentry/react";

export const getSegments = (props) => {
  const loginAccessToken = localStorage.getItem("loginAccessToken");
  let { from } = props;
  const url = `/v1/segment`;

  return audienceApi
    .post(
      url,
      {
        query: props.nameFilter
          ? [{ file_name: { $like: `%${props.nameFilter}%` } }]
          : null,
        from: from,
        size: 15,
      },
      {
        headers: { Authorization: `bearer ${loginAccessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    });
};

export const getattributes = (source, segmentId?) => {
  const loginAccessToken = localStorage.getItem("loginAccessToken");
  const url = segmentId
    ? `/v1/upload/file/headers/${segmentId}`
    : `/v1/segment/metadata/${source}`;

  return audienceApi
    .get(url, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    });
};

export const getPreviewCount = (payload) => {
  const loginAccessToken = localStorage.getItem("loginAccessToken");
  const url = `/v1/segment/preview`;

  return audienceApi
    .post(url, payload, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    });
};

export const getFieldValues = (field, dataSource, searchParams, segment) => {
  const loginAccessToken = localStorage.getItem("loginAccessToken");
  const url = `/v1/segment/field-value/${field}/${dataSource}${
    segment ? `/${segment}` : ""
  }${searchParams ? "?" + searchParams : ""}`;

  return audienceApi
    .get(url, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    });
};

export const getInterests = () => {
  const loginAccessToken = localStorage.getItem("loginAccessToken");
  const url = `/v1/audience/interest/extract`;

  return audienceApi
    .get(url, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    });
};

export const postAudience = (payload) => {
  const loginAccessToken = localStorage.getItem("loginAccessToken");
  payload["source_type"] = "customer";
  const url = `/v1/audience`;

  return audienceApi
    .post(url, payload, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    });
};

export const getDataSources = () => {
  const loginAccessToken = localStorage.getItem("loginAccessToken");
  const url = `/data-sources`;
  return api
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
    });
};
