const audienceIconUrl =
  'https://mcassets.blob.core.windows.net/cdn/AudienceAssets';

export const Action = `${audienceIconUrl}/Action.svg`;
export const ArrowRight = `${audienceIconUrl}/ArrowRight.svg`;
export const AttributesArrow = `${audienceIconUrl}/AttributesArrow.svg`;
export const ChevronRight = `${audienceIconUrl}/ChevronRight.svg`;
export const ChevronDown = `${audienceIconUrl}/ChevronDown.svg`;
export const Close = `${audienceIconUrl}/Close.svg`;
export const CloseSmall = `${audienceIconUrl}/CloseSmall.svg`;
export const DataUpload = `${audienceIconUrl}/DataUpload.svg`;
export const Duplicate = `${audienceIconUrl}/Duplicate.svg`;
export const Edit = `${audienceIconUrl}/Edit.svg`;
export const EngageSegments = `${audienceIconUrl}/EngagementSegments.svg`;
export const InsightOnDemand = `${audienceIconUrl}/InsightOnDemand.svg`;
export const LeftArrow = `${audienceIconUrl}/LeftArrow.svg`;
export const RightArrow = `${audienceIconUrl}/RightArrow.svg`;
export const SegmentLabel = `${audienceIconUrl}/SegmentLabel.svg`;
export const Segments = `${audienceIconUrl}/Segments.svg`;
export const SegmentsIcon = `${audienceIconUrl}/SegmentsIcon.svg`;
export const Tick = `${audienceIconUrl}/Tick.svg`;
export const TrashCan = `${audienceIconUrl}/TrashCan.svg`;
