import { useEffect, useState } from "react";
import { getAccountStatus, getWalletTransactions } from "../../apis/billing";
import InsufficientWalletModal from "../../../modals/insufficientFund";
import { topupModalStates } from "../../../pages/billing/constants/fundModalTypes";
import * as Sentry from "@sentry/react";
import FundWalletModal from "../../../modals/fund-wallet-modal";
import { Provider } from "react-redux";
import loaderStore from "../../../redux-loader/loader-store/loaderStore";

const UnitValidation = ({
  productId,
  requiredUnits,
  currencySymbol,
  successCallBack,
  type,
}) => {
  const [fundModalStatus, setFundModalStatus] = useState(false);
  const [unitsToBuy, setUnitstoBuy] = useState(0);
  const [eventType, setEventType] = useState("");
  const [paymentModalStatus, setPaymentModalStatus] = useState(false);
  const [accountStatus, setAccountStatus] = useState([]);
  const [planTiers, setPlanTiers] = useState([]);
  const [topupModalState, setTopupModalState] = useState(
    topupModalStates.FUND_MODAL
  );

  useEffect(() => {
    try {
      getAccountStatus()
        .then((statusResponse: any) => {
          let plan = JSON.parse(statusResponse.subscriptions[0])[0];
          let tierId = plan.tier_id;
          if (tierId !== 0) {
            successCallBack(true);
          } else {
            getWalletTransactions()
              .then((response) => {
                if (response?.data[0].balance < requiredUnits) {
                  const amountReuquired =
                    requiredUnits - response?.data[0].balance;
                  const amountRequiredWithVAT = parseFloat(
                    (amountReuquired + (7.5 / 100) * amountReuquired).toFixed(2)
                  );

                  setUnitstoBuy(Math.ceil(amountRequiredWithVAT));
                  setFundModalStatus(true);
                } else {
                  successCallBack(true);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  }, []);

  const onPurchase = () => {
    setFundModalStatus(false);
    setPaymentModalStatus(true);
  };

  const onDecline = () => {
    setFundModalStatus(false);
    successCallBack(false);
  };

  const unitsToBuyOnChange = (event) => {
    setUnitstoBuy(event.target.value);
  };

  return (
    <Provider store={loaderStore}>
      <div>
        {fundModalStatus && (
          <InsufficientWalletModal
            unitsReuquired={unitsToBuy}
            currencySymbol={currencySymbol}
            onPurchase={onPurchase}
            onDecline={onDecline}
            type={type}
          />
        )}
        {paymentModalStatus && (
          <FundWalletModal
            topupModalState={topupModalState}
            setTopupModalState={(modal) => {
              console.log(modal);
              if (modal === "NEW_USER_MODAL") {
                setPaymentModalStatus(false);
                setFundModalStatus(false);
                successCallBack(false);
              } else {
                setTopupModalState(topupModalStates.FUND_MODAL);
              }
            }}
            handleClose={() => {
              successCallBack(false);
            }}
            amount={unitsToBuy}
            amountValidation={false}
          />
        )}
      </div>
    </Provider>
  );
};

export default UnitValidation;
