import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Add, ArrowRight } from "../../../assets/icons/icons";
import { Ellipsis } from "../../../assets/icons/icons";
import { RightArrow } from "../../../assets/icons/icons";
import { Play } from "../../../assets/icons/icons";
import { ChevronBack } from "../../../assets/icons/icons";
import { Delete } from "../../../assets/icons/icons";
import { Plus } from "../../../assets/icons/icons";
import { updateSurvey } from "../../../common/services/survey-services";
import { QuestionFile } from "../../../assets/icons/icons";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import { getCustomerId } from "../../../common/constants/billingConstants";
import * as Sentry from "@sentry/react";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import UssdSurveyPreview from "./ussd-survey-preview";

const SurveyStepTwoUssd = (props: any) => {
  useEffect(() => {
    if (props?.data?.content?.questions) {
      let quests = [];
      props.data.content.questions.forEach((e) => {
        let q: any = {};
        (q.q = e.text), (q.index = e.index);
        let c = [];
        e?.options?.forEach((element) => {
          c.push(element.optionText);
        });
        q.choice = c;
        quests.push(q);
      });
      setQuestions(quests);
    }
    if (props?.data?.content?.welcomeMessage)
      setIntroducion(props.data.content.welcomeMessage.questionText);
    if (props?.data?.content?.endMessage)
      setEndMessage(props.data.content.endMessage.questionText);
  }, []);

  const [questions, setQuestions] = useState([]);
  const [introduction, setIntroducion] = useState("");
  const [endMessage, setEndMessage] = useState("");
  const [type, setType] = useState("introduction");
  const [popupStatus, setPopupStatus] = useState(false);
  const [questionType, setQuestionType] = useState("text");
  //const [question, setQuestion] = useState({ q: "", index: 0, choice: [] });
  //const [questionInput, setQuestionInput] = useState("")
  const [choice, setChoice] = useState(["", ""]);
  const [previewToggle, setPreviewToggle] = useState(false);
  // const [previewSelection, setPreviewSelection] = useState({ type: '', value: { q: '', index: '', choice: [] } });
  const [previewSelection, setPreviewSelection] = useState(0);
  const [questionLimit, setQuestionLimit] = useState(0);
  const [gIndex, setGIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const onEndMessageChange = (event) => {
    if (event.target.value.length > 120) return;
    setEndMessage(event.target.value);
  };

  const onIntroductoryMessageChange = (event) => {
    if (event.target.value.length > 120) return;
    setIntroducion(event.target.value);
  };

  const selectType = (type) => {
    setType(type);
  };

  const addQuestion = () => {
    //setQuestionInput("");
    setQuestionType("text");
    selectType("edit");
    let q = [...questions];
    let index = q.length + 1;
    setGIndex(q.length);
    let t = {
      q: "",
      index: index,
      choice: [],
    };
    //setQuestionInput("");
    //setQuestion(t);
    q.push(t);
    setQuestions(q);
  };

  const editMessage = (index) => {
    setType("edit");
  };

  const duplicateMessage = (index) => {
    setPopupStatus(true);
    let qList = [...questions];
    let q = qList[index];
    qList.push(q);
    setQuestions(qList);
    nextStep(0);
  };

  const deleteMessage = (index) => {
    let qList = [...questions];
    qList.splice(index, 1);
    setQuestions(qList);
    nextStep(0);
  };

  const messageTypeOnChange = (event) => {
    let q = [...questions];
    if (event.target.value === "text") q[gIndex].choice = [];
    else q[gIndex].choice = ["", ""];
    setQuestions(q);
    setQuestionType(event.target.value);
  };

  const addChoice = () => {
    let q = [...questions];
    q[gIndex].choice.push("");
    setQuestions(q);
  };

  const deleteChoice = (i) => {
    let q = [...questions];
    q[gIndex].choice.splice(i, 1);
    setQuestions(q);
  };

  const gotoToList = () => {
    if (questions[gIndex].q === "") {
      deleteMessage(gIndex);
    }
    // else {
    //     if (questionType === 'option') {
    //         let cq = [...questions];
    //         cq[question.index - 1].q = questionInput;
    //         cq[question.index - 1].choice = choice;
    //         setQuestions(cq);
    //     }
    //     let q = [...questions];
    //     q[question.index - 1].q = questionInput;
    //     setQuestions(q);
    // }

    let final = [...questions];
    if (final.length === 1 && !final[0].q) {
      selectType("introduction");
    } else {
      setType("message");
    }
    nextStep(0);
  };

  const onQuestionChange = (event) => {
    if (
      (questions[gIndex].q.length >= 160 ||
        questions[gIndex].q.length -
          questions[gIndex].q.length +
          event.target.value.length >
          160) &&
      event.target.value.length > questions[gIndex].q.length
    )
      return;
    let q = [...questions];
    q[gIndex].q = event.target.value;
    setQuestions(q);
    let choiceLength = 0;
    q[gIndex].choice.forEach((ele) => {
      choiceLength += ele.length;
    });
    setQuestionLimit(event.target.value.length + choiceLength);
  };

  const onChoiceChange = (event, i) => {
    if (
      (questions[gIndex].q.length >= 160 ||
        questions[gIndex].q.length -
          questions[gIndex].choice[i].length +
          event.target.value.length >
          160) &&
      event.target.value.length > questions[gIndex].choice[i].length
    )
      return;
    let q = [...questions];
    q[gIndex].choice[i] = event.target.value;
    let choiceLength = 0;
    q[gIndex].choice.forEach((ele) => {
      choiceLength += ele.length;
    });
    setQuestionLimit(questions[gIndex].q.length + choiceLength);
    setQuestions(q);
  };

  // const updateQuestionLimit = () => {
  //     let questionLength = questionInput.length;
  //     let choiceLength = 0;
  //     choice.forEach((ele) => {
  //         choiceLength += ele.length;
  //     })
  //     setQuestionLimit(questionLength + choiceLength);
  // }

  const selectQuestion = (q) => {
    // setQuestion(q);
    // setQuestionInput(q.q);
    setGIndex(q.index - 1);
    setQuestionType("text");
    if (q.choice.length > 0) {
      setChoice(q.choice);
      setQuestionType("option");
    }
    setType("message");
  };

  // const toggleModal = (status) => {
  //   if (status) {
  //     let preview = [];
  //     preview[0] = {
  //       type: "Introduction",
  //       value: introduction,
  //     };
  //     questions.map((q) => {
  //       preview.push({
  //         type: "Question",
  //         value: q,
  //       });
  //     });
  //     preview.push({
  //       type: "End Message",
  //       value: endMessage,
  //     });
  //     setPreviewObject(preview);
  //     setPreviewSelection(0);
  //   }
  //   setPreviewToggle(status);
  // };

  const nextStep = (goNext) => {
    setLoading(true);
    if (goNext)
      if (isQuestionsValid()) {
        setLoading(false);
        return;
      }
    let surveyData = props;
    // surveyData.data.welcomeMessage = {
    //   questionText: introduction,
    // };
    // surveyData.data.endMessage = {
    //   questionText: endMessage,
    // };
    let qList = [];
    questions.forEach((ele) => {
      let cList = [];
      if (ele.choice.length > 0) {
        ele.choice.forEach((e, i) => {
          let c = {
            optionId: i + 1,
            optionText: e,
          };
          cList.push(c);
        });
      }
      let q = {
        text: ele.q,
        type: ele.choice.length > 0 ? "options" : "text",
        index: ele.index,
        property: { options: cList },
      };
      qList.push(q);
    });
    let content = {
      welcomeMessage: {
        questionText: introduction,
      },
      endMessage: {
        questionText: endMessage,
      },
      questions: [
        {
          pageNumber: 1,
          elements: qList,
        },
      ],
    };
    //surveyData.data.ussdQuestionsDetails = qList;
    surveyData.data.content = content;
    surveyData.data.businessId = getCustomerId();
    let id =
      surveyData.data.surveyId?.replaceAll("/", "%2F") ||
      props.data.id?.replaceAll("/", "%2F");
    try {
      updateSurvey(surveyData.data, id)
        .then((response) => {
          setLoading(false);
          props.setData(surveyData.data);
          if (goNext === 4) props.setStep(4);
          else if (goNext === 1)
            Notify({
              type: "success",
              heading: "Success",
              message: "Survey saved successfully",
            });
          else if (goNext === 0) return;
        })
        .catch((err) => {
          setLoading(false);
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (error) {
      setLoading(false);
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
    }
  };

  const addNewQuestionFromView = () => {
    if (questions[gIndex].q != "") {
      addQuestion();
    }
    nextStep(0);
  };

  // const previewPrevious = () => {
  //   if (previewSelection != 0) {
  //     let p = previewSelection;
  //     p--;
  //     setPreviewSelection(p);
  //   }
  // };

  // const previewNext = () => {
  //   if (previewSelection != prieviewObject.length - 1) {
  //     let p = previewSelection;
  //     p++;
  //     setPreviewSelection(p);
  //   }
  // };

  const goBack = (step) => {
    props.setStep(step);
  };

  const onIntroductoryMessagePaste = (event) => {
    if (event.target.value.length > 120) {
      event.preventDefault();
      return;
    }
  };

  // const onMessagePaste = (event) => {
  //     if (event.target.value.length > 160) {
  //         event.preventDefault();
  //         return;
  //     }
  // }

  const isQuestionsValid = () => {
    if (introduction.length === 0) {
      Notify({
        type: "error",
        heading: "Failed",
        message: "Introduction Message is empty",
      });
      return true;
    }

    if (questions.length === 0) {
      Notify({
        type: "error",
        heading: "Failed",
        message: "Questions are empty",
      });
      return true;
    }
    // if ((questions.length > 0)) {
    //     await questions.forEach(async (element, i) => {
    //         if (element.choice.length > 0) {
    //             await element.choice.forEach((e) => {
    //                 if (e.length === 0) {
    //                     return true;
    //                 }
    //             })
    //         }
    //         return false
    //     })
    // }
  };

  return (
    <div>
      <div className="p-10 pb-0">
        <div className="text-[#667085] text-sm font-semibold mb-2">
          STEP 3 OF 4
        </div>
        <div className="flex items-center justify-between">
          <div className="flex gap-6 items-center">
            <div className="bg-[#F1FAF5] h-[61px] w-[61px] rounded-[50%] flex items-center justify-center">
              <img src={QuestionFile} alt="File" />
            </div>
            <div>
              <div className="text-[#475467] font-semibold text-base">
                Survey questions
              </div>
              <div className="text-[#667085] text-sm">
                Set your survey questions seamlessly with the survey builder.
              </div>
            </div>
          </div>
          {/* <div>
            <div className="h-1 w-[200px] rounded-md bg-[#EBEFF3]">
              <div className="w-[50%] bg-[#12B76A] h-full rounded-t-sm rounded-l-sm"></div>
            </div>
            <div className="flex justify-end mt-2 text-xs text-[#667085]">
              <div>Just getting started</div>
            </div>
          </div> */}
          <div className="flex gap-4">
            <button
              className="py-3 px-6 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
              onClick={() => {
                goBack(2);
              }}
              disabled={loading}
            >
              Back
            </button>
            <button
              onClick={() => nextStep(4)}
              disabled={loading}
              className="py-3 px-5 w-24 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75 disabled:bg-blue-75 flex justify-center items-center"
            >
              {loading ? (
                <Spinner className={"w-4 h-4"} />
              ) : (
                <div className="flex gap-3 items-center">
                  {"Next  "} <img src={ArrowRight} alt="" />
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="border h-[535px] rounded m-10 flex">
        <div className="h-full w-[30%] p-4 border-r">
          <div>
            {type !== "edit" ? (
              <div>
                <div className="h-[460px]">
                  <div className="flex border rounded py-2 px-3 justify-between">
                    <div className="flex items-center text-[#475467]">List</div>
                    <div
                      onClick={() => {
                        addQuestion();
                      }}
                      className="h-[31px] w-[31px] bg-[#98A2B3] justify-center items-center flex cursor-pointer rounded"
                    >
                      <img src={Add} alt="Add" />
                    </div>
                  </div>
                  <div
                    className={
                      type === "introduction"
                        ? "h-[44px] flex gap-2 mt-6 bg-[#F2F4F7]"
                        : "h-[44px] flex gap-2 mt-6 cursor-pointer"
                    }
                    onClick={() => selectType("introduction")}
                  >
                    <div
                      className={
                        type === "introduction"
                          ? "h-full w-[48px] flex justify-center items-center"
                          : "border rounded h-full w-[48px] flex justify-center items-center"
                      }
                    >
                      In
                    </div>
                    <div className="flex items-center">Introduction</div>
                  </div>
                  {/* <div className="border mt-6"></div> */}
                  <div className="mt-6 h-[300px] overflow-hidden border-t pt-4">
                    <div className="h-full overflow-y-overlay overflow-x-hidden">
                      {questions.map((q, index) => {
                        return (
                          <div
                            key={index}
                            className={
                              questions[gIndex]?.index === index + 1 &&
                              type === "message"
                                ? "flex items-center h-[44px] mb-4 justify-between bg-[#F2F4F7]"
                                : "flex items-center h-[44px] mb-4 justify-between cursor-pointer"
                            }
                            onClick={() => {
                              selectQuestion(q);
                            }}
                          >
                            <div className="flex gap-2 items-center h-full">
                              <div
                                className={
                                  questions[gIndex]?.index === index + 1 &&
                                  type === "message"
                                    ? " h-full w-[48px] flex justify-center items-center font-semibold"
                                    : "border rounded h-full w-[48px] flex justify-center items-center font-semibold"
                                }
                              >
                                Q{index + 1}
                              </div>
                              <div>{q.q}</div>
                            </div>
                            <div className="mr-4 cursor-pointer">
                              <Popup
                                arrow={false}
                                trigger={
                                  <button>
                                    <img src={Ellipsis} alt="Ellipsis" />
                                  </button>
                                }
                                position="right top"
                                offsetX={10}
                                contentStyle={{
                                  padding: "18px 16px",
                                  border: "none",
                                  borderRadius: "8px",
                                  boxShadow:
                                    "0px 0px 8px rgba(16, 24, 40, 0.04), 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
                                  width: "auto",
                                }}
                              >
                                <div className="text-sm text-gray-700">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      editMessage(index);
                                    }}
                                  >
                                    Edit
                                  </div>
                                  <div
                                    className="mt-5 mb-5 cursor-pointer"
                                    onClick={() => duplicateMessage(index)}
                                  >
                                    Duplicate
                                  </div>
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => deleteMessage(index)}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </Popup>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div
                  className="h-[50px]"
                  onClick={() => selectType("endMessage")}
                >
                  {/* <div className="border mt-4"></div> */}
                  <div
                    className={
                      type === "endMessage"
                        ? "flex py-2 px-3 justify-between bg-[#F2F4F7] border-t"
                        : "flex py-2 px-3 justify-between border-t cursor-pointer"
                    }
                  >
                    <div className="flex items-center text-[#475467]">
                      End Message
                    </div>
                    <div className="h-[31px] w-[31px] bg-[#98A2B3] justify-center items-center flex rounded">
                      <img src={RightArrow} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="h-full">
            {type === "edit" ? (
              <div className="h-full">
                <div className="py-6 px4 relative h-full">
                  <div className="bg-[#F2F4F7] flex justify-center items-center relative h-[38px]">
                    <div
                      className="absolute left-[24px] cursor-pointer"
                      onClick={() => {
                        gotoToList();
                      }}
                    >
                      <img src={ChevronBack} alt="" />
                    </div>
                    <div className="text-[##475467] text-sm">
                      Question {questions[gIndex]?.index}
                    </div>
                  </div>
                  <div className="mt-6 border-t pt-6">
                    <div className="text-sm font-semibold">Type</div>
                    <div className="mt-1">
                      <div className="border rounded p-1 h-[40px] cursor-pointer">
                        <select
                          value={questionType}
                          onChange={(event) => {
                            messageTypeOnChange(event);
                          }}
                          className="w-full h-full border-0 outline-0 texr-[#98A2B3] text-sm cursor-pointer"
                        >
                          <option value="text">Text</option>
                          <option value="option">Multiple choice</option>
                        </select>
                      </div>
                    </div>
                    <div className="absolute bottom-0 w-full">
                      <div className="border rounded flex items-center justify-center cursor-pointer h-[40px] w-full">
                        <div className="flex gap-3 items-center">
                          <img src={Plus} alt="" />
                          <div
                            onClick={() => {
                              addNewQuestionFromView();
                            }}
                            className="text-sm font-semibold text-[#475467]"
                          >
                            New question
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-[70%] h-full bg-[#F9FAFB]">
          <div className="m-4 mb-0 mt-0">
            <div className="h-[50px] flex justify-end items-center cursor-pointer">
              {" "}
              <div
                onClick={() => {
                  //toggleModal(true);
                  setPreviewToggle(true);
                }}
              >
                <img src={Play} alt="" />
              </div>{" "}
            </div>
            <div className="h-[483px] bg-[#ffffff] border border-b-0 rounded rounded-b-none relative">
              <div>
                {type === "endMessage" ? (
                  <div>
                    <div className="m-10">
                      <div className="text-[#475467 font-semibold text-sm mb-1">
                        End Message
                      </div>
                      <div>
                        <div className="border border-[#E4E7EC] rounded px-2 py-3 mb-1">
                          <input
                            onChange={(event) => {
                              onEndMessageChange(event);
                            }}
                            value={endMessage}
                            onPaste={(event) => {
                              onIntroductoryMessagePaste(event);
                            }}
                            className="text-base w-full border-0 outline-0"
                            placeholder="Message goes here"
                          />
                        </div>
                      </div>
                      <div className="text-sm">
                        {endMessage.length}/120 Characters
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div>
                {type === "introduction" ? (
                  <div>
                    <div className="m-10">
                      <div className="text-[#475467 font-semibold text-sm mb-1">
                        Introductory message
                      </div>
                      <div>
                        <div className="border border-[#E4E7EC] rounded py-2 px-3">
                          <textarea
                            className="h-[112px] w-full border-0 outline-0"
                            onChange={(event) => {
                              onIntroductoryMessageChange(event);
                            }}
                            value={introduction}
                            onPaste={(event) =>
                              onIntroductoryMessagePaste(event)
                            }
                            placeholder="Enter introductory message"
                          />
                        </div>
                      </div>
                      <div className="text-sm">
                        {introduction.length}/120 Characters
                      </div>
                      <div className="border rounded mt-2 bg-[#F9FAFB] text-[#98A2B3] p-3 text-base">
                        <div>
                          {props.businessName
                            ? `Courtesy: ${props.businessName}`
                            : ""}
                        </div>
                        <div>Press</div>
                        <div>1. Accept</div>
                        <div>2. Cancel</div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div>
                {type === "message" || type === "edit" ? (
                  <div>
                    <div className="m-10">
                      <div>
                        {questionType === "text" ? (
                          <div>
                            <div>
                              <div className="border border-[#E4E7EC] rounded px-2 py-3 mb-1">
                                <input
                                  className="w-full text-xl text-[#98A2B3] border-0 outline-0 font-semibold"
                                  onChange={(event) => {
                                    onQuestionChange(event);
                                  }}
                                  value={questions[gIndex]?.q}
                                  placeholder="Main question here"
                                />
                              </div>
                            </div>
                            <div>
                              <div className="mt-4 bg-[#F9FAFB] h-[196px] w-full border rounded">
                                <div className="pt-2 pl-3 text-[#98A2B3]">
                                  for Participant’s answer
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div>
                        {questionType === "option" ? (
                          <div>
                            <div>
                              <div>
                                <div className="border border-[#E4E7EC] rounded px-2 py-3 mb-1">
                                  <input
                                    onChange={(event) => {
                                      onQuestionChange(event);
                                    }}
                                    value={questions[gIndex]?.q}
                                    className="w-full text-xl text-[#98A2B3] border-0 outline-0 font-semibold"
                                    placeholder="Main question here"
                                  />
                                </div>
                              </div>
                              <div>
                                {questions[gIndex].choice.map((c, i) => {
                                  let index = i + 1;
                                  return (
                                    <div
                                      key={i}
                                      className="flex items-center gap-[10px] mt-4"
                                    >
                                      <div>{i + 1}.</div>
                                      <div className="w-full">
                                        <div className="border-b h-[40px] w-full flex items-center">
                                          <input
                                            onChange={(event) => {
                                              onChoiceChange(event, i);
                                            }}
                                            value={questions[gIndex].choice[i]}
                                            className="border-0 outline-0 w-full h-full"
                                            placeholder={"Choice " + index}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="cursor-pointer"
                                        onClick={() => deleteChoice(i)}
                                      >
                                        {index > 2 ? (
                                          <img src={Delete} alt="" />
                                        ) : null}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              <div
                                className="text-sm font-semibold text-blue-75 mt-[18px] cursor-pointer"
                                onClick={() => {
                                  addChoice();
                                }}
                              >
                                Add choice
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="absolute bottom-0 w-full">
                {type === "message" || type === "edit" ? (
                  <div className="border-t border-t-[#E7EDF3] py-3 px-12 flex gap-6 text-sm font-semibold text-[#98A2B3]">
                    <span>Character limit:</span>
                    <span className="font-normal">{questionLimit} / 160</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mx-10">
        <div className="flex justify-between">
           <button
            onClick={(e) => {
              nextStep(1);
            }}
            disabled={loading}
            className="text-gray-70 font-semibold text-sm px-6 py-3 border border-white hover:border-gray-50 rounded-lg"
          >
            Save as draft
          </button>
           <div className="flex gap-4">
            <button
              className="py-3 px-6 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
              onClick={() => {
                goBack(1);
              }}
              disabled={loading}
            >
              Back
            </button>
            <button
              onClick={() => nextStep(2)}
              disabled={loading}
              className="py-3 px-24 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75 disabled:bg-gray-400 disabled:text-slate-500 disabled:border-slate-200"
            >
              Continue
            </button>
          </div>
        </div>
      </div> */}
      <UssdSurveyPreview
        question={{
          introduction: introduction,
          questions: questions,
          endMessage: endMessage,
        }}
        previewToggle={previewToggle}
        setPreviewToggle={setPreviewToggle}
        // prieviewObject={prieviewObject}
        //previewSelection={previewSelection}
        //  toggleModal={toggleModal}
        //previewPrevious={previewPrevious}
        //previewNext={previewNext}
      />
      {/* <Modal open={previewToggle}>
        <div className="h-[651px] bg-[#F2F6FC] flex justify-center items-center relative">
          <Apple />
          <div className="absolute bg-none h-[562px] w-[309px]">
            <div className="bg-none absolute top-[45px] bottoom-[25px] left-[15px] right-[15px] h-[500px]">
              {previewToggle ? (
                <div>
                  <div className="p-3">
                    {prieviewObject[previewSelection].type ===
                    "Introduction" ? (
                      <div>
                        <div className="text-sm text-gray-700 opacity-80">
                          {prieviewObject[previewSelection].value}
                        </div>
                        <div className="mt-5 pl-5 text-sm text-gray-700">
                          <div className="flex gap-3">
                            <div>1.</div>{" "}
                            <div className="opacity-80">Accept</div>
                          </div>
                          <div className="flex gap-3 mt-3">
                            <div>2. </div>{" "}
                            <div className="opacity-80">Reject</div>
                          </div>
                        </div>
                        <div className="text-xs text-[#667085] opacity-80 mt-6 pt-3 pl-2">
                          Reply with 1 or 2
                        </div>
                      </div>
                    ) : null}
                    {prieviewObject[previewSelection].type === "Question" ? (
                      <div>
                        <div className="text-gray-700 p-2 text-lg">
                          {prieviewObject[previewSelection].value.q}
                        </div>
                        {prieviewObject[previewSelection].value.choice.length >
                        0 ? (
                          <div className="mt-3">
                            {prieviewObject[previewSelection].value.choice.map(
                              (e, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="flex items-center gap-4 text-sm mt-2 text-gray-700 pl-1"
                                  >
                                    <div>{i + 1}.</div>
                                    <div className="opacity-80">{e}</div>
                                  </div>
                                );
                              }
                            )}
                            <div className="text-[#667085] pl-2 mt-7 text-xs flex gap-1">
                              <span>Reply with</span>
                              {prieviewObject[
                                previewSelection
                              ].value.choice.map((e, i) => {
                                return (
                                  <span key={i}>
                                    {i + 1}{" "}
                                    <span>
                                      {i !=
                                      prieviewObject[previewSelection].value
                                        .choice.length -
                                        1
                                        ? ","
                                        : null}
                                    </span>
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                        ) : null}
                        {prieviewObject[previewSelection].value.choice.length ==
                        0 ? (
                          <div className="text-[#667085] opacity-80 mt-7 pl-2 text-xs">
                            Reply
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    {prieviewObject[previewSelection].type === "End Message" ? (
                      <div className="text-blue-75 text-xl p-2">
                        {prieviewObject[previewSelection].value}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className="absolute border-t w-full bottom-5 pt-5">
                {previewToggle ? (
                  <div className="flex justify-between items-center">
                    <div className="flex gap-1 text-gray-700 opacity-80 text-sm items-center ml-3">
                      <div>{prieviewObject[previewSelection].type}</div>
                      <div>
                        {prieviewObject[previewSelection].type == "Question" ? (
                          <div>
                            {prieviewObject[previewSelection].value.index}/
                            {prieviewObject.length - 2}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex items-center gap-7 mr-3">
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          previewPrevious();
                        }}
                      >
                        <LeftChevron />
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          previewNext();
                        }}
                      >
                        <RightChevron />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div
            className="absolute right-[24px] top-[24px] cursor-pointer"
            onClick={() => {
              toggleModal(false);
            }}
          >
            <Close />
          </div>
        </div>
      </Modal> */}
    </div>
  );
};

export default SurveyStepTwoUssd;
