import { useEffect, useState } from "react";
import {
  Close,
  DropDownArrow,
  Delete,
  ArrowBend,
} from "../../../../assets/icons/icons";

const WebSurveyRules = ({
  close,
  operators,
  pages,
  selectedQuestion,
  currentRules,
}) => {
  console.log(operators);
  useEffect(() => {
    console.log(pages, selectedQuestion);
    if (currentRules) {
      let r = [];
      currentRules.map((ele) => {
        let rule: any = {};
        (rule.questionListVisibility = false),
          (rule.filterListVisibility = false),
          (rule.question = `Q${ele.operand}`),
          (rule.filter = getFiltersForId(
            ele.operator,
            ele.visibility.toLowerCase()
          )),
          (rule.value = ele.value),
          (rule.relation = ele.relationship);
        r.push(rule);
      });
      setRules(r);
    }
  }, []);

  const getFiltersForId = (filter, visibility) => {
    for (let key in operators[visibility]) {
      if (operators[visibility][key] === filter) {
        return { name: key, value: filter };
      }
    }
  };

  const getFilters = (obj) => {
    let f = [];
    for (let key in obj) {
      let filter: any = {};
      filter.name = key;
      filter.value = obj[key];
      f.push(filter);
    }
    return f;
  };
  const getValidQuestions = () => {
    let question = [];
    pages.map((p, i) => {
      p.map((q, j) => {
        if (q.index !== selectedQuestion) {
          question.push("Q" + q.index);
        }
      });
    });
    return question;
  };
  const [questions, setQuestions] = useState(getValidQuestions());
  const [filters, setFilters] = useState(getFilters(operators.show));
  const [rules, setRules] = useState([
    {
      questionListVisibility: false,
      filterListVisibility: false,
      question: questions[0],
      filter: filters[0],
      value: "",
      relation: "AND",
    },
  ]);
  const [ruleVisibility, setRuleVilibility] = useState("Show");
  const [ruleVisibilityToggle, setRuleVisibilityToggle] = useState(false);

  const switchRuelVisibility = (value) => {
    setRuleVilibility(value);
  };

  const toggleQuestionListVisibility = (index) => {
    let rule = [...rules];
    rule[index].questionListVisibility = !rule[index].questionListVisibility;
    setRules(rule);
  };

  const toggleFilterListVisibility = (index) => {
    let rule = [...rules];
    rule[index].filterListVisibility = !rule[index].filterListVisibility;
    setRules(rule);
  };

  const addNewRule = () => {
    let rule = [...rules];
    let r = {
      questionListVisibility: false,
      filterListVisibility: false,
      question: questions[0],
      filter: filters[0],
      value: "",
      relation: "AND",
    };
    rule.push(r);
    setRules(rule);
  };

  const deleteRule = (index) => {
    let rule = [...rules];
    rule.splice(index, 1);
    setRules(rule);
  };

  const setFilter = (index, filter) => {
    let r = [...rules];
    r[index].filter = filter;
    setRules(r);
  };

  const setQuestion = (index, question) => {
    let r = [...rules];
    r[index].question = question;
    setRules(r);
  };

  const applyRules = () => {
    if (rules.length === 0) {
      close([]);
    }
    let finalRules = [];
    rules.map((rule, i) => {
      let r = {
        operator: operators[ruleVisibility.toLowerCase()][rule.filter.name],
        relationship: i === 0 ? null : rule.relation,
        operand: rule.question.split("Q")[1],
        value: rule.value,
        visibility: ruleVisibility,
      };
      finalRules.push(r);
    });
    close(finalRules);
  };

  const toggleRelation = (index) => {
    let rule = [...rules];
    if (rule[index].relation === "AND") rule[index].relation = "OR";
    else rule[index].relation = "AND";
    setRules(rule);
  };

  const onRuleInputChange = (event, index) => {
    let r = [...rules];
    r[index].value = event.target.value;
    setRules(r);
  };

  return (
    <div className="absolute h-full w-full top-0 bg-[#3440544D] flex justify-center items-center left-0">
      <div className="h-[396px] w-[40%] bg-white rounded p-8 shadow-md relative overflow-auto">
        <div className="flex justify-between">
          <div className="text-gray-700 text-lg font-semibold">Add rule</div>
          <div className="cursor-pointer" onClick={() => close(false)}>
            <img src={Close} alt="" />
          </div>
        </div>
        <div className="min-h-[250px]">
          <div className="flex mt-7 gap-3 items-center">
            <div
              className="flex items-center px-5 py-3 gap-2 rounded-full bg-[#F9FAFB] text-[#475467] text-sm font-semibold relative"
              // onClick={() => {
              //   setRuleVisibilityToggle(!ruleVisibilityToggle);
              // }}
            >
              {/* {ruleVisibility} <img src={DropDownArrow} alt="" /> */}
              {ruleVisibility}
              {ruleVisibilityToggle && (
                <div className="absolute top-11 left-0 bg-white py-2 rounded shadow-md w-full font-normal z-10">
                  <div
                    className="h-[40px] flex items-center pl-3"
                    onClick={() => {
                      switchRuelVisibility("Show");
                    }}
                  >
                    Show
                  </div>
                  <div
                    className="h-[40px] flex items-center pl-3"
                    onClick={() => {
                      switchRuelVisibility("Hide");
                    }}
                  >
                    Hide
                  </div>
                </div>
              )}
            </div>
            <div className="text-sm text-gray-700">this question if:</div>
          </div>
          {rules.map((x, i) => {
            return (
              <div key={i}>
                {i > 0 && (
                  <div className="flex justify-between items-center mt-4">
                    <div className="flex gap-3 items-center">
                      <img src={ArrowBend} alt="" />
                      <div
                        className="flex items-center px-5 py-3 gap-2 rounded-full bg-[#F9FAFB] cursor-pointer text-[#475467] text-sm font-semibold relative"
                        onClick={() => {
                          toggleRelation(i);
                        }}
                      >
                        {x.relation}
                      </div>
                    </div>
                    <div>
                      <div
                        className="flex items-center cursor-pointer mr-2"
                        onClick={() => {
                          deleteRule(i);
                        }}
                      >
                        <img src={Delete} alt="" />
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={`flex gap-3 mt-4 ${
                    rules.length > 1 && i !== rules.length - 1
                      ? "pb-7 border-b-2"
                      : ""
                  }`}
                >
                  <div
                    className="flex items-center px-5 py-3 gap-2 rounded-full bg-[#F9FAFB] cursor-pointer text-[#475467] text-sm font-semibold relative"
                    onClick={() => {
                      toggleQuestionListVisibility(i);
                    }}
                  >
                    {x.question} <img src={DropDownArrow} alt="" />
                    {x.questionListVisibility && (
                      <div className="absolute top-11 left-0 bg-white py-2 rounded shadow-md w-full font-normal z-10 max-h-[170px] overflow-y-auto overflow-x-hidden">
                        {questions.map((question, k) => {
                          return (
                            <div
                              key={k}
                              className="h-[40px] flex items-center pl-3"
                              onClick={() => {
                                setQuestion(i, question);
                              }}
                            >
                              {question}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div
                    className="flex items-center px-5 py-3 gap-2 rounded-full bg-[#F9FAFB] cursor-pointer text-[#475467] text-sm font-semibold relative whitespace-nowrap"
                    onClick={() => {
                      toggleFilterListVisibility(i);
                    }}
                  >
                    {x.filter.name} <img src={DropDownArrow} alt="" />
                    {x.filterListVisibility && (
                      <div className="absolute top-11 left-0 bg-white py-2 rounded shadow-md w-max font-normal z-10 max-h-[170px] overflow-y-auto overflow-x-hidden">
                        {filters.map((x, j) => {
                          return (
                            <div
                              key={j}
                              className="h-[40px] flex items-center pl-3"
                              onClick={() => {
                                setFilter(i, x);
                              }}
                            >
                              {x.name}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  {x.filter.name !== "Answered" && (
                    <div className="border rounded w-full">
                      <input
                        className="border-none outline-none w-full h-full px-2"
                        type="text"
                        onChange={(event) => {
                          onRuleInputChange(event, i);
                        }}
                        value={rules[i].value}
                      />
                    </div>
                  )}
                  {i === 0 && (
                    <div className="flex items-center">
                      <div
                        className="flex items-center cursor-pointer mr-2"
                        onClick={() => {
                          deleteRule(i);
                        }}
                      >
                        <img src={Delete} alt="" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <div
            className="text-blue-75 text-sm font-semibold cursor-pointer mt-7"
            onClick={() => {
              addNewRule();
            }}
          >
            + Add condition
          </div>
        </div>
        <div className="flex justify-end w-full">
          <div className="flex gap-4">
            <div
              className="border rounded text-[#667085] py-3 px-5 font-semibold text-sm cursor-pointer"
              onClick={() => close(false)}
            >
              Cancel
            </div>
            <div
              className="border-blue-75 rounded text-[#ffffff] bg-blue-75 py-3 px-5 font-semibold text-sm cursor-pointer"
              onClick={() => applyRules()}
            >
              Apply rule
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebSurveyRules;
