import { useEffect } from "react";
import { addGAEventListener, getPageName } from "../../../../../marketing-cloud-container/src/ga-events";

const ChannelMenuSurvey = (props) => {
  const {
    showChannelMenu,
    channelMenu,
    setChannel,
    channel,
    setFilter,
    setPageNum,
    setPageSize,
    setFrom,
    audience,
    status,
  } = props;

  useEffect(() => {
    addGAEventListener();
  }, [showChannelMenu]);

  const handleSelect = (e) => {
    setPageNum(0);
    setPageSize(10);
    setFrom(0);
    setChannel(e.target.value);
    setFilter(true);
    if (channel === e.target.value) {
      setChannel("");
      if (audience || status) {
        return setFilter(true);
      } else {
        return setFilter(false);
      }
    }
  };

  return (
    showChannelMenu && (
      <div
        ref={channelMenu}
        className="absolute mt-1 z-10 bg-white rounded-lg shadow w-40"
      >
        <ul className="p-2 text-sm text-gray-700">
          <li
            className="triggerGA"
            data-event-category={`${getPageName()}-list-page`}
            data-event-label={`${getPageName()}-list_channel-option_click`}
            data-event-action="filter_click"
            data-event-value="USSD"
          >
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={channel === "ussd"}
                id="ussd"
                type="checkbox"
                value="ussd"
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                onChange={handleSelect}
              />
              <label
                htmlFor="ussd"
                className="w-full ml-3 text-[14px] leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                USSD
              </label>
            </div>
          </li>
          <li
            className="triggerGA"
            data-event-category={`${getPageName()}-list-page`}
            data-event-label={`${getPageName()}-list_channel-option_click`}
            data-event-action="filter_click"
            data-event-value="WEB"
          >
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={channel === "web"}
                id="web"
                type="checkbox"
                value="web"
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
                onChange={handleSelect}
              />
              <label
                htmlFor="web"
                className="w-full ml-3 text-[14px] leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                Web
              </label>
            </div>
          </li>
        </ul>
      </div>
    )
  );
};

export default ChannelMenuSurvey;
