import { useEffect, useReducer, useRef, useState } from "react";
import { ConfigProvider, DatePicker, TimePicker } from "antd";
import type { DatePickerProps } from "antd/es/date-picker";
import locale from "antd/es/date-picker/locale/en_GB";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { CUSTOMER_ID } from "../../../common/constants/billingConstants";
import NotificationModal from "../../../modals/notification/NotificationModal";
import {
  postStepOne,
  updateMessage,
  getSenderIds,
  getObjectives,
  getSegmentVariables,
  getRcsTemplates,
} from "../../../common/services/message-services";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import * as Sentry from "@sentry/react";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import DeletionModal from "../../../common/components/campaign-deletion-modal/campaignDeletionModal";
import chatSvg from "../../../common/assets/icons/Chat.png";
import RcsTemplateSelector from "../rcs-template-selector/rcs-template-selector";
import {
  ArrowRight,
  CalendarIcon,
  Chevron,
  Sms,
} from "../../../assets/icons/icons";

const RcsStepOne = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [showScheduleMenu, setShowScheduleMenu] = useState(false);
  const [showSenderIDMenu, setShowSenderIDMenu] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showObjectiveMenu, setShowObjectiveMenu] = useState(false);
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const [senderIDs, setSenderIDs] = useState([]);
  const [messageLength, setMessageLength] = useState(127);
  const [startDate, setStartDate] = useState(null);
  const [scheduleError, setScheduleError] = useState("");

  const [rcsExists, setRcsExists] = useState(false);
  const [rcsNameError, setRcsNameError] = useState("");
  const [pushTimeError, setPushTimeError] = useState("");
  const [defaultName, setDefaultName] = useState([]);

  const scheduleMenu = useRef(null);
  const senderIDMenu = useRef(null);
  const objectiveMenu = useRef(null);

  const [rcsObjectives, setRcsObjectives] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [attributeMatches, setAttributeMatches] = useState([]);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [templateListLoader, setTemplateListLoader] = useState(false);
  const [templateModalState, setTemplateModalState] = useState(false);
  const [selectedTemplate, setSelectedtemplate] = useState(null);

  useEffect(() => {
    if (props.data.content.default?.length) {
      setDefaultName(props.data.content.default);
      return;
    }
    if (attributesList && attributesList.length) {
      // const params = attributeMatches.map((attr) => attr.column_name);
      // setParams(params);

      const defaultNames = attributesList.map((attr) => {
        return {
          fieldName: attr.column_name,
          defaultName: attr.display_name?.substring(0, 10),
        };
      });
      setDefaultName([...defaultNames]);
    }
  }, [attributesList, props.data]);

  const getPersonalizationVariables = (segmentId) => {
    // try {
    //   getattributes("csv")
    //     .then((res) => {
    //       const { error, response = [] } = res;
    //       if (!error || error === "false") {
    //         setAttributesList(res.data);
    //         setAttributeMatches(res.data);
    //       }
    //     })
    //     .catch((err) => {});
    // } catch (err) {
    //   console.log(err);
    // }
    try {
      getSegmentVariables(segmentId)
        .then((res) => {
          const { error, response = [] } = res;
          if (!error || error === "false") {
            setAttributesList(res.data);
            setAttributeMatches(res.data);
          }
        })
        .catch((err) => {});
    } catch (err) {
      console.log(err);
    }
  };

  const filterAttributes = (event) => {
    let matches = attributesList.filter((el) =>
      el.display_name
        ?.toLowerCase()
        ?.includes(event.target.value?.toLowerCase())
    );
    setAttributeMatches(matches);
  };

  const enablePersonalization = () => {
    return (
      props?.data?.audience?.segmentId &&
      props?.data?.selectedSegment &&
      props?.data?.selectedSegment.audience_source !== "terragon"
    );
  };

  useEffect(() => {
    if (enablePersonalization())
      getPersonalizationVariables(props?.data?.selectedSegment?.segment_ids);
  }, [props.data]);

  const fetchObjectives = () => {
    try {
      getObjectives()
        .then((res) => {
          const { error, response = {} } = res;
          if (!error) {
            setRcsObjectives(response.campaignObjectives || []);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  useEffect(() => {
    if (props.data.id) {
      setRcsExists(true);
      setSelectedtemplate(
        typeof props.data.message === "string"
          ? JSON.parse(props.data.message)
          : props.data.message
      );
    }

    if (props.data.campaignStartDate && props.data.campaignStartTime) {
      setStartDate(
        new Date(
          props.data.campaignStartDate + "T" + props.data.campaignStartTime
        )
      );
    }

    fetchSenderIDs();
    fetchObjectives();
  }, []);

  const fetchSenderIDs = () => {
    try {
      getSenderIds()
        .then((res) => {
          const { error, response = {} } = res;
          if (error === false) {
            setSenderIDs(response);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
            Notify({
              type: "error",
              heading: "Failed to retrieve sender IDs.",
              message: error,
            });
            setSenderIDs([]);
          }
        })
        .catch((error) => {
          error.response
            ? Sentry.captureMessage("Error", error.response)
            : Sentry.captureMessage("Error", error);
          console.log(error);
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
    }
  };

  dayjs.extend(customParseFormat);
  dayjs.extend(localizedFormat);

  const onStartTimeChange = (time: Dayjs, timeString: string) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "campaignStartTime",
        value: time.format("HH:mm:ss").split(" ")[0],
      },
    });
  };

  const onStartDateChange = (
    value: DatePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "campaignStartDate",
        value: dateString,
      },
    });
  };

  const onStartDateSave = () => {
    if (!formState.campaignStartDate) {
      formState.errors.campaignStartDate = true;
      return;
    }
    if (!formState.campaignStartTime) {
      formState.errors.campaignStartTime = true;
      return;
    }

    if (
      Number(formState.campaignStartTime.replaceAll(":", "")) < 80000 ||
      Number(formState.campaignStartTime.replaceAll(":", "")) > 190000
    ) {
      formState.errors.campaignStartTime = true;
      setScheduleError("Select an active time (8 AM - 7 PM WAT)");
      return;
    }

    const startDate = new Date(
      formState.campaignStartDate + "T" + formState.campaignStartTime
    );
    if (startDate.getTime() < new Date().getTime()) {
      formState.errors.campaignStartTime = true;
      setScheduleError("Selected time has already passed");
      return;
    } else {
      setScheduleError("");
    }
    setStartDate(startDate);
    setShowCalendar(false);
  };

  const disabledDates = (current) => {
    return current && current < dayjs().endOf("day").subtract(1, "day");
  };

  const copyToClipboard = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setShowCopyTooltip(true);
        setTimeout(() => {
          setShowCopyTooltip(false);
        }, 4000);
      })
      .catch((error) => {
        error.response
          ? Sentry.captureMessage("Error", error.response)
          : Sentry.captureMessage("Error", error);
        console.error("Failed to copy message body:", error);
      });
  };

  const closeOpenMenus = (e) => {
    if (
      senderIDMenu.current &&
      showSenderIDMenu &&
      !senderIDMenu.current.contains(e.target)
    ) {
      setShowSenderIDMenu(false);
    }

    if (
      objectiveMenu.current &&
      showObjectiveMenu &&
      !objectiveMenu.current.contains(e.target)
    ) {
      setShowObjectiveMenu(false);
    }

    if (
      scheduleMenu.current &&
      showScheduleMenu &&
      !scheduleMenu.current.contains(e.target)
    ) {
      setShowScheduleMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);

    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  });

  const handleNotificationClose = () => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      show: false,
    }));
  };

  const [notificationData, setNotificationData] = useState({
    show: false,
    close: handleNotificationClose,
    type: "",
    message: "",
    heading: "",
  });

  const handleNotificationData = (data: any) => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      ...data,
    }));
    setTimeout(() => {
      handleNotificationClose();
    }, 4000);
  };

  const formInitValue = {
    campaignName: props.data.name || "",
    campaignStartDate: props.data.campaignStartDate || "",
    campaignStartTime: props.data.campaignStartTime || "",
    senderID: props.data.senderName || "",
    message: props.data.message || "",
    objective: props.data.objective || "",
    url: props.data.landingPagesURL || "",
    schedule: props.data?.schedule
      ? props.data?.schedule?.immediate
        ? "Send now"
        : "Send later"
      : "",
    saveAsDraft: false,
    errors: {
      campaignName: false,
      campaignStartDate: false,
      campaignStartTime: false,
      senderID: false,
      message: false,
      objective: false,
      schedule: false,
    },
  };

  useEffect(() => {
    dispatch({ type: "reinitialize", value: formInitValue });
    // setParams(props.data.content.params || []);
    if (props.data.id) {
      setRcsExists(true);
    }
  }, [props.data]);

  const isValidURL = (str) => {
    if (
      /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g.test(
        str
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "CHANGE":
        return {
          ...state,
          [action.payload.name]: action.payload.value,
          errors: {
            ...state.errors,
            [action.payload.name]: "",
          },
        };
      case "SUBMIT":
        const errors: any = {};
        if (!state.campaignName.trim()) {
          errors.campaignName = true;
        }
        if (!state.schedule) {
          errors.schedule = true;
        }
        if (state.schedule === "Send later" && !startDate) {
          errors.campaignStartDate = true;
        }
        if (!state.senderID) {
          errors.senderID = true;
        }
        if (!state.message) {
          errors.message = true;
        }
        if (!state.objective) {
          errors.objective = true;
        }
        if (
          state.objective !== "Brand Awareness" &&
          state.url.length > 0 &&
          !isValidURL(state.url)
        ) {
          errors.url = true;
        }

        return {
          ...state,
          errors: {
            ...errors,
          },
        };

      case "reinitialize":
        return { ...state, ...action.value };
      default:
        return state;
    }
  };
  const [formState, dispatch] = useReducer(reducer, formInitValue);

  const handleFormChange = (e) => {
    let { name, value } = e.target;

    if (name === "campaignName") {
      if (rcsNameError) {
        setRcsNameError("");
      }
      value = value.replace(/[^a-z0-9 ]/gi, "");
    }

    if (name === "message") {
      try {
        e.preventDefault();
      } catch (error) {
        error.response
          ? Sentry.captureMessage("Error", error.response)
          : Sentry.captureMessage("Error", error);
      }
      if (value.length > messageLength) {
        return;
      }
    }

    dispatch({
      type: "CHANGE",
      payload: {
        name,
        value,
      },
    });
  };

  const validateForm = () => {
    const { campaignName, objective, schedule, message } = formState;

    if (!schedule) {
      return false;
    }

    if (schedule === "Send later" && !startDate) {
      return false;
    }

    if (!campaignName || !objective || !selectedTemplate) {
      return false;
    }

    return true;
  };

  const getCampaignData = () => {
    const data = {
      id: "",
      name: formState.campaignName,
      channel: "RCS",
      bot: "Terragon Group",
      rcsTemplate: selectedTemplate.templateDeails.templateName,
      objective: formState.objective,
      content: {},
      messageType: "PUSH",
      chatChannel: "",
      senderName: formState.senderID,
      landingPagesURL: formState.url,
      status: "DRAFT",
      businessId: CUSTOMER_ID,
      budget: props.data?.budget,
      schedule: {
        immediate: formState.schedule === "Send now" ? true : false,
        timeline: {
          start: startDate ? startDate.getTime() : "",
        },
      },
      audience: props.data.audience || {
        segmentId: "",
        phoneNumbers: [],
      },
      message: selectedTemplate,
      campaignStartDate: formState.campaignStartDate,
      campaignStartTime: formState.campaignStartTime,
      rcsPages: 1,
    };

    return data;
  };

  const setCampaignData = (data) => {
    const campaignData = {
      ...props,
      data: {
        ...props.data,
        ...data,
      },
    };

    return campaignData;
  };

  const saveDraft = () => {
    Notify({
      type: "success",
      heading: "Saved successfully.",
      message: "Your campaign has been saved as draft.",
    });
    props.navigate("/engagements");
  };

  const handleSubmit = (event, saveAsDraft) => {
    dispatch({
      type: "CHANGE",
      payload: {
        name: "message",
        value: selectedTemplate,
      },
    });
    event.preventDefault();

    const updatedState = reducer(formState, { type: "SUBMIT" });
    dispatch({ type: "SUBMIT", payload: updatedState });

    if (!validateForm()) {
      return;
    }

    const data = getCampaignData();
    const campaignData = setCampaignData(data);

    if (!rcsExists) {
      try {
        setLoading(true);
        campaignData.data.timezone =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
        postStepOne(campaignData.data)
          .then((res) => {
            const { error, response = {} } = res;
            if (error === false) {
              if (saveAsDraft) {
                saveDraft();
              } else {
                campaignData.data = response;
                campaignData.data.message = selectedTemplate;
                props.setData(campaignData.data);
                props.setStep(3);
              }
            } else {
              error.response
                ? Sentry.captureMessage("Error", error.response)
                : Sentry.captureMessage("Error", error);
            }

            setLoading(false);
          })
          .catch((err) => {
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            if (
              err?.response?.data?.response ===
              "Message name already exists, please provide a different name"
            ) {
              setRcsNameError(err.response.data.response);
            } else if (
              err?.response?.data?.response ===
              "Push time should be before 19:00 if start date is today."
            ) {
              setPushTimeError(err.response.data.response);
            }
            setLoading(false);
          });
      } catch (err) {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        setLoading(false);
        return;
      }
    } else {
      campaignData.data["id"] = props.data.id;
      try {
        setLoading(true);
        updateMessage(campaignData.data, props.data.id.replaceAll("/", "%2F"))
          .then((res) => {
            setLoading(false);
            if (!res.error) {
              if (saveAsDraft) {
                saveDraft();
              } else {
                // const campaignData = setCampaignData(data);
                props.setData(campaignData.data);
                props.setStep(3);
              }
            } else {
              Sentry.captureMessage("Error", res);
            }
          })
          .catch((err) => {
            setLoading(false);
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            console.log("error", err);
          });
      } catch (err) {
        setLoading(false);
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        Notify({
          type: "error",
          heading: "Error",
          message:
            err?.response?.data?.response ||
            err?.message ||
            `${err} Please try after some time`,
        });
      }
    }
  };

  const insertVariable = (variable) => {
    let textArea = document.getElementById("text-box") as HTMLInputElement;
    textArea.setRangeText(variable);
    let { value = "" } = textArea;
    handleFormChange({ target: { name: "message", value: value } });
  };

  const selectTemplate = () => {
    setTemplateListLoader(true);
    getRcsTemplates()
      .then((response) => {
        try {
          setTemplateList(response.templates);
          setTemplateModalState(true);
          setTemplateListLoader(false);
        } catch (error) {
          console.log(error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeTemplateSelector = (status) => {
    setTemplateModalState(false);
    if (status) setSelectedtemplate(status);
  };

  return (
    <div className="py-12 px-12">
      <div className="text-gray-500 font-semibold text-sm">STEP 2 OF 3</div>
      <div className="flex justify-between items-center w-full">
        <div className="flex-wrap justify-start items-start">
          <div className="flex rounded-xl py-4">
            <div className="flex gap-6">
              <div className="flex justify-center items-center flex-shrink-0 w-16 bg-gray-100 rounded-full h-16">
                <img src={Sms} alt="" />
              </div>
              <div className="text-left py-2">
                <div className="text-gray-70 font-semibold text-base">
                  Message details
                </div>
                <div className="text-gray-500 mt-1 font-normal text-sm whitespace-no-wrap">
                  Compose your message.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-2 ml-auto">
          <button
            onClick={() => props.setStep(1)}
            className="px-5 py-3 rounded-md text-sm leading-6 text-slate-600 font-semibold border border-gray-200"
          >
            Back
          </button>
          <button
            onClick={(event) => {
              handleSubmit(event, false);
            }}
            disabled={loading}
            className="px-5 py-3 w-24 rounded-md text-sm leading-6 bg-blue-75 disabled:bg-blue-75 hover:bg-green-75 text-white font-semibold border border-gray-200 flex justify-center items-center"
          >
            {loading ? (
              <Spinner className={"w-4 h-4"} />
            ) : (
              <div className="flex gap-3 items-center">
                {"Next  "} <img src={ArrowRight} alt="" />
              </div>
            )}
          </button>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="py-4 w-[600px]">
          <div className="py-3">
            <div className="flex justify-between gap-6">
              <label className="text-gray-70 w-full text-sm mb-1.5">
                <span className="font-semibold">Campaign name</span>
                <input
                  type="text"
                  name="campaignName"
                  placeholder="Enter campaign name"
                  value={formState.campaignName}
                  maxLength={50}
                  className={`block w-full h-12 px-3 py-3 mt-1 border border-gray-200 rounded-md focus:outline-none ${
                    formState.errors.campaignName && "border-red-500"
                  }`}
                  onChange={handleFormChange}
                  disabled={loading}
                />
                {rcsNameError && (
                  <div className="text-red-500 text-sm mt-2">
                    {rcsNameError}
                  </div>
                )}
              </label>
            </div>

            <div className="text-gray-70 text-sm mb-1.5 mt-4">
              <span className="font-semibold"> Message objective</span>
              <div className="relative w-full">
                <div>
                  <button
                    onClick={() => setShowObjectiveMenu(!showObjectiveMenu)}
                    className={`flex w-[292px] justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-40 rounded-md focus:outline-none ${
                      formState.objective === "" && "text-gray-400"
                    } ${formState.errors.objective && "border-red-500"}`}
                    //type="button"
                    // disabled={loading}
                  >
                    {formState.objective || "Select your objective"}
                    <img src={Chevron} alt="" />
                  </button>
                </div>

                {showObjectiveMenu && (
                  <div
                    ref={objectiveMenu}
                    className="absolute z-10 bg-white rounded-lg shadow w-[292px]"
                  >
                    {rcsObjectives?.map((objective, index) => (
                      <button
                        key={index}
                        className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                        role="menuitem"
                        onClick={() => {
                          dispatch({
                            type: "CHANGE",
                            payload: {
                              name: "objective",
                              value: objective,
                            },
                          });
                          setShowObjectiveMenu(false);
                          if (objective === "Brand Awareness") {
                            setMessageLength(160);
                          } else {
                            setMessageLength(127);
                            formState.message.length > 127
                              ? handleFormChange({
                                  target: {
                                    name: "message",
                                    value: formState.message.substring(0, 127),
                                  },
                                })
                              : null;
                          }
                          // objective === "Brand Awareness"
                          //   ? setMessageLength(160)
                          //   : setMessageLength(130);
                          // setShowObjectiveMenu(false);
                          // formState.message.length > messageLength
                          //   ? handleFormChange({
                          //       target: {
                          //         name: "message",
                          //         value: formState.message.substring(
                          //           0,
                          //           messageLength
                          //         ),
                          //       },
                          //     })
                          //   : null;
                        }}
                      >
                        {objective}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="mt-4">
              <div className="text-gray-70 font-semibold text-sm mb-1.5">
                Message body
              </div>
              <div
                className={`border border-gray-40 rounded-md  ${
                  formState.errors.message && "border-red-500"
                }`}
              >
                <div className="flex h-[40px]">
                  <div className="w-3/4 py-2 px-3 flex items-center text-gray-35">
                    {selectedTemplate
                      ? selectedTemplate.templateDeails.templateName
                      : "Select template message"}
                  </div>
                  <div
                    className={`w-1/4 border-l py-2 px-3 flex items-center ${
                      templateListLoader
                        ? "text-gray-35 cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                    onClick={!templateListLoader ? selectTemplate : null}
                  >
                    Choose template
                  </div>
                </div>
                <div className="h-[200px] bg-gray-60 text-gray-35 flex p-4 border-t rounded-b-md overflow-hidden">
                  {selectedTemplate ? (
                    <div className="h-full overflow-auto w-full">
                      {selectedTemplate.carouselDetails?.length > 0 && (
                        <div className="py-2 px-6">
                          {selectedTemplate.carouselDetails.map(
                            (carousel, index) => {
                              return (
                                <div className="mt-4" key={carousel.cardId}>
                                  <div className="text-gray-700 leading-6">
                                    {carousel.cardDescription}
                                  </div>
                                  <img
                                    className="h-[60px] w-[100px] mt-4 rounded"
                                    src={carousel.mediaUrl}
                                    alt=""
                                  />
                                  {carousel.suggestions?.length > 0 && (
                                    <div>
                                      {carousel.suggestions.map(
                                        (suggestion, index) => {
                                          return (
                                            <div key={suggestion.suggestionId}>
                                              {suggestion.suggestionType ===
                                                "reply" && (
                                                <div className="mt-4 border rounded-md text-xs py-2 px-5 text-gray-500 w-fit bg-white">
                                                  {suggestion.displayText}
                                                </div>
                                              )}
                                              {suggestion.suggestionType ===
                                                "dialer_action" && (
                                                <div className="mt-4 border rounded-md text-xs py-2 px-5 text-gray-500 w-fit bg-white">
                                                  {suggestion.phoneNumber}
                                                </div>
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    "Your selected template message shows here..."
                  )}
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div className="py-3">
                <div className="text-gray-70 font-semibold text-sm mb-2">
                  Schedule
                  {startDate && (
                    <span className="font-light ml-1.5">
                      (Send on {dayjs(startDate).format("LLLL")})
                    </span>
                  )}
                </div>

                <div className="flex justify-between gap-6">
                  <div className="relative w-1/2">
                    <button
                      name="schedule"
                      className={`flex w-[292px] justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-200 rounded-md focus:outline-none ${
                        formState.schedule === "" && "text-gray-400"
                      } ${formState.errors.schedule && "border-red-500"}`}
                      type="button"
                      onClick={() => setShowScheduleMenu(!showScheduleMenu)}
                      disabled={loading}
                    >
                      {formState.schedule || "Select schedule"}
                      <img src={Chevron} alt="" />
                    </button>

                    {/* Schedule Dropdown menu */}
                    {showScheduleMenu && (
                      <div
                        ref={scheduleMenu}
                        className="absolute z-10 bg-white rounded-lg shadow w-[292px]"
                      >
                        {["Send now", "Send later"]?.map((schedule, index) => (
                          <button
                            key={index}
                            className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                            role="menuitem"
                            onClick={() => {
                              dispatch({
                                type: "CHANGE",
                                payload: {
                                  name: "schedule",
                                  value: schedule,
                                },
                              });
                              setPushTimeError("");
                              setShowScheduleMenu(false);
                            }}
                          >
                            {schedule}
                          </button>
                        ))}
                      </div>
                    )}
                    {formState.schedule === "Send now" && !pushTimeError && (
                      <div className="mt-2 text-sm">
                        Message is sent immediately after review
                      </div>
                    )}
                    {pushTimeError && (
                      <div className="text-red-500 text-sm mt-2">
                        {pushTimeError}
                      </div>
                    )}
                  </div>

                  {formState.schedule === "Send later" && (
                    <ConfigProvider
                      theme={{
                        token: {
                          colorIcon: "#98A2B3",
                          colorBorder: "#D0D5DD",
                          colorPrimary: "#008138",
                          colorPrimaryHover: "#008138",
                          colorText: "#344054",
                          colorTextHeading: "#344054",
                          colorTextDisabled: "#D0D5DD",
                          colorTextLightSolid: "var(--main-color)",
                          colorTextPlaceholder: "#98A2B3",
                          controlItemBgActive: "#DAEEE1",
                          controlOutline: "#FFFFFF",
                        },
                      }}
                    >
                      <div className="w-[292px] mt-1">
                        <div
                          onClick={() => {
                            if (!showCalendar) {
                              setShowCalendar(true);
                            }
                          }}
                        >
                          <DatePicker
                            className={`w-[292px] h-12 px-4 hover:border-gray-300 ${
                              (formState.errors.campaignStartDate ||
                                formState.errors.campaignStartTime) &&
                              "border-red-500"
                            }`}
                            placeholder="Enter date"
                            disabled={loading}
                            value={
                              formState.campaignStartDate
                                ? dayjs(
                                    formState.campaignStartDate,
                                    "YYYY-MM-DD"
                                  )
                                : undefined
                            }
                            defaultValue={
                              formState.campaignStartDate
                                ? dayjs(
                                    formState.campaignStartDate,
                                    "YYYY-MM-DD"
                                  )
                                : undefined
                            }
                            format="YYYY-MM-DD"
                            onChange={onStartDateChange}
                            disabledDate={disabledDates}
                            locale={locale}
                            open={showCalendar}
                            showNow={false}
                            showToday={false}
                            suffixIcon={<img src={CalendarIcon} />}
                            allowClear={false}
                            popupStyle={{ paddingBottom: "64px" }}
                            cellRender={(current) => {
                              const style: React.CSSProperties = {};
                              style.borderRadius = "50%";
                              style.width = "30px";
                              style.height = "30px";
                              style.lineHeight = "30px";
                              return (
                                <div
                                  className="ant-picker-cell-inner"
                                  style={style}
                                >
                                  {current.date()}
                                </div>
                              );
                            }}
                            renderExtraFooter={() => {
                              return (
                                <>
                                  <div className="flex justify-between items-end px-5">
                                    <input
                                      type="text"
                                      placeholder="Pick a start date"
                                      value={
                                        formState.campaignStartDate &&
                                        dayjs(
                                          formState.campaignStartDate
                                        ).format("MMM D, YYYY")
                                      }
                                      className={`w-36 h-12 px-2 py-3 mt-3 text-[13.5px] border border-gray-300 rounded-md focus:outline-none ${
                                        formState.errors.campaignStartDate &&
                                        "border-red-500"
                                      }`}
                                      onChange={() => {}}
                                    />
                                    <TimePicker
                                      className={`h-12 ${
                                        formState.errors.campaignStartTime &&
                                        "border-red-500"
                                      }`}
                                      defaultValue={
                                        formState.campaignStartTime
                                          ? dayjs(
                                              formState.campaignStartTime,
                                              "HH:mm:ss"
                                            )
                                          : undefined
                                      }
                                      format="h:mm A"
                                      onChange={onStartTimeChange}
                                      use12Hours
                                      size="small"
                                      showNow={false}
                                      placement="bottomRight"
                                      inputReadOnly={true}
                                    />
                                  </div>
                                  <div className="pt-4 mt-80 bg-white rounded-b-lg drop-shadow-lg">
                                    <hr className="border-gray-300" />
                                    <div className="flex gap-4 p-4 rounded-b-lg">
                                      <button
                                        className="py-3 px-10 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
                                        onClick={() => setShowCalendar(false)}
                                      >
                                        Cancel
                                      </button>

                                      <button
                                        className="py-3 px-14 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75"
                                        onClick={() => {
                                          onStartDateSave();
                                        }}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </>
                              );
                            }}
                          />
                        </div>
                        {!startDate && !scheduleError && (
                          <div className="mt-2 text-sm">
                            Select a time between 8 AM - 7 PM WAT
                          </div>
                        )}
                        {scheduleError && (
                          <div className="text-red-500 text-sm mt-2">
                            {scheduleError}
                          </div>
                        )}
                      </div>
                    </ConfigProvider>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative mt-10 h-[510px]">
          <img className="h-[510px]" src={chatSvg} alt="" />
          {selectedTemplate && (
            <div className="absolute top-[53px] left-[12px] right-[12px] bottom-[50px]">
              <div className="flex items-end h-full overflow-hidden">
                <div className="p-2 w-full h-full overflow-auto">
                  <div className="flex gap-3 w-full h-auto">
                    <div
                      className="flex items-end"
                      style={{ height: "inherit" }}
                    >
                      <div className="h-[20px] w-[20px] rounded-full bg-blue-400 text-white flex justify-center items-center text-xs font-semibold">
                        S
                      </div>
                    </div>
                    <div className="h-full flex items-end w-[85%]">
                      <div className="bg-white text-xs p-2 w-full h-fit">
                        {selectedTemplate.carouselDetails?.length > 0 && (
                          <div className="py-2 px-6">
                            {selectedTemplate.carouselDetails.map(
                              (carousel, index) => {
                                return (
                                  <div className="mt-4" key={carousel.cardId}>
                                    <div className="text-gray-700 leading-6">
                                      {carousel.cardDescription}
                                    </div>
                                    <img
                                      className="h-[60px] w-[100px] mt-4 rounded"
                                      src={carousel.mediaUrl}
                                      alt=""
                                    />
                                    {carousel.suggestions?.length > 0 && (
                                      <div>
                                        {carousel.suggestions.map(
                                          (suggestion, index) => {
                                            return (
                                              <div
                                                key={suggestion.suggestionId}
                                              >
                                                {suggestion.suggestionType ===
                                                  "reply" && (
                                                  <div className="mt-4 border rounded-md text-xs py-2 px-5 text-gray-500 w-fit bg-white">
                                                    {suggestion.displayText}
                                                  </div>
                                                )}
                                                {suggestion.suggestionType ===
                                                  "dialer_action" && (
                                                  <div className="mt-4 border rounded-md text-xs py-2 px-5 text-gray-500 w-fit bg-white">
                                                    {suggestion.phoneNumber}
                                                  </div>
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <NotificationModal data={notificationData} />
      {showDeletionModal && (
        <DeletionModal
          step={2}
          navigate={props.navigate}
          closeModal={setShowDeletionModal}
        />
      )}
      {templateModalState && (
        <RcsTemplateSelector
          templates={templateList}
          selectedTemplate={
            selectedTemplate ?? templateList[0]
              ? JSON.parse(templateList[0].content)
              : null
          }
          close={(status) => closeTemplateSelector(status)}
        />
      )}
    </div>
  );
};

export default RcsStepOne;
