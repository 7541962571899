import { useEffect, useState } from "react";
import Loader from "../pages/settingsComponents/Engagements/whatsappTemplateTab/Loader";
import {
  authenticatePayment,
  submitOrderRequest,
} from "../pages/billing/apis/pesapalApis";
import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";
import { setLoaderAccountStatus } from "../redux-loader/loader-slice/loaderSlice";

const PesapalModal = ({ amount, type, planId, tierId, productId, units }) => {
  const [iframeUrl, setIframeUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  function submitOrder({ amount, token }) {
    let ob = {
      t: type,
      p: planId,
      tr: tierId,
      pr: productId,
      u: units,
    };
    let obs = JSON.stringify(ob);
    localStorage.setItem("pd", obs);
    try {
      submitOrderRequest({
        amount: amount,
        token: token,
      })
        .then((res) => {
          console.log(res);
          const { error, response = [] } = res;
          if (!error || error === "false") {
            setLoading(false);
            setIframeUrl(res.redirect_url);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })

        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  }

  function initiatePayment() {
    console.log("pesapal props", {
      amount,
      type,
      planId,
      tierId,
      productId,
      units,
    });
    setLoading(true);
    try {
      authenticatePayment()
        .then((res) => {
          const { error, response = [] } = res;
          if (!error || error === "false") {
            localStorage.setItem("pesapalPaymentToken", res.token);
            submitOrder({ amount: amount, token: res.token });
            dispatch(setLoaderAccountStatus({}));
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })

        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  }

  useEffect(() => {
    initiatePayment();
  }, []);

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 z-20 flex items-center justify-center">
      <div className="bg-white !h-[600px] !w-[875px] flex justify-center items-center">
        {" "}
        {loading ? (
          <Loader className="w-20 h-20" /> // size in tailwind units
        ) : (
          <iframe src={iframeUrl} width={"100%"} height={"100%"}></iframe>
        )}
      </div>
    </div>
  );
};

export default PesapalModal;
