const ArrowRight = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3592 7.52637L6.58584 3.7597C6.52387 3.69721 6.45013 3.64762 6.36889 3.61377C6.28765 3.57993 6.20052 3.5625 6.11251 3.5625C6.0245 3.5625 5.93736 3.57993 5.85612 3.61377C5.77488 3.64762 5.70115 3.69721 5.63917 3.7597C5.51501 3.88461 5.44531 4.05357 5.44531 4.2297C5.44531 4.40582 5.51501 4.57479 5.63917 4.6997L8.93917 8.03303L5.63917 11.333C5.51501 11.4579 5.44531 11.6269 5.44531 11.803C5.44531 11.9792 5.51501 12.1481 5.63917 12.273C5.70092 12.336 5.77455 12.3861 5.8558 12.4205C5.93705 12.4548 6.0243 12.4727 6.11251 12.473C6.20071 12.4727 6.28797 12.4548 6.36922 12.4205C6.45047 12.3861 6.5241 12.336 6.58584 12.273L10.3592 8.50637C10.4268 8.44394 10.4808 8.36817 10.5178 8.28384C10.5547 8.1995 10.5738 8.10843 10.5738 8.01637C10.5738 7.9243 10.5547 7.83323 10.5178 7.74889C10.4808 7.66456 10.4268 7.58879 10.3592 7.52637Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowRight;
