import { useState, useEffect } from "react";
import SelectedTemplate from "../selected-template";
import TemplatesList from "../templates-list";
import "./index.css";

const navbarTitles = [
  {
    text: "All templates",
    value: "ALL",
  },
  { text: "Text", value: "TEXT" },
  { text: "Image", value: "IMAGE" },
  { text: "Video", value: "VIDEO" },
  { text: "Document", value: "DOCUMENT" },
];

const SelectTemplate = (props: any) => {
  const {
    channelId = "",
    publishTemplate = "",
    showModal = false,
    setShowModal,
    setText,
    loading,
    templatesList,
    setTemplatesList,
    templatesObj,
  } = props;
  const [firstOpen, setFirstOpen] = useState(showModal);
  const [secondOpen, setSecondOpen] = useState(false);
  const [activeTemplate, setActivetemplate] = useState(0);
  const [activeNav, setActiveNav] = useState(0);

  const channelIdToSend = channelId;

  useEffect(() => {
    !firstOpen ? setShowModal(true) : null;
  }, [firstOpen]);

  const changeTemplatesCategory = (index: any) => {
    setActiveNav(index);
    const selectedType = navbarTitles[index].value;
    setTemplatesList(templatesObj[selectedType]);
  };

  const switchToScreen2 = () => {
    setFirstOpen(false);
    setSecondOpen(true);
  };

  const switchToScreen1 = () => {
    setFirstOpen(true);
    setSecondOpen(false);
  };

  return (
    <>
      {/* <div
        className="pn-custom-select-button"
        onClick={() => {
          setFirstOpen(true);
        }}
      >
        Select template
      </div> */}

      {firstOpen && (
        <TemplatesList
          firstOpen={firstOpen}
          setFirstOpen={setFirstOpen}
          setSecondOpen={setSecondOpen}
          activeTemplate={activeTemplate}
          setActivetemplate={setActivetemplate}
          switchScreens={switchToScreen2}
          activeNav={activeNav}
          setActiveNav={changeTemplatesCategory}
          template={templatesList}
          navbarTitles={navbarTitles}
          setText={setText}
          loading={loading}
        />
      )}
      {secondOpen && (
        <SelectedTemplate
          secondOpen={secondOpen}
          setSecondOpen={setSecondOpen}
          switchScreens={switchToScreen1}
          activeTemplate={activeTemplate}
          template={templatesList}
          channelId={channelIdToSend}
          publishTemplate={publishTemplate}
        />
      )}
    </>
  );
};

export default SelectTemplate;
