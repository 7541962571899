import { useContext, useState } from "react";
import CrossSmall from "../common/assets/icons/CrossSmall";
import Payment from "../common/assets/icons/Payment";
import { topupModalStates } from "../pages/billing/constants/fundModalTypes";
import PesapalModal from "./pesapalModal";
import { TenantContext as HomeTenantContext } from "../pages/home";
import { TenantContext as SmsTenantContext } from "../../../engagements/src/pages/create-sms/sms-parent";
import { TenantContext as SurveyTenantContext } from "../../../engagements/src/pages/create-survey/survey-parent/survey-parent";
import { TenantContext as WhatsappTenantContext } from "../../../engagements/src/pages/create-message/message-parent/message-parent";
import PaystackModal from "./paystack-payment";
import { getCurrenySymbol } from "../common/utils/currencySymbol";
import CurrencySymbol from "../common/components/currency-symbol/currency-symbol";

const MIN_FUND_ALLOWED = 1000;

const FundWalletModal = (props: any) => {
  const { setTopupModalState, handleClose } = props;
  const [fundAmount, setFundAmount] = useState(props.amount ?? 0);
  const [showPesapalModal, setShowPesapalModal] = useState(false);
  const showFundError = fundAmount && Number(fundAmount) < MIN_FUND_ALLOWED;
  const showFundSuccess = fundAmount && Number(fundAmount) >= MIN_FUND_ALLOWED;
  const homeTenantContext: any = useContext(HomeTenantContext);
  const smsTenantContext: any = useContext(SmsTenantContext);
  const surveyTenantContext: any = useContext(SurveyTenantContext);
  const whatsappTenantContext: any = useContext(WhatsappTenantContext);
  const tenantContext: any = homeTenantContext.id
    ? homeTenantContext
    : smsTenantContext.id
    ? smsTenantContext
    : surveyTenantContext.id
    ? surveyTenantContext
    : whatsappTenantContext;
  const country = tenantContext.country;
  const currency = tenantContext.currency;
  const currencySymbol = getCurrenySymbol(country, currency);

  const handleFundChange = (e) => {
    setFundAmount(e.target.value);
  };

  const handleContinue = () => {
    if (country?.toLowerCase() === "nigeria") {
      let paystackBtn: HTMLElement = document.getElementsByClassName(
        "paystack_btn"
      )[0] as HTMLElement;
      paystackBtn.click();
      handleClose();
    } else if (country?.toLowerCase() === "kenya") {
      setShowPesapalModal(true);
    }
    /*
      try {
        fundWallet(Number(fundAmount))
          .then((res) => {
            if (res && res !== "error") {
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    */
  };

  return (
    <div>
      <div
        className="fixed z-20 overflow-y-auto top-0 w-full left-0 rounded"
        id="modal"
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className=" inline-block align-center rounded bg-white shadow-xl transform transition-all p-8 sm:my-8 sm:align-middle sm:max-w-md sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="w-full flow-root">
              <div className="flex justify-center items-center float-left flex-shrink-0 w-16 bg-gray-100 rounded-full h-16">
                <Payment size={48} />
              </div>
              <div className="!text-right py-2">
                <button
                  onClick={() => {
                    setTopupModalState(topupModalStates.NEW_USER_MODAL);
                  }}
                  className="text-[#687992] font-bold text-lg cursor-pointer"
                >
                  <CrossSmall />
                </button>
              </div>
            </div>
            <div className="">
              <div
                className="flex rounded-xl pl-2 py-2"
                onClick={() => {
                  setTopupModalState(topupModalStates.FUND_MODAL);
                }}
              >
                <div className="flex gap-3 w-full">
                  <div className="text-left w-full">
                    <div className="text-gray-700 font-semibold text-base mb-8">
                      Fund wallet
                    </div>

                    <p className="text-gray-600 font-semibold text-sm my-2">
                      Amount
                    </p>
                    <div className="my-2">
                      <input
                        type="number"
                        className={`
                          border border-gray-200 py-3 px-4 w-full rounded placeholder-gray-400 ${
                            showFundError && props.amountValidation
                              ? "border-red-600 hover:border-red-600 active:border-red-600 focus-visible:border-red-600 focus-visible:outline-red-600"
                              : ""
                          } ${
                          showFundSuccess
                            ? "border-primary-green hover:border-primary-green active:border-primary-green focus-visible:border-primary-green focus-visible:outline-primary-green"
                            : ""
                        }`}
                        placeholder={`Enter amount to fund (${currencySymbol})`}
                        onChange={handleFundChange}
                        value={fundAmount}
                      />
                    </div>
                    {props.amountValidation && (
                      <p className="text-[#475467] text-sm">
                        Minimum funding amount is{" "}
                        <CurrencySymbol value={1000} /> and 7.5% VAT will be
                        deducted from the amount.
                      </p>
                    )}
                    <div className="flex justify-center w-full mt-10 text-white">
                      <button
                        className={
                          (showFundError && props.amountValidation) ||
                          !fundAmount
                            ? "rounded-lg text-sm font-semibold px-6 py-3 w-[350px]"
                            : "bg-blue-75 hover:bg-primary-green rounded-lg text-sm font-semibold px-6 py-3 w-[350px]"
                        }
                        onClick={handleContinue}
                        disabled={
                          (showFundError && props.amountValidation) ||
                          !fundAmount
                        }
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPesapalModal && (
        <PesapalModal
          amount={fundAmount}
          type="topup"
          planId=""
          tierId=""
          productId=""
          units=""
        />
      )}
      <PaystackModal
        amount={Number(fundAmount)}
        type="topup"
        planId=""
        tierId=""
        productId=""
        units=""
        success={handleClose}
      />
    </div>
  );
};

export default FundWalletModal;
