import { audienceApi } from '../services/common-api-service';
import * as Sentry from '@sentry/react';

interface EnrichmentField {
  label: string;
  value: string;
  id: number;
}

export const getDataSegments = (params?: any) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  // const {
  //     pageSize = 10,
  //     from = 0,
  //     startDate,
  //     endDate,
  //     segmentType = '',
  // } = params;
  let url = `/v1/segment`;
  // if (from) {
  //     url = url + `from=${from}`;
  // }
  // if (pageSize) {
  //     url = url + `&size=${pageSize}`;
  // }
  // if (segmentType) {
  //     url = url + `&segment_type=${segmentType}`;
  // }
  // if (startDate) {
  //     url = url + `&startDate=${startDate}`;
  // }
  // if (endDate) {
  //     url = url + `&endDate=${endDate}`;
  // }
  return audienceApi
    .post(url, {}, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getSegments = (params: any) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const {
    pageSize = 10,
    from = 0,
    startDate,
    endDate,
    segmentType = '',
    audienceSource = '',
  } = params;
  let url = `/v1/audience?from=${from}&size=${pageSize}`;
  if (segmentType) {
    url = url + `&audience_type=${segmentType}`;
  }
  if (startDate) {
    url = url + `&startDate=${startDate}`;
  }
  if (endDate) {
    url = url + `&endDate=${endDate}`;
  }
  if (audienceSource) {
    url = url + `&audience_source=${audienceSource}`;
  }

  return audienceApi
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const getAudienceById = (id: string) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/v1/audience/${id}`;

  return audienceApi
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    });
};

export const getEnrichmentFields = () => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/v1/segment/csv/enrichment-field`;

  return audienceApi
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    });
};

export const uploadSegmentCSV = (
  body: Blob,
  enrichmentFields: EnrichmentField[] = []
) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/v1/upload/file`;
  let data = new FormData();
  data.append('file', body);
  data.append('source_type', 'customer');

  if (enrichmentFields.length > 0) {
    const selectedEnrichmentFields = enrichmentFields.map(
      (field) => field.value
    );
    const enrichmentFieldsString = selectedEnrichmentFields.join(',');
    data.append('enrichment_fields', enrichmentFieldsString);
  }

  const headers = {
    Authorization: `Bearer ${loginAccessToken}`,
    'Content-Type': 'multipart/form-data',
  };
  return audienceApi
    .post(url, data, {
      headers: headers,
    })
    .then((response) => {
      try {
        let { segment } = response.data.data;
        enrichSegment(segment)
          .then((response) => {})
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
      return response;
    })
    .catch((err) => {
      err.response
        ? Sentry.captureMessage('Error', err.response)
        : Sentry.captureMessage('Error', err);
      console.log(err);
      return err;
    });
};

export const getFileUploadDetails = (segmentId) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/v1/data-spool/file-upload-logs?segment_id=${segmentId}`;
  const headers = {
    Authorization: `Bearer ${loginAccessToken}`,
  };
  return audienceApi
    .get(url, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      err.response
        ? Sentry.captureMessage('Error', err.response)
        : Sentry.captureMessage('Error', err);
      return err;
    });
};

export const postAudienceFromCampaign = (payload) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/v1/audience`;
  let audiencePayload = {
    audience_type: 'static',
    audience_name: payload.name,
    audience_description: payload.description,
    client_id: 'data_team',
    audience_size: payload.size,
    immediate: false,
    query: [],
    interests: { include: [] },
    audience_source: 'csv',
    segment_ids: [payload.segment],
    source_type: 'customer',
  };
  return audienceApi
    .post(url, audiencePayload, {
      headers: { Authorization: `bearer ${loginAccessToken}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
    });
};

export const enrichSegment = (segment_id) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken');
  const url = `/v1/upload/file/enrichment/${segment_id}`;
  return audienceApi
    .post(
      url,
      {},
      {
        headers: {
          Authorization: `bearer ${loginAccessToken}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      error.response
        ? Sentry.captureMessage('Error', error.response)
        : Sentry.captureMessage('Error', error);
      return error.response;
    });
};
