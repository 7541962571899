import { api } from "../../../../../common/services/common-api-service";
import * as Sentry from "@sentry/react";

export const submitEmailId = (idToken, payload) => {
  const url = `/email-id`;
  return api
    .post(url, payload, { headers: { Authorization: `bearer ${idToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
};

export const updateEmailId = (idToken, payload) => {
  const url = `/account?channel=sms`;
  return api
    .put(url, payload, { headers: { Authorization: `bearer ${idToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
};

export const updateEmailStatus = (idToken, id, usable = false) => {
  const url = `/account/${id}?channel=sms`;
  const payload = { usable }
  return api
    .put(url, payload, { headers: { Authorization: `bearer ${idToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
};

export const getEmailId = (params) => {
  const loginAccessToken = localStorage.getItem('loginAccessToken')
  const { from = 0, size = 10, status = '' } = params;

  let url = `/email-id`;
  if (status) {
    url = url + `?status=${status}`;
  }

  return api
    .get(url, { headers: { Authorization: `bearer ${loginAccessToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
};

export const deleteEmailId = (idToken, id) => {
  const url = `/account?channel=sms`;
  return api
    .delete(url, { headers: { Authorization: `bearer ${idToken}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      (error.response) ? Sentry.captureMessage("Error", error.response) : Sentry.captureMessage("Error", error);
    });
};
