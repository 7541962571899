import Close from "../../assets/icons/Close";

const SubscriptionPromptDialog = ({ handleClick }) => {
  console.log("Dialog");
  return (
    <div className="absolute w-full h-full flex justify-center items-center left-0 top-0 bg-dialog-overlay">
      <div className="p-8 shadow-md rounded bg-white w-1/2">
        <div className="flex justify-between">
          <span className="text-[#1D2939] text-lg font-semibold">
            Upgrade your plan!
          </span>{" "}
          <div className="cursor-pointer" onClick={() => handleClick(false)}>
            <Close />{" "}
          </div>
        </div>
        <div className="text-sm text-[#667085] mt-4">
          Upgrade to a higher plan on the Marketing Cloud and get access to more
          audience engagement features such as Rewards and Survey. Contact our
          team at support@terragonltd.com to learn more.
        </div>
        <div className="flex justify-end gap-3 mt-8">
          <a
            href="mailto:support@terragonltd.com"
            onClick={() => handleClick(false)}
            className="py-3 px-6 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
          >
            Contact support
          </a>
          <button
            onClick={() => handleClick(true)}
            className="py-3 px-24 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75 disabled:bg-gray-400 disabled:text-slate-500 disabled:border-slate-200"
          >
            Upgrade plan
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPromptDialog;
