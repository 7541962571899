import { useEffect, useState } from "react";
import { RatingStar } from "../../../../assets/icons/icons";

const Rating = (props: any) => {
  const [question, setQuestion] = useState(props.question);
  const [count, setCount] = useState([]);

  const onQuestionChange = (event) => {
    let q = question;
    q.q = event.target.value;
    props.setRating(q);
  };

  const onSubTextChange = (event) => {
    let q = question;
    q.s = event.target.value;
    props.setRating(q);
  };

  useEffect(() => {
    const { question, countprop } = props;
    setQuestion(question);
    let c = Array.from(Array(Number(countprop)).keys());
    setCount(c);
  }, [props]);

  return (
    <div>
      <div>
        <div className="border border-[#E4E7EC] rounded px-2 py-3 mb-1">
          <input
            className="w-full text-xl text-[#98A2B3] border-0 outline-0 font-semibold"
            onChange={(event) => {
              onQuestionChange(event);
            }}
            value={question?.q}
            placeholder="Main question here"
          />
        </div>
        <div className="border border-[#E4E7EC] rounded px-2 py-3 mb-1">
          <input
            className="w-full text-sm text-[#98A2B3] border-0 outline-0"
            onChange={(event) => {
              onSubTextChange(event);
            }}
            value={question?.s}
            placeholder="Subtext goes here (optional)"
          />
        </div>
      </div>
      <div>
        {count.length > 0 ? (
          <div className="flex mt-11 gap-6 flex-wrap">
            {count.map((ele, i) => {
              return (
                <span key={i}>
                  <img src={RatingStar} alt="" />
                </span>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Rating;
