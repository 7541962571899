import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { X } from "react-feather";
import "../common/components/toast/toast.css";
import Dot from "../common/assets/icons/Dot";
import NotificationIcon from "../common/assets/icons/Notification";
import {
  markNotificationAsRead,
  markAllNotificationsAsRead,
} from "../redux-loader/loader-slice/loaderSlice";

const NotificationListModal = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notificationList = [] } = props;

  const dateTimeFormatter = (timestamp) => {
    const date = new Date(timestamp);
    const mediumTime = new Intl.DateTimeFormat("en", {
      timeStyle: "medium",
      dateStyle: "medium",
    });
    return mediumTime.format(date);
  };

  const handleClick = (id, type = "", campaignType = "", index: Number) => {
    if (type === "whatsapp") {
      navigate(`/conversations`);
    }

    if (id) {
      if (campaignType?.toLowerCase() === "message") {
        navigate(`/engagements/${id}`);
      } else if (campaignType?.toLowerCase() === "survey") {
        navigate(`/engagements/surveys/${id}`);
      } else if (campaignType?.toLowerCase() === "reward") {
        navigate(`/engagements/rewards/${id}`);
      } else if (campaignType?.toLowerCase() === "email") {
        navigate(`/engagement-review/${id}`);
      }
    }
    dispatch(markNotificationAsRead({ index }));
    props?.handleClose();
  };

  const markAllRead = () => {
    dispatch(markAllNotificationsAsRead());
  };

  return (
    <div className="z-10 w-[410px] rounded" id="modal">
      <div className="flex items-center justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="inline-block rounded bg-white py-4 max-h-[600px] w-[410px] shadow-xl transform transition-all pl-6 pr-4 sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex w-full py-2">
            <div className="text-left w-full">
              <div className="flex justify-between items-start">
                <div className="text-gray-700 text-lg font-semibold mb-4">
                  Notifications
                </div>
                <div className="text-right justify-end mt-1">
                  <X
                    className="text-gray-600 cursor-pointer rounded-full hover:bg-gray-30 hover:p-0.5"
                    size={18}
                    onClick={props?.handleClose}
                  />
                </div>
              </div>
              <div className="max-h-[492px] pr-2 overflow-auto">
                {notificationList.map((notification, index) => {
                  const {
                    title,
                    text,
                    type,
                    campaignType,
                    dateCreated,
                    id,
                    isRead,
                  } = notification;
                  return (
                    <div
                      className="text-left w-full px-4 py-2 mb-2 cursor-pointer bg-gray-60 hover:bg-green-60 rounded-lg"
                      key={text}
                      onClick={() => handleClick(id, type, campaignType, index)}
                    >
                      <div className="flex items-start align-middle ml-1 w-full">
                        <div className="pt-2.5">
                          <Dot fill={isRead ? "#E4E7EC" : "#12B76A"} />
                        </div>
                        <div className="p-2 ml-2 -mt-1 w-11/12">
                          {title && (
                            <div className="font-semibold mb-1.5 capitalize">
                              {title}
                            </div>
                          )}
                          <div className="text-sm line-clamp-2">{text}</div>
                          <div className="text-xs mt-2 text-slate-500">
                            {dateTimeFormatter(dateCreated)}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {notificationList?.length === 0 && (
            <>
              <div className="-mt-12 scale-50 opacity-50">
                <NotificationIcon />
              </div>
              <div className="-mt-14 text-gray-75">
                You have no notifications right now
              </div>
            </>
          )}

          <div
            className={`flex items-end text-sm font-semibold ${
              notificationList?.length > 0 ? "justify-between" : "justify-end"
            }`}
          >
            {notificationList?.length > 0 && (
              <button
                className="text-blue-75 hover:text-primary-green my-4"
                onClick={markAllRead}
              >
                Mark all as read
              </button>
            )}
            <button
              className="text-blue-75 hover:text-primary-green my-4 mr-2"
              onClick={() => {
                navigate("/notifications");
                props?.handleClose();
              }}
            >
              See all notifications
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationListModal;
