import { useEffect, useState } from "react";
import { Plus, FilePlus } from "../../../../assets/icons/icons";
import { uploadLogo } from "../../../../common/services/survey-services";
import * as Sentry from "@sentry/react";

const ImageChoice = (props: any) => {
  const [question, setQuestion] = useState(props.question);

  useEffect(() => {
    const { question } = props;
    if (question?.property?.options) {
      setQuestion(question);
    } else {
      let q = question;
      let option = { imageURL: "", choice: "" };
      let property = { options: [option] };
      q.property = property;
      setQuestion(q);
      props.setRating(q);
    }
  }, []);

  const onQuestionChange = (event) => {
    let q = question;
    q.q = event.target.value;
    props.setRating(q);
  };

  const onSubTextChange = (event) => {
    let q = question;
    q.s = event.target.value;
    props.setRating(q);
  };

  const onChoiceChange = (event, index) => {
    if (question.property) {
      question.property.options[index].choice = event.target.value;
    } else {
      let property: any = {};
      property.options[index] = event.target.value;
      question.property = property;
    }
    props.setRating(question);
  };

  const deleteChoice = (index) => {
    let q = question;
    q.property.options.splice(index, 1);
    props.setRating(q);
  };

  const addChoice = () => {
    let q = question;
    let option = { imageURL: "", choice: "" };
    q.property.options.push(option);
    props.setRating(q);
  };

  const setImageURL = (url, index) => {
    if (question.property) {
      question.property.options[index].imageURL = url;
    }
    props.setRating(question);
  };

  const validateImage = (event: any, index) => {
    uploadImage(event.target.files[0], index);
  };

  const uploadImage = (file, index) => {
    try {
      uploadLogo(file)
        .then((res) => {
          const { error, response = {} } = res;
          if (!error || error === "false") {
            setImageURL(response?.url, index);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  return (
    <div>
      <div>
        <div>
          <div className="border border-[#E4E7EC] rounded px-2 py-3 mb-1">
            <input
              onChange={(event) => {
                onQuestionChange(event);
              }}
              value={question?.q}
              className="w-full text-xl text-[#98A2B3] border-0 outline-0 font-semibold"
              placeholder="Main question here"
            />
          </div>
          <div className="border border-[#E4E7EC] rounded px-2 py-3 mb-1">
            <input
              onChange={(event) => {
                onSubTextChange(event);
              }}
              value={question?.s}
              className="w-full text-sm text-[#98A2B3] border-0 outline-0"
              placeholder="Subtext goes here (optional)"
            />
          </div>
        </div>
        <div className="flex mt-10 gap-5 flex-wrap">
          {question?.property?.options?.map((c, i) => {
            let index = i + 1;
            return (
              <div key={i}>
                <div className="h-[210px] w-[250px] bg-[#F9FAFB] rounded p-2">
                  {question.property.options[i].imageURL === "" ? (
                    <div className="h-[146px] bg-[#E4E7EC] rounded flex items-center justify-center">
                      <div>
                        <div className="flex justify-center">
                          <img src={FilePlus} alt="" />
                        </div>
                        <label
                          htmlFor="logo-upload"
                          className="text-sm font-semibold text-blue-75 cursor-pointer underline"
                        >
                          click to upload
                          <input
                            id="logo-upload"
                            type="file"
                            accept={".png, .jpg, .jpeg"}
                            onChange={(event) => validateImage(event, i)}
                            className="hidden"
                          />
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div className="h-[146px] w-full rounded">
                      <img
                        className="rounded w-full h-full"
                        src={
                          question.property.options[i].url
                            ? question.property.options[i].url
                            : question.property.options[i].imageURL
                        }
                        alt=""
                      />
                    </div>
                  )}
                  <div className="flex h-[56px] py-2 items-center gap-2">
                    <div className="bg-white h-[20px] w-[20px] rounded-full border"></div>
                    <div className="w-[206px] h-[40px] border rounded p-1 bg-white">
                      <input
                        className="outline-0 border-0 h-full w-full text-sm"
                        placeholder={"Choice " + (i + 1)}
                        onChange={(event) => {
                          onChoiceChange(event, i);
                        }}
                        value={
                          question.property.options[i].optionText
                            ? question.property.options[i].optionText
                            : question.property.options[i].choice
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="w-[250px] h-[154px] bg-[#F9FAFB] flex justify-center items-center">
            <div>
              <div className="flex items-center justify-center">
                <img src={Plus} alt="" />
              </div>
              <div
                className="text-sm font-semibold cursor-pointer text-[#475467]"
                onClick={() => addChoice()}
              >
                Add image
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageChoice;
