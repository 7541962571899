import { Notify } from '../../../../marketing-cloud-main/src/common/components/Notify/Notify';

export const validateCSV = (str, delim = ',') => {
  const splitCsvLine = (line) => {
    const regex = new RegExp(
      `${delim}(?=(?:(?:[^"]*"){2})*[^"]*$)` // Split on delimiter but ignore commas inside quotes
    );
    return line.split(regex).map((value) => value.replace(/^"|"$/g, '')); // Remove leading and trailing quotes
  };

  // Process headers and rows
  const headers = splitCsvLine(str.slice(0, str.indexOf('\n')));
  const rows = str.slice(str.indexOf('\n') + 1).split('\n');

  // Remove last element if it's empty
  if (rows[rows.length - 1] === '') {
    rows.pop();
  }

  // Validation: Ensure all rows have a valid number of columns
  for (let index = 0; index < rows.length; index++) {
    const row = splitCsvLine(rows[index]);
    if (row.length > headers.length) {
      Notify({
        type: 'error',
        heading: 'Validation Error',
        message: `Row ${index + 1} has a mismatch in number of columns.`,
      });

      return false;
    }
  }

  return true;
};
